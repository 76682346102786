import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import web3 from "web3";
import {
    OuterWrapper,
    Wrapper,
    ImageWrapper,
    EditionSize,
    DetailsWrapper,
    CurrentSaleType,
    CurrentPriceValue,
    Title,
    Pricing,
    PriceLabel,
    CollectionSize,
    Artist,
    Image,
    Video,
    ButtonWrapper,

    Row
} from './ArtworkCard.styles'

import { removeLeadingSlash, getFileType } from '../../../../helpers'
import { roundDataWei, convert_WEI_ETH, getNonFungibleIndex, getNonFungibleBaseType } from '../../../../blockchain/blockchainUtils'
import API from '../../../../API'
import { VALID_FILE_TYPES } from '../../../../config.js'
import Button from '../../../Button'
import DotMenu from '../../../DotMenu'
import GridItem from '../../../GridItem'
import { useImportCollectionStatus } from '../../../../contexts/CollectionStatusContext'
import { useNavigate } from 'react-router-dom'
import { useWeb3 } from '../../../../contexts/Web3Context'

const ImageArtwork = ({ artwork, title }) => {
    return (
        <ImageWrapper>
            <Image src={artwork} alt={title} />
        </ImageWrapper>
    )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (

        <Video
            ref={videoRef}
            src={artwork}
            width="100%"
            autoPlay={false}
            controls={false}
            onMouseOver={(event) => videoRef.current.play()}
            onMouseOut={(event) => videoRef.current.pause()}
            loop={true}
            muted
            playsInline
        />
    )
}

const Artwork = ({ artwork, title }) => {
    const fileType = getFileType(artwork.image)

    if (fileType === 'video') {
        return <VideoArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
    }
    else {
        return <ImageArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
    }
}


const PricingData = ({ isPending, isLive, artwork, pricingData }) => {

    const getPriceLabel = () => {
        if (pricingData.isAuction) {
            if (isLive || isPending)
                return convert_WEI_ETH(pricingData.bid.auctionHigh) > 0 ? 'High' : 'Minimum Bid'
            else return 'Paid'
        } else {
            if (isLive || isPending)
                return 'Price'
            else return 'Paid'
        }
    }
    const price = roundDataWei(pricingData.bid.auctionHigh, 4) > 0 ? roundDataWei(pricingData.bid.auctionHigh, 4) : roundDataWei(pricingData.price, 4)
    const priceLabel = getPriceLabel()
    const myBid = roundDataWei(pricingData.bid.amount, 4)

    return (
        <Pricing

            gridTemplateAreas={pricingData.isAuction ? "'high low''hLbl lLbl'" : "'high high''hLbl hLbl'"}
            isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
            <GridItem alignItems="left" gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
            <GridItem alignItems="left" gridArea="hLbl"><PriceLabel>{priceLabel}</PriceLabel></GridItem>
            {isLive &&
                <>
                    <GridItem color={price === myBid ? '#23ad00' : '#ad0000'} gridArea="low"><CurrentPriceValue right>{myBid}Ξ</CurrentPriceValue></GridItem>
                    <GridItem gridArea="lLbl"><PriceLabel right>My Bid</PriceLabel></GridItem>
                </>
            }

        </Pricing>
    )
    // if (isLive || isPending) {

    //     if (pricingData.isAuction && parseInt(artwork.bid.auctionHigh) !== 0) {
    //         const price = pricingData.isAuction ? web3.utils.fromWei(web3.utils.toBN(artwork.bid.auctionHigh))
    //             : web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    //         const priceLabel = pricingData.isAuction ? 'High' : 'Price'
    //         const myBid = web3.utils.fromWei(web3.utils.toBN(pricingData.bid.amount))
    //         // Math.min(...artwork.bids.map(b => parseInt(b))) ?
    //         //     web3.utils.fromWei(web3.utils.toBN(Math.min(...artwork.bids.map(b => parseInt(b))))) :
    //         //     web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    //         return (
    //             <Pricing

    //                 gridTemplateAreas={pricingData.isAuction ? "'high low''hLbl lLbl'" : "'high high''hLbl hLbl'"}
    //                 isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
    //                 <GridItem alignItems="left" gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
    //                 <GridItem alignItems="left" gridArea="hLbl"><PriceLabel>{priceLabel}</PriceLabel></GridItem>
    //                 {isLive &&
    //                     <>
    //                         <GridItem color={price === myBid ? '#23ad00' : '#ad0000'} gridArea="low"><CurrentPriceValue right>{myBid}Ξ</CurrentPriceValue></GridItem>
    //                         <GridItem gridArea="lLbl"><PriceLabel right>My Bid</PriceLabel></GridItem>
    //                     </>
    //                 }

    //             </Pricing>
    //         )
    //     } else {
    //         const price = web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    //         const priceLabel = pricingData.isAuction ? 'Minimum Bid' : 'Price'
    //         return (
    //             <Pricing
    //                 gridTemplateAreas={"'high high''hLbl hLbl'"}
    //                 isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
    //                 <GridItem alignItems="left" gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
    //                 <GridItem alignItems="left" gridArea="hLbl"><PriceLabel>{priceLabel}</PriceLabel></GridItem>

    //             </Pricing>
    //         )
    //     }

    // } else {
    //     const price = pricingData.isAuction ?
    //         web3.utils.fromWei(web3.utils.toBN(artwork.bid.amount))
    //         //    web3.utils.fromWei(web3.utils.toBN(artwork.bids[parseInt(pricingData.edition)]))
    //         : web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    //     const priceLabel = pricingData.isAuction ? 'Paid' : 'Sold'
    //     return (
    //         <Pricing
    //             gridTemplateAreas={pricingData.isAuction ? "'high low''hLbl lLbl'" : "'high high''hLbl hLbl'"}
    //             isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
    //             <GridItem alignItems="left" gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
    //             <GridItem alignItems="left" gridArea="hLbl"><PriceLabel>{priceLabel}</PriceLabel></GridItem>
    //         </Pricing>
    //     )
    // }

}

const Edition = ({ artwork, isLive, contractData, isPending }) => {
    if (artwork.supply === undefined) {
        return (<EditionSize>{artwork.max_mint} Edition{artwork.max_mint === '1' ? '' : 's'}</EditionSize>)
    }
    else if (artwork.supply !== undefined && isPending) {
        return (<EditionSize>{artwork.supply} Editions</EditionSize>)
    }
    else {
        //else if (artwork.supply !== undefined && isLive) {
        const r = artwork.primaryMarketData.isAuction ? artwork.primaryMarketData.bids.filter(it => parseInt(it) !== 0).length : artwork.supply - artwork.currentSupply;
        return (<EditionSize>{r} {artwork.primaryMarketData.isAuction || Number(artwork.primaryMarketData.salesCloseTime) <= Number(dayjs().unix()) ? 'Minted' : 'Remaining'}</EditionSize>)
        // } else {
        //     return (<EditionSize>{artwork.currentSupply !== 0 ? `${artwork.currentSupply} Minted` : ''} </EditionSize>)
    }

}


const ArtworkCard = ({ pricingData, setClaimNFTButtonDisabled, setSelectedArtwork, fetchBids, artwork, onClick, itemContractData }) => {
    const { handleClaimNFT, itemsSubmitting, marketplaceClaim } = useWeb3()
    console.log(artwork)
    const navigate = useNavigate()
    const buttonRef = useRef(null)
    const dotMenuRef = useRef(null)
    const dropDownMenuRef = useRef(null)
    const [contractData, setContractData] = useState(null)
    const [hoverActive, setHoverActive] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    useEffect(() => {
        if (itemsSubmitting.find(item => (item.tokenId === artwork.tokenId && item.contractAddress === artwork.contractAddress)))
            setIsDisabled(true)
        else
            setIsDisabled(false)
    }, [itemsSubmitting])
    const [isPending, setIsPending] = useState(dayjs().unix() < parseInt(pricingData.salesOpenTime));
    const [isLive, setIsLive] = useState(dayjs().unix() < parseInt(pricingData.salesCloseTime) && dayjs().unix() > parseInt(pricingData.salesOpenTime))

    const getNavDestination = () => {
        if (pricingData.constructor.name === 'PrimaryMarketData')
            return 'collections'
        else
            return 'marketplace'

    }

    const viewArtworkOption = {
        value: 1, innerText: 'View Artwork',
        onClick: () => {
            navigate(`/${getNavDestination()}/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`)
        }
    }
    const options = pricingData.isClaimable && !pricingData.isClaimed ? [
        viewArtworkOption,
        {
            value: 2, innerText: 'Claim',
            onClick: async () => {
                // setButtonDisabled(true)
                setClaimNFTButtonDisabled(true)
                const res = pricingData.itemId !== undefined
                    ? await marketplaceClaim(pricingData.itemId, fetchBids)
                    : await handleClaimNFT(artwork.contractAddress, artwork.tokenId, fetchBids)
                setClaimNFTButtonDisabled(false)
            },
            isDisabled: isDisabled
        }
    ] : [viewArtworkOption
    ]
    useEffect(() => {
        setIsLive(dayjs().unix() < parseInt(pricingData.salesCloseTime) && dayjs().unix() > parseInt(pricingData.salesOpenTime))
        setIsPending(dayjs().unix() < parseInt(pricingData.salesOpenTime))
    }, [artwork])
    useEffect(() => {
        if (itemContractData) {
            setContractData({ ...itemContractData })
        }
    }, [itemContractData])
    const mouseOver = (e) => {
        e.stopPropagation();
        if (setHoverActive)
            setHoverActive(true)
        setShowMenu(true)
    }
    const mouseOut = (e) => {
        e.stopPropagation();
        if (setHoverActive)
            setHoverActive(false)
        setShowMenu(false)
    }
    if (pricingData.price === undefined) return <OuterWrapper></OuterWrapper>
    else
        return (
            <OuterWrapper>
                <Wrapper
                    onMouseEnter={(e) => mouseOver(e)}
                    onMouseLeave={(e) => mouseOut(e)}
                    cursor={'pointer'}
                    onClick={
                        typeof (onClick) === 'function' ?
                            onClick
                            : (e) => {
                                if (e.target !== buttonRef.current &&
                                    e.target !== dotMenuRef.current &&
                                    e.target !== dropDownMenuRef.current &&
                                    e.target.tagName !== 'LI' &&
                                    e.target.tagName !== 'UL' &&
                                    !e.target.className.includes("buttonText")) {
                                    pricingData.constructor.name === 'PrimaryMarketData' ?
                                        navigate(`/collections/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`) :
                                        navigate(`/marketplace/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`)
                                }
                            }}>
                    <DotMenu
                        dotMenuRef={dotMenuRef}
                        dropDownMenuRef={dropDownMenuRef}
                        setSelectedArtwork={setSelectedArtwork}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                        options={options}
                        hoverActive={hoverActive}
                        artwork={artwork}
                    />
                    <Artwork artwork={artwork} title={artwork.artwork_title} />
                    <DetailsWrapper isPending={isPending}>
                        <div style={{ display: 'flex' }}><Title>{artwork.name}</Title><Title>#{parseInt(artwork.edition)}</Title></div>
                        <Artist>{artwork.artist_name}</Artist>
                        <PricingData isPending={isPending} isLive={isLive} artwork={artwork} contractData={contractData} pricingData={pricingData} />
                        <Row>
                            {pricingData.isClaimed === true && <CurrentSaleType>Won & Claimed</CurrentSaleType>}
                            {(isLive || isPending) && <CurrentSaleType>{pricingData.isAuction ? 'Auction' : 'Fixed Price'}</CurrentSaleType>}
                            <Edition isPending={isPending} isLive={isLive} artwork={artwork} contractData={contractData} />
                        </Row>
                    </DetailsWrapper>

                </Wrapper>
            </OuterWrapper>
        )
}

export default ArtworkCard
