import React, { useState, useEffect } from "react";

import { Wrapper, ContentWrapper, SectionWrapper, EmailSignupWrapper, InputWrapperButton, InputWrapper } from "./LandingPage.styles";
import dayjs from 'dayjs'
import ArtworkCard from "../ArtworkCardOLD";
import InputField from '../InputField'
import ArtworkCardContainer from "../ArtworkCardContainer";
import CollectionTile from "../CollectionTile";
import HomeIntro from "../HomeIntro";
import HomeFeaturedArtists from "../HomeFeaturedArtists";
import HomeRecentDrops from "../HomeRecentDrops";
import Footer from "../Footer";
import HomeBanner from "../HomeBanner";
import HomeListings from "../HomeListings";
import HomePopularArt from "../HomePopularArt";
import LoadingWheel from "../LoadingWheel";
import { useArtworkFetch } from "../../hooks/useArtworkFetch";
import EmailIcon from '../../images/emailIcon.svg'
import Button from "../Button";
import HomeDropDisplay from "../HomeDropDisplay";
import axios from 'axios'
import API from '../../API'
import { getPopularArtworks, fetchLiveCollections, getCollectionItemsAndData, getDeployedCollections, getCollectionItems, getItemData, getCollectionOpenAndClose } from '../../blockchain/functionsUiollyNew'

const LandingPage = () => {
  const [drops, setDrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [mostRecentDrop, setMostRecentDrop] = useState({ items: [], collection_title: '' });
  const [featuredArtwork, setFeaturedArtwork] = useState(null);
  const [featuredArtists, setFeaturedArtists] = useState([]);
  const [popularMarketItems, setPopularMarketItems] = useState([])

  const fetchContent = async () => {
    setLoading(true)
    console.log("HERE")
    try {
      const live_c = await API.fetchLiveDrops()//fetchLiveCollections()
      const most_recent = live_c.sort((a, b) => a.end_date - b.end_date)[0] || null
      if (most_recent) {
        setDrops(prev => ([...prev, ...live_c.filter(c => c.collection_id !== most_recent.collection_id)]))
        const _most_recent = await getCollectionItemsAndData(most_recent.contract_address)
        setMostRecentDrop({ ...most_recent, ..._most_recent })
      }
      let recentDrops = 2;
      const numToFetch = (recentDrops + 1) - live_c.length
      if (numToFetch > 0) {
        setLoading(true)
        const drops_notlive = await API.fetchNotLiveDrops(numToFetch)

        if (drops_notlive.length) {
          if (numToFetch === 3) {
            const recent = drops_notlive.length > 1 ? drops_notlive.pop() : drops_notlive[0]
            const most_recentNL = await getCollectionItemsAndData(recent.contract_address)
            setMostRecentDrop({ ...recent, ...most_recentNL })

          }
          setDrops(prev => ([...prev, ...drops_notlive]))
          const rand_indx = Math.floor(Math.random() * drops_notlive.length)
          var rand_artwork = drops_notlive[rand_indx].items[Math.floor(Math.random() * drops_notlive[rand_indx].items.length)];
          setFeaturedArtwork(rand_artwork)
        } else {
          if (live_c.length) {
            const remaining_drops = live_c.filter(d => d.collection_id !== most_recent.collection_id)
            const rand_indx = Math.floor(Math.random() * remaining_drops.length)
            var rand_artwork = remaining_drops.length ? remaining_drops[rand_indx].items[Math.floor(Math.random() * remaining_drops[rand_indx].items.length)]
              : live_c[0].items[Math.floor(Math.random() * live_c[0].items.length)]

            setFeaturedArtwork(rand_artwork)
          }
        }
      } else {
        const remaining_drops = live_c.filter(d => d.id !== most_recent.id)
        const rand_indx = Math.floor(Math.random() * remaining_drops.length)
        var rand_artwork = remaining_drops.length ? remaining_drops[rand_indx].items[Math.floor(Math.random() * remaining_drops[rand_indx].items.length)]
          : live_c[0].items[Math.floor(Math.random() * live_c[0].items.length)]

        setFeaturedArtwork(rand_artwork)
      }

      setLoading(false)
    } catch (error) {
      console.log(error, "E")
    }
  }
  useEffect(() => {
    const fetchJSON = async (uri) => {
      let res = await axios({
        method: 'get',
        url: `${uri}`
      });
      return res.data
    }
    fetchContent()
    setLoading(true)
    const getPopularMarketItems = async () => {
      try {
        const artworks = await getPopularArtworks()
        setPopularMarketItems(artworks)
      } catch (error) {
        
      }
    }
    getPopularMarketItems()
    
    API.getFeaturedArtists().then((res2) => {
      if (res2 !== null)
        setFeaturedArtists(res2)
    })
  }, [])
  const onClickArtist = (artistID) => (event) => {
    event.preventDefault();
  }
  console.log("XXXXXXXXXXXXXXXX",drops, mostRecentDrop, popularMarketItems )

  if (loading) {
    return (
      <Wrapper>
        <LoadingWheel />
      </Wrapper>
    )
  }
  else return (
    <>
      <ContentWrapper>
        <Wrapper>
          {mostRecentDrop.items.length > 0 && <HomeDropDisplay collection={mostRecentDrop} />}
        </Wrapper>
        <Wrapper padding="0 10%" margin="50px 0 0" >
          {featuredArtwork !== null && <HomeIntro artwork={featuredArtwork} />}
        </Wrapper>
        {featuredArtists.length !== 0 &&
          <Wrapper margin="50px 0 0" padding="0 10%"><HomeFeaturedArtists artists={featuredArtists} /></Wrapper>
        }
        {drops.length !== 0 && <Wrapper margin="50px 0 0" padding="0 10%"><HomeRecentDrops collections={drops} /></Wrapper>}
        {popularMarketItems.length !== 0 && <Wrapper margin="50px 0 0" padding="0 10%"><HomePopularArt artworks={popularMarketItems} /></Wrapper>}
        <Wrapper margin="50px 0 0" padding="0 10%" textalign="center">
          <h3>Looking to be a part of Uiolly?</h3>
          <h4 style={{ textAlign: 'center' }}>Mint Your Own Nfts. Open your own collection. Become a partner.</h4>
          <EmailSignupWrapper>
            <p style={{ fontSize: "15px", color: 'var(--secondary)' }}>Sign up to learn more.</p>
            <InputWrapper>
              <InputField
                type="email"
                name="email"
                value={email}
                // boxShadow="0 0 5px 1px inset var(--grey)"
                padding="0 50px 0 20px"
                placeholder="Email Address"
                
                onChange={(e) => { setEmail(e.target.value) }}
              /><InputWrapperButton><img src={EmailIcon} /></InputWrapperButton>
            </InputWrapper>
          </EmailSignupWrapper>

        </Wrapper>

      </ContentWrapper>
      <Footer></Footer>
    </>
  );
};

export default LandingPage;
