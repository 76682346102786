import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
position: relative;
width: 100%;
min-height: calc(100vh - 60px);
top: 60px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
background: var(--lighterGrey);
/* background-image: ${props => props.bgdArt ? `url(${props.bgdArt})` : 'x'};
background-position: center;
background-repeat: no-repeat;
background-size: cover; */
`;

export const InnerWrapper = styled.div`
    min-width: 400px;`;

export const Content = styled.div`
    padding: 50px;
    min-width: 400px;
    background: var(--background);
    display: flex;
    position: relative;
    width: 500px;
    height: min-content;
    /* justify-content: space-between; */
    flex-direction: column;
    display: grid;
    grid-template-columns: 1fr;
    border: var(--defOutline);
    grid-auto-rows: min-content;
    /* box-shadow: 0 0px 5px 1px rgba(0,0,0,0.2); */
    margin: 50px;
    row-gap: 40px;
    min-height: 779.5px;
    grid-template-rows: ${props => props.ofType === 'SIGNUP' ? 'repeat(6,min-content) 1fr' : 'repeat(3,min-content) 1fr min-content min-content'};
`;

export const QuestionWrapper = styled.div`
position: relative;
display: grid;
grid-template-areas: ${props => props.gridTemplateAreas ? props.gridTemplateAreas : ''};
grid-template-columns: ${props => props.cols ? props.cols : '1fr'};
grid-template-rows: ${props => props.rows ? props.rows : ''};
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
justify-content: space-between;
align-items: end;
margin-top: ${props => props.marginTop ? props.marginTop : '0'};
width: 100%;
grid-column-gap: 10px;
`;

export const Title = styled.h1`
text-align: center;
font-size: 1.7rem;
text-transform: uppercase;
`;

export const Text = styled.div`

`;

export const Small = styled.div`
font-size: 10px;
grid-area: small;
margin-top: 0.2rem;
`;

export const ImageWrapper = styled.div`
display: flex;
justify-content: center;
img{
    width: 40%;
}
`;

export const TermsAndConditions = styled.div`
font-size: 10px;
`;

export const Error = styled.div`
font-size: 10px;
max-width: 100%;
user-select: none;
`;

export const LoginSignUpSwitch = styled(Link)`
all: unset;
user-select: none;
cursor: pointer;
/* color: ${props => props.isCurrent ? 'var(--fontColor)' : 'var(--secondary)'}; */
text-align: left;
text-decoration: ${props => props.isCurrent ? 'underline' : 'none'};
text-transform: uppercase;
`;

export const SwitchContainer = styled.div`
display: flex;
font-size: 16px;
margin-top: 1rem;
justify-content: space-around;
width: 100%;
`;

export const LoadingWheel = styled.div`
    border: 10px solid var(--grey);
    border-top: 10px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;