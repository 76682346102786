import styled from 'styled-components'

import { NavLink as Link } from 'react-router-dom'
export const Wrapper = styled.div`
height: 60px;
width: 100%;
position: relative;
top: 0;
z-index: 1;
background: var(--background);
padding: 0 60px;
display: flex;
align-items: center;
justify-content: space-around;

width: 100%;



`;

export const Title = styled.div`
grid-area: siteTitle;
`;

export const StepTitle = styled.h2`

`;

export const Grid = styled.div`
width: 100%;
max-width: 1000px;
display: grid;
grid-template-areas: 'back step next';
grid-template-columns: min-content 1fr min-content;
`;


export const Next = styled.div`
grid-area: next;
display: flex;
align-items: center;
`;

export const Back = styled.div`
grid-area: back;
display: flex;
align-items: center;
`;

export const Content = styled.div`
    display: flex;
    grid-area: step;
    flex-direction: column;

    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
`;


export const Step = styled.div`
    
    letter-spacing: 0.02rem;
`;


export const Description = styled.div`
    color: var(--fontColor);
    font-weight: 500;
`;

export const Button =  styled(Link)`
    all: unset;
    cursor: pointer;
    right: 30px;
    position: absolute;
`;