import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
export const Wrapper = styled.div`
width: min-content;
height: min-content;
padding: 5px 0;
display: flex;
align-items: center;
position: relative;
top: 60px;
display: grid;
grid-template-areas: 'image details''address editions''stats stats';
grid-column-gap: 30px;
grid-row-gap: 10px;
grid-template-rows: min-content min-content min-content;
grid-template-columns: 1fr minmax(400px, 50%);

@media screen and (max-width: 700px){
    grid-template-columns: 1fr;
    width: 90%;
    grid-template-rows: min-content;
    grid-auto-flow: row;
    grid-template-areas: 'image''address''details''editions''stats''stats';

}
`;

export const AdditionalStats = styled.div`
display: flex;
justify-content: space-between;
width: 100%;

h1{
    margin-top: 0;
}
@media screen and (max-width: 700px){
    flex-direction: column;

}
`;
export const OuterWrapper = styled.div`
width: 100%;
min-height: calc( 100vh - 60px);
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export const InnerWrapper = styled.div`
width: 100%;
height: min-content;
display: flex;
justify-content: center;
position: relative;
align-items: flex-start;
`;

export const ColumnWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const LeftWrapper = styled.div`
max-width: 50%;
display: flex;
flex-direction: column;
position: relative;
align-items: flex-end;
justify-content: center;
`;

export const LeftWrapperContent = styled.div`
max-width: 500px;
width: 500px;
height: 500px;
@media screen and (max-width: 700px){
    width: 90vw;
    height: 90vw;

}
`;

export const RightWrapper = styled.div`
flex-grow: 1;
display: flex;
position: relative;
align-self: end;
flex-direction: column;
align-items: flex-start;
justify-content: flex-end;
`;

export const InfoOuterWrapper = styled.div`
display: flex;
position: relative;
margin-left: -2em;
`;

export const InfoWrapper = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;

    align-items: flex-end;
`;

export const ImageWrapper = styled.div`
    width: var(--artworkCardImageSize);
    height: var(--artworkCardImageSize);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
`;

export const Image = styled.img`
    object-fit: contain;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    border-radius: 5px;
`;

export const Editions = styled.div`
font-size: 10px;
font-weight: 500;
`;


export const Artist = styled(Link)`
all: unset;
user-select: none;
font-size: inherit;
font-weight: inherit;
`;

export const Collection = styled(Link)`
    all: unset;
    user-select: none;
    font-size: 10px;
    font-weight: 500;
`;


export const Description = styled.div`
    font-size: 12px;
    display: flex;
    align-items: start;
    width: 100%;
`;



export const CurrentAsk = styled.div``;

export const LastSold = styled.div``;

export const RowWrapper = styled.div`
align-items: end;
margin-top: 0.9rem;
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: auto auto;
grid-template-areas: 
    'CurrentBidValue LastSoldValue'
    'CurrentBidLabel LastSoldLabel';
`;

export const CurrentBidValue = styled.div`
    grid-area: CurrentBidValue;
    font-size: 1.8em;
    font-weight: 600;
    text-align: left;
    line-height: 1.9rem;

`;

export const CurrentBidLabel = styled.div`
    grid-area: CurrentBidLabel;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
`;

export const LastSoldValue = styled.div`
    grid-area: LastSoldValue;
    font-size: 1.8em;
    font-weight: 600;
    line-height: 1.9rem;
    text-align: right;
`;

export const LastSoldLabel = styled.div`
    grid-area: LastSoldLabel;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
`;

export const GlobalTitle = styled.div`
color: var(--lightGrey);
grid-area: title;
writing-mode: vertical-lr;
transform: rotate(-180deg);
font-size: 2em;
text-transform: uppercase;
font-weight: 700;
`;

export const DetailsWrapper = styled.div`
display: grid;
align-self: end;
grid-row-gap: 5px;
/* grid-template-columns: ${props => props.cols ? props.cols : ''};
grid-template-areas: ${props => props.gridTemplateAreas ? props.gridTemplateAreas : ''}; */
max-width: 400px;
grid-template-columns: 2fr 1fr 2fr;
width: 100%;
grid-auto-rows: min-content;
/* >div:nth-of-type(2n+1){
    display: flex;
    align-items: start;
} */
@media screen and (max-width: 700px){
    /* grid-row: 2;
    grid-column : 1 / span 1;
     */
    grid-area: details;
    max-width: 100%;

}
`;

export const GridItem = styled.div`
display: flex;
white-space: ${props => props.noWrap ? 'nowrap' : ''};
grid-area: ${props => props.gridArea ? props.gridArea : ''};
text-align: ${props => props.align ? props.align : 'left'};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
align-items: ${props => props.alignItems ? props.alignItems : 'flex-end'};
color: ${props => props.color ? props.color : 'var(--fontColor)'};
font-size: ${props => props.fontSize ? props.fontSize : '12px'};
font-weight: ${props => props.fontWeight ? props.fontWeight : '300'};
border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
`;

export const PricingWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-column: 1 / span 3;
grid-row: 5;

`;

export const ContractAddress = styled.a`
all: unset;
cursor: pointer;
color: var(--lightGrey);
font-size: 10px;
font-weight: 700;
text-transform: uppercase;
display: flex;
width: 100%;
`;