import React, { useState, useEffect, useRef } from 'react'

import {
    Wrapper,
    Frame,
    Content,
    ThumbnailBar,
    ArtworkContainer,
    ArtistInfo,
    NavLink,
    ThumbnailBarWrapper,
    FeaturedArtInfo,
    Bio,
    Artist,
    FrameImageWrapper,
    Container,
    Video
} from './HomeRecentDrops.styles'

import { getFileType } from '../../helpers'
import { useNavigate } from 'react-router-dom'
const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <FrameImageWrapper>
            <Video
                ref={videoRef}
                src={artwork}
                width="100%"
                autoPlay={false}
                controls={false}
                onMouseOver={(event) => videoRef.current.play()}
                onMouseOut={(event) => videoRef.current.pause()}
                loop={true}
                muted
                playsInline
            />
        </FrameImageWrapper>
    )
}


const ImageArtwork = ({ artwork, title }) => {
    return (
        <FrameImageWrapper>
            <img src={artwork} alt={title} />
        </FrameImageWrapper>
    )
}

const Artwork = ({ artwork }) => {
    const fileType = getFileType(artwork.image)

    if (fileType === 'video') {
        return <VideoArtwork artwork={artwork.image || artwork.cover_path} title={artwork.artwork_title} />
    }
    else {
        return <ImageArtwork artwork={artwork.image || artwork.cover_path} title={artwork.artwork_title} />
    }
}

const HomeRecentDrops = ({ collections }) => { //artistName, artistID, picture, description
    const navigate = useNavigate()
    console.log("recent drops", collections)
    return (
        <Wrapper>
            <h3>Latest Drops!</h3>
            <Content>
                {
                    collections.map(c => (
                        <Frame key={c.id} onClick={() => { navigate(`/collections/${c.contract_address}`) }}>
                            <Artwork artwork={c} />
                            <FeaturedArtInfo className="info"><Artist>{c.collection_title}</Artist><Container className="bioContainer"><Bio className="bio">{c.collection_description}</Bio></Container></FeaturedArtInfo>
                        </Frame>
                    ))
                }
            </Content>
        </Wrapper>
    )
}

export default HomeRecentDrops
