import React, { useEffect, useState, useRef } from 'react'

import ArtworkThumbnail from '../ArtworkThumbnail'

import { ItemCount, RemoveAll, FlexRow, Wrapper, ScrollBarContainer, OuterWrapper, ColWrapper, ScrollWrapper, EmptySpacer } from './ArtworkThumbnailContainer.styles'

import FormNavButton from '../FormNavButton'

const ArtworkThumbnailContainer = ({ removeAllArtworks, values, step, index, scrollTo, removeItem }) => {
    const [visible, setVisible] = useState(values.length > 5 ? true : false)
    const [winHeight, setWinHeight] = useState(window.innerHeight);
    const scrollableContainer = useRef(null);

    useEffect(() => {

        if (scrollableContainer.current != null) {
            if (scrollableContainer.current.scrollWidth > scrollableContainer.current.clientWidth) {
                setVisible(true)
            }
            else {
                setVisible(false)
            }
        }

    }, [values.length, winHeight])
    console.log()

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setWinHeight(newWidth);

        };
        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)


    }, [])

    const scrollUp = (event) => {
        event.preventDefault()

        scrollableContainer.current.scrollLeft += 100;
    }

    const scrollDown = (event) => {
        event.preventDefault()

        scrollableContainer.current.scrollLeft -= 100;
    }

    useEffect(() => {
        if (scrollableContainer.current != null) {
            scrollableContainer.current.scrollLeft = 92 * (index);
        }

    }, [index, step])



    return (
        <>
            <ColWrapper>
                <OuterWrapper>

                    <Wrapper ref={scrollableContainer}>
                        <ScrollWrapper >

                            {
                                values.map((item, idx) => (
                                    <ArtworkThumbnail
                                        thumbnail={item.thumbnail}
                                        type={item.type}
                                        title={item.fileName}
                                        key={idx}
                                        onClick={removeItem}
                                        step={step}
                                        currentIndex={index}
                                        activeIndex={idx === index ? true : false}
                                        itemIndex={idx}
                                    />
                                ))
                            }

                        </ScrollWrapper>
                    </Wrapper>
                    {visible &&
                        <>
                            < FormNavButton direction="back" validContinue={true} onClick={scrollDown} visible={visible} height="35px" width="35px" />
                            < FormNavButton direction="down" validContinue={true} onClick={scrollUp} visible={visible} height="35px" width="35px" />
                        </>
                    }
                </OuterWrapper>
                <FlexRow>
                    {
                        values.length > 0 &&
                        <>
                            <ItemCount>{values.length > 1 ? `${values.length} Uploads` : '1 Upload'}</ItemCount>
                            <RemoveAll onClick={removeAllArtworks}>{values.length > 1 ? 'Remove All' : 'Remove'}</RemoveAll>
                        </>
                    }

                </FlexRow>
            </ColWrapper>
        </>
    )
}

export default ArtworkThumbnailContainer
