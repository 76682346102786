import styled from 'styled-components'

export const OuterWrapper = styled.div`
position: relative;
display: flex;
width: 100%;
justify-content: center;
`;


export const Wrapper = styled.div`
border: 1px solid #000000;
overflow: hidden;
width: 100%;
display: grid;
height: 400px;
background: linear-gradient(to right, rgba(255,255,255,0.85), rgba(255,255,255,0.25));
position: relative;
align-items: start;
grid-template-columns: 1fr 300px ;
grid-template-rows: 1fr min-content;
grid-template-areas: 'text picture''button picture';
align-content: center;
grid-column-gap: 50px;
h2{
    margin: 0;
}
h1{
    text-align: left;
}
padding: 50px;
@media screen and (max-width: 900px)  {
        grid-template-areas: 'picture''text''button';
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        grid-row-gap: 20px;
        height: 100%;
    }
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const TextWrapper = styled.div`
grid-area: text;
`;

export const Text = styled.div`
font-size: 14px;
margin-bottom: 1rem;
`;


export const ImageWrapper = styled.div`
`;


export const Frame = styled.div`
height: 100%;
position: relative;
justify-self: end;
grid-area: picture;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
box-shadow: 0 0px 15px 1px rgba(0,0,0,0.5);
justify-content: center;
overflow: hidden;
max-width: 300px;
@media screen and (max-width: 900px)  {
    height: 200px;
    max-width: 100%;
    }
.info{
    opacity: 0;

}

&:hover .info{
    opacity: 1;

}

`;

export const FeaturedArtInfo = styled.div`
text-transform: uppercase;
color: var(--background);
position: absolute;
background: var(--blackOverlay);
width: 100%;
text-align: right;
height: 100%;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;


`;


export const Title = styled.div`
font-size: 12px;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  text-align: right;
`;

export const Artist = styled.div`
font-size: 10px;`;

export const FrameImageWrapper = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    img{
        object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    }
`;

export const Video = styled.video`
        object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;


`;