import bigInt from "big-integer";
import { roundData } from '../utils'
import web3 from "web3"
export const NF_INDEX_MASK = bigInt(2).pow(128).subtract(1)

export const getNonFungibleIndex = (fullTokenId) => { // the ugly one
    return NF_INDEX_MASK.and(bigInt(fullTokenId)).toString()
}

export const getNonFungibleBaseType = (fullTokenId) => { // the ugly one
    return bigInt(fullTokenId).shiftRight(128).toString()
}

export const getNonFungibleTokenIdFromBaseType = (baseType, edition) => { // the ugly one
    return bigInt(baseType).shiftLeft(128).xor(bigInt(edition)).toString()
    //return NF_INDEX_MASK.and(bigInt(fullTokenId)).toString()
}

export const getNonFungibleTokenIdFromBaseId = (baseType, edition) => { // the ugly one
    return bigInt(baseType).shiftLeft(128).xor(bigInt(edition)).toString()
    //return NF_INDEX_MASK.and(bigInt(fullTokenId)).toString()
}

export const isBaseType = (tokenId) => { // the ugly one
    return parseInt(getNonFungibleIndex(tokenId)) === 0
}

export const roundDataWei = (data, digits = 2) => {
    return roundData(convert_WEI_ETH(data), digits)
}

export const convert_WEI_ETH = (priceWei) => {
    return web3.utils.fromWei(web3.utils.toBN(priceWei))
}