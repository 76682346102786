import React, { useState, useEffect} from 'react'

import {Wrapper, BubbleStyled, LabelStyled} from './RadioBubbles.styles'


const Label = ({name, marginBottom, label}) => (


    <LabelStyled htmlFor={name}
    marginBottom={marginBottom}
   >{label}</LabelStyled>
)

const Bubble = ({value, isSelected, onClick, index}) =>{
    
    return (
    <BubbleStyled index={index} value={value} onClick={onClick} isSelected={isSelected}>{value}</BubbleStyled>)
}


const RadioBubbles = ({label, options, handleChange, value, name, marginBottom}) => {
    console.log("value" + value, options)
    if( label !== undefined ){
        return (
            <>
            <Label
            htmlFor={name}
            marginBottom={ marginBottom }
            label={label}
            ></Label>
            <Wrapper>
                {
                    options.map( (option, index) => (
                        <Bubble 
                        key={index}
                        index={index}
                        value={option}
                        isSelected={ value === option ? true : false }
                        onClick={handleChange}
                        ></Bubble>
                    ))
                }
            </Wrapper>
            </>
        )
    }
    else{
        return (
            <Wrapper>
                {
                    options.map( (option, index) => (
                        <Bubble 
                        key={index}
                        value={option}
                        isSelected={ value === option ? true: false }
                        onClick={handleChange}
                        ></Bubble>
                    ))
                }
            </Wrapper>
        )
    }
    
}

export default RadioBubbles
