import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import web3 from "web3";
import { useNavigate } from 'react-router-dom'
import {
  OuterWrapper,
  Wrapper,
  ImageWrapper,
  EditionSize,
  DetailsWrapper,
  CurrentSaleType,
  CurrentPriceValue,
  Title,
  Pricing,
  PriceLabel,
  CollectionSize,
  Artist,
  Image,
  Video,
  GridItem,
  Row
} from './ArtworkCard.styles'

import { removeLeadingSlash, getFileType } from '../../helpers'
import API from '../../API'
import { VALID_FILE_TYPES } from '../../config.js'
import { convert_WEI_ETH, getNonFungibleIndex, getNonFungibleBaseType, roundDataWei } from '../../blockchain/blockchainUtils'
import { useImportCollectionStatus } from '../../contexts/CollectionStatusContext'

const ImageArtwork = ({ artwork, title }) => {
  return (
    <ImageWrapper>
      <Image src={artwork} alt={title} />
    </ImageWrapper>
  )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
  const videoRef = useRef(null);
  if (isFeatured && videoRef.current !== null) {
    videoRef.current.play()
  }
  else if (videoRef.current !== null) {
    videoRef.current.pause()
  }
  return (
    <Video
      ref={videoRef}
      src={artwork}
      width="100%"
      autoPlay={false}
      controls={false}
      onMouseOver={(event) => videoRef.current.play()}
      onMouseOut={(event) => videoRef.current.pause()}
      loop={true}
      muted
      playsInline
    />
  )
}

const Artwork = ({ artwork, title }) => {
  const fileType = getFileType(artwork.image)

  if (fileType === 'video') {
    return <VideoArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
  }
  else {
    return <ImageArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
  }
}

const PricingData = ({ isPending, isLive, artwork, pricingData }) => {
  const formatPrice = () => {
    if (pricingData.isAuction) {
        if (pricingData.constructor.name === 'PrimaryMarketData')
            return artwork.edition ? 
            { price: convert_WEI_ETH(pricingData.bids[artwork.edition - 1]), label: 'Price' }
            : { price: convert_WEI_ETH(pricingData.auctionHigh), label: 'Price' }
        else return convert_WEI_ETH(pricingData.auctionHigh) > 0
            ? { price: convert_WEI_ETH(pricingData.auctionHigh), label: 'Highest Bid' }
            : { price: convert_WEI_ETH(pricingData.price), label: 'Min Bid' }
    } else return { price: convert_WEI_ETH(pricingData.price), label: 'Price' }
}

const { price, label } = formatPrice()

  if ((isLive || isPending) && pricingData.constructor.name === 'PrimaryMarketData') {

    if (pricingData.isAuction) {
      const price = pricingData.isAuction ? web3.utils.fromWei(web3.utils.toBN(pricingData.auctionHigh))
        : web3.utils.fromWei(web3.utils.toBN(pricingData.price))
      const priceLabel = pricingData.isAuction ? 'High' : 'Price'
      const price_low = Math.min(...pricingData.bids.map(b => parseInt(b))) ?
        web3.utils.fromWei(web3.utils.toBN(Math.min(...pricingData.bids.map(b => parseInt(b))))) :
        web3.utils.fromWei(web3.utils.toBN(pricingData.price))
      if (isLive) {
        return (
          <Pricing

            gridTemplateAreas={pricingData.isAuction ? "'high low''hLbl lLbl'" : "'high high''hLbl hLbl'"}
            isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
            <GridItem gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
            <GridItem gridArea="hLbl"><PriceLabel>{priceLabel}</PriceLabel></GridItem>
            {
              (artwork.supply > 1) && <><GridItem gridArea="low"><CurrentPriceValue right>{price_low}Ξ</CurrentPriceValue></GridItem>
                <GridItem gridArea="lLbl"><PriceLabel right>Low</PriceLabel></GridItem></>
            }

          </Pricing>
        )
      }
      else {
        return (
          <Pricing

            gridTemplateAreas={pricingData.isAuction ? "'high high''hLbl lLbl'" : "'high high''hLbl hLbl'"}
            isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
            <GridItem gridArea="high"><CurrentPriceValue>{web3.utils.fromWei(web3.utils.toBN(pricingData.price))}Ξ</CurrentPriceValue></GridItem>
            <GridItem gridArea="hLbl"><PriceLabel>Minimum Bid</PriceLabel></GridItem>

          </Pricing>
        )
      }
    } else {
      const price = web3.utils.fromWei(web3.utils.toBN(pricingData.price))
      const priceLabel = pricingData.isAuction ? 'High' : 'Price'
      return (
        <Pricing
          gridTemplateAreas={"'high high''hLbl hLbl'"}
          isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
          <GridItem gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
          <GridItem gridArea="hLbl"><PriceLabel>Price</PriceLabel></GridItem>

        </Pricing>
      )
    }

  } else {
    const price_eth = pricingData.isAuction && roundDataWei(pricingData.auctionHigh, 8) !== 0 ? roundDataWei(pricingData.auctionHigh, 3) : web3.utils.fromWei(pricingData.price);
    const priceLabel = pricingData.isAuction ? 'High' : 'Price'
    return (
      <Pricing
        gridTemplateAreas={"'high high''hLbl hLbl'"}
        isAuction={pricingData.isAuction} isPending={isPending} isLive={isLive}>
        <GridItem gridArea="high"><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
        <GridItem gridArea="hLbl"><PriceLabel>{label}</PriceLabel></GridItem>
      </Pricing>
    )
  }

}

const Edition = ({ artwork, isLive, isPending, pricingData }) => {
  if (artwork.currentSupply === undefined) {
    return (<EditionSize>{artwork.max_mint} Edition{artwork.max_mint === '1' ? '' : 's'}</EditionSize>)
  }
  else if (artwork.maxSupply !== undefined && isPending) {
    return (<EditionSize>{artwork.supply} Editions</EditionSize>)
  }
  else if (artwork.maxSupply !== undefined && isLive) {
    const r = artwork.maxSupply - artwork.currentSupply;
    return (<EditionSize>{artwork.primaryMarketData.isAuction ? `${r} Editions` : r > 0 ? `${r} Remaining` : `${artwork.currentSupply} Minted`}</EditionSize>)
  } else {
    return (<EditionSize>{!artwork.primaryMarketData.isAuction ? artwork.currentSupply : artwork.primaryMarketData.bids.filter(it => parseInt(it) !== 0).length} Minted</EditionSize>)
  }

}


const ArtworkCard = ({ artwork, onClick, isSecondary, isPrimary }) => {
  const navigate = useNavigate()
  const [pricingData, setPricingData] = useState(null)
  const [isPending, setIsPending] = useState(dayjs().unix() < parseInt(artwork.primaryMarketData.salesOpenTime))
  const [isLive, setIsLive] = useState(dayjs().unix() < parseInt(artwork.primaryMarketData.salesCloseTime) && dayjs().unix() > parseInt(artwork.primaryMarketData.salesOpenTime))
  useEffect(() => {
    setIsLive(dayjs().unix() < parseInt(artwork.primaryMarketData.salesCloseTime) && dayjs().unix() > parseInt(artwork.primaryMarketData.salesOpenTime))
    setIsPending(dayjs().unix() < parseInt(artwork.primaryMarketData.salesOpenTime))
    if (artwork.secondaryMarketData !== undefined || isSecondary) {
      setPricingData(artwork.secondaryMarketData)
    }
    else {
      setPricingData(artwork.primaryMarketData)
    }
  }, [artwork])
  if (pricingData === null) return <OuterWrapper></OuterWrapper>
  else
    return (
      <OuterWrapper>
        <Wrapper
          cursor={onClick ? typeof (onClick) === 'function' ? 'pointer' : 'default' : 'pointer'}
          onClick={onClick ? onClick : () => {
            navigate(`/collections/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`, { state: { artwork: artwork } })
          }}
        // onClick={onCardSelect ? onCardSelect : () => { navigate(`/collections/${collection.contract_address}`, { state: { collection: collection, statusKey: statusKey } }) }}
        >
          <Artwork artwork={artwork} title={artwork.artwork_title} />
          <DetailsWrapper isPending={isPending}>
            <Artist>{artwork.artist_name}</Artist>
            {artwork.edition !== undefined && <div style={{ display: 'flex' }}>
              <Title>{artwork.name}</Title>
              <Title marginLeft="2px"> #{parseInt(artwork.edition)}
              </Title>
            </div>}
            {artwork.edition === undefined && <Title>{artwork.name}</Title>}
            <PricingData isPending={isPending} isLive={isLive} artwork={artwork} pricingData={pricingData} />
            <Row>
              <CurrentSaleType>
                {pricingData.isAuction ? 
                pricingData.salesOpenTime > dayjs().unix() ? 
                `Opens ${dayjs.unix(pricingData.salesOpenTime).fromNow()}` :
                pricingData.salesCloseTime > dayjs().unix() ? 
                `Auction Ends ${dayjs.unix(pricingData.salesCloseTime).fromNow()}` : 'Auction' : 'Fixed Price'}
              </CurrentSaleType>
              <Edition isPending={isPending} isLive={isLive} artwork={artwork} pricingData={pricingData} />
            </Row>
          </DetailsWrapper>
        </Wrapper>
      </OuterWrapper>
    )
}

export default ArtworkCard
