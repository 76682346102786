import styled from 'styled-components';

export const Wrapper = styled.div`
position: absolute;
width: 100%;
display: flex;
height: 5px;
top: 0;
z-index: 2;
background: var(--lightestGrey);

`;

export const Progression = styled.div`
background: linear-gradient(45deg,var(--uiollyPurple),var(--lightBlue));
position: absolute;
transition: width 0.5s;
height: 100%;

width: ${ props => props.step };
`;