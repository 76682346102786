import Web3 from "web3";

let web3;

if (typeof window.ethereum !== "undefined") {
  web3 = new Web3(Web3.givenProvider);
} else {
  web3 = new Web3(
    "https://speedy-nodes-nyc.moralis.io/7eaf3418cd37ce3e965df916/eth/rinkeby"
  );
}

export default web3;
