import styled from "styled-components";


export const OuterWrapper = styled.div``;

export const Wrapper = styled.div`
    
    overflow: hidden;
    display: flex;
    position: relative;
   
    width: 35vw;
    max-width: 450px;
    max-height: 450px;
    height: 35vw;
    line-height: 1rem;
    font-size: 5em;
    text-align: center;
    color: #FFF;
    
    
`;

export const Image = styled.img`
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;

    position: relative;
   
    
`;

export const Video = styled.video`
    min-width: 100%;
    min-height: 100%;
   
    object-fit: cover;

    
`;