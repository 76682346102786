import dayjs from "dayjs";
import web3 from 'web3'
import { roundData } from '../utils'

export class SocialMedia {
    facebook = "";
    instagram = "";
    tiktok = "";
    twitter = "";
    website = "";
    youtube = "";
    constructor(data = null) {
        if (data) {
            this.facebook = data.facebook;
            this.instagram = data.instagram;
            this.tiktok = data.tiktok;
            this.twitter = data.twitter;
            this.website = data.website;
            this.youtube = data.youtube;
        }

    }
}

export class User {
    name = "";
    bio = "";
    constructor(data) {
        this.display_name = data.display_name;
        this.handle = data.handle;
        this.bio = data.bio || '';
        this.socialMedia = new SocialMedia(data)
        this.isArtist = false
        this.public_address = data.public_address
        this.profile_pic = data.profile_pic
    }
}

export class Artist extends User {
    constructor(data) {
        super(data.userDetails)
        this.collections = data.collections
        this.isArtist = true
    }
}

export class UserProfileDetails extends User {
    isArtist = false
    constructor(data) {
        super(data.userDetails)
        this.contentOptions = ['Curated']
        if (data.collections !== undefined && data.collections.length > 0) {
            this.contentOptions = ['Curated', 'Created']
            this.collections = data.collections
            this.isArtist = true
        }
    }
}

export class Collection {
    id;
    contractAddress;
    title;
    description;
    cover_path;
    salesOpenTime;
    salesCloseTime;
    unique_title;
    artist;
    items = [];

    constructor(data) {
        if (data) {
            this.facebook = data.facebook;
            this.instagram = data.instagram;
            this.tiktok = data.tiktok;
            this.twitter = data.twitter;
            this.website = data.website;
            this.youtube = data.youtube;
        }
    }
}



export class SecondaryMarketStats {
    pricePremium = '-'
    constructor(data) {
        // this.lastSoldDate = data.last_sold_date;
        // this.lastSoldPrice = data.last_sold;
        this.initialDrop = dayjs.unix(data.primaryMarketData.salesOpenTime).format('MM/DD/YY')
        this.marketplaceSales = data.marketplace_sales
        this.marketplaceSalesEdition = data.edition_marketplace_sales
        this.avgResalePrice = roundData(data.average_resale_price, 3) + 'Ξ'
        this.avgResalePriceEdition = roundData(data.average_resale_price_edition, 3) + 'Ξ'
        // this.globalHigh = roundData(data.high, 8) + 'Ξ'
        this.highestBidAllEditions = data.highestBidAllEditions;
        if (data.secondaryMarketData){
            const premium = (web3.utils.fromWei(web3.utils.toBN(data.secondaryMarketData.price)) - data.primary_market_price) / data.primary_market_price * 100
            this.pricePremium = `${premium < 0 ? '' : '+'}${roundData(premium, 2)}%`
        }
        if (data.edition_high){
            this.lifespanRange = `${roundData(data.edition_low, 4)}-${roundData(data.edition_high, 4)}Ξ`
        }else{
            this.lifespanRange = `${roundData(data.low, 4)}-${roundData(data.high, 4)}Ξ`
        }
        
        // this.primaryMarketPrice = roundData(data.primary_market_price, 4) + 'Ξ';
        this.marketplaceVolume = roundData(data.marketplace_volume, 4) + 'Ξ'
        this.marketplaceVolumeEdition = roundData(data.edition_marketplace_volume, 4) + 'Ξ'
        // this.globalLow = roundData(data.low, 8) + 'Ξ'
        this.lowestAskAllEditions = data.lowestAskAllEditions;
    }
}

export class PriceData {
    isAuction = false
    step = 0
    auctionHigh = 0
    
    constructor(data) {
        this.salesOpenTime = data.salesOpenTime;
        this.salesCloseTime = data.salesCloseTime;
        this.isAuction = data.isAuction
        this.step = data.step
        this.price = data.price
        this.auctionHigh = data.auctionHigh
    }
}

export class PrimaryMarketData extends PriceData {
    bids = []
    auctionLow = 0
    constructor(data) {
        super(data)
        this.bids = data.bids
        this.auctionLow = data.auctionLow
        // this.maxSupply = data.maxSupply
        // this.currentSupply = data.currentSupply
    }
}

export class SecondaryMarketData extends PriceData {
    activeOffers = []
    myOffer = {}
    highestOffer = 0
    lowestOffer = 0
    highestBidder = '0x0'

    constructor(data) {
        super(data)
        this.seller = data.seller
        this.owner = data.owner
        this.itemId = data.itemId
        this.activeOffers = data.activeOffers
        this.highestOffer = data.highestOffer
        this.lowestOffer = data.lowestOffer
        this.highestBidder = data.highestBidder
        this.myOffer = data.myOffer
    }
}

export class NftArtwork {
    tokenId;
    baseType = 1;
    baseTypeId;
    edition = 0;
    // salesOpenTime;
    // salesCloseTime;
    price_primaryMarket;
    isAuction_primaryMarket;
    auctionHigh_primaryMarket;
    auctionLow_primaryMarket;
    step_primaryMarket;
    bids_primaryMarket = [];
    uri;
    name;
    description;
    artist;
    contractAddress;
    collection;
    image;
    supply;
    currentSupply; // num left

    constructor(data) {
        this.contractAddress = data.contractAddress;
        this.tokenId = data.tokenId;
        this.baseType = data.baseType;
        this.baseTypeId = data.baseTypeId;
        this.edition = data.edition;
        this.salesOpenTime = data.salesOpenTime;
        this.salesCloseTime = data.salesCloseTime;
        this.isAuction_primaryMarket = data.isAuction_primaryMarket;
        this.price_primaryMarket = data.price_primaryMarket;
        this.bids_primaryMarket = data.bids_primaryMarket;
        this.auctionHigh_primaryMarket = data.auctionHigh_primaryMarket;
        this.auctionLow_primaryMarket = data.auctionLow_primaryMarket;
        this.bids_primaryMarket = data.bids_primaryMarket;
        this.high_baseType = data.high_baseType;
        this.low_baseType = data.low_baseType;
        this.high_thisEdition = data.high_thisEdition;
        this.low_thisEdition = data.low_thisEdition;
        this.uri = data.uri;
        this.name = data.name;
        this.description = data.description;
        this.artist = new Artist(data);
    }

}


export class NftArtworkSecondaryMarket extends NftArtwork {

    marketplaceSales;
    avgResalePrice;
    marketplaceVolume;
    pricePremium;
    high_baseType;
    low_baseType;
    auctionHigh_thisEdition;
    lowAsk_thisEdition;
    auctionHigh_allEditions;
    lowAsk_allEditions;
    isAuction = false;
    lastSold;
    editionHistory; //from chain events

    constructor(data) {

    }

}