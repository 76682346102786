import styled from "styled-components";

export const Wrapper = styled.div`
    background-image: ${props=> props.coverArt ? `url(${props.coverArt})` : ''};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 200px;
    display: flex;

    align-items: center;
    position: relative;
    justify-content: center;
`;

export const FrostedGlass = styled.div`
    width: 80%;
    height: 80%;
    position: absolute;
    border: 1px solid rgba(255, 255, 255,0.5);
    background: rgba(255, 255, 255,0.25);
    filter: drop-shadow(0px 0px 12px 50px rgba(0,0,0,.25));

    color:rgba(255,255,255,.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: Raleway;
    text-transform: uppercase;
    letter-spacing: .2rem;
    text-shadow: -.2rem .2rem .55rem rgba(3,0,112,0.25), .2rem -.2rem .45rem rgba(255,255,255,1);
    padding: 20px;
    text-align: center;
    
`;

export const BlurredImg = styled.img`
    filter: blur(4px);
    width: 100%;
    object-fit: contain;
    position: relative;
   
`;

export const BlurredContainer = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
clip-path: inset(10%);
`;


export const ArtistName = styled.div`
font-size: 14px;
`;


export const CollectionTitle = styled.div`
font-size: 20px;
width: 100%;
position: relative;
overflow-wrap: break-word;
`;