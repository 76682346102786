import React, { useEffect, useState } from 'react'

import { SecondaryMarketStats } from '../../../objectPrototypes/objectClasses'
import { getMarketplaceItemHighsAndLows } from '../../../blockchain/functionsUiollyNew'
import { getNonFungibleBaseType } from '../../../blockchain/blockchainUtils'
import { getValidConstraints } from '../../../blockchain/blockchainHelpers'
import API from '../../../API'
import { VALID_STATS_PRODUCT_PAGE } from '../../../config'

import { Subtext, GridWrapper, OuterWrapper, Icon, Wrapper, Title, Data } from './Stats.styles'
import InitialDrop from '../../../images/initialDropDate.svg'
import MarketplaceSales from '../../../images/marketplaceSales.svg'
import AvgResalePrice from '../../../images/avgResalePrice.svg'
import HighestBid from '../../../images/highestBid.svg'
import PricePremium from '../../../images/pricePremium.svg'
import LifespanRange from '../../../images/lifespanRange.svg'
import MarketplaceVolume from '../../../images/marketplaceVolume.svg'
import LowestAsk from '../../../images/lowestAsk.svg'

import GridItem from '../../GridItem'

const iconimage = (title, data, stats) => {
    switch (title) {
        case 'initialDrop':
            return { imgSrc: InitialDrop, title: 'Initial Drop', formattedData: data };
        case 'primaryMarketPrice':
            return { imgSrc: InitialDrop, title: 'Primary Market Price', formattedData: data };
        case 'marketplaceSalesEdition':
            return {
                imgSrc: MarketplaceSales, title: 'No. of Sales', hoverTitle: 'No. of Sales',
                formattedData: data, hoverData: stats['marketplaceSales'], subtextHover: 'All Editions'
            };
        case 'avgResalePriceEdition':
            return {
                imgSrc: AvgResalePrice, title: 'Avg Price', formattedData: data,
                hoverTitle: 'Avg Price', hoverData: stats['avgResalePrice'], subtextHover: 'All Editions'
            };
        case 'highestBidAllEditions':
            return { imgSrc: HighestBid, title: 'Highest Bid', formattedData: data };
        case 'pricePremium':
            return { imgSrc: PricePremium, title: 'Price Premium', formattedData: data, subtext: '(Over Initial Drop Price)' };
        case 'lifespanRange':
            return { imgSrc: LifespanRange, title: 'Lifespan Range', formattedData: data };
        case 'marketplaceVolumeEdition':
            return {
                imgSrc: MarketplaceVolume, title: 'Volume', formattedData: data,
                subtextHover: 'All Editions', hoverData: stats['marketplaceVolume']
            };
        case 'lowestAskAllEditions':
            return { imgSrc: LowestAsk, title: 'Lowest Ask', formattedData: data };
        // case 'globalLow':
        //     return { imgSrc: LowestAsk, title: 'Lowest Ask', formattedData: data };
        default:
            return { imgSrc: null, title: 'Unknown', formattedData: data }
            break;
    }
}

const Stat = ({ data, obKey, stats }) => {
    const { imgSrc, title, formattedData, hoverData, hoverTitle, subtext, subtextHover } = iconimage(obKey, data, stats)
    const [dataToShow, setData] = useState({ data: formattedData, title, subtext })
    const mouseOver = (e) => {
        e.stopPropagation();
        if (hoverData !== undefined)
            setData({ data: hoverData, title: hoverTitle !== undefined ? hoverTitle : title, subtext: subtextHover !== undefined ? subtextHover : subtext });
    }
    const mouseOut = (e) => {
        e.stopPropagation();
        if (hoverData !== undefined)
            setData({ data: formattedData, title: title, subtext })
    }
    return (
        <Wrapper
            onMouseEnter={(e) => mouseOver(e)}
            onMouseLeave={(e) => mouseOut(e)}>
            <Icon src={imgSrc} />
            <Data>{dataToShow.data}</Data>
            <GridItem height="25.2px">
                <Title>{dataToShow.title}</Title>
                <Subtext>{dataToShow.subtext}</Subtext>
            </GridItem>
        </Wrapper>)
}


const Stats = ({ artwork, pricingData }) => {
    const { contractAddress, tokenId, edition, baseType } = artwork
    const [stats, setStats] = useState(null)
    const [data, setData] = useState(null)
    useEffect(() => {
        const getData = async () => {
            try {
                const highsAndLows = await getMarketplaceItemHighsAndLows(contractAddress, tokenId)
                const data = { ...artwork, ...highsAndLows }
                setStats(getValidConstraints(VALID_STATS_PRODUCT_PAGE, new SecondaryMarketStats(data)))
                setData(new SecondaryMarketStats(data))
            } catch (error) {

            }
        }
        if (artwork)
            getData(baseType)
    }, [artwork])
    return (
        <OuterWrapper>
            <h1>Stats</h1>
            <GridWrapper>
                {(stats !== null && data !== null) &&
                    Object.keys(stats).map(key => {
                        //getValidConstraints(VALID_STATS_PRODUCT_PAGE, new SecondaryMarketStats(stats)
                        return (
                            <Stat data={stats[key]} obKey={key} key={key} stats={data} />
                        )
                    })
                }
            </GridWrapper>
        </OuterWrapper>
    )
}

export default Stats