import React, { useState, useEffect, useRef } from 'react'
import web3 from "web3";
import dayjs from "dayjs"
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getMarketplaceItem, getEditionOfferEvents, buy, bid, getCollectionItems, getURI, getItemData, getBids } from "../../blockchain/functionsUiollyNew";
import { getNonFungibleTokenIdFromBaseType, roundDataWei } from "../../blockchain/blockchainUtils"
import { roundData } from '../../utils'
import { SecondaryMarketStats } from '../../objectPrototypes/objectClasses'
import {
    // GridItem,
    DetailsWrapper, OuterWrapper,
    InnerWrapper, PricingWrapper,
    Wrapper, ColumnWrapper, LeftWrapperContent, RightWrapper, LeftWrapper, ImageWrapper, Image, InfoWrapper, RowWrapper,
    CurrentBidValue, CurrentBidLabel, LastSoldLabel, LastSoldValue, ContractAddress,
    Editions, Artist, Collection, Description, CurrentAsk, LastSold, GlobalTitle, InfoOuterWrapper, AdditionalStats


} from './ProductPage.styles'

import { useProductFetch } from '../../hooks/useProductFetch'
import API from '../../API'
import PlaceBidModal from './PlaceBidModal'
import MakeOfferModal from './MakeOfferModal'
import Artwork from '../Artwork'
import GridItem from '../GridItem'
import Button from '../Button'
import EditionHistory from './EditionHistory'
import Stats from './Stats'
import ProductPageButtons from './ProductPageButtons'
import ListingGlobalStats from '../ListingGlobalStats'
import SelectEditionPopup from '../SelectEditionPopup'
import LoadingWheel from '../LoadingWheel'
import UserIcon from '../UserIcon'
import { useAuth } from '../../contexts/AuthContext';
import { useWeb3 } from '../../contexts/Web3Context';
const EditionInfo = ({ isSecondary, artwork, children, isLive, isPending }) => {
    if (isLive || isPending) {
        const editions_text = isLive ? `${artwork.supply - artwork.currentSupply} of ${artwork.supply} Editions Remaining`
            : `${artwork.supply} Editions`
        return (
            <Editions>{editions_text}</Editions>
        )
    }
    else return <Editions>
        {artwork.isAuction && artwork.bids !== undefined
            ? `${isSecondary ? 'Edition of' : ''} ${artwork.bids.length} ${!isSecondary ? 'Minted' : ''}`
            : `${isSecondary ? 'Edition of' : ''} ${artwork.primaryMarketData.isAuction ? artwork.primaryMarketData.bids.filter(it => parseInt(it) !== 0).length : artwork.currentSupply} ${!isSecondary ? 'Minted' : ''}`}
    </Editions>
}

const ItemDetails = ({ pricingData, pricingDataType, isSecondary, txProcessing, tokenEdition, artwork, children, isLive, isPending, onClick, currentUser, userAddress }) => {
    const price_eth =
        pricingData.isAuction && parseInt(pricingData.auctionHigh)
            ? web3.utils.fromWei(web3.utils.toBN(pricingData.auctionHigh))
            : web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    const price_type =
        tokenEdition !== undefined && !isLive ? 'Last Sold' :
            pricingData.isAuction ? isPending || parseInt(pricingData.auctionHigh) === 0 ? 'Minimum Bid' : 'Highest Bid' : 'Fixed Price';
    return (
        <DetailsWrapper>
            <GridItem
                display="flex"
                flexDirection="row"
                fontWeight="500" fontSize="12px" gridCol="1 / span 2" gridRow="1 / span 1">
                <Artist to={`/profile/${artwork.artist_handle.replace('@', '')}`}>{artwork.artist_name}</Artist>
            </GridItem>
            <GridItem alignItems="start" display="flex"
                fontSize="15px" fontWeight="500" borderBottom="1px solid #000000" gridCol="1 / span 2" gridRow="2 / span 1">
                {artwork.name}{tokenEdition ? ` #${tokenEdition}` : ''}
            </GridItem>
            <GridItem noWrap
                fontSize=".7em"
                fontWeight="500"
                gridCol="3/ span 1"
                gridRow="2 / span 1"
                justifyContent="flex-end"
                display="flex"
                flexDirection="row"
                alignItems="end"
                borderBottom="1px solid #000000"
                align="right"
            >{!isSecondary && <>{price_type + ': ' + price_eth} Ξ</>}
                {isSecondary && <div style={{ margin: '5px 0', fontSize: '10px', display: 'flex', alignItems: 'center', lineHeight: '9px' }}>
                    <div style={{ marginRight: '2px' }}>@{artwork.seller_handle}</div>
                    <UserIcon handle={artwork.seller_handle} radius="20px" image={artwork.seller_profile_pic} /></div>}
            </GridItem>
            <GridItem color="var(--lightGrey)" gridCol="1/ span 2" display="flex" alignItems="flex-start"
                gridRow="3 / span 1">
                <Collection to={`/collections/${artwork.contractAddress}`}>{artwork.collection_title}</Collection>
            </GridItem>
            {children}
            <GridItem gridCol="1/ span 3"
                gridRow="4 / span 1">
                <Description>{artwork.description}</Description>
            </GridItem>
            {
                isSecondary &&
                <PricingWrapper gridCol="1/ span 3" gridRow="5 / span 1" gridTempCols="1fr 1fr" display="grid">
                    <GridItem fontSize="18px" fontWeight="600" flexDirection="row" justifySelf="start" gridRow="1">{roundData(artwork.primary_market_price, 8)}Ξ</GridItem>
                    <GridItem margin="0 0 5px" flexDirection="row" justifySelf="start" gridRow="2" >Original Listing</GridItem>
                    {(isSecondary && artwork.secondaryMarketData) &&
                        <>
                            <GridItem fontSize="18px" fontWeight="600" flexDirection="row" justifyContent="end" gridCol="2" gridRow="3">
                                {!pricingData.isAuction || roundDataWei(pricingData.auctionHigh, 8) === 0 ? roundDataWei(pricingData.price, 8) : roundDataWei(pricingData.auctionHigh, 8)}Ξ</GridItem>
                            <GridItem flexDirection="row" alignItems="start" justifyContent="end" gridCol="2" gridRow="4" >
                                {pricingData.isAuction ? roundDataWei(pricingData.auctionHigh, 8) === 0 ? 'Minimum Bid' : 'Highest Bid' : isSecondary ? 'Current Ask' : 'Fixed Price'}
                            </GridItem>
                        </>}
                    {(isSecondary && pricingData.isAuction) &&
                        <>
                            <GridItem fontSize="18px" fontWeight="600" flexDirection="row" justifyContent="start" gridCol="1" gridRow="3">{dayjs.unix(pricingData.salesCloseTime).format('M/D/YY h:mm A')}</GridItem>
                            <GridItem flexDirection="row" alignItems="start" justifyContent="start" gridCol="1" gridRow="4" >
                                Auction Closes
                            </GridItem>
                        </>}
                    {(isSecondary && !pricingData.isAuction && /^0x0+$/.test(pricingData.owner) && pricingData.highestOffer > 0) &&
                        <>
                            <GridItem fontSize="18px" fontWeight="600" flexDirection="row" justifyContent="start" gridCol="1" gridRow="3">{pricingData.highestOffer}Ξ</GridItem>
                            <GridItem flexDirection="row" alignItems="start" justifyContent="start" gridCol="1" gridRow="4" >
                                Highest Offer
                            </GridItem>
                        </>}
                    <GridItem fontSize="18px" fontWeight="600" flexDirection="row" justifySelf="end" justifyContent="end" gridRow="1">{roundData(artwork.last_sold, 8)}Ξ</GridItem>
                    <GridItem margin="0 0 5px" flexDirection="row" justifySelf="end" justifyContent="end" gridRow="2" >Last Sold</GridItem>
                </PricingWrapper>
            }
            <GridItem
                gridCol={isSecondary ? "3 / span 1" : "1/ span 1"}
                justifyContent={isSecondary ? "flex-end" : "flex-start"}
                gridRow={isSecondary ? "3" : "8 / span 1"} flexDirection="row" >
                <EditionInfo isSecondary={isSecondary} isPending={isPending} isLive={isLive} artwork={artwork} />
            </GridItem>
        </DetailsWrapper>

    )


}

const ProductPage = ({ global, isSecondary }) => { // this needs to be updated to global product page
    const { currentUser } = useAuth()
    const navigate = useNavigate()
    const { userAddress, handleBuy, txProcessing, marketplaceBuy, marketplaceMakeOffer } = useWeb3()
    const [showModal, setShowModal] = useState(false)
    const [showOfferModal, setShowOfferModal] = useState(false)
    const [pricingData, setPricingData] = useState(null)
    const [pricingDataType, setPricingDataType] = useState('PrimaryMarketData')
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [edition, setEdition] = useState(1)
    const [state, setState] = useState()
    const [artwork, setArtwork] = useState({
        contractAddress: '',
        release_date: '',
        end_date: '',
        artist_handle: '',
        supply: '',
        currentSupply: ''
    })

    const [artworkData, setArtworkData] = useState({})
    let location = useLocation();
    const { blockchainAddress, baseType, tokenEdition } = useParams();
    const [isPending, setIsPending] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {
        if (tokenEdition !== undefined && artwork.supply !== '' && parseInt(tokenEdition) > artwork.supply) {
            navigate(`/collections/${blockchainAddress}/${baseType}/1`)
        }
    }, [artwork.supply])
    const handlePopupClose = (event) => {
        setShowModal(false);
    }//web3.utils.toWei(el)
    useEffect(() => {

        setLoading(true)

        if (location.state !== null && location.state.artwork !== undefined && location.state.artwork.artist_handle !== null) {
            const init = async () => {
                const art = location.state.artwork
                const res = isSecondary ? await API.fetchUser(undefined, art.secondaryMarketData.seller) : await API.fetchProfile(art.artist_handle)
                const { userDetails } = res;
                if (isSecondary) {
                    const ed_details = await API.getEditionDetails(blockchainAddress, baseType, tokenEdition)
                    // const secondaryMarketData = new SecondaryMarketStats({ ...ed_details, ...art })
                    // console.log("secondary market data", secondaryMarketData)
                    setArtwork({ ...art, seller_handle: userDetails.handle, seller_profile_pic: userDetails.profile_pic, ...ed_details })
                } else {
                    setArtwork({ ...art, seller_handle: userDetails.handle, seller_profile_pic: userDetails.profile_pic })
                }
                setPricingDataType(art.secondaryMarketData ? 'SecondaryMarketData' : 'PrimaryMarketData')
                setPricingData(art.secondaryMarketData ? art.secondaryMarketData : art.primaryMarketData)
                // setPricingData(isSecondary ? art.secondaryMarketData : art.primaryMarketData)
                setIsPending(dayjs().unix() < parseInt(art.primaryMarketData.salesOpenTime))
                setIsLive(dayjs().unix() < parseInt(art.primaryMarketData.salesCloseTime) && dayjs().unix() > parseInt(art.primaryMarketData.salesOpenTime))
                setLoading(false)
            }
            init()

        } else {
            const fetchData = async () => {
                const data = await getItemData(blockchainAddress, baseType, tokenEdition)
                setPricingDataType('PrimaryMarketData')
                setPricingData(data.primaryMarketData)
                setArtwork({ ...data })
                setIsPending(dayjs().unix() < parseInt(data.primaryMarketData.salesOpenTime))
                setIsLive(dayjs().unix() < parseInt(data.primaryMarketData.salesCloseTime)
                    && dayjs().unix() > parseInt(data.primaryMarketData.salesOpenTime))
                setLoading(false)
            }
            if (isSecondary) {

                const initS = async () => {

                    const res2 = await getMarketplaceItem(null, blockchainAddress, getNonFungibleTokenIdFromBaseType(baseType, tokenEdition))
                    const stillLive = dayjs().unix() < parseInt(res2.primaryMarketData.salesCloseTime) && dayjs().unix() > parseInt(res2.primaryMarketData.salesOpenTime)
                    if (stillLive
                        && res2.primaryMarketData.isAuction
                        || (res2.secondaryMarketData === undefined && /^0x0+$/.test(res2.owner))
                    ) {
                        navigate(`/collections/${blockchainAddress}/${baseType}/${tokenEdition}`)
                    } else {
                        const res = await API.fetchUser(undefined, res2.secondaryMarketData ? res2.secondaryMarketData.seller : res2.owner)
                        const { userDetails } = res;
                        const ed_details = await API.getEditionDetails(blockchainAddress, baseType, tokenEdition)
                        setArtwork({ ...res2, seller_handle: userDetails.handle, seller_profile_pic: userDetails.profile_pic, ...ed_details })
                        setPricingDataType(res2.secondaryMarketData ? 'SecondaryMarketData' : 'PrimaryMarketData')
                        setPricingData(res2.secondaryMarketData ? res2.secondaryMarketData : res2.primaryMarketData)
                        setIsPending(dayjs().unix() < parseInt(res2.primaryMarketData.salesOpenTime))
                        setIsLive(dayjs().unix() < parseInt(res2.primaryMarketData.salesCloseTime) && dayjs().unix() > parseInt(res2.primaryMarketData.salesOpenTime))
                        setLoading(false)
                    }
                }
                initS()
            }
            else fetchData()
        }

    }, [location])

    const getData = async (baseType) => {
        if (blockchainAddress) {
            if (isSecondary) {
                const res2 = await getMarketplaceItem(pricingData.itemId)
                setArtworkData(res2);
                setArtwork(prev => ({ ...prev, ...res2 }))
            } else {
                let newData = await getItemData(blockchainAddress, baseType);
                setArtworkData(newData);
                setArtwork(prev => ({ ...prev, ...newData }))

            }
        }

    };

    const buyItem = async () => {
        try {
            const res = await handleBuy(blockchainAddress, baseType, artwork.primaryMarketData.price)
            getData(baseType)
        } catch (error) {
            console.log(error)
        }
    }

    const buyMarketplaceItem = async () => {
        try {
            const res = await marketplaceBuy(artwork.secondaryMarketData.itemId, web3.utils.fromWei(web3.utils.toBN(artwork.secondaryMarketData.price)))

        } catch (error) {

        }
    }

    if (loading || artwork.contractAddress === '' || artwork.artist_handle === undefined || pricingData === null) {
        return <Wrapper><LoadingWheel /></Wrapper>
    }
    else {
        const shortenedAddress = artwork.contractAddress.slice(0, 5) + '...' + artwork.contractAddress.slice(artwork.contractAddress.length - 5, artwork.contractAddress.length.end);
        const link = '/collections/' + artwork.contractAddress
        return (
            <OuterWrapper>
                <InnerWrapper>
                    <Wrapper>
                        {/* <LeftWrapper> */}
                        <LeftWrapperContent>
                            <Artwork width="100%" height="100%" artwork={artwork} borderRadius="0" />

                        </LeftWrapperContent>
                        <GridItem gridArea="address">
                            <ContractAddress href={`https://ropsten.etherscan.io/address/${artwork.contractAddress}`} target="_blank" data-address={artwork.contractAddress}>Contract Address: {shortenedAddress}</ContractAddress>
                        </GridItem>
                        <ItemDetails
                            pricingDataType={pricingDataType}
                            pricingData={pricingData}
                            isSecondary={isSecondary}
                            tokenEdition={global ? undefined : tokenEdition}
                            txProcessing={txProcessing}
                            currentUser={currentUser}
                            userAddress={userAddress}
                            isPending={isPending}
                            isLive={isLive}
                            onClick={
                                isSecondary && /^0x0+$/.test(artwork.owner) ?
                                    buyMarketplaceItem :
                                    currentUser === null || userAddress === '' ? undefined
                                        : tokenEdition !== undefined && !isLive && !artwork.isAuction || (tokenEdition !== undefined && !isLive)
                                            ? () => { navigate(`/collections/${blockchainAddress}/${baseType}`) }
                                            : isLive ? artwork.isAuction ? () => setShowModal(true)
                                                : artwork.currentSupply < artwork.supply ? buyItem
                                                    : undefined
                                                : undefined}
                            artwork={artwork} >
                            <ProductPageButtons
                                closeModal={() => { setShowOfferModal(false) }}
                                setShowModal={setShowModal}
                                setShowOfferModal={setShowOfferModal}
                                isSecondary={isSecondary}
                                pricingData={pricingData}
                                isPending={isPending}
                                isLive={isLive}
                                getData={getData}
                                artwork={artwork} />
                        </ItemDetails>
                        {isSecondary &&
                            <GridItem gridArea="stats">
                                <AdditionalStats>
                                    <EditionHistory artwork={artwork} pricingData={pricingData} />
                                    <Stats artwork={artwork} pricingData={pricingData} />
                                </AdditionalStats>
                            </GridItem>}

                    </Wrapper >
                    {showModal && <PlaceBidModal submitting={submitting}
                        setValid={setValid}
                        pricingData={pricingData}
                        valid={valid}
                        getData={getData}
                        blockchainAddress={blockchainAddress}
                        baseType={baseType}
                        showModal={showModal}
                        artwork={artwork}
                        setShowModal={setShowModal} />}
                    {showOfferModal && <MakeOfferModal submitting={submitting}
                        setValid={setValid}
                        pricingData={pricingData}
                        valid={valid}
                        closeModal={() => { setShowOfferModal(false) }}
                        getData={getData}
                        blockchainAddress={blockchainAddress}
                        baseType={baseType}
                        showModal={showOfferModal}
                        artwork={artwork}
                        setShowModal={setShowOfferModal} />}
                </InnerWrapper>
            </OuterWrapper>
        )
    }


}

export default ProductPage
