import React from 'react'

import { Wrapper, Content, Header, Filter } from './Sidebar.styles'

const Sidebar = ({ loading, sidebarContent, activeFilters, setActiveFilters, onClick }) => {
    console.log(activeFilters)
    if (loading) return <></>
    return (
        <Wrapper>
            <Content>
                {Object.keys(sidebarContent).map((value, index) => (
                    <div key={index}>
                        <Header key={value}>{value}</Header>
                        {sidebarContent[value].map(filter => {
                            const itemType = value === 'Artists' ? 'artist' : 'collection'
                            const item = value === 'Artists'
                                ? { type: itemType, id: filter.artist_id, address: filter.public_address, value: filter.display_name }
                                : { type: itemType, id: filter.collection_id, address: filter.contract_address, value: filter.collection_title }
                            return (
                                <Filter
                                    value={filter.id}
                                    key={value + item.id}
                                    isActive={activeFilters.find(it => (
                                        // it.type == item.type && it.id === item.id
                                        it[item.type] === item.address
                                    )) ? true : false}
                                    onClick={async (e) => {
                                        if (activeFilters.find(it => (
                                            // it.type == item.type && it.id === item.id
                                            it[item.type] === item.address
                                        ))) {
                                            // const updatedActiveFilter = activeFilters.filter(it => !(
                                            //     it.type == item.type && it.id === item.id
                                            // ))
                                            const updatedActiveFilter = activeFilters.filter(it => !(
                                                it[item.type] === item.address
                                            ))
                                            setActiveFilters(prev => ([...updatedActiveFilter]))
                                            //await fetchBids(filter)
                                        } else {
                                            // setActiveFilters(prev => ([...prev, { type: item.type, id: item.id, address: item.address }]))
                                            setActiveFilters(prev => ([...prev, { [item.type]: item.address }]))
                                        }

                                    }}>
                                    {item.value}
                                </Filter>
                            )
                        })}
                    </div>
                ))}
            </Content>
        </Wrapper>
    )
}

export default Sidebar