import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../Dropdown'
import DotMenuIcon from '../../images/verticalDotMenu.svg'

import {
    DropDownContainerBox,
    DotMenuWrapper
} from './DotMenu.styles'


const DotMenu = ({ dropDownMenuRef, dotMenuRef, setSelectedArtwork, options, hoverActive, artwork, fill }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const handleChange = (e) => {
        const value = parseInt(e.target.value)
        return options[value - 1].onClick()
    }

    useEffect(() => {
        if (!hoverActive)
            setMenuOpen(false)
    }, [hoverActive])

    if (!hoverActive)
        return <></>
    else return (
        <>
            <DropDownContainerBox ref={dropDownMenuRef} onClick={(e) => { e.stopPropagation(); }}>
                <Dropdown
                    name='header'
                    width="200px"
                    textAlign="right"
                    headerWidth="100px"
                    dropdownWidth="200px"
                    marginBottom={'0'}
                    listBackground="rgba(255,255,255,0.75)"
                    color="#000000"
                    // boxShadow="0 0 10px rgb(0,0,0)"
                    colorOnSelect="var(--goldSolid)"
                    dropdownListMarginTop="20px"
                    justifyContent="flex-start"
                    background="rgba(255,255,255,0.5)"
                    options={options}
                    valueX=""
                    setMenuState={setMenuOpen}
                    menuState={menuOpen}
                    handleStateChange={handleChange}
                    index={0}
                    id='filter'
                    setValidContinue={true}
                ></Dropdown>
            </DropDownContainerBox>
            <DotMenuWrapper
                ref={dotMenuRef}
                onClick={() => { setMenuOpen(!menuOpen); setSelectedArtwork(artwork); }}
                onMouseOver={() => { setMenuOpen(true); setSelectedArtwork(artwork); }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 16">
                    <g style={{ fill: '#000000' }}>
                        <circle cx="4" cy="2" r="2" />
                        <circle cx="4" cy="8" r="2" />
                        <circle cx="4" cy="14" r="2" />
                        {/* <circle cx="1.5" cy="7.5" r="3" />
                        <circle cx="1.5" cy="15.5" r="3" /> */}
                    </g>
                </svg>
            </DotMenuWrapper>
        </>
    )
}

export default DotMenu
