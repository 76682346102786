import React from 'react'

import {
    Wrapper,
    StepTitle,
    Step,
    Description,
    Content,
    Next,
    Back,
    Title,
    Button,
    Grid
} from './FormHeader.styles'

import FormProgressBar from '../FormProgressBar'
import FormNavButton from '../FormNavButton'
import GoldText from '../../GoldText'


const FormHeader = ({ validateStep, step, totalLength, userDetailsStep, nextStep, prevStep, artworkIndex, collectionLength }) => {
    console.log("step: ", step, totalLength, userDetailsStep)

    var stepTitle, desciption;
    switch (step) {
        case 1:
            stepTitle = 'Submit New Drop';
            desciption = "Collection Details"
            break;
        case 2:
            stepTitle = 'Submit New Drop';
            desciption = 'Upload Items'
            break;
        case 3:
            stepTitle = 'Submit New Drop';
            desciption = `Item Details | ${artworkIndex + 1} of ${collectionLength}`
            break;
        case 4:
            stepTitle = 'Submit New Drop';
            desciption = 'Review & Submit'
            break;


    }

    return (
        <Wrapper>
            <FormProgressBar step={step} totalLength={totalLength} userDetailsStep={userDetailsStep} />

            <Grid>

                <Back>
                    <FormNavButton height="35px" width="35px" direction="back" onClick={prevStep} validContinue={step !== 1 ? true : false} />
                </Back>
                <Content>
                    <StepTitle fontSize="20px">{stepTitle}</StepTitle>
                    <Description>{desciption}</Description>
                </Content>
                <Next>
                    <FormNavButton height="35px" width="35px" direction="next" onClick={nextStep} validContinue={validateStep(step)} />
                </Next>

            </Grid>
            <Button to="/account">&#10006;</Button>
        </Wrapper>
    )
}

export default FormHeader;