import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 2;
    height: ${props => props.visibility !== 'hidden' ? '200px' : '20px'};
    transition: height .5s linear;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => props.visibility ? props.visibility : 'visible'};
    border-bottom: ${props => props.visibility ? '1px solid var(--lightGrey)' : ''};
    scroll-behavior: smooth;
    mask-image: linear-gradient(90deg, transparent 2%, red 5%, red 95%, transparent 98%);
`;