import styled from "styled-components";

export const Wrapper = styled.div`
    display: grid;
    width: 100%;
    position: relative;
    padding: 20px;
    
    margin-top: ${props=> props.marginTop ? props.marginTop : ''};
    grid-template-columns:  1fr 1fr ;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    background: var(--grey);
    grid-template-areas: 
             'activelistings editions'
             'highestbid lowestask'
             'highestsale avgsale'
             'releaseDate numOfSales';
`;

export const Label = styled.div`
    font-size: 12px;
    text-transform: uppercase;
  
`;

export const Value = styled.div`
    font-size: 16px;
   
`;

export const ActiveListingsWrapper = styled.div`
grid-area: activelistings;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const EditionsWrapper = styled.div`
grid-area: editions;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const HighestBidWrapper = styled.div`
grid-area: highestbid;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const LowestAskWrapper = styled.div`
grid-area: lowestask;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const HighestSaleWrapper = styled.div`
grid-area: highestsale;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const AvgSaleWrapper = styled.div`
grid-area: avgsale;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const ReleaseDateWrapper = styled.div`
grid-area: releaseDate;
text-align: ${props => props.align ? props.align : 'left'};
`;

export const NumberOfSalesWrapper = styled.div`
grid-area: numOfSales;
text-align: ${props => props.align ? props.align : 'left'};
`;

