import React, { useRef } from 'react';

import { Wrapper, Image, Video } from './Artwork.styles'
import { getFileType } from '../../helpers'

const ImageArtwork = ({ image, title }) => {

    return (
        // <ImageWrapper>

        <Image src={image} alt={title} />

        // </ImageWrapper>
    )
}

const VideoArtwork = ({ artwork, isFeatured, width, height }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play().catch(e => { console.log("e", e) })
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <Video
            ref={videoRef}
            src={artwork}
            width={width}
            height={height}
            autoPlay={true}
            controls={false}
            onMouseOver={(event) => videoRef.current.play()}
            onMouseOut={(event) => videoRef.current.pause()}
            loop={true}
            muted
            playsInline
        />
    )
}

const Artwork = ({ artwork, width, height, borderRadius }) => {
    if (getFileType(artwork.image) === 'image') {
        return (
            <Wrapper width={width} height={height} borderRadius={borderRadius}>
                <a href={artwork.image} target="_blank">
                    <ImageArtwork image={artwork.image} title={artwork.name} />
                </a>
            </Wrapper>


        )
    }
    else {
        return (

            <Wrapper width={width} height={height} borderRadius={borderRadius}>
                <VideoArtwork artwork={artwork.image} title={artwork.name} width={width} height={height} />

            </Wrapper>

        )
    }
}

export default Artwork;