import React, { useState, useEffect, useRef, useCallback } from 'react'

import InputField from '../InputField'
import TextArea from '../TextArea'
import StepHeader from '../StepHeader'
import NavWrapper from '../NavWrapper'
import RadioBubbles from '../RadioBubbles'




import { Wrapper, QuestionWrapper, RowWrapper, Content } from './ArtworkDetails.styles'



const ArtworkDetails = ({ validateStep, step, priceOnBlur, handleKeyPress, artworkIndex, title, handleChange, setState, nextStep, values, prevStep }) => {
    const [validContinue, setValidContinue] = useState(false);

    useEffect(() => {

        validateStep(step);
    },[values.collectionArtworks[artworkIndex]])


    const continueStep = (event) => {
        event.preventDefault();


        if (validateStep(step)) {
            nextStep(artworkIndex);
        }

    }

    const previousStep = (event) => {
        event.preventDefault();
        prevStep()
    }

    return (
        <Wrapper>
            <Content>
                <QuestionWrapper>
                    <InputField
                        type="text"
                        onChange={handleChange('title')}
                        // onKeyPress={continueKey}
                        name='title'
                        label='Artwork Title'
                        placeholder=''
                        marginBottom={'0.8rem'}
                        value={values.collectionArtworks[artworkIndex].title}
                    // setValidContinue={setValidContinue}
                    />
                </QuestionWrapper>
                <QuestionWrapper>

                    <RadioBubbles
                        handleChange={handleChange('saleType')}
                        name='saleType'
                        label='Sale Method'
                        marginBottom={'0.8rem'}
                        options={["Auction", "Buy Now"]}
                        value={values.collectionArtworks[artworkIndex].saleType}
                    />
                </QuestionWrapper>

                <QuestionWrapper>
                    <TextArea
                        type="text"
                        onChange={handleChange('artworkDescription')}
                        // onKeyPress={continueKey}
                        name='artworkDescription'
                        label='Artwork Description'
                        placeholder=''
                        marginBottom={'0.8rem'}
                        value={values.collectionArtworks[artworkIndex].artworkDescription}
                    // setValidContinue={setValidContinue}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <RowWrapper>
                        <InputField
                            type="text"
                            onChange={handleChange('price')}
                            // onKeyPress={continueKey}
                            name='price'
                            priceOnBlur={priceOnBlur}
                            label={
                                values.collectionArtworks[artworkIndex].saleType ===
                                    'Auction' ? 'Minimum Bid (Per Edition)'
                                    : 'Price (Per Edition)'}
                            placeholder=''
                            marginBottom={'0.8rem'}
                            value={values.collectionArtworks[artworkIndex].price}
                        // setValidContinue={setValidContinue}
                        />
                        <InputField
                            type="text"
                            onChange={handleChange('maxMint')}
                            // onKeyPress={continueKey}
                            name='maxMint'
                            label='No. of Editions'
                            placeholder=''
                            marginBottom={'0.8rem'}
                            value={values.collectionArtworks[artworkIndex].maxMint}
                        // setValidContinue={setValidContinue}
                        />
                    </RowWrapper>
                </QuestionWrapper>
            </Content>
        </Wrapper>
    )
}

export default ArtworkDetails
