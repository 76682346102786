import styled from "styled-components";

export const Wrapper = styled.div`
height: ${props=> props.height? props.height : '100vh'};
position: ${props=> props.position ? props.position : 'fixed'};
width: ${props=> props.width? props.width : '100%'};
top: ${props=> props.top? props.top : '0'};
z-index: ${props=> props.networkError ? '15' : props.zIndex? props.zIndex : "3"};
background: ${props => props.background? props.background : 'var(--background)'};
display: flex;
justify-content: center;
align-items: center;
border: ${props => props.border? props.border : ''};
`;

export const LoadingWheelWrapper = styled.div`
    border: ${props => props.border ? props.border : '2px solid var(--lightestGrey)'};
    border-top: 2px solid var(--uiollyPurple);
    border-radius: 50%;
    width: ${props => props.radius ? props.radius : '50px'};
    height: ${props => props.radius ? props.radius : '50px'};
    animation: spin 2s ease-out infinite;

    @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% { transform: rotate(360deg);
    }
    }

`;

export const ErrorWrapper = styled.h2`
position: absolute;
text-transform: none;
margin-bottom: 200px;
text-align: center;`;