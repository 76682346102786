import React, { useState, useEffect, useRef } from 'react'
import { getCollectionItems, getURI, getItemData } from "../../blockchain/functionsUiollyNew";
import web3 from 'web3'
const Testing = () => {
    // 0xF9aB613BDc58f4422371836E9334fE6533dbCb1b
    useEffect(() => {
        const getItems = async () => {
            const items = await getCollectionItems('0x7AE7699902D32795Eb8B884Bb2Bd8c02Ad19Df31')
            const itemData = await getItemData('0x7AE7699902D32795Eb8B884Bb2Bd8c02Ad19Df31', 0)
            console.log(web3.utils.toBN(1) << 128)
            console.log(items, itemData)
        }
        getItems()
    }, [])
    return (//
        <div>Testing</div>
    )
}

export default Testing