import React, { useEffect } from 'react'

import { Route, Navigate, useLocation, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const PrivateRoute = () => {
    const location = useLocation()
    const { currentUser } = useAuth();
    useEffect(() => {
    },[currentUser])

    return currentUser ? <Outlet /> : <Navigate to="/login" state={{ from: location.pathname, isRerouted: true }} />;
}

export default PrivateRoute
