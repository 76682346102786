import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    Content,
    ViewMore,
    Row,
    Text
} from './HomePopularArt.styles'

import ArtworkCard from '../ArtworkCard'
import { getNonFungibleBaseType, getNonFungibleIndex } from '../../blockchain/blockchainUtils'

const HomePopularArt = ({ artworks }) => {
    const navigate = useNavigate()
    return (
        <Wrapper>
            <h1>Most Popular</h1>
            <Row>
                <Text>The marketplace artworks with the most traction.</Text>
                <ViewMore to='/marketplace'>View More</ViewMore>
            </Row>
            <Content>
                {artworks.map((artwork, index) => (
                    <ArtworkCard artwork={artwork}
                        key={artwork.secondaryMarketData.itemId}
                        isSecondary={true}
                        pricingData={artwork.secondaryMarketData}
                        onClick={() => { navigate(`/marketplace/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`, { state: { artwork: artwork } }) }}
                    />
                ))}
            </Content>
        </Wrapper>
    )
}

export default HomePopularArt
