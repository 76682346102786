import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    Frame,
    Content,
    ThumbnailBar,
    ArtworkContainer,
    ArtistInfo,
    NavLink,
    ThumbnailBarWrapper,
    FeaturedArtInfo,
    Bio,
    Artist,
    FrameImageWrapper,
    Container,
    Video
} from './HomeFeaturedArtists.styles'

import { getFileType } from '../../helpers'

const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <FrameImageWrapper>
            <Video
                ref={videoRef}
                src={artwork}
                width="100%"
                autoPlay={false}
                controls={false}
                onMouseOver={(event) => videoRef.current.play()}
                onMouseOut={(event) => videoRef.current.pause()}
                loop={true}
                muted
                playsInline
            />
        </FrameImageWrapper>
    )
}


const ImageArtwork = ({ artwork, title }) => {
    return (
        <FrameImageWrapper>
            <img src={artwork} alt={title} />
        </FrameImageWrapper>
    )
}

const Artwork = ({ artwork }) => {
    const fileType = getFileType(artwork.image)

    if (fileType === 'video') {
        return <VideoArtwork artwork={artwork.image || artwork.thumbnail || artwork.artwork_sample || artwork.profile_pic} title={artwork.artwork_title} />
    }
    else {
        return <ImageArtwork artwork={artwork.image || artwork.thumbnail || artwork.artwork_sample || artwork.profile_pic} title={artwork.artwork_title} />
    }
}

const HomeFeaturedArtists = ({ artists }) => { //artistName, artistID, picture, description
    const navigate = useNavigate()
    return (
        <Wrapper>
            <h3 style={{textAlign: 'center'}}>Featured Artists</h3>
            <Content>
                {
                    artists.map(artist => (
                        <Frame key={artist.artist_name} onClick={() => navigate(`/profile/${artist.handle}`)}>
                            <Artwork artwork={artist} />
                            <FeaturedArtInfo className="info"><Artist>{artist.artist_name}</Artist><Container className="bioContainer"><Bio className="bio">{artist.artist_bio}</Bio></Container></FeaturedArtInfo>
                        </Frame>
                    ))
                }
            </Content>
        </Wrapper>
    )
}

export default HomeFeaturedArtists
