import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import web3 from "web3";
import dayjs from 'dayjs'
import { buy, bid, getDeployedCollections, getCollectionItems, getURI, getItemData, getBids } from "../../../blockchain/functions";
import { useWeb3 } from '../../../contexts/Web3Context'
import ArtworkCard from './ArtworkCard'
import { Wrapper } from './Bids.styles'
const Bids = ({ ownedNFTs, userBids, fetchBids, content, fetchItems, claimNFTButtonDisabled, setClaimNFTButtonDisabled }) => {
    const { handleClaimNFT } = useWeb3()
    const [selectedArtwork, setSelectedArtwork] = useState(null)

    return (<Wrapper>
        {
            Object.keys(userBids).map((key) => (
                userBids[key].map((item, index) => {
                    console.log(item)
                    return (<ArtworkCard
                        key={index}
                        pricingData={item.secondaryMarketData !== undefined ? item.secondaryMarketData : item.primaryMarketData}
                        setSelectedArtwork={setSelectedArtwork}
                        // isClaimed={item.isClaimed !== undefined ? item.isClaimed : false}
                        // disabled={buttonDisabled}
                        disabled={claimNFTButtonDisabled}
                        setClaimNFTButtonDisabled={setClaimNFTButtonDisabled}
                        artwork={item}
                        fetchBids={fetchBids}
                        fetchItems={fetchItems}
                        // onClick={item.isClaimed !== undefined && !item.isClaimed ? () => { console.log("claim") } : undefined}
                    />)
                })
            ))
        }</Wrapper>);
};

export default Bids;
