import styled from "styled-components";

export const NavWrapperStyled = styled.div`
width: 100%;
display: grid;
grid-template-columns: ${props => props.backIsValid ? '1fr 1fr' : '1fr'};
grid-column-gap: 60px;
width: ${props => props.width ? props.width : '100%'};
height: ${props => props.height ? props.height : 'min-content'};
max-width: ${props => props.maxWidth ? props.maxWidth : '480px'};
justify-content: space-between;

`;