import React, { useState, useEffect } from 'react'
import API from '../../../API'
import web3 from 'web3'
import dayjs from 'dayjs'
import { updateRelTime, roundData } from '../../../utils'
import { roundDataWei } from '../../../blockchain/blockchainUtils'
import { Action, Wrapper, OuterWrapper, LoadWrapper } from './EditionHistory.styles'
import { getEditionEvents } from '../../../blockchain/functionsUiollyNew'

import DroppedIcon from '../../../images/droppedIcon.svg'
import ListedIcon from '../../../images/listedIcon.svg'
import PurchasedIcon from '../../../images/purchasedIcon.svg'
import GridItem from '../../GridItem'
import UserIcon from '../../UserIcon'
import LoadingWheel from '../../LoadingWheel'

const User = ({ user }) => {
    return <UserIcon handle={user.handle} radius="20px" image={user.profile_pic} />
}

const iconimage = (title) => {
    switch (title) {
        case 'DROPPED':
            return DroppedIcon;
        case 'PURCHASED':
            return PurchasedIcon;
        case 'LISTED':
            return ListedIcon;
        default:
            return null
            break;
    }
}

const Event = ({ event }) => {
    return (
        <>
            <GridItem gridCol="1 / span 1"><User user={event.user1} /></GridItem>
            <GridItem gridCol="2 / span 1"><Action><img src={iconimage(event.eventType)} alt={event.event} /></Action></GridItem>
            <GridItem gridCol="3 / span 1">{event.user2 !== null && <User user={event.user2} />}</GridItem>
            <GridItem gridCol="4 / span 1" justifyContent="center" fontSize="12px" fontWeight="400" alignItems='flex-start' whiteSpace="nowrap">{event.event}</GridItem>
            <GridItem gridCol="5 / span 1" justifyContent="center" color="var(--grey)" fontSize="10px" alignItems='flex-start' whiteSpace="nowrap">{dayjs.unix(event.time).fromNow()}</GridItem>
        </>)
}

const fetchUserDetails = async (wallet_address) => {
    const { userDetails } = await API.fetchUser(undefined, wallet_address)
    return userDetails
}

const organizeEventHistory = async (resultArr, historyArray, index) => {
    const { event, returnValues } = resultArr[index]
    historyArray.push({
        user1: await fetchEventUser1(historyArray, event, returnValues),
        event: fetchEventLabel(event, returnValues),
        user2: await fetchEventUser2(event, returnValues),
        time: returnValues.time,
        eventType: getEventTypeLabel(event, returnValues)
    })
}

const fetchEventUser1 = async (historyArray, event, returnValues) => {
    switch (event) {
        case 'itemSold':
            return historyArray[0].user1
        default:
            return await fetchUserDetails(returnValues.seller)
    }

}

const fetchEventUser2 = async (event, returnValues) => {
    switch (event) {
        case 'MarketItemCreated':
            return null
        case 'OfferAccepted':
            return await fetchUserDetails(returnValues.offerer)
        default:
            return await fetchUserDetails(returnValues.buyer)
    }

}

const fetchEventLabel = (event, returnValues) => {
    switch (event) {
        case 'itemSold':
            return `Purchased for ${roundDataWei(returnValues.pricePaid, 3)}`
        case 'MarketItemCreated':
            return `Listed for ${roundDataWei(returnValues.price, 3)}`
        case 'ItemSold':
            return `Purchased for ${roundDataWei(returnValues.price, 3)}`
        case 'OfferAccepted':
            return `Purchased for ${roundDataWei(returnValues.offer, 3)}`
        default:
            return ''
    }
}

const getEventTypeLabel = (event) => {
    switch (event) {
        case 'MarketItemCreated':
            return 'LISTED'
        default:
            return 'PURCHASED'
    }
}

const EditionHistory = ({ artwork }) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const init = async () => {
            try {

                // updateRelTime()
                const history = []
                const res = await getEditionEvents(artwork.contractAddress, artwork.tokenId)
                const { userDetails } = await API.fetchProfile(artwork.artist_handle)
                history.push({
                    user1: userDetails,
                    event: `Dropped for ${roundDataWei(artwork.primaryMarketData.price, 3)}`,
                    user2: null,
                    time: artwork.primaryMarketData.salesOpenTime,
                    eventType: 'DROPPED'
                })
                if (res.length) {
                    for (let i = 0; i < res.length; i++) {
                        await organizeEventHistory(res, history, i)
                    }
                    setData(history.sort((a, b) => parseInt(b.time) - parseInt(a.time)))
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        init()

    }, [])

    return (
        <OuterWrapper>
            <h1>Edition History</h1>
            <Wrapper>
                {loading && <GridItem
                    position="relative"
                    height="calc(140px - 40px)"
                    gridCol="1 / span end"
                    gridRow="1 / span end">
                    <LoadingWheel background="transparent" position="relative" radius="20px" height="100%" width="100%" />
                </GridItem>
                }
                {!loading &&
                    data.map((it, idx) => <Event key={idx} event={it} />)
                }
            </Wrapper>
        </OuterWrapper>
    )
}

export default EditionHistory