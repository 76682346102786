import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(2 * var(--navBarHeight));
    /* height: calc(100vh); */
    height: 100%;
`;

export const Center = styled.div`
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
row-gap: 10px;
`;

export const ArtistBio = styled.div`
    display: grid;
    min-height: 200px;
    grid-template-columns: 10% auto 10%;
    grid-template-areas: 'spacer1 content spacer2';
    width: 100%;
    color: var(--lightGrey);
    position: relative;
    grid-column-gap: 20px;
    > div {
        border: 1px solid var(--lightGrey);
    }
`;

export const ArtistBioText = styled.div`
    font-size: 14px;
    display: flex;
    position: relative;
    max-width: 800px;
`;


export const SocialWrapper = styled.a`
all: unset;
cursor: pointer;
height: 15px;
width: 15px;
position: relative;
img{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
`;

export const ArtistBioContent = styled.div`
position: relative;
display: flex;
height: 100%;

justify-content: center;
border: 1px solid var(--lightGrey);
grid-area: content;
padding: 40px;
flex-direction: column;

a{
    all: unset;
    font-size: 12px;
    color: var(--goldSolid);
    cursor: pointer;
    font-weight: 500;
    display: flex;
    width: min-content;

}
`

export const CollectionWrapper = styled.div`
display: grid;
text-align: left;
grid-template-columns: 1fr 1fr;
grid-template-rows: min-content min-content;
font-size: 12px;
width: 100%;
max-width: 800px;
&>:nth-child(2){
text-align: right;
}
`;

export const CollectionCoverWrapper = styled.div`
    width: 100%;
    column-span: 2;
    height: 300px;
    overflow: hidden;
    grid-column: 1 / 2 end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
   
`;

export const ArtistBioHeader = styled.h2`
position: relative;
text-transform: none;
margin: 0;
`;

export const Spacer = styled.div`
position: relative;
display: flex;
height: 100%;
width: 100%;
border: 1px solid var(--lightGrey);
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`

export const Social = styled.div`
display: flex;
column-gap: 10px;
width: min-content;
max-width: 500px;
`;

export const ArtistBioPicture = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    img{
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;

    }
`;

export const DropCountdownWrapper = styled.div`
    display: flex;
    color: var(--lightGrey );
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    text-transform: uppercase;
    img{
        height: 60px;
    }
`;

export const DropdownCounter = styled.div`
    background: var(--goldGradient);
    font-size: 3em;
    
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ToggleWrapper = styled.div`
display: flex;
column-gap: 10px;
position: relative;
/* div:not(:first-child){
    margin-left: 1rem;
} */
`;

export const ToggleOption = styled.div`
cursor: pointer;
border-bottom: ${props => props.isSelected ? '1px solid var(--primary)' : 'var(--secondary)'};
color:  ${props => props.isSelected ? 'var(--primary)' : 'var(--secondary)'};
user-select: none;
font-size: 14px;
padding: 5px 0;
text-transform: uppercase;

`;

export const EditProfileLink =  styled(Link)`
all: unset;
cursor: pointer;
display: flex;
align-items: center;
text-transform: uppercase;
background: var(--alphaPurple);
font-size: 12px;
color: var(--primary);
border: 1px solid var(--primary);
height: 30px;
padding: 0 20px;
img{
    margin-left: 5px;
    height: 15px;
    width: 15px;
}
`;

export const ContentWrapper = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
overflow: auto;
padding: 50px;
position: relative;
justify-content: center;
`;