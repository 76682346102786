import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    sendPasswordResetEmail
} from "firebase/auth";

import API from '../API'
import { useWeb3 } from './Web3Context'
const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const { connectWallet } = useWeb3();
    const [user, setUser] = useState(null)
    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }
    const logout = (email, password) => {
        return signOut(auth)
    }
    const reAuth = (password) => {
        const { email } = currentUser;
        const credential = EmailAuthProvider.credential(email, password);
        return reauthenticateWithCredential(user, credential);
    }

    const changePassword = (password) => {
        return updatePassword(user, password)
    }
    const sendResetPasswordEmail = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    const retrieveUserDetails = async (email) => {
        try {
            // setCurrentUser(prev => ({ ...userDetails, userDetails: additionalDetails, isArtist: isArtist }))
            API.fetchUser(email).then(({userDetails, isArtist}) => {
                setCurrentUser(prev => ({ ...prev, userDetails, isArtist }))
            })
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {


        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                //setCurrentUser({user, user: user});
                setCurrentUser(user);
                //retrieveUserDetails(user.email)
                API.setAccessToken(user.email, user.accessToken).then(() => {
                    retrieveUserDetails(user.email)
                    //setUser(user) // check
                    connectWallet().catch(e=> console.log(e))
                    console.log("user is logged in")
                })
                setUser(user)

            }
            else {
                console.log("user has logged out")
                setCurrentUser(null);
                setUser(null)
            }

        })
        return unsubscribe // unsubscribe from listener on unmount
    }, [])
    const value = { currentUser, signup, login, logout, reAuth, changePassword, retrieveUserDetails, sendResetPasswordEmail }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

