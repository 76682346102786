import styled from "styled-components";

export const Wrapper = styled.div`
position: fixed;
right: 30px;
bottom:  ${props => props.showMsg ? '30px' : '-200px'};
transition: all 0.5s;
column-gap: 10px;
font-size: 12px;
white-space: nowrap;
border-radius: 5px;
z-index: 11;
display: flex;
align-items: center;
padding: 10px;
background: #e0ddff;
border: 1px solid var(--fontColor);
`;

export const CloseWrapper = styled.div`
display: flex;
align-items: center;
width: 25px;
padding: 3px;
`;