import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import web3 from "web3";
import {
    OuterWrapper,
    Wrapper,
    ImageWrapper,
    EditionSize,
    DetailsWrapper,
    CurrentSaleType,
    CurrentPriceValue,
    Title,
    Pricing,
    PriceLabel,
    CollectionSize,
    Artist,
    Image,
    Video,
    EdNumber,
    Row
} from './ArtworkCard.styles'
import { convert_WEI_ETH, getNonFungibleIndex, getNonFungibleBaseType } from '../../../../blockchain/blockchainUtils'
import { removeLeadingSlash, getFileType, varNameToString } from '../../../../helpers'
import API from '../../../../API'
import {
    VALID_FILE_TYPES,
    ACCOUNT_ACTIVITY_OPTIONS,
    TRANSACTIONS_CATEGORY,
    BIDS_CATEGORY,
    VALID_BID_FILTERS,
    ARTIST_ACCCOUNT_ACTIVITY_OPTIONS,
    VALID_TRANSACTION_FILTERS,
    TRANSACTION_FILTER_PURCHASED,
    TRANSACTION_FILTER_FOR_SALE,
    TRANSACTION_FILTER_OFFERS_MADE,
    TRANSACTION_FILTER_OFFERS_RECEIVED,
    TRANSACTION_FILTER_OFFERS_EXPIRED,
    PENDING,
    UNMINTED,
    UNSCHEDULED,
    SCHEDULED,
    LIVE,
    PAST,
    PURCHASED,
    SOLD,
    OFFERS_MADE,
    ALL_LISTED,
    OFFERS_RECEIVED
} from '../../../../config'
import { useNavigate } from 'react-router-dom'
import Button from '../../../Button'
import GridItem from '../../../GridItem'
import DotMenu from '../../../DotMenu'
import { useImportCollectionStatus } from '../../../../contexts/CollectionStatusContext'
import { useWeb3 } from '../../../../contexts/Web3Context'
const ImageArtwork = ({ artwork, title }) => {
    return (
        <ImageWrapper>
            <Image src={artwork} alt={title} />
        </ImageWrapper>
    )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (

        <Video
            ref={videoRef}
            src={artwork}
            width="100%"
            autoPlay={false}
            controls={false}
            onMouseOver={(event) => videoRef.current.play()}
            onMouseOut={(event) => videoRef.current.pause()}
            loop={true}
            muted
            playsInline
        />
    )
}

const DotMenuWrapper = ({ options, dropDownMenuRef, dotMenuRef, showMenu, setShowMenu, setShowModal, setSelectedArtwork, artwork, hoverActive }) => {
    return (
        <DotMenu
            dotMenuRef={dotMenuRef}
            dropDownMenuRef={dropDownMenuRef}
            setSelectedArtwork={setSelectedArtwork}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            options={options}
            hoverActive={hoverActive}
            artwork={artwork}
        />
    )
}

const Artwork = ({ artwork, title }) => {
    const fileType = getFileType(artwork.image)

    if (fileType === 'video') {
        return <VideoArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
    }
    else {
        return <ImageArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
    }
}

const PricingData = ({ isPending, isLive, artwork, filter, pricingData }) => {
    console.log("pricingData", pricingData, filter)
    const formatPrice = () => {
        if (pricingData.isAuction) {
            if (pricingData.constructor.name === 'PrimaryMarketData')
                return { price: convert_WEI_ETH(pricingData.bids[artwork.edition - 1]), label: 'Price' }
            else return convert_WEI_ETH(pricingData.auctionHigh) > 0
                ? { price: convert_WEI_ETH(pricingData.auctionHigh), label: 'Highest Bid' }
                : { price: convert_WEI_ETH(pricingData.price), label: 'Min Bid' }
        } else return { price: convert_WEI_ETH(pricingData.price), label: 'Price' }
    }

    const { price, label } = formatPrice()
    return (
        <GridItem
            display="grid"
            gridColGap="8px"
            margin="0.5rem 0 0"
            gridTempCols="1fr 1fr"
            gridTempRows="min-content min-content">
            {(filter !== varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }) || !pricingData.highestOffer) &&
                <>
                    <GridItem gridCol="1" gridRow="1" alignItems="start" ><CurrentPriceValue>{price}Ξ</CurrentPriceValue></GridItem>
                    <GridItem gridCol="1" gridRow="2" alignItems="start" ><PriceLabel>{label}</PriceLabel></GridItem>
                </>
            }
            {
                (filter === varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }) && pricingData.highestOffer > 0) && <>
                    <GridItem gridCol="1" gridRow="1" alignItems="start" ><CurrentPriceValue>{pricingData.highestOffer + 'Ξ'}</CurrentPriceValue></GridItem>
                    <GridItem gridCol="1" gridRow="2" alignItems="start"><PriceLabel>Highest Offer</PriceLabel></GridItem>
                </>
            }
            {
                filter === varNameToString({ TRANSACTION_FILTER_OFFERS_RECEIVED }) && <>
                    <GridItem gridCol="2" gridRow="1" alignItems="end" ><CurrentPriceValue>{pricingData.offer.price}</CurrentPriceValue></GridItem>
                    <GridItem gridCol="2" gridRow="2" alignItems="end"><PriceLabel>Offer</PriceLabel></GridItem>
                </>
            }
            {
                (filter === varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }) && pricingData.myOffer) && <>
                    <GridItem gridCol="2" gridRow="1" alignItems="end" ><CurrentPriceValue>{pricingData.myOffer.price}</CurrentPriceValue></GridItem>
                    <GridItem gridCol="2" gridRow="2" alignItems="end"><PriceLabel>My Offer</PriceLabel></GridItem>
                </>
            }
            {(filter === varNameToString({ TRANSACTION_FILTER_FOR_SALE }) && !pricingData.isAuction && pricingData.highestOffer !== 0) && <>
                <GridItem gridCol="2" gridRow="1" alignItems="end" ><CurrentPriceValue>{pricingData.activeOffers.find(it => Number(it.price.replace('Ξ', '')) === pricingData.highestOffer).price}</CurrentPriceValue></GridItem>
                <GridItem gridCol="2" gridRow="2" alignItems="end"><PriceLabel>Highest Offer</PriceLabel></GridItem>
            </>
            }
        </GridItem>
    )
}

const Edition = ({ artwork, isLive, contractData, isPending }) => {
    if (artwork.supply === undefined) {
        return (<EditionSize>{artwork.max_mint} Edition{artwork.max_mint === '1' ? '' : 's'}</EditionSize>)
    }
    else if (artwork.supply !== undefined && isPending) {
        return (<EditionSize>{artwork.supply} Editions</EditionSize>)
    }
    else if (artwork.supply !== undefined && isLive) {
        const r = artwork.supply - artwork.currentSupply;
        return (<EditionSize>{r} {'Remaining'}</EditionSize>)
    } else {
        return (<EditionSize>{artwork.currentSupply !== 0 ? `${artwork.currentSupply} Minted` : ''} </EditionSize>)
    }

}


const ArtworkCard = ({ pricingData, filter, fetchItems, setResellToken, artwork, onClick, itemContractData, setShowModal, setSelectedArtwork }) => {
    const navigate = useNavigate();
    const [hoverActive, setHoverActive] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [contractData, setContractData] = useState(null)

    const [disabled, setDisabled] = useState(false)
    const buttonRef = useRef(null)
    const dotMenuRef = useRef(null)
    const dropDownMenuRef = useRef(null)
    const isPending = useRef(dayjs().unix() < parseInt(artwork.salesOpenTime));
    const isLive = useRef(dayjs().unix() < parseInt(artwork.salesCloseTime) && dayjs().unix() > parseInt(artwork.salesOpenTime))
    const { setTokenResale, marketplaceAcceptOffer, marketplaceRevokeOffer } = useWeb3();

    const getOnClick = (filter) => {
        switch (filter) {
            case varNameToString({ TRANSACTION_FILTER_PURCHASED }):
                return () => {
                    setHoverActive(false)
                    setShowMenu(false)
                    setResellToken({ artwork, showModal: true })
                }
            case varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }):
                return async () => {
                    // cancel offer
                    await marketplaceRevokeOffer(artwork.itemId)
                    await fetchItems(filter)
                }
            case varNameToString({ TRANSACTION_FILTER_OFFERS_EXPIRED }):
                return async () => {
                    console.log("artwork!!!!!!!!!!!!!!!!!!!!", artwork)
                    await marketplaceRevokeOffer(artwork.itemId)
                    await fetchItems(filter)
                }
            case varNameToString({ TRANSACTION_FILTER_OFFERS_RECEIVED }):
                return async () => {
                    // accept offer
                    if (!pricingData.isAuction) {
                        await marketplaceAcceptOffer(artwork.offer.itemId, artwork.offer.offerId)
                        await fetchItems(filter)
                    }
                }
            default:
                return () => { }
        }
    }

    const getNavDestination = (filter) => {
        switch (filter) {
            case varNameToString({ TRANSACTION_FILTER_PURCHASED }):
                return 'marketplace'
            default:
                return 'marketplace'
        }
    }

    const getOptions = (filter) => {
        const options = [{
            value: 1, innerText: 'View Artwork', onClick: () => {
                navigate(`/${getNavDestination(filter)}/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`)
            }
        }]
        switch (filter) {
            case varNameToString({ TRANSACTION_FILTER_FOR_SALE }):
                return options
            case varNameToString({ TRANSACTION_FILTER_PURCHASED }):
                options.push({
                    value: 2, innerText: 'Sell on Marketplace',
                    onClick: getOnClick(filter)
                })
                return options
            case varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }):
                options.push({
                    value: 2, innerText: 'Cancel Offer',
                    onClick: getOnClick(filter)
                })
                return options
            case varNameToString({ TRANSACTION_FILTER_OFFERS_EXPIRED }):
                options.push({
                    value: 2, innerText: 'Retrieve Funds',
                    onClick: getOnClick(filter)
                })
                return options
            case varNameToString({ TRANSACTION_FILTER_OFFERS_RECEIVED }):
                options.push({
                    value: 2, innerText: 'Accept Offer',
                    onClick: getOnClick(filter)
                })
                return options
            default:
                return options

        }
    }
    const [options, setOptions] = useState(getOptions(filter))

    useEffect(() => {
        if (itemContractData) {
            setContractData({ ...itemContractData })
        }
    }, [itemContractData])
    useEffect(() => {
        setOptions(getOptions(filter))
    }, [filter])
    const mouseOver = (e) => {
        e.stopPropagation();
        if (setHoverActive)
            setHoverActive(true)
        setShowMenu(true)
    }
    const mouseOut = (e) => {
        e.stopPropagation();
        if (setHoverActive)
            setHoverActive(false)
        setShowMenu(false)
    }
    if (pricingData.price === undefined) return <OuterWrapper></OuterWrapper>
    else
        return (
            <OuterWrapper>
                <Wrapper
                    onMouseEnter={(e) => mouseOver(e)}
                    onMouseLeave={(e) => mouseOut(e)}
                    cursor={'pointer'}
                    onClick={
                        typeof (onClick) === 'function' ?
                            onClick
                            : (e) => {
                                if (e.target !== buttonRef.current &&
                                    e.target !== dotMenuRef.current &&
                                    e.target !== dropDownMenuRef.current &&
                                    e.target.tagName !== 'LI' &&
                                    e.target.tagName !== 'UL' &&
                                    !e.target.className.includes("buttonText")) {
                                    // navigate(`/collections/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`)
                                    options[0].onClick()
                                }
                            }}
                // onClick={onCardSelect ? onCardSelect : () => { navigate(`/collections/${collection.contract_address}`, { state: { collection: collection, statusKey: statusKey } }) }}
                >
                    <DotMenuWrapper
                        dotMenuRef={dotMenuRef}
                        options={options}
                        setShowModal={setShowModal}
                        dropDownMenuRef={dropDownMenuRef}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        hoverActive={hoverActive}
                        artwork={artwork}
                        setSelectedArtwork={setSelectedArtwork}
                    />
                    <Artwork artwork={artwork} title={artwork.artwork_title} />
                    <DetailsWrapper isPending={isPending.current}>

                        <div style={{ display: 'flex' }}><Title>{artwork.name}</Title><EdNumber>#{parseInt(artwork.edition)}</EdNumber></div>
                        <Artist>{artwork.artist_name}</Artist>
                        <PricingData filter={filter} isPending={isPending.current} isLive={isLive.current} artwork={artwork} contractData={contractData} pricingData={pricingData} />
                        <Row>
                            {(isLive.current || isPending.current) && <CurrentSaleType>{pricingData.isAuction ? 'Auction' : 'Fixed Price'}</CurrentSaleType>}
                            <Edition isPending={isPending.current} isLive={isLive.current} artwork={artwork} contractData={contractData} />
                        </Row>
                    </DetailsWrapper>
                </Wrapper>
            </OuterWrapper >
        )
}

export default ArtworkCard
