import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    /* height: calc ( 100vh - 60px ); */
    position: relative;
   margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : ''};
    padding: ${props => props.padding ? props.padding : ''};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
`;

export const SectionWrapper = styled.div`
margin-top: 200px;
position: relative;
width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;


`;
export const EmailSignupWrapper = styled.div`
max-width: 400px;
width: 90%;
text-align: center;
position: relative;
`;

export const InputWrapper = styled.div`
width: 100%;
position: relative;
align-items: center;
display: flex;
`;
export const InputWrapperButton = styled.div`
width: 50px;
position: absolute;
right: 0;
height: 100%;
display: flex;
padding: 5px;
`;

export const ContentWrapper = styled.div`
min-height: calc(100vh - var(--navBarHeight));
`;