import React from 'react'
import {
    Wrapper,
    CollectionArtworks,
    CollectionArtworksHeader,
    CollectionArtworksHeaderBgd
} from './DropsArtworkContent.styles'

import ArtworkCard from '../ArtworkCardOLD'

const DropArtworksContent = ({ collectionArtworks, text, textColor }) => {

    const headerText = text ? text : ` Item${collectionArtworks.length > 1 ? 's' : ''} in this collection`;

    return (
        <Wrapper>
            <CollectionArtworksHeader>
                <CollectionArtworksHeaderBgd textColor={textColor}>
                    {collectionArtworks.length}{headerText}
                </CollectionArtworksHeaderBgd>
            </CollectionArtworksHeader>
            <CollectionArtworks>
                {collectionArtworks.map((artwork, index) => (
                    <ArtworkCard
                        value={artwork}
                        key={index}
                        collectionState="pending"
                        market="primary" />
                ))}
            </CollectionArtworks>
        </Wrapper>
    )
}

export default DropArtworksContent
