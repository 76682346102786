import React from 'react';

import Pending from '../../images/pending.svg'
import Live from '../../images/live.svg'
import Unminted from '../../images/mintIcon.svg'
import Unscheduled from '../../images/pending.svg'
import Past from '../../images/past.svg'
import Upcoming from '../../images/upcoming.svg'

import API from '../../API'
import { Wrapper } from './CollectionStatusIcon.styles'
import { unCamelCase, returnMonth } from '../../helpers'

const Icon = ({ status, isSelected }) => {
    switch (status) {
        case "PENDING":
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.42 16.55">
                <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                    <path d="M6.34,6.93a3,3,0,0,0,.91-1.77H4.17A4.75,4.75,0,0,0,5.72,7.64C5.93,7.4,6.15,7.17,6.34,6.93Z" />
                    <path d="M10.92,15.55h-.68a.86.86,0,0,0,0-.16v-2c0-2-1.28-3.41-2.44-4.66-.14-.17-.3-.34-.45-.5l.49-.54c.26-.29.54-.57.79-.87a5.82,5.82,0,0,0,1.61-3.76V1.16a.81.81,0,0,0,0-.16h.68a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H.5A.5.5,0,0,0,0,.5.5.5,0,0,0,.5,1h.68a.81.81,0,0,0,0,.16v2c0,2,1.3,3.42,2.43,4.67.16.17.32.33.45.5-.15.18-.31.35-.49.53C2.44,10,1.17,11.42,1.17,13.44v2a.44.44,0,0,0,0,.16H.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5H10.92a.5.5,0,0,0,.5-.5A.5.5,0,0,0,10.92,15.55ZM2.17,13.44c0-2,1.6-3.3,2.8-4.69a.74.74,0,0,0,0-1C3.8,6.44,2.17,5,2.17,3.11V1.16H9.25v2A4.76,4.76,0,0,1,7.88,6.22c-.46.54-1,1.06-1.43,1.58a.74.74,0,0,0,0,1c1.17,1.37,2.8,2.75,2.8,4.69v2H8.4v-1.1c-.05-1-1-2-2.06-3a1,1,0,0,0-1.28,0c-1.25,1.19-2,2.07-2,3v1.1H2.17Z" />
                </g></svg>)

        case "UNMINTED":
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
                <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                    <g id="bgd">
                        <path d="M6.54.12C7.28.05,7.92,0,8.37,0L6.54,1.83Z" />
                        <path d="M5.65.23V2.72l-1.1,1.1V.41Z" />
                        <path d="M3.66.6V4.71L2.44,5.93V1A9.69,9.69,0,0,1,3.66.6Z" />
                        <path d="M1.55,1.45V6.82l-.6.6C.37,6.54-.83,4.21.88,2.05A3,3,0,0,1,1.55,1.45Z" />
                        <path d="M8.88,2.46H7.17L9,.63C9,1.08,9,1.73,8.88,2.46Z" />
                        <path d="M8.59,4.45H5.18l1.1-1.1H8.77C8.72,3.71,8.66,4.08,8.59,4.45Z" />
                        <path d="M8,6.56H3.07L4.29,5.34H8.4A9.69,9.69,0,0,1,8,6.56Z" />
                        <path d="M7,8.12c-2.16,1.71-4.49.51-5.37-.07l.6-.6H7.55A3,3,0,0,1,7,8.12Z" /></g></g></svg>)
            break;
        case "UNSCHEDULED":
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 284.35">
                <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                    <g id="bgd">
                        <path d="M56,29.35c0-6.44,0-12.09,0-17.73C56,4.58,60.12.08,66.33.08s10.24,4.55,10.35,11.6c.08,5.65,0,11.29,0,17.3H189.05c0-5.76-.08-11.68,0-17.59.1-5.72,3-9.81,7.65-11,6.55-1.73,12.77,3,13,10.06.21,6.11,0,12.24,0,18.93,11.63,0,22.74,0,33.85,0,14.66,0,22.1,7.42,22.11,22.15q0,105.73,0,211.46c0,13.78-7.57,21.38-21.29,21.38q-111.48,0-222.95,0C7.65,284.34,0,276.87,0,263.14Q0,156.93,0,50.69C0,37,7.75,29.37,21.43,29.35,32.73,29.33,44,29.35,56,29.35Zm-39.7,76.32c-.11,1.7-.29,3.16-.29,4.61q0,74.49,0,149c0,6.9,2.32,9.11,9.41,9.11H239.86c7.94,0,9.92-2,9.92-10.09q0-73.74,0-147.48c0-1.62-.14-3.24-.23-5.14ZM56,46.18c-5.65,4.91-7,11.2-4.44,17.53a16,16,0,0,0,30.27-2.1c1.51-6.08-.35-11.78-5.12-14.94,0,3.52.16,6.84,0,10.14C76.29,63,71.84,67.31,66.1,67.2S56.16,62.68,56,56.42C55.89,53.19,56,50,56,46.18Zm133.09.11a15.23,15.23,0,0,0-.74,22.8,16,16,0,0,0,23.5-1.33c5.49-6.67,4.71-16.56-2-21.15,0,3.21,0,6.17,0,9.12-.13,7-4.24,11.49-10.41,11.47-5.87,0-10-4.51-10.28-11.18C189,53,189.06,49.87,189.06,46.29Z" />
                        <path d="M109.2,155.17H99.72c-3.6,0-5.72-1.68-5.76-5.36-.09-6.65-.07-13.31,0-20,0-3.44,2-5.24,5.34-5.29,6.65-.1,13.31-.07,20,0,3.4.05,5.27,2,5.33,5.34.11,6.65.16,13.31,0,20-.09,3.54-2.21,5.38-5.87,5.31C115.53,155.12,112.36,155.17,109.2,155.17Z" /><path d="M171.81,140.12c0,3.16,0,6.32,0,9.48,0,3.64-1.9,5.54-5.56,5.55-6.48,0-13,0-19.46,0-3.73,0-5.54-2-5.61-5.56q-.18-9.73,0-19.46c.06-3.54,2-5.57,5.63-5.59,6.49,0,13-.07,19.47,0,3.9,0,5.66,2.22,5.58,6.08-.06,3.16,0,6.32,0,9.48Z" /><path d="M219,140c0,3.33,0,6.65,0,10,0,3.47-1.92,5.2-5.3,5.22-6.65.05-13.3.08-19.94,0-3.43,0-5.17-2-5.18-5.38,0-6.65,0-13.3,0-19.94,0-3.49,2-5.21,5.31-5.26,6.48-.08,13,0,19.44,0,3.86,0,5.79,2,5.69,5.93C219,133.63,219,136.79,219,140Z" /><path d="M46.71,139.55c0-3.16,0-6.31,0-9.47,0-3.72,2-5.5,5.56-5.53,6.48-.06,13-.09,19.44,0,3.62,0,5.47,2,5.46,5.67,0,6.49,0,13,0,19.45,0,3.69-1.92,5.49-5.56,5.5-6.49,0-13,0-19.45,0-3.69,0-5.45-2-5.43-5.63C46.73,146.2,46.71,142.88,46.71,139.55Z" /><path d="M171.83,234.69c.06,16.13,1.16,14.85-14.74,14.81-3.32,0-6.65-.06-10,0-3.92.08-5.93-1.85-6-5.69q-.13-9.47,0-18.95c0-3.73,1.81-5.89,5.82-5.85q9.48.09,19,0c4.23,0,6,2.1,5.95,6.18C171.79,228.37,171.84,231.53,171.83,234.69Z" />
                        <path d="M108.88,202.35c-3,0-6,0-9,0-4.16,0-6-2.06-6-6.16.06-6,.1-12,0-18-.08-4.34,1.78-6.49,6.23-6.43,6.15.08,12.3.05,18.45,0,4.2,0,6.05,2.11,6,6.15,0,6.15,0,12.3,0,18.46q0,6.12-6.25,5.94C115.2,202.26,112,202.34,108.88,202.35Z" /><path d="M109.05,249.5c-3.16,0-6.32,0-9.48,0-3.6,0-5.63-1.73-5.64-5.45q0-9.72,0-19.44c0-3.64,1.8-5.56,5.5-5.56,6.48,0,13,0,19.45,0,3.71,0,5.62,1.86,5.69,5.43q.17,9.72,0,19.45c-.06,3.84-2.17,5.7-6.07,5.6C115.36,249.43,112.2,249.49,109.05,249.5Z" /><path d="M171.82,187.14v9.47c0,3.61-1.73,5.67-5.41,5.7-6.65.06-13.29,0-19.94,0-3.48,0-5.23-2.09-5.3-5.34q-.19-10,0-19.94c.08-3.33,2-5.18,5.43-5.19,6.48,0,13,0,19.44,0,3.89,0,5.81,1.92,5.77,5.84C171.8,180.82,171.83,184,171.82,187.14Z" /><path d="M77.16,187.18c0,3.15,0,6.31,0,9.46,0,3.6-1.61,5.66-5.37,5.67-6.64,0-13.28.05-19.92,0-3.45,0-5.15-1.94-5.15-5.35,0-6.64,0-13.29,0-19.93,0-3.45,1.94-5.16,5.34-5.17,6.47,0,12.95,0,19.43,0,3.93,0,5.74,2,5.68,5.89C77.13,180.87,77.17,184,77.16,187.18Z" /><path d="M219,187.53c0,16.09,1.36,14.85-14.66,14.82-3.49,0-7,0-10.46,0s-5.25-1.83-5.28-5.23c-.07-6.64-.07-13.29,0-19.93,0-3.42,1.81-5.28,5.22-5.31,6.64-.07,13.28,0,19.93,0,3.71,0,5.36,2.09,5.3,5.72C219,180.89,219,184.21,219,187.53Z" /><path d="M46.7,234.33c0-3.16,0-6.31,0-9.47C46.63,221,48.44,219,52.4,219c6.48,0,12.95,0,19.43,0,3.46,0,5.27,1.78,5.3,5.19.06,6.64.05,13.28,0,19.93,0,3.4-1.77,5.27-5.21,5.29-6.64.06-13.28.05-19.92,0-3.73,0-5.36-2.08-5.31-5.7C46.73,240.64,46.7,237.48,46.7,234.33Z" />
                    </g></g></svg>
            )
        case "UPCOMING":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.54 16.53">
                    <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                        <g id="bgd">
                            <path d="M15.68,0a1.27,1.27,0,0,0-.28,0h-.24l-.68.06A16.22,16.22,0,0,0,8,2.31,12.81,12.81,0,0,0,4.84,4.85H4.72c-.19,0-.39,0-.59,0A3.24,3.24,0,0,0,1.75,6.09,9.36,9.36,0,0,0,0,9.74a.91.91,0,0,0,0,.34,1,1,0,0,0,.34.75,1,1,0,0,0,.65.24h.15l1.1-.16A2.34,2.34,0,0,0,1.56,12a7.93,7.93,0,0,0-.24,2.08,1.19,1.19,0,0,0,.1.46.93.93,0,0,0,.92.62h0a8,8,0,0,0,2.33-.32,2.17,2.17,0,0,0,.91-.62l-.16,1.13a1,1,0,0,0,.33.89,1,1,0,0,0,.66.25,1.24,1.24,0,0,0,.27,0l.2-.06c.15,0,.3-.07.44-.12a8.68,8.68,0,0,0,3.26-1.71,2.86,2.86,0,0,0,.89-1.41,5.14,5.14,0,0,0,.19-1.53l.32-.29a15.82,15.82,0,0,0,4.38-8.86c0-.28.07-.57.1-.85l0-.36a.49.49,0,0,0,0-.12V1A1,1,0,0,0,15.68,0ZM5.08,13.26a1.13,1.13,0,0,1-.71.67,6.91,6.91,0,0,1-2,.27.25.25,0,0,1,0-.07,7,7,0,0,1,.21-1.83,1.21,1.21,0,0,1,.71-.87l.1,0a.15.15,0,0,1,.11.05,2.47,2.47,0,0,0,.28.28A1.07,1.07,0,0,0,3.35,13a.33.33,0,0,0,.16.17,1.16,1.16,0,0,0,.4.07,1.14,1.14,0,0,0,.87-.46c.09.1.19.19.28.3A.22.22,0,0,1,5.08,13.26ZM15.54,1.19c0,.39-.08.79-.14,1.18a14.7,14.7,0,0,1-4.1,8.3,4.51,4.51,0,0,1-.53.45.22.22,0,0,0-.1.23,4.44,4.44,0,0,1-.13,1.58,1.88,1.88,0,0,1-.59.92,7.84,7.84,0,0,1-2.9,1.52c-.19.06-.38.1-.61.16.16-1.08.31-2.14.46-3.21H6.14a.36.36,0,0,1-.22-.09L4.29,10.6a.47.47,0,0,1-.09-.22c0-.25,0-.49,0-.76L1,10.07S1,10,1,10A8.45,8.45,0,0,1,2.53,6.71a2.24,2.24,0,0,1,1.68-.84,4.35,4.35,0,0,1,.51,0H5.2a.22.22,0,0,0,.16-.07A11.47,11.47,0,0,1,8.47,3.17,15,15,0,0,1,14.6,1.06c.26,0,.53,0,.79-.06h0l.14,0Z" />
                            <path d="M11.9,5.81A1.21,1.21,0,0,1,10.69,7h0a1.22,1.22,0,0,1,0-2.43A1.22,1.22,0,0,1,11.9,5.81Z" /></g></g></svg>
            )
        case "LIVE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.27 13">
                    <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                        <g id="bgd">
                            <path d="M2.34.12,0,7.25a.17.17,0,0,0,.16.22H2.78L1.62,12.86a.12.12,0,0,0,.21.1L7.12,6A.17.17,0,0,0,7,5.7H4.48L7.26.16A.12.12,0,0,0,7.15,0H2.5A.17.17,0,0,0,2.34.12Z" /></g></g></svg>
            )
        case "PAST":
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                    <g id="bgd">
                        <path d="M8.44,7.83V3.3a.44.44,0,1,0-.87,0V8a.44.44,0,0,0,.13.31l2.08,2.08a.43.43,0,0,0,.31.13.39.39,0,0,0,.3-.13.43.43,0,0,0,0-.61Z" />
                        <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM.87,8A7.14,7.14,0,1,1,8,15.14,7.14,7.14,0,0,1,.87,8Z" />
                        <path d="M10.39,9.78l-2-1.95V3.3a.44.44,0,1,0-.87,0V8a.44.44,0,0,0,.13.31l2.08,2.08a.43.43,0,0,0,.31.13.39.39,0,0,0,.3-.13A.43.43,0,0,0,10.39,9.78Z" /></g></g></svg>

            )
        case "NEEDS RESCHEDULE":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                    <g >
                        <g fill={"#ffffff"} stroke={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                        <circle cx="9.5" cy="9.5" r="9" />
                        </g>
                        <g fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                            <path d="M5.56,11.61,11.2,6l2.18,2.19L7.74,13.8l-2.53.6A.21.21,0,0,1,5,14.15Z" />
                            <rect x="12.53" y="4.61" width="1.34" height="3.09" transform="translate(-0.49 11.14) rotate(-45)" />
                        </g>
                    </g>
                </svg>
            )
        default: //upcoming
            return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
                <g id="Layer_2" fill={isSelected ? 'var(--uiollyPurple)' : 'var(--grey)'}>
                    <g id="bgd">
                        <path d="M6.54.12C7.28.05,7.92,0,8.37,0L6.54,1.83Z" />
                        <path d="M5.65.23V2.72l-1.1,1.1V.41Z" />
                        <path d="M3.66.6V4.71L2.44,5.93V1A9.69,9.69,0,0,1,3.66.6Z" />
                        <path d="M1.55,1.45V6.82l-.6.6C.37,6.54-.83,4.21.88,2.05A3,3,0,0,1,1.55,1.45Z" />
                        <path d="M8.88,2.46H7.17L9,.63C9,1.08,9,1.73,8.88,2.46Z" />
                        <path d="M8.59,4.45H5.18l1.1-1.1H8.77C8.72,3.71,8.66,4.08,8.59,4.45Z" />
                        <path d="M8,6.56H3.07L4.29,5.34H8.4A9.69,9.69,0,0,1,8,6.56Z" />
                        <path d="M7,8.12c-2.16,1.71-4.49.51-5.37-.07l.6-.6H7.55A3,3,0,0,1,7,8.12Z" /></g></g></svg>)
    }

    // return <div></div>;
};
const CollectionStatusIcon = ({ status, isSelected }) => {
    return (<Wrapper>
        <Icon status={status} isSelected={isSelected} />
    </Wrapper>)
}

export default CollectionStatusIcon;
