import React from 'react'
import dayjs from 'dayjs'
import {
    Wrapper,
    OverlayWrapper,
    TextOverlay,
    Artist,
    Title,
    Date,
    Time,
    Description
} from './Banner.styles'
import { useNavigate } from 'react-router-dom'
import { formatTimeZone2 } from '../../../utils.js'

const Banner = ({ collection }) => {
    const navigate = useNavigate()
    const localTimeZoneAbbrev = ' ' + formatTimeZone2(dayjs())
    return (
        <Wrapper artwork={collection}>
            <img src={collection.cover_path} alt={collection.collection_title} style={{ position: 'relative' }} />
            <OverlayWrapper>
                <TextOverlay>
                    <Artist onClick={()=>{navigate(`/profile/${collection.artist_handle}`)}}>{collection.artist_name}</Artist>
                    <Title>{collection.collection_title}</Title>
                    <Date>{dayjs.unix(collection.release_date).format('MMM DD')}</Date>
                    <Time>{dayjs.unix(collection.release_date).format('hh:mm A')} {localTimeZoneAbbrev}</Time>
                </TextOverlay>
            </OverlayWrapper>
        </Wrapper>
    )
}

export default Banner
