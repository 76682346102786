import styled from 'styled-components'

export const Wrapper = styled.div`
position: relative;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
top: 60px;
h2{
    text-align: center;
}
`;

export const Banner = styled.div`
height: 430px;
width: 100%;
display: flex;
user-select: none;
background: var(--lightBlue);
position: relative;
overflow: hidden;
border-bottom: 1px solid var(--grey);
background-repeat: no-repeat;
background-size: cover;
row-gap: 5px;
background-position: center;
margin-bottom: 20px;
/* justify-content: center;
align-items: center; */
display: grid;
grid-template-columns: 10% 1fr 1fr 10%;
grid-template-rows: 20px 1fr minmax(20px,min-content) 20px ;
grid-template-areas: '. . . .''. one two .''. three four .''. . . . ';
@media screen and (max-width: 1040px){
    grid-template-areas: '. . . .''. one two .''. three four .''. f s .''. sev e .''. . . . ';
    grid-template-rows: 20px 70% min-content min-content min-content 20px;
    height: 470px;
}
`;


export const BannerImgBgdContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
height: inherit;
align-items: center;
grid-column: 1 / end;
grid-row: 1 / end;
position: absolute;
object-fit: cover;
justify-content: center;
position: ${props => props.overlay ? 'absolute' : 'relative'};
background: ${props => props.overlay ? 'linear-gradient(to bottom, rgba(255,255,255,0.25), rgba(255,255,255,.90))' : 'none'};
img{
    min-height: 100%;
    min-width: 100%;
    object-position: center;
    object-fit: cover;
    grid-column: 1 / end;
}
`;

export const BannerImgContainer = styled.div`
grid-column: 3;
grid-row: 2;
z-index: 1;
height: 100%;
width: 100%;
overflow: hidden;
position: relative;
border: 5px solid var(--lightestGrey);
box-shadow: 0 0px 15px 1px rgba(0,0,0,0.5);
display: flex;
@media screen and (max-width: 1040px){
    grid-column: 2 / span 2;
}
`;

export const BannerImg = styled.img`
object-fit: cover;
min-height: 100%;
min-width: 100%;
`;

export const BannerAttribute = styled.div`
z-index: 1;
grid-column: 3;
grid-row: 3;
margin: 0;
text-align: right;
@media screen and (max-width: 1040px){
    grid-row: 3;
    text-align: center;
    grid-column: 2 / span 2;
    margin: 0;
}
`;
export const DateWrapper = styled.div`
margin: 0;
grid-row: 3 / span 1;
z-index: 1;
display: flex;
height: 100%;
align-items: center;
text-transform: uppercase;
position: absolute;
grid-column: 2;
@media screen and (max-width: 1040px){
    grid-row: 6 / span 1;
    text-align: center;
    grid-column: 2 / span 2;
    width: 100%;
    p{
        width: 100%;
    }
}
`;

export const TextOverlay = styled.h1`
/* width: 100%;
height: 100%; */

/* font-size: 21px; */
/* text-align: center; */
margin: 0;
@media screen and (max-width: 1040px){
font-size: 2em;
}
`;

export const BannerTitle = styled.div`
justify-content: center;
grid-column: 2;
text-align: left;
bottom: 0;
grid-row: 2 / span 1;
margin: 0;
position: absolute;
z-index: 1;
@media screen and (max-width: 1040px){
    grid-row: 4 / span 1;
    text-align: center;
    grid-column: 2 / span 2;
    width: 100%;
    position: relative;
}
`;

export const ReleaseDateWrapper = styled.div`
font-size: 21px;
height: 100%;
text-transform: uppercase;
font-weight: 700;
`;

export const Section = styled.div`
display: flex;
align-items: center;
flex-direction: column;
`;

export const PageNav = styled.div`
font-size: 15px;
user-select: none;
color: ${props => props.valid === undefined || props.valid ? 'var(--primary)' : 'var(--secondary)'};
text-transform: uppercase;
`;

export const BottomPageNav = styled.div`
display: grid;
margin-top: 30px;
padding: 20px 0;
grid-template-columns: min-content 10rem min-content;
justify-items: center;
column-gap: 20px;
`;