import React, { useRef, useState } from 'react'

import {
    ModalOverlay,
    RowWrapper,
    ModalWrapper,
    GridItem,
    ModalTitle,
    SubText,
    CloseButtonContainer
} from './Modal.styles'

import Close from '../../images/close.svg'
import BackArrow from '../../images/backArrow.svg'

// components
import Button from '../Button'
import LoadingWheel from '../LoadingWheel'
const Back = ({ onBackClick }) => (
    <CloseButtonContainer >
        <img src={BackArrow} alt="back" onClick={onBackClick} />
    </CloseButtonContainer>
)

const ClosePopup = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)


const Modal = ({ maxWidth, minWidth, width, submitting, direction, showBackArrow, onBackClick, showModal, children, closeModal, title, subtext, onClick, buttonText, disabled }) => {
    const overlayRef = useRef(null)
    return (
        <ModalOverlay ref={overlayRef} showModal={showModal} onClick={(e) => { console.log(e.target); if (e.target === overlayRef.current) closeModal(); }}>
            <ModalWrapper showBackArrow={showBackArrow} maxWidth={maxWidth} minWidth={minWidth} width={width}>
                <GridItem gridArea="title">
                    <ModalTitle>{title}</ModalTitle>
                </GridItem>
                {showBackArrow && <GridItem gridArea="back">
                    <Back onBackClick={onBackClick} />
                </GridItem>}
                <GridItem gridArea="close">
                    <ClosePopup closeModal={closeModal} />
                </GridItem>
                <GridItem gridArea="subtext">
                    <SubText>
                        {subtext}
                    </SubText>
                </GridItem>
                <GridItem gridArea="content" direction={direction ? direction : undefined}>
                    {children}
                </GridItem>
                <GridItem gridArea="button">
                    {/* <Button
                        disabled={disabled !== undefined ? disabled : undefined}
                        width="100%"
                        background="transparent"
                        text={buttonText ? buttonText : 'Close'}
                        onClick={onClick !== undefined ? onClick : closeModal}
                    /> */}
                    {
                        !submitting && <Button
                            onClick={onClick !== undefined ? onClick : closeModal}
                            disabled={disabled !== undefined ? disabled : undefined}
                            background="transparent"
                            cursor={!disabled ? 'pointer' : 'default'}
                            width='100%'
                            text={buttonText ? buttonText : 'Close'} />
                    }
                    {
                        submitting && <LoadingWheel
                            width='100%'
                            radius="20px"
                            height='var(--buttonHeight)'
                            position='relative'
                            top='0'
                            zIndex='3' />
                    }
                </GridItem>
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default Modal
