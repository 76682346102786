import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
position: relative;
font-size: 10px;

font-size: var(--fontSmall);
width: fit-content;

>div:nth-of-type(1){
    margin-left: 0;
}
`;

export const BubbleStyled = styled.div`
width: min-content;
white-space: nowrap;
margin: 0 5px;
user-select: none;
display: flex;
padding-top: 0.1rem;
align-items: center;
position: relative;
color: ${props => props.isSelected ? 'var(--fontColor)' : 'var(--lightGrey)'};
justify-content: center;
border-bottom:  ${props => props.isSelected ? '1px solid var(--fontColor)' : ''};
font-size: 10px;
text-transform: uppercase;
letter-spacing: var(--buttonLetterSpacing);
font-weight: var(--buttonFontWeight);
height: 1.5rem;
align-items: center;
justify-content: center;
/* background:  ${props => props.isSelected ? 'var(--goldGradient)' : 'transparent'}; */
`;

export const LabelStyled = styled.label`
    position: relative;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;

    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;