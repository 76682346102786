import React from 'react'
import instagramIcon from '../../images/instagramIcon.svg';
import emailIcon from '../../images/emailIcon.svg';

import {
    Wrapper,
    LinksContainer,
    NavLink,
    PhoneNumber,
    Copyright,
    Content
} from './Footer.styles'


const Footer = () => {
    return (
        <Wrapper>
            <Content>
                {/* <LinksContainer width="100px">
                    <a href="https://www.instagram.com/" target="_blank"><img src={instagramIcon} /></a>
                    <a href="mailto:"><img src={emailIcon} /></a>
                </LinksContainer> */}

                <LinksContainer
                    // width="300px"
                    marginTop="20px"
                >
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact">Contact Us</NavLink>
                    <NavLink to="/faq">FAQ</NavLink>
                    <NavLink to="/terms">Terms</NavLink>
                    <NavLink to="/privacy">Privacy</NavLink>
                </LinksContainer>
                <PhoneNumber></PhoneNumber>
                <Copyright>© 2022 Uiolly</Copyright>
            </Content>
        </Wrapper>
    )
}

export default Footer
