import styled from "styled-components";

export const GoldTextWrapper = styled.div`
    font-size: ${props => props.fontSize ? props.fontSize : '1em'};
    font-family: ${ props => props.fontFamily ? props.fontFamily : 'Montserrat, arial, sans-serif'};
    text-align: ${props => props.align ? props.align : 'left'};
    text-transform: ${props => props.transform ? props.transform : 'uppercase'};
    letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : undefined};
    font-weight: ${props => props.fontWeight ? props.fontWeight : undefined};
    background: var(--goldGradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;