import React, { useState } from 'react'

import { Wrapper, Button, Thumb, Border, Video } from './ArtworkThumbnail.styles'

import TrashIcon from '../../../images/removeItem.svg'


const RemoveItem = ({ onClick, showTrash }) => (
    <Button onClick={onClick} showTrash={showTrash}>
        <img src={TrashIcon} alt="" />
    </Button>
)

const Thumbnail = ({ thumbnail, type }) => {
    const typeBase = type.split('/')[0];
    if (typeBase === 'video') {
        return(<Video 
            autoPlay={false}
            src={thumbnail}
            playsInline
            controls={false}
        />)
    }
    else {
        return (
            <Thumb src={thumbnail} alt="image-preview" />
        )
    }

}

const ArtworkThumbnail = ({ thumbnail, onClick, title, activeIndex, step, type }) => {

    const [showTrash, setShowTrash] = useState(false);

    return (
        <Wrapper value={title} activeIndex={activeIndex} step={step}
            onMouseEnter={(event) => setShowTrash(true)}
            onMouseLeave={(event) => setShowTrash(false)}
        >
            <Border>
                <Thumbnail thumbnail={thumbnail} type={type} />
            </Border>
            <RemoveItem onClick={onClick} showTrash={showTrash} />
        </Wrapper>
    )
}

export default ArtworkThumbnail
