import styled from 'styled-components';

export const UploadWrapper = styled.div`
width: 50%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
padding: 50px;
flex-direction: column;
`;

export const BodyWrapper = styled.div`
display: flex;
width:100%;
height: 100%;
`;

export const CoverArtInfoWrapper = styled.div`
  background: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.6);
  filter: drop-shadow(0 0 5px rgba(3, 0, 112, .4));
  height: 100%;
  position: relative;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px;

  

`;

export const NavWrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: ${props => props.backIsValid ? '1fr 1fr' : '1fr'};
grid-column-gap: 2%;
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
justify-content: space-between;
position: relative;
`;

export const ContentWrapper = styled.div`

width: 100%;


display: grid;
grid-row-gap: 5%;
grid-template-rows: auto 1fr min-content;

`;