import React, { useState, useEffect, useRef } from 'react'

import { CoverArtPrevWrapper, UploadWrapper, BodyWrapper, CoverArtInfoWrapper, ContentWrapper, LeftWrapper, RightWrapper } from './UploadCoverArt.styles'

import DragAndDrop from '../DragAndDrop'

import ReplaceCoverArtButton from '../ReplaceCoverArtButton'

import CoverArtInfo from '../CoverArtInfo';
import NavWrapper from '../NavWrapper';
import ArtworkThumbnail from '../ArtworkThumbnail';
import CoverArtPreview from '../CoverArtPreview';
import StepHeader from '../StepHeader';
// import UploadProgressMeter from '../UploadProgressMeter';




const UploadCoverArt = ({ handleCoverArtRemoval, startingStep, handleFileUpload, values, nextStep, prevStep, handleFieldChange, collectionCover }) => {
    const [validContinue, setValidContinue] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const backIsValid = false;
    const initial = useRef(true);

    console.log("values " + values.collectionCoverArt)
    useEffect(() => {

        if (initial.current) {
            initial.current = false;
            validateStep(values.step)
            return;
        }
        validateStep(values.step)

    }, [values])


    const validateStep = () => {
        if (values.collectionCoverArt !== "") {
            setShowPreview(true);
        }

        if (values.collectionCoverArt !== undefined && values.collectionCoverArt !== ""
            && values.collectionTitle !== ""
            && values.collectionDescription !== "") {

            setValidContinue(true)

            return true
        }
        else {
            setValidContinue(false)



            return false
        }

    }

    return (
        <>

            <BodyWrapper>
                <ContentWrapper>
                    <CoverArtInfo

                        values={values}
                        handleChange={handleFieldChange}
                        // maxWidth='480px'
                        width='100%'
                    >
                    </CoverArtInfo>
                    {/* {!showPreview && */}
                    <DragAndDrop
                        background={collectionCover}
                        width="100%"
                        maxWidth="100%"
                        height="400px"
                        handleSongUpload={handleFileUpload}
                        title={'Upload Cover Art'}
                        note={'(4:1 aspect ratio, 300 dpi recommended)'} />


                    {/* <CoverArtPrevWrapper>
                        {showPreview &&

                            <ArtworkThumbnail className="preview"
                                onClick={handleCoverArtRemoval}
                                artist={values.artistName}
                                type={"image"}
                                thumbnail={collectionCover}
                            />}
                    </CoverArtPrevWrapper> */}

                    {/* {showPreview &&
                        <CoverArtPrevWrapper height="400px">
                            <CoverArtPreview className="preview"
                                title={values.collectionTitle}
                                artist={values.artistName}
                                coverArt={collectionCover} />
                            <ReplaceCoverArtButton
                                handleSongUpload={handleFileUpload}
                                width="480px"
                                border="1px solid #000"
                                text={"Replace Cover Art"}
                            />
                        </CoverArtPrevWrapper>
                    } */}

                </ContentWrapper>

            </BodyWrapper>


        </>
    )
}

export default UploadCoverArt
