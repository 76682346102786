import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs'
import { buy, bid, getDeployedCollections, getCollectionItems, getURI, getItemData, getBids, getCollectionOpenAndClose } from "../../../blockchain/functions";
import ArtworkCard from './ArtworkCard'
import { Wrapper } from './Purchased.styles'

const Purchased = ({ fetchItems, content, setResellToken, ownedNFTs }) => {
    console.log("filter is", content, ownedNFTs[content.value])
    const [auctionItems, setAuctionItems] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedArtwork, setSelectedArtwork] = useState(null)
    const [myNFTs, setMyNFTs] = useState([])
    if (ownedNFTs.length === 0) {
        return (<Wrapper
            background="var(--lightestGrey)"
            isEmpty
        >
            <h1>You haven't purchased any Uiolly NFTs </h1>
        </Wrapper>)
    }
    else {
        return (<Wrapper>
            {

                ownedNFTs[content.value].map((item, index) => (
                    <ArtworkCard
                        key={index}
                        pricingData={item.secondaryMarketData !== undefined ? item.secondaryMarketData : item.primaryMarketData}
                        filter={content.value}
                        fetchItems={fetchItems}
                        setSelectedArtwork={setSelectedArtwork}
                        setShowModal={setShowModal}
                        setResellToken={setResellToken}
                        artwork={item} />
                ))

            }
        </Wrapper>);
    }
};

export default Purchased;
