import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, ImageContainer, Subtext, Emoji } from './SuccessPage.styles'
import { useNavigate } from 'react-router-dom'
import LoadingWheel from '../../LoadingWheel'
import FormNavButton from '../FormNavButton'
import Header from '../../Header'
import Button from '../../Button'
import DropSubmittedImage from '../../../images/dropSubmittedSuccess@3x.png'

import { useAuth } from '../../../contexts/AuthContext'
import { useLocation } from 'react-router-dom'
import API from '../../../API'


const SuccessPage = () => {
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const { currentUser, retrieveUserDetails } = useAuth()
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    let location = useLocation();
    const values = location.state.values;
    const initial = useRef(true);
    const runBlobCleanup = () => {

        values.collectionArtworks.forEach(artwork => {
            URL.revokeObjectURL(artwork.thumbnail)
            console.log("removing: " + artwork.thumbnail)
        })

    }

    useEffect(() => {
        const submitCollection = async (collection) => { //
            try {
                setError(false);
                setLoading(true);
                //receiver_id, sender_id, songwriter_id, song_id, message
                const res = await API.submitCollection(collection);
                console.log("server response: ", res)
                setLoading(false);
            } catch (error) {
                setError(true);
                console.log("error", error)
                setLoading(false);
            }

        };
        if (values !== null && !submitted) {
            submitCollection(values).then((res) => {
                retrieveUserDetails(currentUser.email)
                setSubmitted(true)
            }).catch((e) => {
                setError(true)
            })

        }


    }, [values])

    useEffect(() => {

        if (initial.current === true) {
            initial.current = false
            return;
        }
        runBlobCleanup()

    }, [values])

    if (loading) return <Wrapper><Header /><h2 style={{ transform: "translateY(-100px)", position: "absolute", textTransform: "none" }}>Submitting...<br />This may take a few minutes...</h2><LoadingWheel position="relative" /></Wrapper>
    else if (submitted) {
        return (
            <Wrapper>
                <Header />
                <h1>Your collection has been submitted for approval.</h1>
                <Subtext>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea com</Subtext>
                <Button
                    marginTop="20px"
                    width="300px"
                    color="var(--uiollyPurple)"
                    onClick={() => { navigate('/faq') }}
                    text="Learn More"
                    border="2px solid var(--uiollyPurple)" />
            </Wrapper>
        )
    }
    else if (error) {
        return (
            <Wrapper>
                <Header />

                <h1>There has been an error in submitting your application.</h1>
                <Subtext>Please try again. If you encounter trouble submitting your artworks, please contact us.</Subtext>
                <Button
                    marginTop="20px"
                    onClick={() => { navigate('/contact') }}
                    width="300px"
                    color="var(--uiollyPurple)"
                    text="Contact Us"
                    border="2px solid var(--uiollyPurple)" />
            </Wrapper>
        )
    }
    else {
        return (
            <Wrapper>
                <Header />

            </Wrapper>
        )
    }

}

export default SuccessPage
