import styled from 'styled-components'

export const Wrapper = styled.div`
height: 100%;
width: 100%;
position: relative;
`;

export const Content = styled.div`

display: grid;
grid-template-rows: min-content 1fr min-content;
grid-template-columns: 1fr;
grid-row-gap: 20px;
grid-template-areas: 'popupheader' 'listings' 'button';

`;

export const ItemDetailsWrapper = styled.div`
    display: flex;
`;

export const Title = styled.div`
font-size: 16px;
max-width: 80%;
overflow: hidden;
font-weight: 200;
text-overflow: ellipsis;
white-space: nowrap;
`;

export const Artist = styled.div`
font-size: 12px;
`;

export const Edition = styled.div`
font-size: 10px;
text-align: right;
margin-bottom: 2px;
margin-left: 0.25rem;
`;

export const RowWrapper = styled.div`
display: flex;
position: relative;
align-items: flex-end;

`;

export const ColumnWrapper = styled.div`
display: flex;
margin-left: 20px;
flex-direction: column;
justify-content: space-between;
user-select: none;
`;

export const Owner = styled.div`
font-size: 12px;

text-transform: uppercase;
`;

export const ChainDetails = styled.div`
font-size: 12px;
text-transform: uppercase;
color: var(--lighterGrey);
display: flex;
flex-direction: column;
`;

export const Pricing = styled.div`
user-select: none;

`;
export const Price = styled.div`
    font-weight: 600;
    font-size: 21px;
    text-align: left;
    background: var(--goldGradient);
    -webkit-background-clip: text;
    background-clip: text;
    line-height: 1rem;
    -webkit-text-fill-color: transparent;
`;

export const PriceLabel = styled.div`
    
    font-size: 10px;
    text-transform: uppercase;
    text-align: left;
`;

export const OfferDetailsWrapper = styled.div`
    font-size: 10px;
    color: var(--lighterGrey);
    strong {
        color: var(--fontColor);
        text-transform: uppercase;
    }
`;

export const ConversionWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const ETHConversionWrapper = styled.div`
    background: #ffffff;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding-right: 20px;
    color: #000000;
    user-select: none;
`;