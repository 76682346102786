import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper, NavToDrop, ItemWrapper, CollectionArtworksHeaderBgd, CollectionArtworksHeader } from './HomeDropDisplay.styles'
import { getNonFungibleIndex, getNonFungibleBaseType } from '../../blockchain/blockchainUtils'
import DropArtworksContent from '../DropArtworksContent'

import { useHomeFetch } from '../../hooks/useHomeFetch'
import { useInterval } from '../../hooks/useInterval';

import Banner from '../Collection/Banner'
import HomeBanner from '../HomeBanner'
import ArtworkCard from '../ArtworkCard'
import DropCountdown from '../DropCountdown'

const HomeDropDisplay = ({ collection }) => {
    // const [formattedDate, unformattedDate, dropState] = useInterval(featuredCollection.releaseDate)

    const navigate = useNavigate();

    return (
        <Wrapper>
            <NavToDrop onClick={() => { navigate(`/collections/${collection.contract_address}`, { state: { collection: collection } }) }}>
                {/* <Banner collection={collection} /> */}
                <HomeBanner collection={collection} />
            </NavToDrop>
            <h3>
                {collection.items.length} Work{collection.items.length > 1 ? 's' : ''} In This Drop
            </h3>
            <ItemWrapper>{
                collection.items.map(item => {
                    return (
                        <ArtworkCard
                            key={item.tokenId}
                            pricingData={item.secondaryMarketData === undefined ? item.primaryMarketData : item.secondaryMarketData}
                            onClick={() => {
                                // navigate(`/collections/${collection.contract_address}/${item.tokenID}`, { state: { artwork: item } })
                                navigate(`/collections/${item.contractAddress}/${item.baseType}`, { state: { artwork: item } })
                            }}
                            artwork={item} />
                    )
                })
            }</ItemWrapper>
            {/* {unformattedDate > 0 &&
                    <DropCountdown releaseDate={formattedDate} dropState={dropState} />} */}
            {/* <DropArtworksContent
                    text=" Works in this drop"
                    collectionArtworks={featuredCollection.collectionArtworks}
                    textColor="#ffffff"
                /> */}

        </Wrapper >
    )


}

export default HomeDropDisplay
