import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, LabelStyledOverlap, InputStyled, LabelStyled, DollarSign, InnerWrapper } from './InputField.styles'


const Label = ({ name, marginBottom, label, overlap }) => {
    if (overlap) {
        return <LabelStyledOverlap htmlFor={name}
            marginBottom={marginBottom}
        >{label}</LabelStyledOverlap>
    }
    else return (
        <LabelStyled htmlFor={name}
            marginBottom={marginBottom}
        >{label}</LabelStyled>
    )
}




const InputField = ({ textAlign, boxShadow, padding, overlapLabel, disabled, priceOnBlur, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);




    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }

    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])



    if (label !== undefined) {

        return (
            <Wrapper >

                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                    overlap={overlapLabel ? overlapLabel : undefined}
                ></Label>
                <InnerWrapper>
                    {name === 'price' && <DollarSign disabled={disabled !== undefined ? disabled : undefined}>Ξ</DollarSign>}
                    <InputStyled
                        type={type}
                        ref={inputRef}
                        placeholder={placeholder}
                        textAlign={textAlign}
                        value={value}
                        className={`cp`}
                        onChange={handleCursor}
                        name={name}
                        padding={padding}
                        onKeyPress={onKeyPress}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                        disabled={disabled !== undefined ? disabled : undefined}
                        boxShadow={boxShadow}
                    />
                </InnerWrapper>
            </Wrapper>

        )
    }
    else {
        return (
            <Wrapper >
                <InnerWrapper>
                    {name === 'price' && <DollarSign disabled={disabled !== undefined ? disabled : undefined}>Ξ</DollarSign>}
                    <InputStyled
                        type={type}
                        placeholder={placeholder}
                        padding={padding}
                        textAlign={textAlign}
                        value={value}
                        onChange={onChange}
                        name={name}
                        boxShadow={boxShadow}
                        ref={inputRef}
                        disabled={disabled !== undefined ? disabled : undefined}


                    />
                </InnerWrapper>
            </Wrapper>

        )
    }



}

export default InputField
