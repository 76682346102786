import styled from "styled-components";

export const UploadWrapper = styled.div`
width: 50%;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
`;

export const CoverArtPrevWrapper = styled.div`
height: ${props => props.height ? props.height : ''};
position: relative;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
padding-bottom: 20px;
justify-content: space-between;
background: #000000;
`;



export const CoverArtInfoWrapper = styled.div`
  background: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.6);
  filter: drop-shadow(0 0 5px rgba(3, 0, 112, .4));
  height: 100%;
  position: relative;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  padding: 50px;

  

`;

export const NavWrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: ${props => props.backIsValid ? '1fr 1fr' : '1fr'};
grid-column-gap: 2%;
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
justify-content: space-between;
`;

export const ContentWrapper = styled.div`
height: 100%;
width: 100%;

display: grid;
grid-template-rows: min-content min-content;
grid-row-gap: 20px;
flex-direction: column;
`;


export const LeftWrapper = styled.div`
grid-row-start: 1;
  grid-row-end: 2;
position: relative;
display: flex;
align-items: center;
justify-content: center;
padding: 50px;
flex-direction: column;
`;

export const BodyWrapper = styled.div`
display: flex;
justify-content: center;
width:100%;
height: 100%;
`;

export const CenterWrapper = styled.div`
grid-column-start: center-start;
grid-column-end: center-end;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
grid-row-start: 1;
  grid-row-end: 2;

`;

export const RightWrapper = styled.div`
  background: var(--grey);
  filter: drop-shadow(0 0 5px rgba(3, 0, 112, .4));
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: center-start;
  grid-column-end: right-end;
  /* padding: 50px; */
  grid-row-start: 1;
  grid-row-end: 2;



`;