import styled from 'styled-components'

export const DropDownContainerBox = styled.div`
position: absolute;
top: 30px;
z-index: 10;
`;

export const Action = styled.div`

    width: 35px;
height: 35px;
z-index: 7;
background: rgba(255,255,255,0.5);
border-radius: 50%;
left: 5px;
top: 5px;
border: 1px solid #ffffff;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
`;

export const DotMenuWrapper = styled(Action)`

svg{
    height: 15px;
    transform: rotate(90deg);
}

`;