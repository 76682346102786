import styled from "styled-components";

export const Wrapper = styled.div`
height: 318px;
margin: 20px;
width: var(--artworkCardSize);
position: relative;
display: flex;
justify-content: space-between;
flex-direction: column;
`;

export const ImageWrapper = styled.div`
width: var(--artworkCardSize);
height: var(--artworkCardSize);
overflow: hidden;
position: relative;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
box-shadow:  5px 5px 5px 3px rgba(0, 60, 249, .2),
               -3px -3px 5px 3px rgba(255, 255, 255, .9);
`;

export const Image = styled.img`
object-fit: cover;
position: relative;
min-height: 100%;
min-width: 100%;

border-radius: 2px;
`;

export const Video = styled.video`
object-fit: cover;
position: relative;
min-height: 100%;
min-width: 100%;

border-radius: 2px;
`;

export const ArtworkBadgeContainer = styled.div`
width: 100%;
position: relative;
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center;
justify-content: center;
justify-items: center;
`;


export const Info = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
user-select: none;
align-items: center;
text-align: center;
`;

export const Artist = styled.div`
font-size: var(--artworkCardArtistFontSize);
font-weight: var(--artworkCardArtistFontWeight);
letter-spacing: var(--commonLetterSpacing);
`;

export const Title = styled.div`

font-size: var(--artworkCardTitleFontSize);
font-weight: var(--artworkCardTitleFontWeight);
letter-spacing: var(--commonLetterSpacing);
width: 100%;
height: 20px;
position: relative;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
`;