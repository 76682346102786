import { varNameToString } from './helpers'
const VALID_FILE_TYPES = {
    collectionCover: ["image/jpeg", "image/png", "image/jpg"],
    collectionArtwork: ["image/jpeg", "image/jpg", "image/png", "audio/mpeg", "audio/wav", "video/mpeg", "video/mp4"]
};
const MAX_SIZE = 500000000;
const MAX_DEC_PLACES = 6; //price
const ARTIST = 1;
const BUYER = 2;
const ACCOUNT_ACTIVITY = 'account';
const LOGOUT = "LOGOUT";
const LOGIN = "LOGIN";
const SIGNUP = "SIGNUP";
const ACCOUNT_SETTINGS = 'settings';
const CONNECT_WALLET = "CONNECT";
const MY_PROFILE = 'profile';
const UPLOAD = 'upload';
const PENDING = 1;
const UNMINTED = 2;
const UNSCHEDULED = 3;
const SCHEDULED = 4;
const LIVE = 5;
const PAST = 6;
const PURCHASED = 1;
const SOLD = 2;
const OFFERS_MADE = 1;
const ALL_LISTED = 1;
const OFFERS_RECEIVED = 2;
const ALL_BIDS = 1;
const OUTBID = 2;
const WON = 3;
const PROFILE_TAB = 1;
const SETTINGS_TAB = 2;
const BID_FILTER_WON = 'WON';
const BID_FILTER_WINNING = 'WINNING';
const BID_FILTER_OUTBID = 'OUTBID';
const TRANSACTION_FILTER_PURCHASED = 'PURCHASED';
const TRANSACTION_FILTER_FOR_SALE = 'FOR SALE'
const TRANSACTION_FILTER_OFFERS_MADE = 'OFFERS MADE'
const TRANSACTION_FILTER_OFFERS_RECEIVED = 'OFFERS RECEIVED'
const TRANSACTION_FILTER_OFFERS_EXPIRED = 'EXPIRED OFFERS'
const VALID_BID_FILTERS = { NO_FILTER: null, BID_FILTER_WON, BID_FILTER_WINNING, BID_FILTER_OUTBID }
const VALID_TRANSACTION_FILTERS = { NO_FILTER: null, TRANSACTION_FILTER_PURCHASED, TRANSACTION_FILTER_FOR_SALE, TRANSACTION_FILTER_OFFERS_MADE, TRANSACTION_FILTER_OFFERS_RECEIVED, TRANSACTION_FILTER_OFFERS_EXPIRED }
const BIDS_CATEGORY = 'BIDS'
const TRANSACTIONS_CATEGORY = 'TRANSACTIONS'

const ARTIST_ACCCOUNT_ACTIVITY_OPTIONS = {
    Collections: [
        { value: PENDING, text: "Pending" },
        { value: UNMINTED, text: "Unminted" },
        { value: UNSCHEDULED, text: "Unscheduled" },
        { value: SCHEDULED, text: "Scheduled" },
        { value: LIVE, text: "Live" },
        { value: PAST, text: "Past" }
    ]
}
const ACCOUNT_ACTIVITY_OPTIONS = {
    [TRANSACTIONS_CATEGORY]:
        [{
            value: varNameToString({ TRANSACTION_FILTER_PURCHASED }),
            text: VALID_TRANSACTION_FILTERS.TRANSACTION_FILTER_PURCHASED,
            subtext: "Items you've purchased through primary collection drops & the marketplace."
        },
        {
            value: varNameToString({ TRANSACTION_FILTER_FOR_SALE }),
            text: VALID_TRANSACTION_FILTERS.TRANSACTION_FILTER_FOR_SALE,
            subtext: <>Items you're selling in the marketplace. To view all offers for your items, visit the 'Offers Received' Tab.</>
        },
        {
            value: varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }),
            text: VALID_TRANSACTION_FILTERS.TRANSACTION_FILTER_OFFERS_MADE,
            subtext: "Offers you've made on items in the marketplace. You can revoke your offer at any time up until it is accepted. If your offer is accepted, the nft will be transferred to 'Purchased'."
        },
        {
            value: varNameToString({ TRANSACTION_FILTER_OFFERS_RECEIVED }),
            text: VALID_TRANSACTION_FILTERS.TRANSACTION_FILTER_OFFERS_RECEIVED,
            subtext: <>Offers you've received on items you've placed for sale in the marketplace. You can accept offers via the 'Accept Offer' option in the menu.</>
        },
        {
            value: varNameToString({ TRANSACTION_FILTER_OFFERS_EXPIRED }),
            text: VALID_TRANSACTION_FILTERS.TRANSACTION_FILTER_OFFERS_EXPIRED,
            subtext: <>Offers you've placed on items which have had another offer accepted. You can retrieve your funded offer through the 'Retrieve funds' option in the menu.</>
        }]
    ,
    // OffersMade:
    //     [{ value: OFFERS_MADE, text: "All" }],
    // Listed: [
    //     { value: ALL_LISTED, text: "All" },
    //     { value: OFFERS_RECEIVED, text: "Offers Received" }],
    [BIDS_CATEGORY]:
        [{
            value: VALID_BID_FILTERS.NO_FILTER,
            text: "All",
            subtext: <>Active auctions you're participating in & unclaimed items you've won in auction. Items can be claimed via the 'Claim' option in the menu</>
        },
        {
            value: BID_FILTER_OUTBID,
            text: BID_FILTER_OUTBID,
            subtext: <>Items you've been outbid on in active auctions. When you are outbid, the funds of your bid are immediately transferred back to your wallet.</>
        }, {
            value: BID_FILTER_WON,
            text: BID_FILTER_WON,
            subtext: <>Unclaimed items you've won in an auction. Items can be claimed via the 'Claim' option in the menu.</>
        }]
}
// zero means no limit
const MAX_UPLOADS =
{
    BASIC: 1,
    PLUS: 0,
    ULTRA: 0
};
const PRIMARY_MARKET = 'PRIMARY_MARKET'
const SECONDARY_MARKET = 'SECONDARY_MARKET'
const MARKET_DATA_TYPES = {
    PRIMARY_MARKET,
    SECONDARY_MARKET
}
const VALID_AUCTION_ITEM_FILTER_CONSTRAINTS = [
    'salesOpenTime',
    'salesCloseTime'
]
const VALID_STATS_PRODUCT_PAGE = [
    'initialDrop',
    'marketplaceSalesEdition',
    'avgResalePriceEdition',
    'highestBidAllEditions',
    'pricePremium',
    'lifespanRange',
    'marketplaceVolumeEdition',
    'lowestAskAllEditions'
]

const VALID_MARKET_ITEM_FILTER_CONSTRAINTS = [
    'itemId',
    'nftContract',
    'baseType',
    'tokenId',
    'seller',
    'owner',
    'isAuction'
]

export {
    PRIMARY_MARKET,
    SECONDARY_MARKET,
    VALID_AUCTION_ITEM_FILTER_CONSTRAINTS,
    VALID_STATS_PRODUCT_PAGE,
    VALID_MARKET_ITEM_FILTER_CONSTRAINTS,
    MARKET_DATA_TYPES,
    VALID_TRANSACTION_FILTERS,
    TRANSACTION_FILTER_PURCHASED,
    TRANSACTION_FILTER_FOR_SALE,
    TRANSACTION_FILTER_OFFERS_MADE,
    TRANSACTION_FILTER_OFFERS_RECEIVED,
    TRANSACTION_FILTER_OFFERS_EXPIRED,
    VALID_BID_FILTERS,
    VALID_FILE_TYPES,
    MAX_DEC_PLACES,
    MAX_SIZE,
    MAX_UPLOADS,
    ARTIST,
    BUYER,
    ACCOUNT_ACTIVITY,
    ACCOUNT_SETTINGS,
    MY_PROFILE,
    UPLOAD,
    CONNECT_WALLET,
    LOGOUT,
    LOGIN,
    SIGNUP,
    ARTIST_ACCCOUNT_ACTIVITY_OPTIONS,
    ACCOUNT_ACTIVITY_OPTIONS,
    BIDS_CATEGORY,
    TRANSACTIONS_CATEGORY,
    ALL_BIDS,
    OUTBID,
    WON,
    PENDING,
    UNMINTED,
    UNSCHEDULED,
    SCHEDULED,
    LIVE,
    PAST,
    PURCHASED,
    SOLD,
    OFFERS_MADE,
    ALL_LISTED,
    OFFERS_RECEIVED,
    PROFILE_TAB,
    SETTINGS_TAB
};