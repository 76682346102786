import React, { useState, useEffect, useRef } from 'react'

import {
    OuterWrapper,
    PageTab,
    ColWrapper,
    SideBarWrapper,
    AccountActivityHeader,
    NavHeader,
    Nav,
    SideBarContent,
    NavLink,
    PageHeader,
    CatWrapper,
    RightContainer,
    GridHeader,
    Row,
    Innercontainer,
    ScrollContainer,
    DateWrapper,
    Subtext
} from './AccountActivity.styles'

import {
    ACCOUNT_ACTIVITY_OPTIONS,
    TRANSACTIONS_CATEGORY,
    BIDS_CATEGORY,
    VALID_BID_FILTERS,
    ARTIST_ACCCOUNT_ACTIVITY_OPTIONS,
    VALID_TRANSACTION_FILTERS,
    TRANSACTION_FILTER_PURCHASED,
    TRANSACTION_FILTER_FOR_SALE,
    TRANSACTION_FILTER_OFFERS_MADE,
    TRANSACTION_FILTER_OFFERS_RECEIVED,
    TRANSACTION_FILTER_OFFERS_EXPIRED,
    PENDING,
    UNMINTED,
    UNSCHEDULED,
    SCHEDULED,
    LIVE,
    PAST,
    PURCHASED,
    SOLD,
    OFFERS_MADE,
    ALL_LISTED,
    OFFERS_RECEIVED
} from '../../config'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useWeb3 } from '../../contexts/Web3Context'
import Pending from '../../images/pending.svg'
import AddIcon from '../../images/addIcon.svg'
import Live from '../../images/live.svg'
import Unminted from '../../images/mintIcon.svg'
import Unscheduled from '../../images/pending.svg'
import Past from '../../images/past.svg'
import Upcoming from '../../images/upcoming.svg'
import { marketBids, getMyOffersMade, getMyOffersExpired, getMarketplaceItemsForSaleByUser, getItemsOwnedAndForSaleAllCollections, getBidsForWalletAddressAllCollections } from '../../blockchain/functionsUiollyNew'
// import { getTokensPurchasedFromMarketplace, getItemData, getBids, getNFTsClaimed, getDeployedCollections, getCollectionOpenAndClose, getClaimedStatus } from '../../blockchain/functions'
import API from '../../API'
import axios from 'axios'
import dayjs from 'dayjs'
import { unCamelCase, returnMonth, varNameToString } from '../../helpers'
import Bids from './Bids'
import Purchased from './Purchased'
import Collections from './Collections'
import CollectionStatusIcon from '../CollectionStatusIcon'
import LoadingWheel from '../LoadingWheel'
import Resale from '../Resale'
import GridItem from '../GridItem'


const Content = ({ setResellToken, claimNFTButtonDisabled, setClaimNFTButtonDisabled, fetchItems, fetchCollections, userBids, setUserBids, ownedNFTs, setOwnedNFTs, statusKey, collections, setItem, breadcrumb, setBreadcrumb, adminID, setError, setLoading, contentFilter, values, loading, error, handleArtistAssignment, setProcessArtist, playing, nowPlaying, handleSongChange }) => {
    if (contentFilter.type === 'Collections') {
        return <Collections
            filter={contentFilter}
            fetchCollections={fetchCollections}
            statusKey={statusKey}
            collections={collections}
            setItem={setItem}
            breadcrumb={breadcrumb}
            setBreadcrumb={setBreadcrumb}
        ></Collections>
    }
    else if (contentFilter.type.toLowerCase() === "bids") {
        console.log("x", contentFilter.type)
        return <Bids setResellToken={setResellToken} claimNFTButtonDisabled={claimNFTButtonDisabled} setClaimNFTButtonDisabled={setClaimNFTButtonDisabled} fetchItems={fetchItems} userBids={userBids} ownedNFTs={ownedNFTs} setUserBids={setUserBids} content={contentFilter} />
    }
    else if (contentFilter.type.toLowerCase() === 'transactions') {
        console.log("should be here", ownedNFTs)
        return <Purchased setResellToken={setResellToken} setOwnedNFTs={setOwnedNFTs} ownedNFTs={ownedNFTs} userBids={userBids} setUserBids={setUserBids} content={contentFilter} fetchItems={fetchItems} />
    }
    else return <></>
}

const AccountActivity = () => {
    const ethereum = window.ethereum;
    const navigate = useNavigate()
    const initialUserBids = {
        winning: [],
        won: [],
        outbid: []
    }

    console.log("BEEO", VALID_TRANSACTION_FILTERS)
    const { currentUser } = useAuth();
    const { userAddress } = useWeb3();
    const isArtist = useRef(false)
    const [tabs, setTabs] = useState(['Curated'])
    const [showTabs, setShowTabs] = useState(tabs[0])
    const [c, setC] = useState([])
    const [deployedCollections, setDeployedCollections] = useState([]);
    // const [ownedNFTs, setOwnedNFTs] = useState([])
    const [ownedNFTs, setOwnedNFTs] = useState({
        TRANSACTION_FILTER_PURCHASED: [],
        TRANSACTION_FILTER_FOR_SALE: [],
        TRANSACTION_FILTER_OFFERS_MADE: [],
        TRANSACTION_FILTER_OFFERS_RECEIVED: [],
        TRANSACTION_FILTER_OFFERS_EXPIRED: []
    })
    const [userBids, setUserBids] = useState(initialUserBids)
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [item, setItem] = useState(null);
    const [collections, setCollections] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingContent, setLoadingContent] = useState(false)
    const [statusKey, setStatusKey] = useState(null)
    const [resellToken, setResellToken] = useState({ artwork: null, showModal: false })
    const [claimNFTButtonDisabled, setClaimNFTButtonDisabled] = useState(false)
    const [error, setError] = useState('')
    const sideBarLinks = ACCOUNT_ACTIVITY_OPTIONS;//currentUser.isArtist ? { ...ARTIST_ACCCOUNT_ACTIVITY_OPTIONS, ...ACCOUNT_ACTIVITY_OPTIONS } : ACCOUNT_ACTIVITY_OPTIONS;
    const [content, setContent] = useState({
        type: Object.keys(sideBarLinks)[0],
        value: sideBarLinks[Object.keys(sideBarLinks)[0]][0].value,
        text: sideBarLinks[Object.keys(sideBarLinks)[0]][0].text,
        subtext: sideBarLinks[Object.keys(sideBarLinks)[0]][0].subtext
    })
    const now = new Date().toLocaleString().split(',')[0]
    const todaysDate = "Today's Date: " + returnMonth(now, true) + ' ' + now.split('/')[1];
    const fetchCollections = async (collection_status = 1) => {
        try {
            setError("")
            setLoading(true)
            API.fetchSubmittedCollections(currentUser, collection_status).then((res) => {
                if (res !== null) {
                    //setCollections(prev => [...prev, ...res])
                    console.log("res", res)
                    setCollections([...res])
                } else {
                    setCollections([])
                }
            })
        }
        catch (err) {
            setError(err)
        }
        setLoading(false)
    }

    const fetchBids = async (filter = null) => {
        try {
            setClaimNFTButtonDisabled(true)
            setLoadingContent(true)
            setUserBids(initialUserBids)
            // setOwnedNFTs([])
            const bids = await getBidsForWalletAddressAllCollections(filter)
            const myMarketBids = await marketBids(window.ethereum.selectedAddress, filter)
            console.log("marketBids", myMarketBids, "bids", bids)
            // setUserBids({ ...bids })
            setUserBids({
                winning: [...bids.winning, ...myMarketBids.winning],
                won: [...bids.won, ...myMarketBids.won],
                outbid: [...bids.outbid, ...myMarketBids.outbid]
            })
            setLoadingContent(false)
        } catch (err) {
            console.log("ERROR", err)
            setLoadingContent(false)
        }
    }

    const transactionsFilter = async (filter) => {
        switch (filter) {
            case varNameToString({ TRANSACTION_FILTER_FOR_SALE }):
                return await getMarketplaceItemsForSaleByUser(window.ethereum.selectedAddress)
            case varNameToString({ TRANSACTION_FILTER_PURCHASED }):
                return await getItemsOwnedAndForSaleAllCollections(window.ethereum.selectedAddress)
            case varNameToString({ TRANSACTION_FILTER_OFFERS_MADE }):
                return await getMyOffersMade(window.ethereum.selectedAddress)
            case varNameToString({ TRANSACTION_FILTER_OFFERS_EXPIRED }):
                return await getMyOffersExpired(window.ethereum.selectedAddress)
            case varNameToString({ TRANSACTION_FILTER_OFFERS_RECEIVED }):
                {
                    let items = await getMarketplaceItemsForSaleByUser(window.ethereum.selectedAddress)
                    console.log("ITEMS ARE", items)
                    items = items.reduce((a, b) => {
                        if (!b.secondaryMarketData.isAuction && b.secondaryMarketData.activeOffers !== undefined && b.secondaryMarketData.activeOffers.length > 0) {
                            const { secondaryMarketData: { activeOffers } } = b
                            console.log("ACTIVE OFFERS", activeOffers)
                            a.push(...activeOffers.map(offer => ({ ...b, secondaryMarketData: { ...b.secondaryMarketData, offer }, offer })))
                        }
                        return a
                    }, [])

                    if (items.length > 0) {
                        return items.sort((a, b) => Number(b.offer.price.replace('Ξ', '')) - Number(a.offer.price.replace('Ξ', '')))
                    } else return []
                }

            default:
                return await getItemsOwnedAndForSaleAllCollections(window.ethereum.selectedAddress)

        }
    }

    const fetchItems = async (filter = 'TRANSACTION_FILTER_PURCHASED') => {
        try {
            setLoadingContent(true)
            setClaimNFTButtonDisabled(true)
            const c = await transactionsFilter(filter)
            if (c !== undefined && c.length > 0) {
                setOwnedNFTs(prev => ({ ...prev, [filter]: c }))
            } else {
                setOwnedNFTs(prev => ({ ...prev, [filter]: [] }))
            }
            setLoadingContent(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
            setClaimNFTButtonDisabled(false)
            setLoadingContent(false)
        }
    }

    useEffect(() => {
        const fetchCollectionStatuses = async () => {
            try {
                setError("")
                const s = await API.fetchCollectionStatuses();
                setStatusKey(s)
            } catch (error) {
                setError(error.response)
            }
        }
        const init = async () => {
            setLoading(true)
            await fetchItems()
            await fetchBids()
            await fetchCollectionStatuses();
            setLoading(false)
        }
        init()
    }, [userAddress])

    useEffect(() => {
        if (currentUser !== null && currentUser.isArtist !== undefined) {
            isArtist.current = currentUser.isArtist
            if (isArtist.current) {
                setTabs(prev => (['Curated', 'Created']))
                fetchCollections()
            }
        }
    }, [currentUser])
    console.log("user bids", userBids)

    if (loading) { return <OuterWrapper><LoadingWheel /></OuterWrapper> }
    else {
        return (
            <ColWrapper>
                <PageHeader>
                    <AccountActivityHeader>
                        Account Activity
                    </AccountActivityHeader>
                    <GridHeader>
                        {statusKey !== null && tabs.map(t => (
                            <PageTab color={showTabs === t ? 'var(--fontColor)' : 'var(--grey)'} key={t}
                                onClick={() => {
                                    setShowTabs(t)
                                    if (t === 'Created') {
                                        setContent({
                                            type: "Collections",
                                            value: statusKey[0].id,
                                            text: statusKey[0].status,
                                            subtext: ''
                                        })
                                    }
                                    else {
                                        setContent({
                                            type: Object.keys(sideBarLinks)[0],
                                            value: sideBarLinks[Object.keys(sideBarLinks)[0]][0].value,
                                            text: sideBarLinks[Object.keys(sideBarLinks)[0]][0].text,
                                            subtext: ''
                                        })
                                    }
                                }}
                            >{t}</PageTab>
                        ))}<NavLink onClick={() => navigate("/upload")}>Create<img src={AddIcon} alt="add-icon" /></NavLink>
                    </GridHeader>
                </PageHeader>
                <OuterWrapper>
                    <SideBarWrapper>
                        <SideBarContent>
                            {
                                (statusKey !== null
                                    // && collections.length > 0 
                                    && isArtist.current
                                    && showTabs === 'Created') &&
                                <>
                                    <NavHeader>Collections</NavHeader>
                                    {statusKey.map(c_status => (
                                        <Nav
                                            value={c_status.id}
                                            key={c_status.id}
                                            isActive={content.text === c_status.status && content.type === "Collections" ? true : false}
                                            onClick={(e) => {
                                                setContent({
                                                    type: "Collections",
                                                    value: c_status.id,
                                                    text: c_status.status,
                                                    subtext: ''
                                                })
                                                fetchCollections(c_status.id)
                                            }
                                            }
                                        >{c_status.status}<CollectionStatusIcon status={c_status.status} isSelected={content.text === c_status.status && content.type === "Collections" ? true : false} />

                                        </Nav>
                                    ))}
                                </>
                            }
                            {showTabs === 'Curated' && Object.keys(sideBarLinks).map((value, index) => (
                                <CatWrapper key={index}>
                                    <NavHeader key={value}>{value}</NavHeader>
                                    {sideBarLinks[value].map(link => {
                                        const ob = value.toUpperCase() === BIDS_CATEGORY ?
                                            userBids[link.text.toLowerCase()] !== undefined ? userBids[link.text.toLowerCase()].length :
                                                userBids['winning'].length + userBids['won'].length + userBids['outbid'].length
                                            : ownedNFTs.length
                                        const ob2 = Object.keys(VALID_TRANSACTION_FILTERS).find(it => VALID_TRANSACTION_FILTERS[it] === link.text)

                                        return (
                                            <Nav
                                                value={link.value}
                                                key={value + link.value}
                                                isActive={content.value === link.value && unCamelCase(content.type) === unCamelCase(value) ? true : false}
                                                onClick={async (e) => {
                                                    setContent({
                                                        type: value,
                                                        value: link.value,
                                                        text: link.text,
                                                        subtext: link.subtext
                                                    })
                                                    if (value.toUpperCase() === BIDS_CATEGORY) {
                                                        console.log(link.value, "value!")
                                                        await fetchBids(link.value)
                                                        setLoading(false)
                                                    } else {
                                                        console.log("link value", link.value)
                                                        await fetchItems(ob2 !== undefined ? ob2 : null)
                                                        setLoading(false)
                                                    }
                                                }
                                                }
                                            >
                                                <div>{link.text}</div><div></div>
                                            </Nav>
                                        )
                                    })}
                                </CatWrapper>
                            ))}
                            {/* <NavHeader>Submit Art</NavHeader>
                        <NavLink to="/upload">New Collection</NavLink>
                        <Nav>New One-Offs</Nav> */}
                        </SideBarContent>
                    </SideBarWrapper>
                    <RightContainer>
                        <GridItem gridGap="10px" margin="20px 50px 10px 35px" width="auto" height="auto" minHeight="65px" display="grid" gridTempCols="1fr min-content" gridTempRows="min-content min-content">
                            {content.type === 'Collections' && <div style={{ fontSize: '16px' }} onClick={() => setBreadcrumb(null)}>{content.text.toUpperCase()} {content.type.toUpperCase()}</div>}
                            {content.type !== 'Collections' &&
                                <>
                                    <div style={{ fontSize: '16px' }} onClick={() => setBreadcrumb(null)}>{content.type} - {content.text}</div>
                                    <GridItem alignItems="start" gridCol="1 / span 2" gridRow="2"><Subtext>{content.subtext}</Subtext></GridItem>
                                </>
                            }
                            {/* <div onClick={() => setBreadcrumb(null)}>{content.text} {content.type}</div> */}
                            {/* <div>{breadcrumb !== null ? ' / ' : ''}</div>
                                <div>{breadcrumb !== null ? breadcrumb : ''}</div> */}

                            <DateWrapper>{todaysDate}</DateWrapper>
                        </GridItem>
                        <Innercontainer>
                            <ScrollContainer>
                                {
                                    loadingContent && <LoadingWheel position="relative"/>
                                }
                                {!loadingContent && <Content
                                    loading={loading}
                                    ownedNFTs={ownedNFTs}
                                    fetchBids={fetchBids}
                                    fetchItems={fetchItems}
                                    setOwnedNFTs={setOwnedNFTs}
                                    setResellToken={setResellToken}
                                    setUserBids={setUserBids}
                                    claimNFTButtonDisabled={claimNFTButtonDisabled}
                                    setClaimNFTButtonDisabled={setClaimNFTButtonDisabled}
                                    userBids={userBids}
                                    statusKey={statusKey}
                                    breadcrumb={breadcrumb}
                                    collections={collections}
                                    fetchCollections={fetchCollections}
                                    setItem={setItem}
                                    setBreadcrumb={setBreadcrumb}
                                    setLoading={setLoading}
                                    setError={setError}
                                    contentFilter={content}
                                />}
                            </ScrollContainer>
                        </Innercontainer>
                    </RightContainer>
                </OuterWrapper>
                {resellToken.showModal && <Resale artwork={resellToken.artwork} closeModal={() => { setResellToken(prev => ({ ...prev, showModal: false })) }} />}
            </ColWrapper>
        )
    }
}


export default AccountActivity
