import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, LabelStyledOverlap, Conversion, InputStyled, LabelStyled, DollarSign, InnerWrapper } from './InputField.styles'


const Label = ({ name, marginBottom, label, overlap, color }) => {
    if (overlap) {
        return <LabelStyledOverlap htmlFor={name}
            marginBottom={marginBottom}
            color={color}
        >{label}</LabelStyledOverlap>
    }
    else return (
        <LabelStyled htmlFor={name}
            color={color}
            marginBottom={marginBottom}
        >{label}</LabelStyled>
    )
}




const InputField = ({ overlapLabel, priceOnBlur, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, disabled }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [textColor, setTextColor] = useState('#000')
    const [cursorPosition, setCursorPosition] = useState(0);
    const [conversionRatio, setConversionRatio] = useState('')
    const [conversionOutline, setConversionOutline] = useState(false)
    const [conversion, setConversion] = useState('')
    const [initialLength, setInitialLength] = useState(0);
    useEffect(() => {
        fetch("https://api.coinbase.com/v2/prices/ETH-USD/spot")
            .then(res => res.json())
            .then(
                (result) => {
                    //setIsLoaded(true);
                    console.log(result)
                    setConversionRatio(result.data.amount);

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                }
            )
    }, [])

    console.log(conversion)

    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }

    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);
            let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let decimal;
            if (value.split('.').length > 1) {
                decimal = value.split('.')[1];//.substring(0, 2)
                price = price + '.' + decimal;
            }
            if (price === '' || price === '.') {
                setConversion('')
            }
            else {
                console.log(parseFloat(price))
                const conv = (Math.round(((parseFloat(price) * conversionRatio) + Number.EPSILON) * 100) / 100).toString();
                setConversion('$' + conv.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '')
            }
        }
    }, [value])



    if (label !== undefined) {

        return (
            <Wrapper >

                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                    color={textColor}
                    overlap={overlapLabel ? overlapLabel : undefined}
                    disabled={disabled !== undefined ? disabled : undefined}
                ></Label>
                <InnerWrapper name={name}>
                    {name === 'price' && <DollarSign>Ξ</DollarSign>}
                    <InputStyled
                        type={type}
                        ref={inputRef}
                        placeholder={placeholder}
                        value={value}
                        onFocus={() => {
                            setTextColor('var(--uiollyPurple)')
                            setConversionOutline(true)
                        }}
                        className={`cp`}
                        color={textColor}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        onBlur={priceOnBlur !== undefined ? (e) => {
                            priceOnBlur(e);
                            setTextColor('var(--fontColor)')
                            setConversionOutline(false)

                        } : () => { setTextColor('var(--fontColor)'); setConversionOutline(false) }}
                        disabled={disabled !== undefined ? disabled : undefined}


                    />
                    {name === 'price' && <Conversion showOutline={conversionOutline} conversion={conversion}>{conversion}</Conversion>}
                </InnerWrapper>

            </Wrapper>

        )
    }
    else {
        return (
            <InputStyled
                type={type}
                color={textColor}
                placeholder={placeholder}
                onFocus={() => { setTextColor('var(--uiollyPurple)') }}
                value={value}
                onChange={onChange}
                ref={inputRef}
                onBlur={priceOnBlur !== undefined ? (e) => { priceOnBlur(e); setTextColor('var(--fontColor)') } : () => { setTextColor('var(--fontColor)') }}
                disabled={disabled !== undefined ? disabled : undefined}


            />

        )
    }



}

export default InputField
