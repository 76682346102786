import {
    VALID_BID_FILTERS
} from '../config'

export const getValidConstraints = (validConstraintKeys, object) => {
    const newObject = Object.entries(object).filter(a => validConstraintKeys.includes(a[0]))
    return Object.fromEntries(newObject)
}

export const getBidStatus = (amWinningOrWinner, isOpen, filter) => {
    if (!amWinningOrWinner
        && isOpen
        && meetsFilterCondition(filter, VALID_BID_FILTERS.BID_FILTER_OUTBID))
        return VALID_BID_FILTERS.BID_FILTER_OUTBID
    else if (amWinningOrWinner
        && isOpen
        && meetsFilterCondition(filter, VALID_BID_FILTERS.BID_FILTER_WINNING))
        return VALID_BID_FILTERS.BID_FILTER_WINNING
    else if (amWinningOrWinner
        && !isOpen
        && meetsFilterCondition(filter, VALID_BID_FILTERS.BID_FILTER_WON))
        return VALID_BID_FILTERS.BID_FILTER_WON
    else return undefined
}

export const meetsFilterCondition = (activeFilter, thisFilter) => {
    if (activeFilter === VALID_BID_FILTERS.NO_FILTER)
        return true
    else if (activeFilter === thisFilter)
        return true
    else return false
}

export const checkIfEventDataRecorded = (bidEvents, itemId) => {
    let found_index = -1;
    let found_key = Object.keys(bidEvents).find(key => bidEvents[key].find((it, index) => {
        if (it.bid && it.bid.itemId === itemId) {
            found_index = index
            return true
        } else return false
    }))
    return { found_index, found_key }
}