import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
height: var(--navBarHeight);
width: 100%;
padding: 0 30px;
position: fixed;
display: grid;
align-items: center;
background: var(--background);
box-shadow: 0 0px 5px 1px rgba(0,0,0,0.2);
grid-gap: 20px;
grid-template-columns: min-content 1fr min-content ${props => props.showAccount ? 'min-content' : ''} min-content;
grid-template-areas: ${props => props.showAccount ? "'home . activity drops marketplace account'" : "'home . drops marketplace account'"};
justify-content: space-around;
top: 0;
z-index: 5;
border-bottom: 1px solid var(--lightGrey);
   .home{
       grid-area: home;
   }
   .activity{
       grid-area: activity;
   }
   .drops{
       grid-area: drops;
   }
   .marketplace{
       grid-area: marketplace;
   }
   .accountActivity{
       grid-area: activity;
   }
   .account{
       grid-area: account;
   }
`;

export const NavLink = styled(Link)`
    color: var(--fontColor);
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    font-size: 16px;

    &.active{
        font-weight: 600;
        letter-spacing: 0.02rem;
    }
    

`
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    justify-content: end;
    grid-area: login;
    img{
     height: 100%;
     width: 23px;
     position: relative;
 }

    @media screen and (max-width: 768px)  {
        display: none;

    }
    `

export const NavBtnLink = styled(Link)`

all: unset;
width: min-content;
height:25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
border: none;
cursor: pointer;
 img{
     height: 100%;
     width: 23px;
     position: relative;
 }
    span{
        color: #000;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02rem;
    background: var(--white);
    margin-left: 2px;
    border-radius: 2px;
    font-weight: 400; 
    }
`

export const DropDownNavBtn = styled.div`

all: unset;
width: min-content;
height:25px;
display: flex;
align-items: center;
position: relative;
background: transparent;
border: none;
cursor: pointer;
 img{
     height: 100%;
     width: 23px;
     position: relative;
 }
    span{
        color: #000;
    white-space: nowrap;
    height: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02rem;
    background: var(--white);
    margin-left: 2px;
    border-radius: 2px;
    font-weight: 400; 
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    width: 100%;
    letter-spacing: .02rem;
    grid-area: links;
    @media screen and (max-width: 768px){
        display: none;
    }
`

export const DropDownContainerBox = styled.div`
right: 30px;
position: absolute;
margin-top: 30px;
`;