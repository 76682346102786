import React, { useState, useEffect, useRef } from 'react'

import { Video, Wrapper, TextWrapper, FrameImageWrapper, GridItem, Text, Frame, OuterWrapper, FeaturedArtInfo, Title, Artist } from './HomeIntro.styles'
import { useNavigate } from 'react-router-dom'
import Droplet from '../../images/goldDrop.png'
import PictureFrame from '../../images/homeIntroPictureFrame.svg'

import Button from '../Button'
import { getFileType } from '../../helpers'
import { useIntroArtFetch } from '../../hooks/useIntroArtFetch';


const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <FrameImageWrapper>
            <Video
                ref={videoRef}
                src={artwork}
                width="100%"
                autoPlay={false}
                controls={false}
                onMouseOver={(event) => videoRef.current.play()}
                onMouseOut={(event) => videoRef.current.pause()}
                loop={true}
                muted
                playsInline
            />
        </FrameImageWrapper>
    )
}

const ImgFrame = ({ image, address, artist, title }) => {

    return (
        <Frame bgdImage={PictureFrame}>
            <FrameImageWrapper><img src={image} alt={title} /></FrameImageWrapper>

            <FeaturedArtInfo><Title>{title}</Title><Artist>by {artist}</Artist></FeaturedArtInfo>
        </Frame>
    )
}

const ImageArtwork = ({ artwork, title }) => {
    return (
        <FrameImageWrapper>
            <img src={artwork} alt={title} />
        </FrameImageWrapper>
    )
}

const Artwork = ({ artwork }) => {
    const fileType = getFileType(artwork.image)

    if (fileType === 'video') {
        return <VideoArtwork artwork={artwork.image || artwork.thumbnail} title={artwork.artwork_title} />
    }
    else {
        return <ImageArtwork artwork={artwork.image || artwork.thumbnail} title={artwork.artwork_title} />
    }
}
// MIGHT NEED TO CHANGE TOKEN_ID TO BASE_TYPE
const HomeIntro = ({ artwork }) => {
    const navigate = useNavigate()
    const { state } = useIntroArtFetch();

    const { artistName, title, url, address } = state;
    console.log(artwork)
    return (
        <OuterWrapper >
            <Wrapper style={{position: 'absolute',objectFit: 'cover',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                <img src={artwork.image} style={{minHeight: '110%', minWidth: '110%',filter: 'blur(5px)',position: 'absolute',objectFit: 'cover',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}/></Wrapper>
            <Wrapper>
                <TextWrapper>
                    <h2 style={{fontWeight: '200'}}>Own the best moments, works of art, and nft memories -</h2>
                    <h1 style={{lineHeight: '3.3rem'}}>Only on Uiolly!</h1>
                </TextWrapper>
                <GridItem gridArea="button" style={{width:'221px',boxShadow: ' 0 0px 5px 1px rgba(0,0,0,0.15)'}}>
                    <Button
                        width='221px'
                        border='0'
                        background="var(--buttonGrey)"
                        onClick={() => { navigate('/faq') }}
                        text="Learn More" />
                </GridItem>
        
                <Frame onClick={() => { navigate(`/collections/${artwork.contract_address}/${artwork.token_id}`) }}>
                    <Artwork artwork={artwork} />
                    <FeaturedArtInfo className="info"><Title>{artwork.artwork_title}</Title><Artist>by {artwork.artist_name}</Artist></FeaturedArtInfo>
                </Frame>

            </Wrapper>
        </OuterWrapper>
    )
}

export default HomeIntro
