import styled from 'styled-components';


export const LabelStyled = styled.label`
    /* position: absolute;
    top: -0.5rem; */
    background: var(--formBackground);
    z-index: 1;
    left: 0.5rem;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const CharacterCount = styled.div`
font-size: 10px;
`;


export const TextAreaStyled = styled.textarea`
  background: none;
  border: var(--inputBorder);
   font-size: 13px;
    padding: 10px 20px;
    font-weight: 600;
   resize: none;
    width: 100%;
    position: relative;
    letter-spacing: .1rem;
    color: ${ props => props.color ? props.color : props.disabled ? 'var(--lightGrey)': 'var(--fontColor)'};

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 2px solid var(--uiollyPurple);
        border: 1px solid var(--uiollyPurple);
        
    }

`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;