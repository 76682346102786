import styled from 'styled-components'

export const Wrapper = styled.div`
display: grid;
position: relative;
grid-auto-rows: min-content;
grid-template-columns: 20px 20px 20px 1fr min-content 13px;
padding: 0 15px;
user-select: none;
grid-gap: 10px;
grid-row-gap: 20px;
width: 100%;
align-items: end;
height: 100%;
max-height: 140px;
overflow: auto;
overflow-x: hidden;
box-shadow: inset 0 0px 5px 1px rgba(0,0,0,0.2);
/* mask-image: linear-gradient(0deg, transparent 2%, red 5%, red 95%, transparent 98%); */
>div:nth-of-type(1),
>div:nth-of-type(2),
>div:nth-of-type(3),
>div:nth-of-type(4),
>div:nth-of-type(5){
    margin-top: 20px;
}
>div:nth-last-of-type(1),
>div:nth-last-of-type(2),
>div:nth-last-of-type(3),
>div:nth-last-of-type(4),
>div:nth-last-of-type(5) {
    margin-bottom: 20px;
}
`;

export const OuterWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: start;
flex-grow: 1;
margin-right: 30px;
`;

export const Action = styled.div`
height: 20px;
width: 20px;
img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}
`;

export const LoadWrapper = styled.div`
display: flex;
width: 100%;
height: 100%;
max-height: 120px;
align-items: center;
justify-content: center;
`;