import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
flex-basis: 300px;
flex-shrink: 1;
position: sticky;
display: flex;
flex-direction: column;
z-index: 2;
background: var(--background);
user-select: none;
height: calc(100vh - var(--navBarHeight) * 2);
top: calc(var(--navBarHeight) * 2);
/* border-right: 1px solid var(--lightGrey); */

`;

export const Content = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
overflow: auto;
`;

export const Header = styled.div`
text-transform: uppercase;
padding: 10px 30px;
margin: 10px 0 0 0;
font-size: 16px;
`;

export const Filter = styled.div`
font-size: 14px;
cursor: pointer;
color: var(--lighterGrey);
display: flex;
align-items: center;
padding: 10px 30px;
letter-spacing: 0.03rem;
font-weight: ${props => props.isActive ? '700' : ''};
font-size: 12px;
text-transform: uppercase;
color: ${props => props.isActive ? 'var(--uiollyPurple)' : 'var(--grey)'};
/* background: ${props => props.isActive ? 'var(--uiollyPurple)' : 'transparent'};  */
img {
    height: 10px;
    width: 10px;
}
`;