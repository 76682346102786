import web3 from   "../web3";
import ABI from "../abiRopsten/CollectionDeployer.json";

// ropsten
export const deployerAddress = "0x92e53435E173Db2aA3aAdf253395e7CE91120d8F"
const instance = async () => {
  try {
    return new web3.eth.Contract(ABI.abi, deployerAddress);
  } catch (error) {
    console.log(error);
  }
};

export default instance;
