import styled from 'styled-components'

export const Wrapper = styled.div`
width: 50px;
height: 50px;
overflow: hidden;
position: relative;
margin: 0 5px;
display: flex;
border-radius: 5px;
align-items: center;
justify-content: center;
:hover{
    border: 2px solid var(--red);
}

`;

export const Thumb = styled.img`

object-fit: cover;
    position: relative;
    width: 100%;
    border-radius: 5px;
    min-height: 100%;
min-width: 100%;
border-radius:2px;
`;

export const Video = styled.video`

object-fit: cover;
    position: relative;
    width: 100%;
    border-radius: 2px;
    min-height: 100%;
min-width: 100%;
border-radius:2px;
`;

export const Border = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
position: relative;
display: flex;
align-items: center;
border-radius:2px;
justify-content: center;
`;


export const Button = styled.button`
all: unset;
cursor: pointer;
width: 100%;
z-index: 1;
position: absolute;
border-radius: 2px;
top: 0;
height: 100%;
background: rgba(255, 255, 255, 0.8);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
visibility: ${props => props.showTrash ? 'visible' : 'hidden'};
img{
    height: 20px;
}
`;