import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
display: flex;
height: ${props => props.height ? props.height : '18px'};
width: ${props => props.width ? props.width : '18px'};
margin: ${props => props.margin ? props.margin : ''};
padding: 3px;
svg{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
`;