import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import web3 from "web3";
import { getTokensOwned, initMarket } from '../../blockchain/functions'
import { CloseButtonContainer, Label, OuterWrapper, Wrapper, Title, EdNumber, Artist, ETHConversionWrapper } from './Resale.styles'
import {
    MAX_DEC_PLACES
} from '../../config'
import { useWeb3 } from '../../contexts/Web3Context'
import { useResale } from '../../hooks/useResale'
import Artwork from '../Artwork'
import Button from '../Button'
import GridItem from '../GridItem'
import InputField from '../InputField'
import RadioBubbles from '../UploadForm/RadioBubbles'

import Close from '../../images/close.svg'

const ClosePopup = ({ closeModal }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closeModal} />
    </CloseButtonContainer>
)

const Resale = ({ artwork, closeModal }) => {
    const {
        modalWrapperRef,
        item,
        setItem,
        saletypeOptions,
        priceOnBlur,
        onPriceChange,
        onSubmit,
        conversion,
        handleFieldInput,
        runTime,
        selectedDay,
        startTime,
        validContinue,
        validateFields
    } = useResale(artwork)

    if (item.artwork === null)
        return (<OuterWrapper>

        </OuterWrapper>)
    else return (
        <OuterWrapper ref={modalWrapperRef} onClick={(e) => {
            if (e.target === modalWrapperRef.current) {
                closeModal()
            }
        }}>
            <Wrapper>
                <GridItem gridCol="1 / span 2" width="100%" alignItems="center" justifyContent="space-between" flexDirection="row" display="flex" >
                    <h3 style={{ margin: '0' }}>Sell On Marketplace</h3>
                    <ClosePopup closeModal={closeModal} />
                </GridItem>
                <GridItem height="100%" width="404px" justifyContent="space-between"
                    gridRow="2 / span 1" gridCol="1 / span 1" display="grid" gridTempCols="1fr min-content" gridTempRows="min-content 1fr min-content">
                    <GridItem gridRow="1 / span 1" gridCol="1 / span 2">
                        <Artwork artwork={item.artwork} width="404px" height="404px" borderRadius="0" />
                    </GridItem>
                    <div style={{ display: 'flex', width: '100%' }}><Title>{item.artwork.name}</Title><EdNumber>#{parseInt(item.artwork.edition)}</EdNumber></div>
                    <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>{web3.utils.fromWei(web3.utils.toBN(item.artwork.isAuction ? item.artwork.auctionHigh : item.artwork.price)) + 'Ξ'}</div>

                    <Artist>{item.artwork.artist_name}</Artist>

                    <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>Paid</div>
                </GridItem>

                <GridItem width="100%" gridRowGap="20px" gridRow="2 / span 1" gridCol="2 / span 1" display="grid" gridTempCols="1fr 1fr" gridAutoRows="min-content">
                    <GridItem gridCol="1 / span 2" alignItems="start">
                        <RadioBubbles
                            handleChange={(e) => {

                                setItem(prev => ({ ...prev, saleType: e.target.attributes.value.value, isAuction: e.target.attributes.value.value === 'Auction' }));
                            }}
                            name='saleType'
                            label='Sale Method'
                            marginBottom={'0.8rem'}
                            options={saletypeOptions}
                            value={item.saleType}
                        />
                    </GridItem>
                    <InputField
                        type="text"
                        onChange={onPriceChange}
                        // onKeyPress={continueKey}
                        name='price'
                        priceOnBlur={priceOnBlur}
                        label={
                            item.saleType ===
                                'Auction' ? 'Minimum Bid'
                                : 'Ask'}
                        placeholder=''
                        marginBottom={'0.8rem'}
                        value={item.price}
                    // setValidContinue={setValidContinue}
                    />
                    <ETHConversionWrapper>{conversion}</ETHConversionWrapper>
                    <Label disabled={!item.isAuction}>Auction Details</Label>
                    <GridItem alignItems="center" display="grid" gridCol="1 / span 2" gridTempCols="1fr 2fr" gridColGap="10px" gridRowGap="10px" gridAutoRows="min-content">
                        <Label disabled={!item.isAuction}>Start Date<br />[MM/DD/YYYY]: </Label>
                        <GridItem gridCol="2 / span 2">
                            <InputField disabled={!item.isAuction} placeholder="d" type="date" name="startDate" value={selectedDay} onChange={(e) => { handleFieldInput(e) }} /></GridItem>
                        <Label disabled={!item.isAuction}>Start Time<br />[hh:mm AM/PM]: </Label>
                        <GridItem gridCol="2 / span 2">
                            <InputField disabled={!item.isAuction} type="time" name="startTime" onChange={handleFieldInput} value={startTime} /></GridItem>
                        <Label disabled={!item.isAuction}>Auction Run Time:<br />day(s)</Label>
                        <GridItem gridCol="2 / span 2">
                            <InputField disabled={!item.isAuction} placeholder="d" type="text" name="days" value={runTime} onChange={(e) => { handleFieldInput(e) }} /></GridItem>
                        {/* <GridItem gridCol="3 / span 1"  >day(s)</GridItem> */}
                    </GridItem>
                    <GridItem gridCol="1 / span 2" alignItems="start">
                        <Button
                            disabled={!validContinue}
                            background={validContinue ? '#ffffff' : 'var(--buttonGrey)'}
                            color={validContinue ? 'var(--fontColor)' : 'var(--grey)'}
                            border={validContinue ? '2px solid #000000' : '1px solid var(--grey)'}
                            validContinue={validContinue}
                            width="100%"
                            onClick={async () => {
                                await onSubmit()
                                closeModal()
                            }}
                            text="Set For Sale"
                        />
                    </GridItem>
                </GridItem>

            </Wrapper>
        </OuterWrapper>
    )
}

export default Resale