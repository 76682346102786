import styled from "styled-components";



export const ButtonStyle = styled.div`
    text-align: center;
    font-size: ${props => props.fontSize ? props.fontSize : 'var(--buttonFontSize)'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0'};
    width: ${({ width }) => width};
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    background: ${props => props.disabled ? 'var(--buttonDisabledBgd)' : props.background ? props.background : 'var(--buttonBackground)'};
    border: ${props => props.disabled ? 'var(--buttonDisabledBorder)' : props.border ? props.border : 'var(--buttonBorder)'};
    text-transform: uppercase;
    cursor: ${props => props.disabled ? 'not-allowed' : props.validContinue ? 'pointer' : 'default'};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${props => props.validContinue ? '500' : '700'};
    letter-spacing: 0.08rem;
    color: ${props => props.disabled ? 'var(--buttonDisabledText)' : props.color ? props.color : 'var(--buttonFontColor)'};
:hover{
    background: ${props => props.disabled ? 'var(--buttonDisabledBgd)' : props.hoverBackground ? props.hoverBackground : 'var(--buttonFontColor)'};
    color: ${props => props.disabled ? 'var(--buttonDisabledText)' : props.hoverColor ? props.hoverColor : 'var(--buttonBackground)'};
}

`;

export const Text = styled.div`
    text-transform: uppercase;
    /* color: ${props => props.disabled ? 'var(--buttonDisabledText)' : props.color ? props.color : 'var(--buttonFontColor)'}; */
`;