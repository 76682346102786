import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import web3 from "web3";
import BigNumber from 'bignumber.js'
import bigInt from "big-integer";
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { countdown2 } from '../../helpers'
import { getCollectionItems, getCollectionItemsAndData, getURI, getItemData, getBids } from "../../blockchain/functionsUiollyNew";
import API from '../../API'
import axios from 'axios'
import Banner from './Banner'

import {
    Wrapper,
    H2,
    Description,
    Bio,
    SocialWrapper,
    Social,
    ArtistPicture,
    ItemWrapper
} from './Collection.styles'

import ArtworkCard from '../ArtworkCard'
import FacebookIcon from '../../images/facebook.svg'
import TiktokIcon from '../../images/tiktok.svg'
import InstagramIcon from '../../images/instagramIcon.svg'
dayjs.extend(RelativeTime)

const Instagram = ({ instagram }) => (
    <SocialWrapper href={`https://instagram.com/${instagram}`} target="_blank" ><img src={InstagramIcon} /></SocialWrapper>
)

const Facebook = ({ facebook }) => (
    <SocialWrapper href={`https://facebook.com/${facebook}`} target="_blank"><img src={FacebookIcon} /></SocialWrapper>
)

const Tiktok = ({ tiktok }) => (
    <SocialWrapper href={`https://tiktok.com/${tiktok}`} target="_blank"><img src={TiktokIcon} /></SocialWrapper>
)


const Collection = ({ c_param }) => {
    const navigate = useNavigate()
    const [collection, setCollection] = useState(c_param ? c_param : {})
    const today = dayjs().unix();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false)
    const [countdown, setCountdown] = useState('')
    const [error, setError] = useState(false)
    let location = useLocation();
    const { blockchainAddress } = useParams();
    useEffect(() => {
        console.log("big number", web3.utils.toBN(1) << web3.utils.toBN(128))
        //let b = (1n << 128n)
        setLoading(true)
        const fetch_uris = async (idx) => {
            let uri = await getURI(blockchainAddress, idx);
        }
        const fetchItems = async () => { }
        const fetchJSON = async (uri) => {

            let res = await axios({
                method: 'get',
                url: `${uri}`
            });
            return res.data
        }

        if (location.state !== null && location.state.collection !== undefined && location.state.pricingData !== undefined) {
            setCollection({ ...location.state.collection })
            // let collectionItems = getCollectionItems(blockchainAddress); //getURI _address, _tokenId
            if (window.ethereum)
                getCollectionItems(blockchainAddress).then((c_items) => {
                    console.log("ITEMS", c_items)
                    c_items.map((item, idx) => {
                        // getNonFungibleIndex(blockchainAddress, bigInt(item.baseType).xor(bigInt(500)).toString()).then(res => {
                        //     console.log("xxxx", res)
                        //     console.log("RESXXXX",bigInt(2).pow(128).subtract(1).and(bigInt(item.baseType).xor(bigInt(500))).toString())
                        // })
                        //getURI(blockchainAddress, idx).then((uri) => {
                        getItemData(blockchainAddress, idx + 1).then((data) => {
                            console.log("outputted data", data)
                            if (!items.filter(item => item.uri === data.uri).length)
                                console.log("data", data.uri)
                            fetchJSON(data.uri).then((res) => {
                                setItems(prev => ([...prev, { ...data, ...res }]));
                            })


                        })
                    })
                })
            setLoading(false)
        } else {
            const fetchColl = async () => {
                const c = await getCollectionItemsAndData(blockchainAddress)
                const offChain = await API.fetchDrops(blockchainAddress)
                setCollection({ ...offChain, ...c })
                setItems(c.items)
            }
            try {
                fetchColl()
                setLoading(false)
            } catch (error) {
                console.log("err", error)
                setLoading(false)
            }

            // API.fetchDrops(blockchainAddress).then((d) => {
            //     setCollection({ ...d })
            //     // let collectionItems = getCollectionItems(blockchainAddress); //getURI _address, _tokenId
            //     if (window.ethereum)
            //         getCollectionItems(blockchainAddress).then((c_items) => {

            //             // c_items.forEach(async (item, index) => {
            //             //     // const itemData = await getItemData(web3.utils.toHex(new BigNumber(item.baseType)).replace('0x', ''), blockchainAddress)
            //             //     //const itemData = await getItemData(bigInt(item.baseType).shiftRight(128), blockchainAddress)
            //             //     // console.log(bigInt(item.baseType).shiftRight(128).toString())
            //             //     const itemData = await getItemData(blockchainAddress, bigInt(item.baseType).shiftRight(128).toString())
            //             //     //console.log(bigInt(20).shiftLeft(128).toString())
            //             // })
            //             // c_items.map((item, idx) => {
            //             //     //getURI(blockchainAddress, idx).then((uri) => {
            //             //     const bigAF = bigInt(item.baseType)//new BigNumber(item.baseType)
            //             //     const bigAF2 = bigInt(2).pow(128).subtract(1)//new BigNumber(2).pow(128).minus(1)
            //             //     // EIP STANDARD DO NOT DELETE
            //             //     // NOTE INCOMPATIBLE WITH OPENSEA MARKETPLACE
            //             //     // web3.utils.toHex(new BigNumber(item.baseType)).replace('0x', '').padStart(64, '0')

            //             //     console.log(web3.utils.toHex(new BigNumber(item.baseType)).replace('0x', '').padStart(64, '0'), bigInt(2).shiftLeft(128).and(bigAF), bigAF.shiftRight(128)) //bigInt(1).shiftLeft(128), bigInt(2).shiftLeft(128)
            //             //     // getURI(blockchainAddress, item.baseType).then((res) => {
            //             //     //     console.log(res)
            //             //     // })
            //             //     // getItemData(idx, blockchainAddress).then((data) => {
            //             //     //     console.log("data", data)
            //             //     //     if (!items.filter(item => item.uri === data.uri).length)
            //             //     //         // console.log("data", data.uri)
            //             //     //     fetchJSON(data.uri).then((res) => {
            //             //     //         setItems(prev => ([...prev, { ...data, ...res }]));
            //             //     //     })
            //             //     //     setLoading(false)

            //             //     // })

            //             //     //})
            //             // })
            //         })



            // }).catch((err) => {
            //     setError(err)
            //     setLoading(false)
            // })
        }

    }, [])
    // useInterval(() => {

    //     setCountdown(countdown2(collection.release_date, collection.end_date))
    // }, items.length ? 1000 : null);

    console.log("items and collections", items, collection)

    if (loading || collection.collection_title === undefined || !items.length) {
        return <Wrapper></Wrapper>
    } else {
        return (
            <Wrapper>
                <Banner collection={collection} />
                <H2>{collection.collection_title}</H2>
                <Description>{collection.collection_description}</Description>
                {/* <H2>{countdown}</H2> */}
                <H2> {dayjs().unix() < parseInt(collection.end_date) && dayjs().unix() > parseInt(collection.release_date) ? <>Collection Closes {dayjs.unix(collection.end_date).format('dddd h:mm A')}</>
                    : dayjs().unix() > parseInt(collection.end_date) ? 'Collection Closed' : `Opens ${dayjs.unix(collection.release_date).format('MMM DD h:mm A')}`} </H2>
                <H2>{collection.items.length} item{collection.items.length > 1 ? 's' : ''} in this Collection</H2>
                <ItemWrapper>{
                    collection.items.map(item => {
                        console.log("ITEM BASE TYPE", item.baseType)
                        const item_chain_info = items.filter(it => parseInt(it.baseType) === parseInt(item.baseType))[0]
                        const keep = ["release_date", "end_date", "artist_name", "artist_handle", "collection_title"]
                        const item_abbrev = (({ release_date, end_date, artist_name, artist_handle, collection_title }) => ({ release_date, end_date, artist_name, artist_handle, collection_title }))(item);
                        return (
                            <ArtworkCard
                                pricingData={item.primaryMarketData}
                                key={item.baseType}
                                itemContractData={item_chain_info}
                                onClick={() => {
                                    navigate(`/collections/${blockchainAddress}/${parseInt(item.baseType)}`, { state: { artwork: { ...item_chain_info, ...item_abbrev } } })
                                }}
                                artwork={{ ...item_abbrev, ...item_chain_info }} />
                        )
                    })
                }</ItemWrapper>
                {collection.artist_bio && <><H2>About {collection.artist_name}</H2>
                    <ArtistPicture onClick={() => { navigate(`/profile/${collection.artist_handle}`) }}>
                        <img src={collection.profile_pic} alt={collection.artist_name} />
                    </ArtistPicture>
                    <Social>
                        {collection.artist_instagram && <Instagram instagram={collection.artist_instagram} />}
                        {collection.artist_facebook && <Facebook facebook={collection.artist_facebook} />}
                        {collection.artist_tiktok && <Tiktok tiktok={collection.artist_tiktok} />}
                    </Social>
                    <Bio>{collection.artist_bio}</Bio></>}
            </Wrapper>
        )
    }
    ;
};

export default Collection;
