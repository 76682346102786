import styled from 'styled-components'

export const OuterWrapper = styled.div`
width: 100%;
position: relative;
display: grid;
grid-template-columns: 1fr repeat(2,min-content);
grid-column-gap: 10px;
margin-top: 20px;
height: 60px;
align-items: center;


`;

export const ColWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ItemCount = styled.div`
font-size: 11px;
text-transform: uppercase;
line-height: 1rem;
`;

export const RemoveAll = styled.div`
color: var(--red);
font-size: 10px;
text-transform: uppercase;
letter-spacing: 0.03rem;
margin-left: .5rem;
line-height: 1rem;
`;


export const ScrollBarContainer = styled.div``;

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    height: 60px;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    scroll-behavior: smooth;
    mask-image: linear-gradient(0deg, transparent 2%, red 5%, red 95%, transparent 98%);
`;

export const EmptySpacer = styled.span`
height: 100%;
width: 25px;
position: relative;
display: inline-block;
flex-shrink: 0;
`;

export const FlexRow = styled.div`
display: flex;
align-items: flex-end;
user-select: none;
margin-top: 5px;
`;

export const ScrollWrapper = styled.div`

max-height: 100%;
display: flex;
flex-wrap: nowrap;

 >div:first-child {
    margin-left: 0px;
}

`;