import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'
export const Wrapper = styled.div`
    margin-top: 50px;
    width: 100%;
    background: var(--lightestGrey);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0 20px 0;
    `;


export const Content = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;



export const LinksContainer = styled.div`
width: ${props => props.width};
margin-top: ${props => props.marginTop ? props.marginTop : '0'};
font-size: 1em;
display: flex;
justify-content: space-between;

img{
    height: 28px;
    width: 28px;
}
`;

export const Copyright = styled.div`
font-size: 10px;
user-select: none;
`;

export const PhoneNumber = styled.div`
margin: 1em 0;
letter-spacing: var(--boldSmallLetterSpacing);
font-weight: var(--boldSmall);
`;

export const NavLink = styled(Link)`
    color: var(--primary);
    display: flex;
    align-items: center;
    padding: 5px 20px;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase;

`