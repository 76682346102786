import { useRef, useEffect, useState } from 'react'

import * as data from '../introArtwork.json';



export const useIntroArtFetch = () => {
    const [state, setState] = useState('');
    
    const initializeState = async() => {
         
            setState(data.IntroArtwork)
        
        
    }

    useEffect(() => { initializeState() }, [])

    return { state }
}