import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  OuterWrapper,
  Wrapper,
  ImageWrapper,
  ButtonWrapper,
  DetailsWrapper,
  Title,
  CollectionSize,
  Artist,
  Image,
  Video
} from './CollectionCard.styles'

import { removeLeadingSlash, getFileType } from '../../helpers'
import API from '../../API'
import { VALID_FILE_TYPES } from '../../config.js'

import { useImportCollectionStatus } from '../../contexts/CollectionStatusContext'
import Pending from '../../images/pendingCollectionIcon.svg'
import Past from '../../images/pastCollectionIcon.svg'
import Unminted from '../../images/mintIcon.svg'
import Scheduled from '../../images/upcomingCollectionIcon.svg'
import Live from '../../images/activeCollectionIcon.svg'

import Button from '../Button'

const ImageArtwork = ({ artwork, title }) => {
  return (
    <ImageWrapper>
      <Image src={artwork} alt={title} />
    </ImageWrapper>
  )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
  const videoRef = useRef(null);
  if (isFeatured && videoRef.current !== null) {
    videoRef.current.play()
  }
  else if (videoRef.current !== null) {
    videoRef.current.pause()
  }
  return (
    <Video
      ref={videoRef}
      src={artwork}
      width="100%"
      autoPlay={false}
      controls={false}
      onMouseOver={(event) => videoRef.current.play()}
      onMouseOut={(event) => videoRef.current.pause()}
      loop={true}
      muted
      playsInline
    />
  )
}

const Artwork = ({ artwork, title }) => {

  if (getFileType(artwork.image) === 'video') {
    return <VideoArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
  }
  else {
    return <ImageArtwork artwork={artwork.image || artwork.thumbnail} title={title} />
  }
}


const CollectionCard = ({ cursor, button, collection, onCardSelect, onButtonSelect, buttonDisabled, setLoading }) => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState(dayjs().unix() >= parseInt(collection.release_date) ? "Admin will reschedule" : "Mint Collection")
  const [error, setError] = useState(false);
  const { statusKey } = useImportCollectionStatus();
  return (
    <OuterWrapper>
      <Wrapper
        cursor={cursor ? cursor : onCardSelect ? typeof (onCardSelect) === 'function' ? 'pointer' : 'default' : 'pointer'}
        onClick={onCardSelect ? onCardSelect : () => { navigate(`/collections/${collection.contract_address}`, { state: { collection: collection, statusKey: statusKey } }) }}>
        <Artwork artwork={collection.items[0]} title={collection.collection_title} />
        <DetailsWrapper>
          <Title>{collection.collection_title}</Title>
          <Artist>{collection.artist_name}</Artist>
          {button && <Artist>RELEASE DATE: {dayjs.unix(collection.release_date).format('MMM D h:mm A')}</Artist>}
          {button && <Artist>{dayjs().unix() >= parseInt(collection.release_date) ? 'Release date elapsed.' : 'Mint before release date.'}</Artist>}
          <CollectionSize>{collection.items.length} Exhibitions</CollectionSize>
        </DetailsWrapper>
        {button &&
          <ButtonWrapper>
            <Button
              onClick={() => {
                if (dayjs().unix() < parseInt(collection.release_date)) {
                  console.log(dayjs().unix(), collection.release_date);
                  setLoading(true)
                  onButtonSelect()
                }
                else {
                  setButtonText('Release date elapsed')
                }
              }}
              fontSize="10px"
              border="1px solid #000000"
              disabled={buttonDisabled || dayjs().unix() > parseInt(collection.release_date)}
              text={buttonText}
              width="100%"
              height="50px"
            />
          </ButtonWrapper>}
      </Wrapper>
    </OuterWrapper>
  )
}

export default CollectionCard
