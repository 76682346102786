import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Wrapper, SubText, Note, Title, Content, ButtonStyle } from './DragAndDrop.styles'

import UploadCloudIcon from '../../../images/upload.svg'



const Button = ({ position, background, height, width, color, text, border, hoverColor, handleSongUpload }) => {
    const inputFile = useRef(null);
    const onButtonClick = (event) => {

        inputFile.current.click();
    }

    const handleFileSelection = (event) => {
        event.preventDefault();
        console.log(event.target.files)
        const files = event.target.files;
        handleSongUpload(files)
    }
    return (

        <ButtonStyle
            onClick={onButtonClick}
            background={background} height={height} width={width} color={color} border={border} hoverColor={hoverColor} >
            {text}
            <input type="file" ref={inputFile} onChange={handleFileSelection} />
        </ButtonStyle>
    )

}

const DragAndDrop = ({ fileType, background, title, values, handleSongUpload, note, height, width, maxWidth, maxHeight }) => {

    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;

        handleSongUpload(files);
    }

    const [fileList, setFileList] = useState('');

    const [isVisible, setIsVisible] = useState(false);



    const onDragEnter = useCallback((e) => {
        setIsVisible(true);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDragOver = useCallback((e) => {
        setIsVisible(true);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const onDragLeave = useCallback((e) => {
        setIsVisible(false);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDrop = useCallback((e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        console.log('Files dropped: ', files);
        handleSongUpload();
        // Upload files
        setIsVisible(false);
        return false;
    }, []);

    useEffect(() => {
        window.addEventListener('mouseup', onDragLeave);
        window.addEventListener('dragleave', onDragLeave);
        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', handleFileDrop);
        return () => {
            window.removeEventListener('mouseup', onDragLeave);
            window.removeEventListener('dragleave', onDragLeave); //verify
            window.removeEventListener('dragenter', onDragEnter);
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', handleFileDrop);
        };
    }, [onDragEnter, onDragLeave, onDragOver, handleFileDrop]);



    return (
        <Wrapper background={background} dragOverEventLive={isVisible} height={height} width={width} maxWidth={maxWidth} maxHeight={maxHeight}>
            <Content >
                <img src={UploadCloudIcon} alt="uploadCloudIcon" />
                <Title>{title}</Title>
                <SubText dragOverEventLive={false}>Drag and drop</SubText>
                <SubText dragOverEventLive={isVisible}>or</SubText>
                <Button
                    width="198px"
                    background={background ? "#ffffff" : "transparent"}
                    // border="2px solid var(--goldSolid)"
                    dragOverEventLive={isVisible}
                    text={background ? "Replace Cover" : "Browse"}
                    handleSongUpload={handleSongUpload}

                > </Button>
                <SubText>{fileType ? fileType : "PNG OR JPEG ACCEPTED"}</SubText>
                <Note>{note}</Note>
            </Content>

        </Wrapper>
    )
}

export default DragAndDrop
