
import { removeLeadingSlash } from './helpers'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'

export const updateRelTime = () => {
    dayjs.extend(RelativeTime)
    var updateLocale = require('dayjs/plugin/updateLocale')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: 'now',
            m: "1m",
            mm: "%d m",
            h: "1hr",
            hh: "%d hrs",
            d: "a day",
            dd: "%dd",
            M: "1M",
            MM: "%d Ms",
            y: "a year",
            yy: "%d years"
        }
    })
}
export const roundData = (data, digits = 2) => {
    return Math.round((Number(data) + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits)
}

export const generateMetadata = (collection, artistWalletAddress) => {
    // use chain address for artist
    const tokenJSONs = new Array(collection.items.length)
    collection.items.forEach((item, index) => {
        tokenJSONs[index] = JSON.stringify({
            id: index,
            artist: artistWalletAddress,
            name: item.artwork_title,
            description: item.artwork_description,
            collection_title: collection.collection_title,
            collection_description: collection.collection_description,
            image: process.env.REACT_APP_BASE_PATH_IMG + removeLeadingSlash(item.path),
            attributes: [{ artist: collection.artist_name }]
        }, null, 4)
    }
    )
    return tokenJSONs
}

export const formatTimeZone2 = (dayObj) => {
    const timeZoneArr = dayObj.toDate().toString().split(' (')[1].replace(')', '').split(' ')
    console.log(dayObj, timeZoneArr)
    return timeZoneArr.reduce((a, b) => a += b[0], '')
}

export const formatTimeZone = (utc_offset) => {
    const utc_time = `UTC ${utc_offset.substring(0, 1)} ${utc_offset.substring(1)}`
    const usa_zones = [
        {
            abbreviation: 'AST',
            offset: '-4'
        },
        {
            abbreviation: 'EST',
            offset: '-5'
        },
        {
            abbreviation: 'CST',
            offset: '-6'
        },
        {
            abbreviation: 'MST',
            offset: '-7'
        },
        {
            abbreviation: 'PST',
            offset: '-8'
        },
        {
            abbreviation: 'AKST',
            offset: '-9'
        },
        {
            abbreviation: 'HST',
            offset: '-10'
        },
        {
            abbreviation: 'SST',
            offset: '-11'
        },
        {
            abbreviation: 'CHST',
            offset: '+10'
        }
    ]
    const zone = usa_zones.filter(zone => zone.offset === utc_offset);
    return zone !== undefined ? zone[0].abbreviation : utc_time;
}