import styled from 'styled-components'

export const Wrapper = styled.div`
  background: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.6);
  filter: drop-shadow(0 0 5px rgba(3, 0, 112, .4));
  height: 100%;
  position: relative;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;

  
`;

export const Content = styled.div`
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
height: ${props => props.height ? props.height : '100%'};

`;

export const QuestionWrapper = styled.div`
    margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
    width: ${props => props.width ? props.width : '100%'};
`;

export const Text = styled.div`

`;