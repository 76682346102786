import React from 'react';

import { ButtonStyle, Text } from './Button.styles';

const Button = ({ buttonRef, disabled, fontSize, validContinue, children, position, background, height, width, color, text, border, hoverColor, onClick, marginTop }) => {
    return (

        // <ButtonGradientBorder height={height} width={width} marginTop={marginTop}>
        <ButtonStyle
            fontSize={fontSize}
            ref={buttonRef !== undefined ? buttonRef : undefined}
            disabled={disabled}
            marginTop={marginTop}
            validContinue={validContinue !== undefined ? validContinue : true}
            onClick={disabled !== undefined && disabled === true ? undefined : onClick }
            background={background} height={height} width={width} color={color} border={border} hoverColor={hoverColor} >
            <Text color={color} disabled={disabled} className="buttonText">{text}</Text>{children}
        </ButtonStyle>
        // </ButtonGradientBorder>
    )
}
export default Button;