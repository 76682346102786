import React, { useEffect, useCallback } from 'react'


import { Wrapper, InnerWrapper } from './FormNavButton.styles'

import NavArrow from '../../../images/formNavArrow.svg'

const FormNavButton = ({ direction, height, onClick, validContinue, text, maxWidth, width }) => {



    return (
        <Wrapper direction={direction} onClick={ (e) => onClick(e)} height={height}
            width={width} maxWidth={maxWidth}
            validContinue={validContinue}>
            <img src={NavArrow} />
        </Wrapper>
    )
}

export default FormNavButton
