import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
user-select: none;
h1{
    font-family: Poppins;
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: 0.05rem;
    margin: 20px 0 5px 0;
    
}
p{
    margin-bottom: 5px;
    font-size: 0.8em;
}
`;


export const UploadTracker = styled.div`
font-size: 10px;
text-transform: uppercase;
letter-spacing: 0.04rem;
font-weight: 600;
height: .5rem;
`;