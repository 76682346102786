import styled from "styled-components";

import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1100px;
    position: relative;
    display: flex;
    flex-direction: column;
    h1{
        text-align: left;
        font-weight: 600;
        margin-bottom: .3rem;
        line-height: 1.5rem;
        text-transform: uppercase;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% + 30px);
    margin: -15px -15px 0;
    position: relative;
    justify-content: space-between;

`;


export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
`;

export const Text = styled.div`
color: var(--grey);
    font-size: 14px;
`;

export const ViewMore = styled(Link)`
    all: unset;
    cursor: pointer;
    position: relative;
    z-index: 1;
    right: 0;
    color: var(--grey);
    font-size: 14px;

`;