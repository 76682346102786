import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    //top: -0.5rem;
    /* background: ${ props => props.disabled ? 'linear-gradient(to bottom,var(--formBackground) 49%,var(--grey) 50%)': 'var(--formBackground)'}; */
    color: ${ props => props.disabled ? 'var(--lightGrey)': 'var(--fontColor)'};
    z-index: 1;
    /* padding: 0 5px; */
    /* left: 0.5rem; */
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const LabelStyledOverlap = styled.label`
    position: absolute;
    top: -0.5rem;
    background: ${ props => props.disabled ? 'linear-gradient(to bottom,var(--formBackground) 49%,var(--grey) 50%)': 'var(--formBackground)'};
    color: ${ props => props.color ? props.color : props.disabled ? 'var(--lightGrey)': 'var(--fontColor)'};
    z-index: 2;
    padding: 0 5px;
    left: 0.5rem;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const DollarSign = styled.div`
user-select: none;
z-index: 10;
position: absolute;
left: 1rem;

`;

export const Conversion = styled.div`
background: var(--fontColor);
display: flex;
padding: ${props=> props.conversion !== '' ? '0 5px' : '0'};
align-items: center;
width: ${props=> props.conversion !== '' ? '100%' : '0%'};
justify-content: end;
height: 49px;
color: var(--background);
transition: width .5s;
outline: ${props => props.showOutline ? '2px solid #000000' : ''};
`;


export const InputStyled = styled.input`
 z-index: 1;
 background: ${ props => props.disabled ? 'var(--lightestGrey)' : ''};
  border: ${ props => props.disabled ? '1px solid var(--lightGrey)': 'var(--inputBorder)'};
  /* background: ${ props => props.disabled ? 'var(--grey)': 'var(--formBackground)'}; */
    height: 49px;
    color: ${ props => props.disabled ? 'var(--lightGrey)': props.color ? props.color :  'var(--fontColor)'};
    font-weight: 600;
   /* font-family: ${props => props.name === 'price' || props.name === 'numberOfEditions' ? 'Stoke' : 'Raleway'}; */
    padding: ${props => props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'};
    width: 100%;

    position: relative;
    letter-spacing: .1rem;
    

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 2px solid var(--uiollyPurple);
        border: 1px solid var(--uiollyPurple);
        
    }
    /* ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }  

   appearance: textfield;
   -webkit-appearance: textfield;
   -moz-appearance: textfield; */


`;

export const Wrapper = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    display: ${props=> props.name === 'price' ? 'grid' : 'flex'};
    display: flex;
    grid-template-rows: min-content;
    grid-template-columns: 1fr 1fr;
`;