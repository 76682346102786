import { useState, useEffect, useRef } from 'react';
import API from '../API';
import { useLocation, useNavigate } from 'react-router-dom'
import { useWeb3 } from '../contexts/Web3Context'
import web3 from "web3";
import axios from 'axios'
import dayjs from 'dayjs'
import {
    MAX_DEC_PLACES
} from '../config'
export const useResale = (artwork) => {
    const location = useLocation()
    const modalWrapperRef = useRef(null)
    const saletypeOptions = ["Auction", "Fixed Price"]
    const { setTokenResale } = useWeb3()
    const [conversion, setConversion] = useState('')
    const [conversionRatio, setConversionRatio] = useState(0)
    const [validContinue, setValidContinue] = useState(false)
    const [item, setItem] = useState({
        artwork: null,
        isAuction: true,
        saleType: saletypeOptions[0],
        price: '',
        salesOpenTime: dayjs().unix(),
        salesCloseTime: dayjs().add(5, 'day').unix(),
        selectedDay: dayjs(),
        startTime: '',
        runTime: ''
    })
    const { selectedDay, startTime, runTime } = item
    const validateFields = () => {
        console.log(item.salesOpenTime)
        if (item.price === undefined || Number(item.price) === 0 || isNaN(Number(item.price))) { console.log("here1"); return false }
        else if (item.isAuction) {
            if (item.salesOpenTime >= item.salesCloseTime) { console.log("here2"); return false }
            else if (dayjs().unix() >= item.salesOpenTime) { return false }
            else if (selectedDay === '') { console.log("here3"); return false }
            else if (runTime === null) { console.log("here4"); return false }
            else if (runTime === 0) { console.log("here5"); return false }
            else if (runTime === '') { console.log("here6"); return false }
            else if (startTime === '') { console.log("here7"); return false }
        }

        console.log("here8"); return true

    }
    useEffect(() => {
        if (validateFields()) {
            setValidContinue(true)
        }
        else {
            setValidContinue(false)
        }

    }, [item])
    useEffect(() => {
        if (location.state !== null && location.state.artwork !== undefined) {
            setItem(prev => ({ ...prev, artwork: location.state.artwork }))
        } else if (artwork) {
            setItem(prev => ({ ...prev, artwork: artwork }))
        }
    }, [])
    useEffect(() => {
        const initConversionRatio = async () => {
            try {
                const res = await fetch('https://api.coinbase.com/v2/prices/ETH-USD/spot')
                const res1 = await res.json()
                setConversionRatio(res1.data.amount);
            } catch (error) {
                
            }
        }
        initConversionRatio()
    }, [])

    const priceOnBlur = (event) => {
        let value;
        if (parseFloat(event.target.value) !== 0 && event.target.value !== '' ) {
            if (event.target.value.split('.').length < 2) {
                value = event.target.value + '.00';
            }
            else {
                value = event.target.value.split('.')[0] + '.' + (event.target.value.split('.')[1]).substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            }
            setItem(prev => ({ ...prev, price: value }))
        }
        else {
            setItem(prev => ({ ...prev, price: value }))
        }
    }

    const onPriceChange = (e) => {
        const value = e.target.value
        let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let decimal; //MAX_DEC_PLACES
        console.log("value", value, price)
        if (value.split('.').length > 1) {
            decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            price = price + '.' + decimal;
        }
        setItem(prev => ({ ...prev, price: price }))
        if (conversionRatio !== '') {
            if (price === '' || value === '.') {
                setConversion('')
            }
            else {
                const conv = (Math.round(((parseFloat(price) * conversionRatio) + Number.EPSILON) * 100) / 100).toString();
                const priceAdj =
                    conv.split('.').length > 1 ?
                        conv.split('.')[0] + `${conv.split('.')[1] !== '00' ? '.' + (conv.split('.')[1] + '0').slice(0, 2) : '.00'}`
                        : conv.split('.')[0] + '.00'
                setConversion('$' + priceAdj.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '')
            }
        }
    }

    const onSubmit = async () => {
        const { artwork } = item
        try {
            if (validateFields() && item.price !== '' && artwork.contractAddress !== undefined && artwork.tokenId !== undefined) {
                const res = await setTokenResale(artwork.contractAddress, artwork.tokenId, item.price, item.isAuction, item.salesOpenTime, item.salesCloseTime)
                //const res = await getTokensOwned(artwork.address, artwork.tokenID)
                console.log("result is", res)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleFieldInput = (event) => {
        let value;
        console.log("here")
        const input = event.target.name;
        console.log("input is", input)
        if (event.target.value !== undefined) {
            value = event.target.value;
        } else {
            value = event.target.attributes.value.value;
        }
        if (input === 'startDate') {
            console.log(event.target.value)

            if (value !== '') {
                const selectedDayX = dayjs(event.target.value, "YYYY-MM-DD")
                if (startTime !== '') {
                    const selectedDayNew = selectedDayX.hour(parseInt(startTime.split(':')[0])).minute(parseInt(startTime.split(':')[1]))
                    setItem(prev => ({ ...prev, selectedDay: event.target.value, salesOpenTime: selectedDayNew.unix() }))
                    if (runTime) {
                        setItem(prev => ({ ...prev, salesCloseTime: selectedDayNew.add(runTime, 'day').unix() }))
                    }
                } else {
                    setItem(prev => ({ ...prev, selectedDay: event.target.value, salesOpenTime: selectedDayX.unix() }))
                    if (runTime) {
                        setItem(prev => ({ ...prev, salesCloseTime: selectedDayX.add(runTime, 'day').unix() }))
                    }
                }
            } else {
                setItem(prev => ({ ...prev, selectedDay: '', salesOpenTime: dayjs().unix(), salesCloseTime: dayjs().add(runTime, 'day').unix() }))
            }

        } else if (input === 'startTime') {
            const selectedDayCurrent = dayjs(selectedDay, "YYYY-MM-DD")
            const selectedDayNew = selectedDayCurrent.hour(parseInt(value.split(':')[0])).minute(parseInt(value.split(':')[1]))

            if (value !== '') {
                setItem(prev => ({ ...prev, startTime: value, salesOpenTime: selectedDayNew.unix() }))
            } else {
                setItem(prev => ({ ...prev, startTime: value }))
            }


        } else {
            value = value.replace(/\D/g, '');
            value = value !== null && value !== '' ? parseInt(value) : 0

            if (value !== 0) {
                setItem(prev => ({
                    ...prev,
                    runTime: value !== 0 ? value : '',
                    salesCloseTime: dayjs.unix(prev.salesOpenTime).add(value, 'day').unix()
                }))
            } else {
                setItem(prev => ({
                    ...prev,
                    runTime: value !== 0 ? value : '',
                    salesCloseTime: prev.salesOpenTime
                }))
            }
            //}
        }
    }




    return {
        modalWrapperRef,
        item,
        setItem,
        saletypeOptions,
        priceOnBlur,
        onPriceChange,
        onSubmit,
        conversion,
        handleFieldInput,
        runTime,
        selectedDay,
        startTime,
        validateFields,
        validContinue
    }
}