import React from 'react'

import { Wrapper } from './ContactUs.styles'

const ContactUs = () => {
  return (
    <Wrapper><h1>Contact Us Coming Soon</h1></Wrapper>
  )
}

export default ContactUs