import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Wrapper,
    MarketplaceHeaderBgd,
    MarketplaceHeader,
    Content,
    DropdownContainer,
    FilterTileContainer,
    RemoveFilter,
    FilterMenu,
    NavArrow
} from './Marketplace.styles'
import web3 from 'web3'
import GridItem from '../GridItem'
import GoldText from '../GoldText'
import CollectionFilterBar from '../CollectionFilterBar'
import ArtworkCard from '../ArtworkCard'
import Banner from '../Banner'
import Dropdown from '../Dropdown'
import Sidebar from './Sidebar'
import LoadingWheel from '../LoadingWheel'

import { getNonFungibleIndex, getNonFungibleBaseType } from '../../blockchain/blockchainUtils'
import { User, Artist } from '../../objectPrototypes/objectClasses'
import { useMarketHook } from '../../hooks/useMarketHook'
import { useCollectionFetch } from '../../hooks/useCollectionFetch'

import FormNavArrow from '../../images/backArrow.svg';

const FilterTile = ({ id, onClick }) => {
    return (
        <FilterTileContainer>{id}<RemoveFilter value={id} onClick={onClick}>&#10006;</RemoveFilter>

        </FilterTileContainer>
    )
}

const Marketplace = () => {
    const {
        state,
        marketItems,
        setState,
        setLoading,
        setError,
        loading,
        filterMethod,
        setFilterMethod,
        activeFilters,
        setActiveFilters,
        sidebarContent,
        banner,
        loadingSidebar,
        sortOptions,
        handleSortChange,
        sortMenuOpen,
        setSortMenuOpen,
        sortBy,
        page,
        totalResults,
        numToServe,
        handlePageNav

    } = useMarketHook();
    const navigate = useNavigate()
    // useCollectionFetch(setState, setLoading, setError, state.unFiltered)
    const { artworkList, collections, artworks } = state;

    const options = [
        {
            value: 'Collection',
            innerText: 'Collection'
        },
        {
            value: 'Artist',
            innerText: 'Artist'
        }
    ];
    console.log("totalResults", totalResults, numToServe);
    if (loading) {
        return <GridItem><LoadingWheel /></GridItem>
    }
    else {
        return (
            <GridItem display="grid" gridTempCols="1fr" margin="60px 0 0">
                <GridItem gridCol="1 / span 1" zIndex="3">
                    <Banner
                        gridTempCols="10% 1fr 300px 10%"
                        gridTempRows="20px 1fr minmax(20px,min-content) 20px"
                        imagePath={banner.image}
                        headingMinor={"Secondary Market For User to User Sales"}
                        artworkTitle={`${banner.name} #${banner.edition}`}
                        artistName={banner.artist_name}
                        bannerTitle="Uiolly Marketplace"
                        onClick={() => { navigate(`/marketplace/${banner.contractAddress}/${getNonFungibleBaseType(banner.tokenId)}/${getNonFungibleIndex(banner.tokenId)}`, { state: { artwork: banner } }) }}
                    />
                </GridItem>
                {/* style={{ display: 'flex', alignItems: 'flex-start' }} */}
                <FilterMenu>
                            <GridItem justifyContent="end" gridColGap="10px" alignItems="center" alignContent="center" display="grid" gridTempCols="repeat(3,min-content)">
                                {/* <NavArrow back isValid={page > 1} onClick={handlePageNav(-1)}><img src={FormNavArrow} alt="back" /></NavArrow>
                                <NavArrow isValid={page <= Math.ceil(totalResults / numToServe) - 1}
                                    onClick={handlePageNav()}><img src={FormNavArrow} alt="next" /></NavArrow>
                                <GridItem alignItems="start" width="75px">Page {page} of {Math.ceil(totalResults / numToServe)}</GridItem> */}
                                <GridItem whiteSpace="nowrap" alignItems="start">Page {page.current} of {Math.ceil(totalResults / numToServe)}</GridItem>
                                <NavArrow back isValid={page.current > 1} onClick={handlePageNav(-1)}><img src={FormNavArrow} alt="back" /></NavArrow>
                                <NavArrow isValid={page.current <= Math.ceil(totalResults / numToServe) - 1}
                                    onClick={handlePageNav()}><img src={FormNavArrow} alt="next" /></NavArrow>
                            </GridItem>
                            <GridItem whiteSpace="nowrap" cursor="pointer" justifyContent="center" onClick={() => { setSortMenuOpen(prev => !prev) }}>Sort By</GridItem>
                            <DropdownContainer>
                                <Dropdown
                                    name='sortby'
                                    width="200px"
                                    textAlign="right"
                                    headerWidth="100px"
                                    dropdownWidth="200px"
                                    marginBottom={'0'}
                                    listBackground={"rgba(255,255,255,0.75)"}
                                    color="#000000"
                                    border="1px solid var(--buttonGrey)"
                                    boxShadow="0 0 2px rgb(0,0,0,0.15)"
                                    colorOnSelect="var(--uiollyPurple)"
                                    dropdownListMarginTop="20px"
                                    justifyContent="flex-start"
                                    background="rgba(255,255,255,0.5)"
                                    options={sortOptions}
                                    valueX={[sortBy.value]}
                                    backgroundOnSelect={"var(--uiollyPurple)"}
                                    setMenuState={setSortMenuOpen}
                                    menuState={sortMenuOpen}
                                    handleStateChange={handleSortChange}
                                    index={0}
                                    id='filter'
                                    setValidContinue={true}
                                ></Dropdown>
                            </DropdownContainer>
                        </FilterMenu>
                <GridItem height="100%" display="flex" alignItems="flex-start" flexDirection="row" gridCol="1 / span 1">
                    <Sidebar loading={loadingSidebar} setActiveFilters={setActiveFilters} activeFilters={activeFilters} sidebarContent={sidebarContent} />

                    {/* <GridItem alignItems="start" overflow="auto" height="calc(100vh - var(--navBarHeight))" flexWrap="wrap" gridCol="2 / span 1" gridRow="2" display="flex" flexDirection="row"> */}
                    <GridItem display="flex" height="100%" >
                        
                            <GridItem 
                            // background="var(--buttonGrey)" 
                            flexDirection="row" flexWrap="wrap" boxShadow="inset 0 0px 5px 1px rgba(0,0,0,0.2)" height="100%">
                                {marketItems.map((artwork) =>
                                    <ArtworkCard
                                        artwork={artwork}
                                        key={artwork.secondaryMarketData.itemId}
                                        isSecondary={true}
                                        pricingData={artwork.secondaryMarketData}
                                        onClick={() => { navigate(`/marketplace/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`, { state: { artwork: artwork } }) }}
                                    />)}
                            </GridItem>
                    </GridItem>
                </GridItem>
                {/* </GridItem> */}
                {/* <MarketplaceHeader>
                    <DropdownContainer>
                        <Dropdown
                            // order={order}
                            // handleOrderChange={handleSortChange}
                            name='filter'
                            width="200px"
                            headerWidth="100px"
                            dropdownWidth="200px"
                            marginBottom={'0'}
                            listBackground="#000000"
                            border="1px solid var(--goldSolid)"
                            color="#ffffff"
                            colorOnSelect="var(--goldSolid)"
                            dropdownListMarginTop="20px"
                            justifyContent="flex-start"
                            background="#000000"
                            options={options}
                            handleStateChange={handleChange}
                            valueX={filterMethod}
                            index={0}
                            id='filter'
                            setValidContinue={true}
                        ></Dropdown>
                        {filterMethod !== 'none' && <FilterTile id={filterMethod} onClick={handleChange} />}
                    </DropdownContainer>
                    <MarketplaceHeaderBgd>
                        <GoldText fontWeight="400">Marketplace</GoldText>
                        <h2>User to user NFT trading</h2>
                    </MarketplaceHeaderBgd>
                </MarketplaceHeader>
                <CollectionFilterBar filterSelected={filter} onClick={handleCollectionFilter} values={collections} visibility={filterMethod === 'Collection' ? 'visible' : 'hidden'}></CollectionFilterBar>
                <Content>
                    {artworkList.map((artwork, index) => (
                        <ArtworkCard value={artwork} key={index} />
                    ))}
                </Content> */}

            </GridItem>
        )
    }
}

export default Marketplace
