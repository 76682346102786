import React, { useState, useEffect, useRef } from 'react'

import {
    Wrapper,
    PageWrapper,
    OuterWrapper,
    InnerWrapper,
    Content,
    QuestionWrapper,
    Title,
    Text,
    ImageWrapper,
    EmailBox,
    Small,
    Error,
    LoginSignUpSwitch,
    SwitchContainer,
    SocialModalLink,
    LabelStyled,
    SP1,
    SP2,
    ProfilePicture,
    EditProfilePictureContainer,
    EditContainer,
    Icon,
    FormWrapper,
    Row
} from './AccountSettings.styles'
import Modal from '../Modal'
import LoadingWheel from '../LoadingWheel'
import InputField from '../UploadForm/InputField'
import SocialMediaModal from './SocialMediaModal'
import TextArea from '../TextArea'
import Button from '../Button'
import GoldText from '../GoldText'

import { validateEmail, validateHandle } from '../../helpers'

import { useAuth } from '../../contexts/AuthContext';

import EditIcon from '../../images/editIcon.svg'

import API from '../../API'

import {
    ARTIST,
    BUYER,
    ACCOUNT_ACTIVITY,
    LOGOUT,
    LOGIN,
    SIGNUP,
    VALID_FILE_TYPES,
    PROFILE_TAB,
    SETTINGS_TAB
} from '../../config'

const Edit = () => (
    <EditContainer src={EditIcon} alt="edit" />
)

const ProfilePic = ({ img, handleFileSelection }) => {
    const inputFile = useRef(null);
    const onButtonClick = (event) => { inputFile.current.click(); }
    return (
        <EditProfilePictureContainer onClick={onButtonClick}>
            <ProfilePicture>
                <img src={img} alt="profile-pic" />
            </ProfilePicture>
            <Edit />
            <input type="file" ref={inputFile} onChange={handleFileSelection} />
        </EditProfilePictureContainer>
    )
}

const Tab = ({ currentUser, tab, state, validatePassword, validateState, handleChange, profilePic, handleFileSelection }) => {
    if (tab === PROFILE_TAB) {
        return (
            <>
                <QuestionWrapper marginTop="0">
                    <Row>
                        <ProfilePic img={profilePic} handleFileSelection={handleFileSelection} />
                    </Row>
                </QuestionWrapper>
                <QuestionWrapper marginTop="0">
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Username"
                        type="text"
                        onChange={handleChange('handle')}
                        name='handle'
                        placeholder='username'
                        marginBottom={'10px'}
                        value={state.handle}
                    />

                </QuestionWrapper>
                <QuestionWrapper >
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="display name"
                        type="text"
                        onChange={handleChange('display_name')}
                        name='display_name'
                        placeholder='Display Name'
                        marginBottom={'10px'}
                        value={state.display_name}
                    />
                </QuestionWrapper>
                <QuestionWrapper >
                    <TextArea
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="bio"
                        showCharCount
                        type="text"
                        onChange={handleChange('bio')}
                        name='bio'
                        placeholder='Enter bio'
                        marginBottom={'10px'}
                        value={state.bio}
                    />
                </QuestionWrapper>
            </>
        )
    }
    else {
        return (
            <>
                <QuestionWrapper textAlign="center" background="var(--lightestGrey)" >
                    <EmailBox><LabelStyled marginBottom={'10px'}>Email Associated with this account</LabelStyled>
                        {currentUser !== null && currentUser.email}
                        {/* <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Email Address"
                        type="email"
                        onChange={handleChange('email')}
                        name='email'
                        placeholder='Email Address'
                        marginBottom={'10px'}
                        value={state.email}
                        disabled
                    /> */}
                        <Small align="center">Your email is private information and will not be displayed on your profile.<br /></Small>
                    </EmailBox>
                </QuestionWrapper>
                <div style={{height: "1px",width: "100%",border:"1px solid #000000"}}></div>
                <Text>
                    Enter your current password to set a new one.
                </Text>
                <QuestionWrapper>
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="Current Password"
                        type="password"
                        onChange={handleChange('password')}
                        value={state.password}
                        name='password'
                        placeholder='Password'
                        marginBottom={'10px'}
                    />
                </QuestionWrapper>
                <QuestionWrapper>
                    <InputField
                        labelFontSize="10px"
                        labelTextTransform="uppercase"
                        label="New password"
                        type="password"
                        subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                        onChange={handleChange('newPassword')}
                        value={state.newPassword}
                        name='newPassword'
                        placeholder='Enter A New Password'
                        marginBottom={'10px'}
                        disabled={!validatePassword(state.password)}
                    />
                    <Small>Min 8 characters, at least one letter and at least one number.</Small>
                </QuestionWrapper>
            </>
        )
    }

}


const AccountSettings = ({ type }) => {

    const signupType = ARTIST;
    const [tab, setTab] = useState(PROFILE_TAB)
    const { signup, login, currentUser, reAuth, changePassword, retrieveUserDetails, sendResetPasswordEmail } = useAuth()
    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const initial = useRef(true)
    const [err, setErr] = useState(false);
    const [submitAttempt, setSubmitAttempt] = useState("");
    const [loading, setLoading] = useState(false);
    const [storageData, setStorageData] = useState(null)
    const [user, setUser] = useState({ id: '', email: '', password: '' })

    const initialState = {
        email: "",
        profile_pic: "",
        password: "",
        newPassword: "",
        display_name: "",
        handle: "",
        instagram: "",
        facebook: "",
        twitter: "",
        website: "",
        youtube: "",
        tiktok: ""

    }
    const validatePassword = (pw) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        return re.test(String(pw));
    }
    const [state, setState] = useState(initialState);
    const [profilePic, setProfilePic] = useState(state.profile_pic);
    const [update, setUpdate] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [submitting2, setSubmitting2] = useState(false)
    const [validContinue, setValidContinue] = useState(false);
    const [socialUpdated, setSocialUpdated] = useState('')
    const handleChange = (input) => (event) => { setState(prev => ({ ...prev, [input]: event.target.value })) }

    const handleSocial = async () => {
        const social = (({ instagram, facebook, twitter, website, youtube, tiktok }) => ({ instagram, facebook, twitter, website, youtube, tiktok }))(state)
        const keys_diff = Object.keys(social).filter(key => { return social[key] !== storageData[key] && key !== "password" && key !== "newPassword" })
        if (keys_diff.length > 0) {
            const newObj = Object.fromEntries(Object.entries(social).filter(item => keys_diff.includes(item[0])))
            newObj["email"] = currentUser.email;
            setSubmitAttempt('')
            //setLoading(true)
            setSubmitting(true)
            try {
                API.updateUser(newObj, currentUser.accessToken).then((res) => {
                    retrieveUserDetails(currentUser.email)
                    //setLoading(false)
                    setSubmitting(false)
                    // setSubmitAttempt('Success!')
                    console.log("data", res, res.data)
                    if (res.data.status.includes('social'))
                        setSocialUpdated(res.data.status.split(': ')[1])
                }).catch((err) => {
                    setSubmitting(false)
                    console.log(err)
                })
                //runBlobCleanup()
            } catch (err) {
                console.log(err)
                //setLoading(false)
                setSubmitting(false)
                setSubmitAttempt('Error. Please try again later.')
                setSocialUpdated('Error. Please try again later.')
            }
        }
        else {
            console.log("no diff")
        }

    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        const keys_diff = Object.keys(state).filter(key => { return state[key] !== storageData[key] && key !== "password" && key !== "newPassword" })
        console.log("keys_diff", keys_diff)
        if (keys_diff.length > 0) {
            const newObj = Object.fromEntries(Object.entries(state).filter(item => keys_diff.includes(item[0])))
            newObj["email"] = currentUser.email;
            setSubmitAttempt('')
            setSubmitting2(true)
            API.updateUser(newObj, currentUser.accessToken).then(() => {
                retrieveUserDetails(currentUser.email)
                setSubmitting2(false)
                setSubmitAttempt('Success!')
                runBlobCleanup()
            }).catch((err) => {
                setSubmitting2(false)
                setSubmitAttempt(err.response.data.message ? err.response.data.message : 'Error. Please try again later.')
                runBlobCleanup()
            })
        }
        else {
            console.log("no diff")
        }

    }

    const handleFileSelection = (event) => {
        event.preventDefault();
        console.log(event.target.files)
        const files = event.target.files;
        handleFiles(files)
    }
    const handleFiles = (files) => {
        console.log(files, state)
        for (let i = 0; i < files.length; i++) {
            if (VALID_FILE_TYPES.collectionCover.includes(files[0].type)) {
                setState(prev => ({ ...prev, profile_pic: files[0] }))
                // free up memory
                if (profilePic !== currentUser.userDetails.profile_pic || profilePic !== "") {
                    URL.revokeObjectURL(profilePic)
                }
                setProfilePic(URL.createObjectURL(files[0]))
            }
        }
    }
    const runBlobCleanup = () => {

        // if (profilePic !== currentUser.userDetails.profile_pic) {
        //     console.log("removing", profilePic)
        URL.revokeObjectURL(profilePic)
        // }
    }
    const onChangePassword = () => {
        if (validatePassword(state.newPassword)) {
            setShowPasswordReset(false)
            reAuth(state.password).then(() => {
                changePassword(state.newPassword)
                setSubmitAttempt('Success!')
            }).catch((error) => {
                console.log(error)
                const errCode = error.code.includes("wrong-password") ? "Incorrect password."
                    : error.code.includes("user-not-found") ? "Account not found."
                        : error.code.includes("too-many-requests") ? "Too many attempts. Try again later or reset your password."
                            : "Unknown";
                if (error.code.includes("wrong-password") || error.code.includes("too-many-requests")) {
                    setShowPasswordReset(true)
                    console.log("show password reset")
                }
                setSubmitAttempt(errCode)
            })
        }
    }



    const validateStep = () => {
        if (!validateEmail(state.email)) {
            setErr("Please enter a valid email address.")
            setValidContinue(false)
            return false
        }
        else if (!validatePassword(state.password)) {
            setErr("Please enter a valid password.")
            setValidContinue(false)
            return false
        }
        else if (!validatePassword(state.newPassword)) {
            setErr("Passwords don't match.")
            setValidContinue(false)
            return false
        }
        else if (state.instagram !== '' && !validateHandle(state.instagram)) {
            setErr("Invalid instagram handle")
            setValidContinue(false)
            return false
        }
        else {
            setValidContinue(true)
            return true
        }
    }

    useEffect(() => {
        if (currentUser.userDetails !== undefined) {
            setState(prev => ({ ...prev, email: currentUser.email }))
            setStorageData(prev => ({ ...prev, email: currentUser.email }))
            const { userDetails } = currentUser
            Object.keys(userDetails).forEach((detail) => {
                setState(prev => ({ ...prev, [detail]: userDetails[detail] }))
                setStorageData(prev => ({ ...prev, [detail]: userDetails[detail] }))
            })

            // setState(prev => ({
            //     ...prev,
            //     email: currentUser.email,
            //     profile_picture: process.env.REACT_APP_BASE_PATH_IMG + currentUser.userDetails.path,
            //     display_name: currentUser.userDetails.display_name,
            //     username: currentUser.userDetails.handle,
            //     bio: currentUser.userDetails.bio,
            //     instagram: currentUser.userDetails.instagram,
            //     website: currentUser.userDetails.website
            // }))
            // setStorageData({
            //     email: currentUser.email,
            //     profile_picture: process.env.REACT_APP_BASE_PATH_IMG + currentUser.userDetails.path,
            //     display_name: currentUser.userDetails.display_name,
            //     username: currentUser.userDetails.handle,
            //     bio: currentUser.userDetails.bio,
            //     instagram: currentUser.userDetails.instagram,
            //     website: currentUser.userDetails.website
            // })

            setProfilePic(currentUser.userDetails.profile_pic)
        }

        return runBlobCleanup
    }, [currentUser])
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        validateStep()

    }, [state])

    if (loading) {
        return <Wrapper><LoadingWheel></LoadingWheel></Wrapper>
    }
    //else if (tab === PROFILE_TAB) {
    else {
        return (
            <PageWrapper>
                <OuterWrapper>

                    <Wrapper>
                        <InnerWrapper>
                            <Content>

                                <FormWrapper>
                                    <h2>
                                        Account Settings
                                    </h2>
                                    <Row>
                                        <Title
                                            isActive={tab === PROFILE_TAB}
                                            onClick={() => { setTab(PROFILE_TAB); setSubmitAttempt(''); setShowPasswordReset(false) }}>
                                            Edit Profile
                                        </Title>
                                        <Title
                                            isActive={tab === SETTINGS_TAB}
                                            onClick={() => { setTab(SETTINGS_TAB); setSubmitAttempt(''); setShowPasswordReset(false) }}>
                                            Security
                                        </Title>
                                    </Row>
                                    <Tab
                                        currentUser={currentUser}
                                        profilePic={profilePic}
                                        handleFileSelection={handleFileSelection}
                                        state={state}
                                        tab={tab}
                                        handleChange={handleChange}
                                        validateStep={validateStep}
                                        validatePassword={validatePassword} />
                                    {tab === PROFILE_TAB &&
                                        <SocialModalLink

                                            onClick={() => setShowModal(true)}>
                                            Social<Icon src={EditIcon} alt="edit" /></SocialModalLink>}


                                    {/*
                                <QuestionWrapper >
                                    <InputField
                                        labelFontSize="10px"
                                        labelTextTransform="uppercase"
                                        label="Email Address"
                                        type="email"
                                        onChange={handleChange('email')}
                                        name='email'
                                        placeholder='Email Address'
                                        marginBottom={'20px'}
                                        value={state.email}
                                        disabled
                                    />
                                </QuestionWrapper>
                                <QuestionWrapper>
                                    <InputField
                                        labelFontSize="10px"
                                        labelTextTransform="uppercase"
                                        label="Current Password"
                                        type="password"
                                        onChange={handleChange('password')}
                                        value={state.password}
                                        name='password'
                                        placeholder='Password'
                                        marginBottom={'20px'}
                                    />

                                </QuestionWrapper>
                                <QuestionWrapper>
                                    <InputField
                                        labelFontSize="10px"
                                        labelTextTransform="uppercase"
                                        label="New password"
                                        type="password"
                                        subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                                        onChange={handleChange('newPassword')}
                                        value={state.newPassword}
                                        name='newPassword'
                                        placeholder='Enter A New Password'
                                        marginBottom={'20px'}
                                        disabled={!validatePassword(state.password)}
                                    />
                                    <Small>Min 8 characters, at least one letter and at least one number.</Small>
                                </QuestionWrapper> */}
                                    <QuestionWrapper>
                                        {
                                            !submitting2 && <Button
                                                onClick={tab === PROFILE_TAB ? handleSubmit : onChangePassword}
                                                disabled={loading || submitting2}
                                                cursor={validContinue ? 'pointer' : 'default'}
                                                width='100%'
                                                text={tab === PROFILE_TAB ? "Save" : "Change Password"} />
                                        }
                                        {
                                            submitting2 && <LoadingWheel
                                                width='100%'
                                                radius="20px"
                                                height='var(--buttonHeight)'
                                                position='relative'
                                                top='0'
                                                zIndex='3' />
                                        }
                                        {/* <Button
                                            // onClick={handleSubmit}
                                            onClick={tab === PROFILE_TAB ? handleSubmit : onChangePassword}
                                            disabled={loading || submitting2}
                                            cursor={validContinue ? 'pointer' : 'default'}
                                            width='100%'
                                            text={tab === PROFILE_TAB ? "Save" : "Change Password"} /> */}
                                    </QuestionWrapper>
                                    {err !== "" && err !== false && submitAttempt !== "" &&
                                        <QuestionWrapper marginTop="5px">
                                            <Error>{submitAttempt}</Error>
                                        </QuestionWrapper>}
                                    {showPasswordReset === true && <Error marginTop="5px" onClick={() => sendResetPasswordEmail(currentUser.email).then(() => { setSubmitAttempt('Email sent.'); setShowPasswordReset(false) })}>Reset Password</Error>}
                                </FormWrapper>
                            </Content>
                        </InnerWrapper>
                    </Wrapper>
                    {/* <SP2 /> */}
                </OuterWrapper>
                {showModal &&
                    <SocialMediaModal
                        submitting={submitting}
                        state={state}
                        socialUpdated={socialUpdated}
                        handleSocial={handleSocial}
                        setState={setState}
                        storageData={storageData}
                        setSocialUpdated={setSocialUpdated}
                        setShowModal={setShowModal}
                        showModal={showModal} />}
            </PageWrapper>
        )
    }

}

export default AccountSettings
