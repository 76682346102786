import styled from "styled-components";

export const Wrapper = styled.div`
max-width: 480px;
position: relative;
display: grid;
grid-row-gap: 5%;
grid-template-rows: 1fr;

`;

export const QuestionWrapper = styled.div`
margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
`;

export const Content = styled.div``;

export const RowWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 30px;
    width: 100%;
  
    justify-content: space-between;
`;