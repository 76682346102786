import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const NavLink = styled(Link)`
    all: unset;
`;

export const OuterWrapper = styled.div`
    padding: 15px;
    /* opacity: ${props => props.isSelected ? props.isSelected === "true" ? '1' : '0.5' : '1'}; */
`;

export const StatusWrapper = styled.div`
visibility: ${props => props.visibility ? props.visibility : 'hidden'};

transition: opacity 1s linear;
img{

  width: 10px;
  height: 10px;
  position: relative;
}

`;

export const Wrapper = styled.div`
  width: ${props => props.width ? props.width : '250px'};
  height: ${props => props.height ? props.height : '250px'};
  opacity: ${props => props.isSelected ? props.isSelected === "true" ? '1' : '0.5' :
      props.visibility ? 
      props.visibility === 'visible' ? '1' : '0' : '1'};
  transition: opacity .5s linear;
  overflow: hidden;
  background: #000;
  box-shadow: var(--goldShadowCentered);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
`;

export const Title = styled.div`
font-size: ${props => props.fontSize ? props.fontSize : '16px' };
`;

export const Editions = styled.div``;

export const ExhibitionSize = styled.div`
font-size: 14px;
`;

export const ArtistFrame = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0,0,0);
  overflow: hidden;
  position: absolute;
  background-image: url(${(props) => props.backgroundIMG});
  background-size: cover;
  display: flex;
  visibility: ${props => props.visibility ? props.visibility : 'visible'};
`;

export const ArtistImg = styled.img`
  object-fit: contain;
  position: relative;
  min-height: 100%;
  min-width: 100%;
`;

export const CollectionCoverWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
  width: 250px;
  display: flex;
  object-fit: contain;

  img {
    object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
  }
`;
