import styled from "styled-components";

export const Wrapper = styled.div`
/* width: 100%; */
/* height: calc(100vh - var(--navBarHeight)); */
background: var(--background);
padding: 5px 0;
display: flex;
height: min-content;
align-items: start;
position: relative;
padding: 30px;
display: grid;
grid-column-gap: 30px;
grid-template-rows: min-content min-content;
grid-row-gap: 15px;
grid-template-columns: min-content 350px;
`;
export const OuterWrapper = styled.div`
width: 100%;
background: rgba(0,0,0,0.75);
height: 100vh;
z-index: 5;
position: fixed;
top: 0;
right: 0;
display: flex;
justify-content: center;
align-items: center;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100 %;
justify-content: flex-end;
position: relative;
img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
`;

export const InnerWrapper = styled.div`
width: 100%;
height: min-content;
display: flex;
justify-content: center;
position: relative;
align-items: flex-start;
`;

export const Title = styled.div`
font-size: 12px;
`;

export const EdNumber = styled.div`
width: min-content;
font-size: 12px;
overflow: visible;
margin-left: 2px;
`;

export const Artist = styled.div`
font-size: 10px;
`;

export const ETHConversionWrapper = styled.div`
    background: #000000;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 20px;
    color: #ffffff;
    user-select: none;
`;

export const Label = styled.div`
display: flex;
width: min-content;
white-space: nowrap;
font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    color: ${props => props.disabled ? 'var(--grey)' : 'var(--fontColor)'};
`;