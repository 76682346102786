import styled from "styled-components";




export const Wrapper = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '100%'};
    overflow: hidden;
    display: flex;
    position: relative;

    a{
        max-width: 100%;
        max-height: 100%;
    }
`;

export const ImageWrapper = styled.div`
    width: var(--artworkCardImageSize);
    height: var(--artworkCardImageSize);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
`;

export const Image = styled.img`
    object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    border-radius: 5px;
`;

export const VideoWrapper = styled.div`
`;

export const Video = styled.video`
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 5px;

`;