
export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getFileType = (name = "") => {
    const fileExtension = name.split('.').pop()
    return ["image/jpeg", "image/jpg", "image/png", "audio/mpeg", "audio/wav", "video/mpeg", "video/mp4"].filter(type => type.includes(fileExtension.toLowerCase()))[0].includes("video") ? "video" : "image"
}

export const returnMonth = (date, abbreviated = false) => {

    const month = date.includes('/') ? date.split('/')[0] : date.split('-')[1];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month_index = month - 1;
    return abbreviated ? monthsAbbreviated[month_index] : months[month_index];
}

export const validateHandle = (handle) => {
    const re = /^(?!.*\.\.)(?!.*\.$)[^\W](?![0-9]*$)[a-zA-Z0-9][\w.]{0,29}[\w](?!.*?\.{2})$/;
    const handleStripped = handle.split('@').length > 1 ? handle.split('@')[1] : handle.split('@')[0];
    return re.test(String(handleStripped));
}

// "Mar 1, 2022 17:20:20"
export const countdown = (date) => {
    const countdownDate = new Date(date).getTime();
    const now = new Date().getTime();
    const runTimeDays = 1; // 3 days
    const runTimems = runTimeDays * 24 * 60 * 60 * 1000; //ms
    const delta = countdownDate - now;
    const finishDate = new Date(countdownDate + runTimems).getTime() - now;
    console.log(finishDate, delta, countdownDate);
    console.log(new Date(countdownDate + runTimems))
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);

    const str = `${days}D ${hours}H ${minutes}M ${seconds}S`.replace(/0D /, "").replace(/0H /, "")

    const result = delta < 0 ? "Expired" : str;

    // Object.entries(result).filter( el => {return el[1] != 0}).forEach( el =>{

    // })

    return [result, delta, finishDate]

}

export const countdown2 = (release_date_s, end_date_s) => {
    const release_date = parseInt(release_date_s) * 1000 //s to ms
    const end_date = parseInt(end_date_s) * 1000 //s to ms

    const now = new Date().getTime();
    if (now >= release_date && now <= end_date) {
        const delta = end_date - now;
        const days = Math.floor(delta / (1000 * 60 * 60 * 24))
        const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((delta % (1000 * 60)) / 1000);

        const str = ` ${days}D ${hours}H ${minutes}M ${seconds}S`.replace(/ 0D /, "").replace(/ 0H /, "")

        const result = delta < 0 ? "Collection Closed" : 'Collection closing in ' + str;
        return result
    }
    else if (now < release_date) {
        const delta = release_date - now;
        const days = Math.floor(delta / (1000 * 60 * 60 * 24))
        const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((delta % (1000 * 60)) / 1000);

        const str = ` ${days}D ${hours}H ${minutes}M ${seconds}S`.replace(/ 0D /, "").replace(/ 0H /, "")

        const result = delta < 0 ? "Collection Closed" : 'Collection opening in ' + str;
        return result
    }
    else {

        const result = "Collection Closed";
        return result
    }



}

export const countdownOpenCollection = (date) => {
    const countdownDate = new Date(date).getTime();
    const runTimeDays = 1; // 3 days
    const runTimems = runTimeDays * 24 * 60 * 60 * 1000; //ms
    const finishDate = new Date(countdownDate + runTimems).getTime()

    const delta = finishDate - countdownDate;

    // Object.entries(result).filter( el => {return el[1] != 0}).forEach( el =>{

    // })

    return delta

}

export const varNameToString = (varObj) => Object.keys(varObj)[0]


export const unCamelCase = (str) => {
    const re = /[A-Z]/g;
    const indexes = [];
    let match;
    while ((match = re.exec(str)) != null) {
        indexes.push(match.index)
    }
    let fixedString = "";
    if (indexes.length > 1) {
        indexes.forEach((splitIndex, idx) => {
            if (indexes[idx + 1] !== undefined) {
                fixedString += str.slice(splitIndex, indexes[idx + 1]) + ' ';
            }
            else {
                fixedString += str.slice(splitIndex)
            }

        })
    }
    else {
        fixedString = str;
    }


    return fixedString;

}

export const removeLeadingSlash = (str) => {
    return str.replace(/^\/+/g, '')
    //return str[0] === '/' ? str.slice(1) : str;
}

export const removeTrailingZeroes = (number) => {
    return number.replace(/^0+(\d)|(\d)0+$/gm, '$1$2')
    
}