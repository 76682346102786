import React, { useState, useEffect, useRef } from 'react'

import { Wrapper, InnerWrapper, Content, QuestionWrapper, Title, Text, ImageWrapper, Small, Error, LoginSignUpSwitch, SwitchContainer } from './SignUp.styles'
import LoadingWheel from '../LoadingWheel'
import InputField from '../UploadForm/InputField'
import Button from '../Button'
import UiollyLogo from '../../images/uiollyLogo@4x.png'
import { validateEmail, validateHandle } from '../../helpers'
import DropImage from '../../images/catchTheNextDrop.png'

import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import { useWeb3 } from '../../contexts/Web3Context';
import API from '../../API'
import web3 from '../../blockchain/web3'
import { getRandomArtwork } from '../../blockchain/functionsUiollyNew'
import {
    ARTIST,
    BUYER,
    ACCOUNT_ACTIVITY,
    LOGOUT,
    LOGIN,
    SIGNUP
} from '../../config'


const SignUp = ({ type }) => {
    const signupType = ARTIST;
    const { signup, login, currentUser, sendResetPasswordEmail } = useAuth();
    const { connectWallet, userAddress } = useWeb3();
    const initial = useRef(true)
    const [err, setErr] = useState(false);
    const [submitAttempt, setSubmitAttempt] = useState("");
    const [user, setUser] = useState({ id: '', email: '', password: '' })
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(location.state !== null && location.state.isRerouted !== undefined ? true : false);
    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (validateStep()) {
                navigate('/get-started', { state: { name: state.name, email: state.email } })
            }
        }
    }
    const initialState = {
        email: "",
        password: "",
        passwordV: "",
        firstName: "",
        lastName: "",
        username: "",
        step: 1
    }
    const validatePassword = (pw) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        return re.test(String(pw));
    }
    const [state, setState] = useState(initialState);
    const [backgroundArtwork, setBackgroundArtwork] = useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [validContinue, setValidContinue] = useState(false);
    const handleChange = (input) => (event) => { setState(prev => ({ ...prev, [input]: event.target.value })) }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateStep()) {
            setShowPasswordReset(false)
            setErr("")
            if (type === SIGNUP) {
                setSubmitting(true);
                addUser(state);
            }
            else if (type === LOGIN) {
                setSubmitting(true);
                connectWallet().then(() => {
                    login(state.email, state.password).then((userCred) => {
                        const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/';
                        navigate(navTo)
                        setSubmitting(false);
                        //setCurrentUser(userCred.user)
                    }).catch((error) => {
                        console.log("error response: ", error.message, error.errors)
                        const errCode = error.code.includes("wrong-password") ? "Incorrect password."
                            : error.code.includes("user-not-found") ? "Account not found."
                                : error.code.includes("too-many-requests") ? "Too many attempts. Try again later or reset your password."
                                    : "Unknown";
                        if (error.code.includes("wrong-password") || error.code.includes("too-many-requests")) {
                            setShowPasswordReset(true)
                            console.log("show password reset")
                        }
                        setErr(errCode)
                        setSubmitAttempt(errCode)
                        setSubmitting(false);
                        setLoading(false)
                    })
                }).catch((e) => {
                    setErr(e.message);
                    setSubmitAttempt(e.message);
                    setSubmitting(false)
                    setLoading(false)
                })
            }
        }
        else {
            setSubmitAttempt(err)
        }
    }
    useEffect(() => {
        if (currentUser !== null) {
            const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/';
            navigate(navTo)
        }
        const getBgd = async () => {
            const art = await API.getRandomArtwork()
            if (art.path)
                setBackgroundArtwork({ title: art.title, path: art.path, artistName: art.artist_name, artistHandle: art.artist_handle, contractAddress: art.contract_address, baseType: art.base_type })
        }
        getBgd()
    }, [currentUser]) //....
console.log("artwork", backgroundArtwork)
    const addUser = async (user) => {
        connectWallet().then((address) => {
            console.log("res", address)
            API.addUser({ ...user, publicAddress: address }).then(() => {
                signup(state.email, state.password).then((userCred) => {
                    setSubmitting(false)
                    const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/';
                    navigate(navTo);
                }).catch((error) => {
                    const errCode = error.code.includes("email-already-in-use") ? "Email address already in use" : "Unknown";
                    setErr(errCode)
                    setSubmitAttempt(errCode)
                    setSubmitting(false)
                })
            }).catch((e) => {
                setErr(e.response.data.message);
                setSubmitAttempt(e.response.data.message);
                setSubmitting(false)
                setLoading(false)
            })
            //const navTo = location.state !== undefined && location.state !== null && location.state.from !== undefined ? location.state.from : '/';
            //navigate(navTo);
        }).catch(e => {
            console.log("ERROR", e, e.message)
            //if (e.response !== undefined) {
            setErr(e.message);
            setSubmitAttempt(e.message);
            setLoading(false)
            //}
            setSubmitting(false)
        })
    }

    const validateStep = () => {
        if (!validateEmail(state.email)) {
            setErr("Please enter a valid email address.")
            setValidContinue(false)
            return false
        }
        else if (!validatePassword(state.password)) {
            setErr("Please enter a valid password.")
            setValidContinue(false)
            return false
        }
        else if (type === SIGNUP && state.password !== state.passwordV) {
            setErr("Passwords don't match.")
            setValidContinue(false)
            return false
        }
        else if (type === SIGNUP && state.firstName === "") {
            setErr("Please enter a first name.")
            setValidContinue(false)
            return false
        }
        else if (type === SIGNUP && state.lastName === "") {
            setErr("Please enter a last name.")
            setValidContinue(false)
            return false
        }
        else if (type === SIGNUP && state.username === "" || state.username === undefined) {
            setErr("Please enter a username.")
            setValidContinue(false)
            return false
        }
        else {
            setValidContinue(true)
            return true
        }
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        validateStep()
    }, [state])
    useEffect(() => {
        setSubmitAttempt("")
    }, [type])
    if (loading) {
        return <Wrapper><LoadingWheel /></Wrapper>
    }
    if (type === SIGNUP) {
        return (
            <Wrapper bgdArt={backgroundArtwork !== null ? backgroundArtwork.path : undefined}>
                <InnerWrapper>
                    <Content ofType="SIGNUP">
                        <Title>
                            <img style={{ height: '55px' }} src={UiollyLogo} />
                        </Title>
                        <SwitchContainer>
                            <LoginSignUpSwitch
                                isCurrent={type === SIGNUP}
                                to={"/signup"}>Sign Up</LoginSignUpSwitch>
                            <LoginSignUpSwitch
                                isCurrent={type === LOGIN}
                                to={"/login"}>Log In</LoginSignUpSwitch>
                        </SwitchContainer>
                        <QuestionWrapper cols="1fr 1fr" gridTemplateAreas="'input input'">
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="first name"
                                type="text"
                                onChange={handleChange('firstName')}
                                name='firstName'
                                placeholder='Enter First Name'
                                marginBottom={'20px'}
                                value={state.firstName}

                            />
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="last Name"
                                type="text"
                                onChange={handleChange('lastName')}
                                name='lastName'
                                placeholder='Enter Last Name'
                                marginBottom={'20px'}
                                value={state.lastName}

                            />
                        </QuestionWrapper>
                        <QuestionWrapper >
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="Email Address"
                                type="email"
                                onChange={handleChange('email')}
                                name='email'
                                placeholder='Email Address'
                                marginBottom={'20px'}
                                value={state.email}

                            />
                        </QuestionWrapper>
                        <QuestionWrapper cols="1fr 1fr" gridTemplateAreas="'input input''small small'">

                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                                label="Set a password"
                                type="password"
                                onChange={handleChange('password')}
                                value={state.password}
                                name='password'
                                placeholder='Password'
                                marginBottom={'20px'}
                            />
                            <Small>Min 8 characters, at least one letter and at least one number.</Small>

                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="Reenter password"
                                type="password"
                                onChange={handleChange('passwordV')}
                                value={state.passwordV}
                                name='passwordV'
                                placeholder='Confirm Password'
                                marginBottom={'20px'}
                            />
                        </QuestionWrapper>
                        <QuestionWrapper >
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="Username"
                                type="text"
                                onChange={handleChange('username')}
                                name='username'
                                placeholder='Enter Username'
                                marginBottom={'20px'}
                                value={state.username}

                            />
                        </QuestionWrapper>
                        <QuestionWrapper rows="1fr minmax(20px,min-content)">

                            {
                                !submitting && <Button
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                    width='100%'
                                    text="SignUp" />
                            }
                            {
                                submitting && <LoadingWheel
                                    width='100%'
                                    outerBorder="1px solid #000000"
                                    background='var(--alphaPurple)'
                                    radius="20px"
                                    height='var(--buttonHeight)'
                                    position='relative'
                                    top='0'
                                    zIndex='3' />
                            }


                            {err !== "" && err !== false && submitAttempt !== "" &&
                                <QuestionWrapper marginTop="5px">
                                    <Error>{submitAttempt}</Error>
                                </QuestionWrapper>
                            }
                        </QuestionWrapper>

                    </Content>

                </InnerWrapper>
            </Wrapper>
        )
    }
    else if (type === LOGIN) {
        return (
            <Wrapper bgdArt={backgroundArtwork !== null ? backgroundArtwork.path : undefined}>
                <InnerWrapper>
                    <Content ofType="LOGIN">
                        <Title>
                            <img style={{ height: '55px' }} src={UiollyLogo} />
                        </Title>

                        <SwitchContainer>
                            <LoginSignUpSwitch
                                isCurrent={type === SIGNUP}
                                to={"/signup"}>Sign Up</LoginSignUpSwitch>
                            <LoginSignUpSwitch
                                isCurrent={type === LOGIN}
                                to={"/login"}>Log In</LoginSignUpSwitch>
                        </SwitchContainer>
                        <h3 style={{ textAlign: 'center', margin: '0' }}>Welcome back.</h3>
                        <QuestionWrapper >
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                label="Email Address"
                                type="email"
                                onChange={handleChange('email')}
                                name='email'
                                placeholder='Email Address'
                                marginBottom={'20px'}
                                value={state.email}

                            />
                        </QuestionWrapper>
                        <QuestionWrapper>
                            <InputField
                                overlapLabel
                                labelFontSize="10px"
                                labelTextTransform="uppercase"
                                subText={<>Min 8 characters<br></br>At least one letter<br />at least one number</>}
                                label="Enter password"
                                type="password"
                                onChange={handleChange('password')}
                                value={state.password}
                                name='password'
                                placeholder='Password'
                                marginBottom={'20px'}
                            />

                        </QuestionWrapper>
                        <QuestionWrapper rows="1fr minmax(20px,min-content)">
                            {
                                !submitting && <Button
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    cursor={validContinue ? 'pointer' : 'default'}
                                    width='100%'
                                    text="Login" />
                            }
                            {
                                submitting && <LoadingWheel
                                    width='100%'
                                    background='var(--alphaPurple)'
                                    radius="20px"
                                    height='var(--buttonHeight)'
                                    position='relative'
                                    top='0'
                                    zIndex='3' />
                            }

                            {(err !== "" && err !== false && submitAttempt !== "") &&
                                <QuestionWrapper marginTop="5px" flexDirection="row" cols="1fr min-content" gridTemplateAreas="'one two'">
                                    <Error>{submitAttempt}</Error>
                                    {showPasswordReset === true &&
                                        <Error style={{ textAlign: "right", whiteSpace: "nowrap" }} marginTop="5px" onClick={() => sendResetPasswordEmail(state.email)}>Reset Password</Error>}
                                </QuestionWrapper>}
                        </QuestionWrapper>


                    </Content>
                </InnerWrapper>
            </Wrapper>
        )
    }
}

export default SignUp
