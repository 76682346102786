import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Button from '../../Button'
import GridItem from '../../GridItem'

import dayjs from 'dayjs'
import { useAuth } from '../../../contexts/AuthContext'
import { useWeb3 } from '../../../contexts/Web3Context'

const ProductPageButtons = ({ pricingData, getData, setShowOfferModal, setShowModal, isSecondary, isPending, isLive, artwork }) => {
    const [button, setButton] = useState({ text: '', onClick: undefined })
    const [showOfferButton, setShowOfferButton] = useState(false)
    const [isDisabled, setisDisabled] = useState(true)
    const navigate = useNavigate()
    const { blockchainAddress, baseType, tokenEdition } = useParams();
    const location = useLocation()
    const { currentUser } = useAuth()

    const {
        userAddress,
        txProcessing,
        marketplaceBid,
        marketplaceBuy,
        handleBuy
    } = useWeb3()

    const isUnsold = pricingData.owner !== undefined && /^0x0+$/.test(pricingData.owner) //0x0 address is initially set as owner for items for sale
    const userIsSeller = pricingData.seller !== undefined && pricingData.seller.toLowerCase() === userAddress.toLowerCase() //0x0 address is initially set as owner for items for sale

    const navigateToGlobal = () => {
        navigate(`/collections/${blockchainAddress}/${baseType}`)
    }

    const showAuctionModal = () => { setShowModal(true) }
    const showOfferModal = () => { setShowOfferModal(true) }
    console.log(isSecondary, isUnsold, isSecondary, pricingData.constructor.name, dayjs().unix() > Number(pricingData.salesCloseTime) && pricingData.constructor.name === 'PrimaryMarketData' && !isSecondary)
    const checkIfDisabled = () => {
        // if (tokenEdition !== undefined) {
        //     if ((pricingData.seller !== undefined && userIsSeller)
        //         // || (!isUnsold && isSecondary && pricingData.constructor.name === 'SecondaryMarketData')
        //         || (pricingData.isAuction && dayjs().unix() > Number(pricingData.salesCloseTime) && pricingData.constructor.name === 'PrimaryMarketData' && !isSecondary)
        //         || (dayjs().unix() > Number(pricingData.salesCloseTime) && pricingData.constructor.name === 'PrimaryMarketData' && !isSecondary)) {
        //         return true
        //     }
        //     return false
        // }
        // else if (txProcessing || isPending || currentUser === null || userAddress === '')
        //     return true
        // else if (!pricingData.isAuction && artwork.currentSupply === artwork.supply)
        //     return true
        // else return false
        if (isSecondary && isUnsold) {
            if (currentUser === null || userAddress === '')
                return true
            else if (!pricingData.isAuction && !userIsSeller) {
                return false
            }
            else {
                if (dayjs().unix() > Number(pricingData.salesCloseTime))
                    return true
                else if (dayjs().unix() < Number(pricingData.salesOpenTime))
                    return true
                else
                    return false
            }
        }
        else if (tokenEdition !== undefined && !isLive)
            return false
        else if (isPending)
            return true
        else if (isLive) {
            if (currentUser === null || userAddress === '')
                return true
            else if (pricingData.isAuction)
                return false
            else if (tokenEdition !== undefined)
                return false
            else if (artwork.currentSupply < artwork.supply)
                return false
            else return true
        }
        else return true
    }

    const buyItem = async () => {
        try {
            const res = await handleBuy(blockchainAddress, baseType, pricingData.price)
            getData(baseType)
        } catch (error) {
            console.log(error)
        }
    }

    const buyMarketplaceItem = async () => {
        const res = await marketplaceBuy(pricingData.itemId, pricingData.price)
    }

    const getButton = () => {
        if (isSecondary && isUnsold) {
            if (currentUser === null || userAddress === '')
                return { text: 'Log In to Participate', onClick: undefined }
            else if (!pricingData.isAuction) {
                setShowOfferButton(true)
                return { text: 'Buy', onClick: buyMarketplaceItem }
            }
            else {
                setShowOfferButton(false)
                if (dayjs().unix() > Number(pricingData.salesCloseTime))
                    return { text: 'Auction Closed', onClick: undefined }
                else if (dayjs().unix() < Number(pricingData.salesOpenTime))
                    return { text: `Auction Opens ${dayjs.unix(pricingData.salesOpenTime).format('M/D/YY hh:mm A')}`, onClick: undefined }
                else
                    return { text: 'Place Bid', onClick: showOfferModal }
            }
        }
        else if (tokenEdition !== undefined && !isLive)
            return { text: 'View Global', onClick: navigateToGlobal }
        else if (isPending)
            return { text: 'Dropping Soon', onClick: undefined }
        else if (isLive) {
            if (currentUser === null || userAddress === '')
                return { text: 'Log In to Participate', onClick: undefined }
            else if (pricingData.isAuction)
                return { text: 'Place Bid', onClick: showAuctionModal }
            else if (tokenEdition !== undefined)
                return { text: 'View Global', onClick: navigateToGlobal }
            else if (artwork.currentSupply < artwork.supply)
                return { text: 'Buy Now', onClick: buyItem }
            else return { text: 'Sold Out', onClick: undefined }
        }
        else return { text: 'Collection Closed', onClick: undefined }
    }

    useEffect(() => {
        setButton(getButton())
        setisDisabled(checkIfDisabled())
    }, [artwork, location])

    const { text, onClick } = button

    return (
        <GridItem
            display="grid"
            gridAutoCols="1fr"
            gridAutoFlow="column"
            gridTempRows="min-content"
            gridColGap="10px"
            gridCol="1/ span 3"
            gridRow="7">
            <Button
                onClick={onClick}
                disabled={isDisabled}
                width="100%"
                text={text} />

            {showOfferButton && < Button
                onClick={showOfferModal}
                disabled={isDisabled}
                width="100%"
                text="Make Offer" />}
            {(isLive && tokenEdition !== undefined && location.pathname.includes('marketplace')) && < Button
                onClick={navigateToGlobal}
                disabled={false}
                width="100%"
                text="View Live Collection" />}
        </GridItem>
    )
}

export default ProductPageButtons