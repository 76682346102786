import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import {
    Wrapper,
    // Banner,
    TextOverlay,
    BannerImgBgdContainer,
    ReleaseDateWrapper,
    PageNav,
    BottomPageNav,
    Section,
    DateWrapper,
    BannerAttribute,
    BannerImgContainer,
    BannerTitle,
    BannerImg
} from './Drops.styles'
import API from '../../API'
import { getDeployedCollections } from '../../blockchain/functionsUiollyNew'
import CollectionCard from '../CollectionCard'
import LoadingWheel from '../LoadingWheel'
import Banner from '../Banner'
import Footer from '../Footer'

const Drops = () => {
    const [drops, setDrops] = useState({ live: [], notLive: [] });
    const [loading, setLoading] = useState(false)
    const [featured, setFeatured] = useState(null)
    const [dates, setDates] = useState(null)
    const dropsPerPage = 3
    const numLive = useRef(0)
    const initialOffset = useRef(5)
    const initialRender = useRef(true)
    const [page, setPage] = useState(1)
    const [deployedColl, setDeployedColl] = useState([]);
    useEffect(() => {
        setLoading(true)
        const init = async () => {
            try {
                const live_collections = await API.fetchLiveDrops()
                initialOffset.current = live_collections.length
                numLive.current = live_collections.length
                setDrops(prev => ({ ...prev, live: [...live_collections] }))

                if (dropsPerPage - numLive.current > 0) {

                    const d = await API.fetchNotLiveDrops(dropsPerPage - numLive.current)
                    setDrops(prev => ({ ...prev, notLive: [...d] }))

                    if (d.length > 0 && live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length === 0) {
                        if (d.length > 0) {
                            const rand_indx = Math.floor(Math.random() * d.length)
                            setFeatured(d[rand_indx])
                            setDates({ start: dayjs.unix(d[0].release_date), end: dayjs.unix(d[d.length - 1].release_date) })
                        }

                    } else {
                        if (live_collections.length > 0) {

                            const lcLength = live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length
                            const rand_indx = Math.floor(Math.random() * (dropsPerPage > lcLength ? lcLength : dropsPerPage))
                            setFeatured(live_collections[rand_indx])
                            setDates({
                                start: dayjs.unix(live_collections[(dropsPerPage > lcLength ? lcLength : dropsPerPage) - 1].end_date),
                                end: dayjs.unix(d.length > 0 ? d[d.length - 1].release_date : live_collections[0].end_date)
                            })
                        }
                    }
                } else {
                    if (live_collections.length > 0 && live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length > 0) {
                        const lcLength = live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length
                        const rand_indx = Math.floor(Math.random() * (dropsPerPage > lcLength ? lcLength : dropsPerPage))
                        setFeatured(live_collections[rand_indx])
                        setDates({
                            start: dayjs.unix(live_collections[dropsPerPage - 1].end_date),
                            end: dayjs.unix(live_collections[0].end_date)
                        })
                    }
                    
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        // API.fetchLiveDrops().then(live_collections => {

        //     initialOffset.current = live_collections.length
        //     numLive.current = live_collections.length
        //     setDrops(prev => ({ ...prev, live: [...live_collections] }))
        //     if (dropsPerPage - numLive.current > 0)
        //         API.fetchNotLiveDrops(dropsPerPage - numLive.current).then((d) => {
        //             //const sorted = d.sort((a, b) => (a.release_date < b.release_date) ? 1 : ((b.release_date < a.release_date) ? -1 : 0))
        //             setDrops(prev => ({ ...prev, notLive: [...d] }))
        //             if (d.length > 0 && live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length === 0) {
        //                 if (d.length > 0) {
        //                     const rand_indx = Math.floor(Math.random() * d.length)
        //                     setFeatured(d[rand_indx])
        //                     setDates({ start: dayjs.unix(d[0].release_date), end: dayjs.unix(d[d.length - 1].release_date) })
        //                 }

        //             } else {
        //                 if (live_collections.length > 0) {
        //                     const lcLength = live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length
        //                     const rand_indx = Math.floor(Math.random() * (dropsPerPage > lcLength ? lcLength : dropsPerPage))
        //                     console.log("lvie collections", live_collections)
        //                     setFeatured(live_collections[rand_indx])
        //                     setDates({
        //                         start: dayjs.unix(live_collections[(dropsPerPage > lcLength ? lcLength : dropsPerPage) - 1].end_date),
        //                         end: dayjs.unix(d.length > 0 ? d[d.length - 1].release_date : live_collections[0].end_date)
        //                     })
        //                 }
        //             }
        //             setLoading(false)
        //         })
        //     else {
        //         if (live_collections.length > 0 && live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length > 0) {
        //             const lcLength = live_collections.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length
        //             const rand_indx = Math.floor(Math.random() * (dropsPerPage > lcLength ? lcLength : dropsPerPage))
        //             setFeatured(live_collections[rand_indx])
        //             setDates({
        //                 start: dayjs.unix(live_collections[dropsPerPage - 1].end_date),
        //                 end: dayjs.unix(live_collections[0].end_date)
        //             })
        //         }
        //         setLoading(false)
        //     }
        // }).catch((e) => {
        //     console.log(e)
        //     setLoading(false)
        // })
        init()
        getDeployedCollections().then(res => { if (res) setDeployedColl(res) }).catch((e) => { console.log("error", e) })
    }, [])

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }
        console.log("page is", page, numLive.current)
        setLoading(true)
        // API.fetchNotLiveDrops(dropsPerPage - initialOffset.current, initialOffset.current, page).then((d) => {
        if (page - (numLive.current - numLive.current % dropsPerPage) / dropsPerPage > 0) {
            const relOffset = page - (numLive.current - numLive.current % dropsPerPage) / dropsPerPage
            const initialOffset = numLive.current % dropsPerPage ? dropsPerPage - numLive.current % dropsPerPage : 0
            API.fetchNotLiveDrops(relOffset === 1 && initialOffset !== 0 ? initialOffset : dropsPerPage, initialOffset,
                // page - numLive.current % dropsPerPage + 1
                relOffset
            ).then((d) => {
                setDrops(prev => ({ ...prev, notLive: [...d] }))
                if (d.length > 0) {
                    const rand_indx = Math.floor(Math.random() * d.length)
                    const rangedLive = drops.live.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage)
                    setFeatured(d[rand_indx])
                    setDates({
                        start: rangedLive.length > 0 ?
                            dayjs.unix(rangedLive[rangedLive.length - 1].end_date) : dayjs.unix(d[0].release_date),
                        end:
                            dayjs.unix(d[d.length - 1].release_date)
                    })
                }
                setLoading(false)
            }).catch(err => { console.log(err); setLoading(false) })
        }
        else {
            if (drops.live.length > 0 && drops.live.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length > 0) {
                const rangedLive = drops.live.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage);
                //setFeatured(rangedLive[rand_indx])
                //setDates({ start: dayjs.unix(rangedLive[0].release_date).format('dddd, MMMM D, YYYY'), end: dayjs.unix(rangedLive[dropsPerPage - 1].release_date).format('dddd, MMMM D') })
                const lcLength = rangedLive.length
                const rand_indx = Math.floor(Math.random() * (dropsPerPage > lcLength ? lcLength : dropsPerPage))
                setFeatured(rangedLive[rand_indx])
                setDates({
                    start: dayjs.unix(rangedLive[dropsPerPage - 1].end_date),
                    end: dayjs.unix(rangedLive[0].end_date)
                })
            }
            setDrops(prev => ({ ...prev, notLive: [] }))
            setLoading(false)
        }


    }, [page])
    console.log()
    // useEffect(() => {
    //     setLoading(true)
    //     API.fetchLiveDrops().then(live_collections => {
    //         initialOffset.current = live_collections.length
    //         setDrops(prev => ({ ...prev, live: [...live_collections] }))
    //         API.fetchNotLiveDrops(5 - initialOffset.current).then((d) => {
    //             //const sorted = d.sort((a, b) => (a.release_date < b.release_date) ? 1 : ((b.release_date < a.release_date) ? -1 : 0))
    //             setDrops(prev => ({ ...prev, notLive: [...d] }))
    //             setLoading(false)
    //         })
    //     }).catch((e) => {
    //         console.log(e)
    //         setLoading(false)
    //     })

    //     getDeployedCollections().then(res => { if (res) setDeployedColl(res) }).catch((e) => { console.log("error", e) })
    // }, [])
    // useEffect(() => {
    //     if (initialRender.current) {
    //         initialRender.current = false
    //         return
    //     }
    //     setLoading(true)
    //     API.fetchNotLiveDrops(5 - initialOffset.current, initialOffset.current, page).then((d) => {
    //         //const sorted = d.sort((a, b) => (a.release_date < b.release_date) ? 1 : ((b.release_date < a.release_date) ? -1 : 0))
    //         setDrops(prev => ({ ...prev, notLive: [...d] }))
    //         setLoading(false)
    //     }).catch(err => { console.log(err); setLoading(false) })

    // }, [page])

    if (loading) return <LoadingWheel></LoadingWheel>
    else return (
        <Wrapper>
            {featured !== null && < Banner
                margin="0 0 20px"
                imagePath={featured.cover_path}
                headingMinor={dates.start.format('dddd, MMMM D') === dates.end.format('dddd, MMMM D') ?
                    dates.end.format('dddd, MMMM D, YYYY')
                    : `${dates.end.format('dddd, MMMM D')} - ${dates.start.format('dddd, MMMM D, YYYY')}`}
                artworkTitle={featured.collection_title}
                artistName={featured.artist_name}
                bannerTitle={'Drop Schedule'}
            />}
            {drops.live.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).length > 0 && <h2>LIVE!</h2>}
            {drops.live.slice(dropsPerPage * (page - 1), dropsPerPage * (page - 1) + dropsPerPage).map(drop => (
                <Section key={drop.id}>
                    <ReleaseDateWrapper >Closing {dayjs.unix(drop.end_date).format('dddd, MMMM DD, hh:mm A')}</ReleaseDateWrapper>
                    <CollectionCard collection={drop} />
                </Section>
            ))
            }
            {drops.notLive.length > 0 && <h2>Upcoming & Previous Drops</h2>}
            {drops.notLive.map(drop => (
                <Section key={drop.id}>
                    <ReleaseDateWrapper >{dayjs.unix(drop.release_date).format('dddd, MMMM DD, hh:mm A')}</ReleaseDateWrapper>
                    <CollectionCard collection={drop} />
                </Section>
            ))
            }
            <BottomPageNav>
                <PageNav valid={page > 1} onClick={() => {
                    if (page > 1)
                        setPage(prev => prev - 1)
                }}>Prev</PageNav><PageNav>Page {page} of {deployedColl.length > dropsPerPage ? Math.ceil(deployedColl.length / dropsPerPage) : 1}</PageNav>
                <PageNav valid={page < deployedColl.length / dropsPerPage} onClick={() => {
                    if (page < deployedColl.length / dropsPerPage)
                        setPage(prev => prev + 1)
                }}>Next</PageNav>
            </BottomPageNav>
        </Wrapper>
    );
    // else return (
    //     <Wrapper>
    //         <Banner>
    //             <h1>Drop Schedule</h1>
    //         </Banner>
    //         {(drops.live.length > 0) && <h1>LIVE!</h1>}
    //         {drops.live.map(drop => (
    //             <Section key={drop.id}>
    //                 <ReleaseDateWrapper >Closing {dayjs.unix(drop.end_date).format('dddd, MMMM DD, hh:mm A')}</ReleaseDateWrapper>
    //                 <CollectionCard collection={drop} />
    //             </Section>
    //         ))
    //         }
    //         {(drops.notLive.length > 0) && <h1>Upcoming & Previous Drops</h1>}
    //         {drops.notLive.map(drop => (
    //             <Section key={drop.id}>
    //                 <ReleaseDateWrapper >{dayjs.unix(drop.release_date).format('dddd, MMMM DD, hh:mm A')}</ReleaseDateWrapper>
    //                 <CollectionCard collection={drop} />
    //             </Section>
    //         ))
    //         }
    //         <BottomPageNav>
    //             <PageNav valid={page > 1} onClick={() => {
    //                 if (page > 1)
    //                     setPage(prev => prev - 1)
    //             }}>Prev</PageNav><PageNav>Page {page} of {deployedColl.length > 5 ? (deployedColl.length - deployedColl.length % 5) / 5 : 1}</PageNav>
    //             <PageNav valid={page < deployedColl.length / 5} onClick={() => {
    //                 if (page < deployedColl.length / 5)
    //                     setPage(prev => prev + 1)
    //             }}>Next</PageNav>
    //         </BottomPageNav>
    //         <Footer></Footer>
    //     </Wrapper>
    // );
};

export default Drops;
