import styled from "styled-components";

export const ModalWrapper = styled.div`
position: fixed;
background: var(--background);
display: grid;
justify-content: center;
align-items: center;
box-shadow: 0 0 20px rgba(0,0,0,0.5);
max-width: ${props => props.maxWidth ? props.maxWidth : ''};
width: ${props => props.width ? props.width : ''};
min-width: ${props => props.minWidth ? props.minWidth : '500px'};
grid-template-columns: 1fr min-content;
grid-column-gap: 10px;
grid-template-areas: ${props=> props.showBackArrow ? "'back close''title title''subtext subtext''content content''button button'" : "'title close''subtext subtext''content content''button button'"};
padding: 20px;
grid-row-gap: 20px;
justify-items: start;
z-index: 3;
`;

export const ModalOverlay = styled.div`
visibility: ${props => props.showModal ? 'visible' : 'hidden'};
position: fixed;
z-index: 2;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
left: 0;
height: calc(100vh - 60px);
top: 60px;
background: transparent;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100 %;
justify-content: flex-end;
position: relative;
img{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
width: 100%;
flex-direction: ${props => props.direction ? props.direction : ''};
display: flex;
`;

export const RowWrapper = styled.div`
display: flex;
justify-content: space-between;
`;

export const SubText = styled.div`
font-size: 12px;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
user-select: none;
color: ${props => props.color ? props.color : 'var(--purpleGrey)'};
`;

export const ModalTitle = styled.div`
user-select: none;
text-transform: uppercase;
`;
