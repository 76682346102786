import styled from "styled-components";

export const Wrapper = styled.div`
height: 430px;
width: 100%;
display: flex;
user-select: none;
background: var(--buttonGrey);
position: relative;
overflow: hidden;
border-bottom: 1px solid var(--grey);
z-index: ${props => props.zIndex ? props.zIndex : ''};
background-repeat: no-repeat;
background-size: cover;
row-gap: 5px;
background-position: center;
margin: ${ props => props.margin ? props.margin : ''};
/* justify-content: center;
align-items: center; */
display: grid;
grid-template-columns:  ${props => props.gridTempCols ? props.gridTempCols : '10% 1fr 1fr 10%'};
grid-template-rows:  ${props => props.gridTempRows ? props.gridTempRows : '20px 1fr minmax(20px,min-content) 20px'};
grid-template-areas: '. . . .''. one two .''. three four .''. . . . ';
@media screen and (max-width: 1040px){
    grid-template-areas: '. . . .''. one two .''. three four .''. f s .''. sev e .''. . . . ';
    grid-template-rows: 20px 70% min-content min-content min-content 20px;
    height: 470px;
}
`;


export const BannerImgBgdContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
height: inherit;
align-items: center;
grid-column: 1 / end;
grid-row: 1 / end;
position: absolute;
object-fit: cover;
justify-content: center;
position: ${props => props.overlay ? 'absolute' : 'relative'};
background: ${props => props.overlay ? 'linear-gradient(to bottom, rgba(255,255,255,0.25), rgba(255,255,255,.90))' : 'none'};
img{
    min-height: 100%;
    min-width: 100%;
    object-position: center;
    object-fit: cover;
    grid-column: 1 / end;
}
`;

export const BannerImgContainer = styled.div`
grid-column: 3;
grid-row: 2;
z-index: 1;
height: 100%;
width: 100%;
overflow: hidden;
position: relative;
border: 5px solid var(--lightestGrey);
box-shadow: 0 0px 15px 1px rgba(0,0,0,0.5);
display: flex;
@media screen and (max-width: 1040px){
    grid-column: 2 / span 2;
}
`;

export const BannerImg = styled.img`
object-fit: cover;
min-height: 100%;
min-width: 100%;
`;

export const BannerAttribute = styled.div`
z-index: 1;
grid-column: 3;
grid-row: 3;
margin: 0;
text-align: right;
@media screen and (max-width: 1040px){
    grid-row: 3;
    text-align: center;
    grid-column: 2 / span 2;
    margin: 0;
}
`;
export const SubText = styled.div`
margin: 0;
grid-row: 3 / span 1;
z-index: 1;
display: flex;
height: 100%;
align-items: start;
text-transform: uppercase;
position: absolute;
grid-column: 2;
@media screen and (max-width: 1040px){
    grid-row: 6 / span 1;
    text-align: center;
    align-items: center;
    grid-column: 2 / span 2;
    width: 100%;
    p{
        width: 100%;
    }
}
`;

export const TextOverlay = styled.h1`
/* width: 100%;
height: 100%; */

/* font-size: 21px; */
/* text-align: center; */
margin: 0;
@media screen and (max-width: 1040px){
font-size: 2em;
}
`;

export const BannerTitle = styled.div`
justify-content: center;
grid-column: 2;
text-align: left;
bottom: 0;
grid-row: 2 / span 1;
margin: 0;
position: absolute;
z-index: 1;
@media screen and (max-width: 1040px){
    grid-row: 4 / span 1;
    text-align: center;
    grid-column: 2 / span 2;
    width: 100%;
    position: relative;
}
`;
