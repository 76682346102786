import React, { useEffect, useState } from 'react'

import {
    Wrapper, CollectionCover, ArtistPicture, Social,
    ArtistHandle, Description, Content, ArtistBioHeader, ColumnWrapper,
    CollectionArtworksHeader, CollectionArtworksHeaderBgd, DropCountdownWrapper,
    CollectionArtworks, ArtistBio, ArtistBioText, ArtistBioPicture, DropdownCounter,
    ArtistName, Spacer
} from './UploadFormDropPrev.styles'


import ArtworkCard from '../../ArtworkCardOLD'
import DropArtworksContent from '../../DropArtworksContent'

import FacebookIcon from '../../../images/facebook.svg'

import InstagramIcon from '../../../images/instagramIcon.jpg'

import Droplet from '../../../images/goldDrop@2x.png'


const Instagram = ({ instagram }) => (
    <img src={InstagramIcon} href={instagram} target="_blank" />
)

const Facebook = ({ facebook }) => (
    <img src={FacebookIcon} href={facebook} target="_blank" />
)

const UploadFormDropPrev = ({state, loading}) => {
    console.log(state)

    if (loading) {
        return <Wrapper>

        </Wrapper>
    }
    else {
        const link = '/profile/' + state.handle.replace('@', '')

        return (
            <Wrapper>
                <CollectionCover>
                    <img src={state.collectionCover} alt={state.collectionTitle} />
                </CollectionCover>
                <ArtistPicture>
                    <img src={state.artistProfilePic} alt={state.collectionTitle} />
                </ArtistPicture>
                <ArtistName to={link}>{state.display_name}</ArtistName>
                <ArtistHandle>@{state.handle}</ArtistHandle>
                <Description>{state.collectionDescription}</Description>
                <DropArtworksContent collectionArtworks={state.collectionArtworks} />

                {/* <ArtistBio>
                    <Spacer gridArea='spacer1' />
                    <Content>
                        <ArtistBioHeader>Artist Bio</ArtistBioHeader>
                        <ArtistBioText>{state.artistBio}</ArtistBioText>
                        <a href={state.socialMedia.website} target="_blank">{state.socialMedia.website}</a>
                        <Social>
                            {state.socialMedia.instagram && <Instagram instagram={state.socialMedia.instagram} />}
                            {state.socialMedia.facebook && <Facebook facebook={state.socialMedia.facebook} />}
                        </Social>
                        <ArtistBioPicture><img src={state.artistProfilePic} alt={state.artistName} /></ArtistBioPicture>
                    </Content>
                    <Spacer gridArea='spacer2' />
                    <a></a>
                </ArtistBio> */}

            </Wrapper>
        )
    }
}

export default UploadFormDropPrev
