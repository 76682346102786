import React from 'react'

import { Wrapper } from './Faq.styles'

const Faq = () => {
  return (
    <Wrapper><h1>Faq Coming Soon</h1></Wrapper>
  )
}

export default Faq