import React, { useContext, useState, useEffect } from 'react'
import API from '../API'
const CollectionStatusContext = React.createContext()

export const useImportCollectionStatus = () => {
    return useContext(CollectionStatusContext)
}

export const CollectionStatusProvider = ({ children }) => {
    const [statusKey, setStatusKey] = useState([]);
    useEffect(() => {
        const fetchCollectionStatuses = async () => {
            try {

                const s = await API.fetchCollectionStatuses();
                setStatusKey(s)
            } catch (error) {
                console.log(error.response)
            }

        }
        fetchCollectionStatuses()
    }, [])
    const value = { statusKey }
    return (
        <CollectionStatusContext.Provider value={value}>
            {children}
        </CollectionStatusContext.Provider>
    )
}