import React from 'react'

import { Content, QuestionWrapper, Text } from './CoverArtInfo.styles'

import InputField from '../InputField'
import TextArea from '../TextArea'

const CoverArtInfo = ({ handleChange, values, width, maxWidth, height }) => {


    return (

        <Content width={width} maxWidth={maxWidth} height={height}>
            <QuestionWrapper width="100%">
                <InputField
                    type="text"
                    onChange={handleChange('collectionTitle')}
                    // onKeyPress={continueKey}
                    name='collectionTitle'
                    label='Collection Title'
                    placeholder=''
                    marginBottom={'0.8rem'}
                    value={values.collectionTitle}
                // setValidContinue={setValidContinue}
                />
            </QuestionWrapper>
            <QuestionWrapper>
                <TextArea
                    type="text"
                    maxLength="1000"
                    onChange={handleChange('collectionDescription')}
                    // onKeyPress={continueKey}
                    name='collectionDescription'
                    label='Collection Description'
                    placeholder=''
                    marginBottom={'0.8rem'}
                    showCharCount
                    value={values.collectionDescription}
                // setValidContinue={setValidContinue}
                />
            </QuestionWrapper>
        </Content>

    )
}

export default CoverArtInfo
