import React, { useState, useEffect, useRef } from 'react'

import { BodyWrapper, UploadWrapper, CoverArtInfoWrapper, ContentWrapper } from './UploadArtwork.styles'
import CoverArtPreview from '../CoverArtPreview'
import StepHeader from '../StepHeader'
import CoverArtInfo from '../CoverArtInfo'
import DragAndDrop from '../DragAndDrop'


const UploadArtwork = ({ step, handleFileUpload, values, nextStep, prevStep, handleFieldChange, validateStep }) => {

    const [validContinue, setValidContinue] = useState(false);

    const initial = useRef(true);

    useEffect(() => {

        if (initial.current) {
            initial.current = false;
            validateStep(step)
            return;
        }
        validateStep(step)

    }, [values])

    return (

        <ContentWrapper>
            <DragAndDrop
                handleSongUpload={handleFileUpload}
                fileType={"JPEG, MPEG, MP4, & PNG ACCEPTED"}
                title={'Upload Artworks'}
                width="100%"
                maxWidth="100%"
                height="400px"
                note={''} />

        </ContentWrapper>

    )
}

export default UploadArtwork
