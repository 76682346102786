import styled from 'styled-components'

export const Wrapper = styled.div`
display: grid;
grid-template-rows: 1fr 1fr;
grid-template-columns: min-content min-content;
user-select: none;
grid-gap: 5px;
`;

export const Icon = styled.img`
height: 45px;
width: 45px;
grid-column: 1;
grid-row: 1 / span 3;
`

export const Title = styled.div`
font-size: 10px;
white-space: nowrap;
align-self: start;
line-height: 0.7rem;
`;
export const Subtext = styled(Title)`
color: var(--grey);
`;


export const OuterWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: start;
`;

export const GridWrapper = styled.div`
display: grid;
grid-template-rows: min-content min-content;
grid-template-columns: repeat(4, min-content);
/* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
width: 100%;
grid-gap: 20px;
user-select: none;
@media screen and (max-width: 700px){
    grid-template-columns: repeat(2, 1fr);  
}
`;

export const Data = styled.div`
font-size: 21px;
font-size: 16px;
font-weight: 500;
align-self: end;
line-height: 0.8rem;
`