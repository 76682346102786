import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const PageWrapper = styled.div`
position: relative;
height: calc(100vh - 60px);
top: 60px;
display: flex;
flex-direction: column;
align-items: center;
>div:first-child{
    margin: 0.5rem 0;
}
overflow: auto;
`;


export const OuterWrapper = styled.div`
display: grid;
position: relative;
height: 100%;
height: min-content;
padding: 0 0 20px;
width: 100%;
grid-template-areas: 'sp1 picture1 picture2 picture3 c . sp2';
grid-template-columns: 1fr 25px 30px 25px minmax(30%,560px) 30px 1fr ;
grid-template-areas: 'sp1 c sp2';
grid-template-columns: 1fr  minmax(30%,560px)1fr ;
grid-column-gap: 20px;
max-width: 600px;
display: flex;
flex-direction: column;
align-items: center;

`;

export const SP1 = styled.div`
grid-area: sp1 / sp1 / picture1 / picture1;
grid-area: sp1;
width: 100%;
height: 100%;
position: absolute;
/* border: 1px solid var(--lighterGrey); */
`;

export const SP2 = styled.div`
grid-area: sp2;
width: 100%;
height: 100%;
position: absolute;
/* border: 1px solid var(--lighterGrey); */
`;

export const Wrapper = styled.div`
grid-area: c;//picture3 / picture3 / c / c;
border: 1px solid var(--lighterGrey);
position: relative;
width: 100%;
min-height: 500px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
height: min-content;
`;

export const InnerWrapper = styled.div`
    min-width: 400px;
    position: relative;
    
    display: flex;
    width: 100%;
    height: 100%;
    height: min-content;
    `;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 50px;
    height: min-content;
    position: relative;
    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 1fr min-content;
    grid-row-gap: 30px; */
    /* box-shadow: var(--goldShadowCentered);
    border-radius: 5px; */
`;

export const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-auto-rows: min-content;
    grid-row-gap: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    >div:last-child {
        flex-grow: 1;
    }
`;

export const SocialModalLink = styled.div`
display: flex;
cursor: pointer;
align-items: center;
align-self: start;
text-transform: uppercase;
font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;

img{
   margin-left: 5px; 
}
`;

export const QuestionWrapper = styled.div`
position: relative;
margin-top: ${props => props.marginTop ? props.marginTop : '0'};
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
text-align: ${props => props.textAlign ? props.textAlign : ''};
`;

export const EmailBox = styled.div`
/* background: var(--lightestGrey); */
width: 100%;
padding: 10px;
`;

export const Title = styled.div`
text-align: left;
user-select: none;
cursor: pointer;
font-size: 1.1rem;
text-transform: uppercase;
opacity: ${ props => props.isActive ? '1' : '0.5'};
`;

export const Text = styled.div`
position: relative;
user-select: none;
display: flex;
width: 100%;
font-size: 14px;
align-items: flex-end;
`;

export const Small = styled.div`
font-size: 10px;
user-select: none;
text-align: ${props => props.align ? props.align : 'left'};
width: 100%;
margin-top: 0.2rem;
`;


export const LabelStyled = styled.div`
    position: relative;
    user-select: none;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const ImageWrapper = styled.div`
display: flex;
justify-content: center;
img{
    width: 40%;
}
`;

export const TermsAndConditions = styled.div`
font-size: 10px;
`;

export const Error = styled.div`
font-size: 10px;
user-select: none;
`;

export const LoginSignUpSwitch  = styled(Link)`
all: unset;
user-select: none;
cursor: pointer;
color: var(--fontColor);
text-align: left;
text-transform: uppercase;
`;

export const SwitchContainer = styled.div`
display: flex;
font-size: 12px;
margin-top: 1rem;
width: 100%;
`;

export const LoadingWheel = styled.div`
    border: 10px solid var(--grey);
    border-top: 10px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;

export const ProfilePicture = styled.div`

width: 100%;
height: 100%;
display: flex;
justify-content: center;
position: relative;
z-index:1;
border-radius: 50%;
border: 2px solid #000000 !important;
overflow: hidden;
img{
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
}

`;

export const EditProfilePictureContainer = styled.div`
grid-area: picture1 / picture1 / picture3 / picture3;
cursor: pointer;
width: 80px;
height: 80px;
position: relative;
/* top: 95px; */
input{
        height: 0;
        width: 0;
        visibility: hidden;
    }
`;

export const Icon = styled.img`
height: 20px;
width: 20px;
`;


export const EditContainer = styled(Icon)`
position: absolute;
bottom: 0;
z-index: 1;
right: 0;
`;

export const Row = styled.div`
display: flex;
width: 100%;
position: relative;
margin: 5px 0 0;
justify-content: space-around;
`;