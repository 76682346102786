import React, { useContext, useState, useEffect } from 'react'

import API from '../API'
import Web3 from 'web3'
import web3 from '../blockchain/web3'
// import {
//     resellSecondary, setForResale, claimNFT,
//     deployCollection, buy, buyFromMarketplace,
//     bid, getDeployedCollections,
//     getCollectionItems, getURI, getItemData, getBids,
//     getItemsSold, getCollectionOpenAndClose
// } from "../blockchain/functions";
import { updateRelTime } from '../utils'
import {
    deployCollection,
    setForResale,
    claimNFT,
    claimNFTMarketplace,
    buy,
    buyFromMarketplace,
    bid,
    bidMarketplaceItem,
    acceptOffer,
    revokeOffer,
    makeOffer,
    withdrawFunds as _withdrawFunds
} from "../blockchain/functionsUiollyNew";

const Web3Context = React.createContext()


export const useWeb3 = () => {
    return useContext(Web3Context)
}

export const Web3Provider = ({ children }) => {
    updateRelTime()
    const [showMsg, setShowMsg] = useState(false)
    const [selectedAccount, SetSelectedAccount] = useState(null)
    const [networkError, setNetworkError] = useState({ status: false, msg: '' })
    const [itemsSubmitting, setItemsSubmitting] = useState([])
    const [txProcessing, setTxProcessing] = useState(false)
    const [txMessage, setTxMessage] = useState("")
    const [userAddress, setUserAddress] = useState("")
    useEffect(() => {
        if (showMsg && !txProcessing) {
            let id = setTimeout(() => setShowMsg(false), 5 * 1000)
            return () => clearTimeout(id);
        }
    }, [showMsg, txProcessing])
    const validateBalance = async (amount) => {
        const balance = await web3.eth.getBalance(userAddress)
        const balanceETH = web3.utils.fromWei(balance)
        return balanceETH > amount
    }
    //useEffect(() => {

    const init = async () => {
        const networkID = await web3.eth.net.getId()
        console.log("networkID", networkID)
        if (parseInt(networkID) !== 3) {
            setNetworkError({ status: true, message: "Please switch to the Ropsten Network to access this site." })
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x3" }],
            }).then(response => { console.log(response) }).catch(e => { console.log(e) })
        }
    }

    const processReceipt = (receipt, message, callback = undefined) => {
        if (typeof receipt !== "string") {
            setTxProcessing(false)
            setShowMsg(true)
            setTxMessage(message)
            if (typeof (callback) === "function") {
                callback()
            }
            return receipt
        }
        else {
            if (receipt.includes('denied transaction signature'))
                setTxMessage("User denied transaction signature.")
            else
                setTxMessage("An error occurred. Please try again later")
            setTxProcessing(false)
            setShowMsg(true)
            return "Error: " + receipt
        }
    } //resellSecondary
    // setForResale = async (_address, _tokenId, _value, setTxProcessing, setTxMessage, setShowMsg)
    const setTokenResale = async (_contractAddress, _tokenId, _price_eth, _isAuction, _salesOpenTime, _salesCloseTime) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            console.log(_contractAddress, _tokenId)
            const receipt = await setForResale(_contractAddress, _tokenId, _price_eth, _isAuction, _salesOpenTime, _salesCloseTime, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Token set for sale!")
        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }

    const handleClaimNFT = async (contractAddress, tokenId, callback) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        //setItemsSubmitting(prev => [...prev, { contractAddress, tokenId }])
        try {
            const receipt = await claimNFT(contractAddress, tokenId, setTxProcessing, setTxMessage, setShowMsg)
            //setItemsSubmitting(prev => [...prev.filter(it => !(it.tokenId === tokenId && it.contractAddress === contractAddress))])
            return processReceipt(receipt, "Transfer is complete!", callback)

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }

    }

    const withdrawFunds = async (contractAddress, tokenId, callback) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await _withdrawFunds(contractAddress, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Funds transfer is complete!", callback)

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }

    }

    const marketplaceClaim = async (itemId, callback) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        //setItemsSubmitting(prev => [...prev, { contractAddress, tokenId }])
        try {
            const receipt = await claimNFTMarketplace(itemId, setTxProcessing, setTxMessage, setShowMsg)
            //setItemsSubmitting(prev => [...prev.filter(it => !(it.tokenId === tokenId && it.contractAddress === contractAddress))])
            return processReceipt(receipt, "Transfer is complete!", callback)

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }
    const handleBuy = async (contractAddress, baseType, price) => { // wei
        console.log("address", contractAddress, baseType)
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await buy(contractAddress, baseType, price, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Your purchase is complete!")

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }

    const marketplaceBuy = async (itemId, price) => { // wei
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await buyFromMarketplace(itemId, price, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Your purchase is complete!")

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }

    const marketplaceBid = async (itemId, bid_ETH) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await bidMarketplaceItem(itemId, bid_ETH, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Your bid has been received!")

        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }

    const marketplaceMakeOffer = async (itemId, offer_ETH) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await makeOffer(itemId, offer_ETH, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Your offer was sent!")

        } catch (err) {
            setTxProcessing(false)
            return err.code
        }
    }

    const marketplaceAcceptOffer = async (itemId, _offerId) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await acceptOffer(itemId, _offerId, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "You accepted an offer!")

        } catch (err) {
            setTxProcessing(false)
            return err.code
        }
    }

    const marketplaceRevokeOffer = async (itemId) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await revokeOffer(itemId, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Offer revoked successfully.")

        } catch (err) {
            setTxProcessing(false)
            return err.code
        }
    }
    const handleBid = async (blockchainAddress, baseType, edition, b) => {
        setTxProcessing(true)
        setTxMessage("Please confirm the transaction in MetaMask.")
        setShowMsg(true)
        try {
            const receipt = await bid(blockchainAddress, baseType, edition, b, setTxProcessing, setTxMessage, setShowMsg)
            return processReceipt(receipt, "Your bid has been received!")
        } catch (err) {
            console.log("setting processing to false")
            setTxProcessing(false)
            return err.code
        }
    }

    const promptNetworkChange = async () => {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x3" }],
        }).then(response => { console.log(response) }).catch(e => { console.log(e) })
    }

    const connectWallet = async () => { //wallet_requestPermissions
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        setUserAddress(web3.utils.toChecksumAddress(accounts[0]));
        window.ethereum.on("accountsChanged", function (accounts) {
            setUserAddress(web3.utils.toChecksumAddress(accounts[0]))
        });
        window.ethereum.on("chainChanged", (_chainId) => {
            window.location.reload()
            if (parseInt(_chainId) !== 3) {
                // setNetworkError({ status: true, message: "Please switch to the Ropsten Network to access this site." })
                // promptNetworkChange()
            }
        }
        );
        return accounts[0]
    };
    // const handleDeployCollection = async (itemURIs, prices, editionSizes, saleFormats, release_date, end_date, collection_id) => {
    //     deployCollection(itemURIs, prices, editionSizes, saleFormats, release_date, end_date, setTxProcessing, setTxMessage, setShowMsg)
    //         .then((receipt) => {
    //             console.log("receipts",receipt)
    //             if (typeof receipt !== "string") {
    //                 setTxProcessing(false)
    //                 setShowMsg(true)
    //                 setTxMessage("Collection Deployed!")
    //                 API.updateCollectionAddress(collection_id, receipt.events.collectionDeployed.returnValues.newCollectionAddress).then(() => {
    //                     window.alert(
    //                         `collection deployed at the address ${receipt.events.collectionDeployed.returnValues.newCollectionAddress}`
    //                     )
    //                 }).catch(e => console.log(e))
    //                 return receipt
    //             }
    //             else {
    //                 setTxProcessing(false)
    //                 //return receipt
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("setting processing to false")
    //             setTxProcessing(false)
    //             return err.code
    //         })
    // }
    useEffect(() => {
        init()
    }, [])
    const value2 = {
        selectedAccount,
        connectWallet,
        userAddress,
        web3,
        handleBid,
        handleBuy,
        marketplaceAcceptOffer,
        marketplaceRevokeOffer,
        marketplaceBuy,
        marketplaceBid,
        marketplaceMakeOffer,
        setTokenResale,
        txProcessing,
        txMessage,
        showMsg,
        setShowMsg,
        networkError,
        validateBalance,
        handleClaimNFT,
        marketplaceClaim,
        itemsSubmitting,
        withdrawFunds
    }
    return (
        <Web3Context.Provider value={value2}>
            {children}
        </Web3Context.Provider>
    )
}

