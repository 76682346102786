import web3 from "../web3";
import ABI from "../abiRopsten/CollectionNFT.json";

const instance = async (tokenAddress) => {
    try {
        return new web3.eth.Contract(ABI.abi, tokenAddress);
    } catch (error) {
        console.log(error);
    }
};

export default instance;
