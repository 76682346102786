import React from 'react'

import {
    Wrapper,
    BannerImgBgdContainer,
    BannerImgContainer,
    BannerImg,
    BannerAttribute,
    SubText,
    TextOverlay,
    BannerTitle
} from './Banner.styles'

const Banner = ({ zIndex, margin, imagePath, headingMinor, artworkTitle, artistName, bannerTitle, gridTempCols, gridTempRows, onClick }) => {
    // imagePath={featured.cover_path}
    // headingMinor={dates.start.format('dddd, MMMM D') === dates.end.format('dddd, MMMM D') ?
    // dates.end.format('dddd, MMMM D, YYYY')
    // : `${dates.end.format('dddd, MMMM D')} - ${dates.start.format('dddd, MMMM D, YYYY')}`}
    // artworkTitle={featured.collection_title}
    //artistName={featured.artist_name}
    //bannerTitle={'Drop Schedule'}
    return (
        <Wrapper margin={margin} zIndex={zIndex} gridTempCols={gridTempCols} gridTempRows={gridTempRows}>{
            imagePath &&
            <>
                <BannerImgBgdContainer>
                    <img src={imagePath}
                        style={{
                            minHeight: '110%', minWidth: '110%', filter: 'blur(5px)',
                            position: 'absolute', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                        }} />
                </BannerImgBgdContainer>
                <BannerImgBgdContainer overlay>
                </BannerImgBgdContainer>
                <BannerImgContainer onClick={ typeof (onClick) === 'function' ? onClick : undefined}>
                    <div style={{ boxShadow: 'inset 0 0px 15px 1px rgba(0,0,0,0.5)', position: 'absolute', height: '100%', width: '100%' }}></div>
                    <BannerImg src={imagePath} />
                </BannerImgContainer>
                <BannerAttribute>
                    <div style={{ fontSize: '12px' }}>{artworkTitle}</div>
                    <div style={{ fontSize: '10px' }}>by {artistName}</div>
                </BannerAttribute>
            </>
        }

            <SubText >
                <p style={{ margin: '0' }}>
                    {headingMinor}
                </p>
            </SubText>
            <BannerTitle><TextOverlay>{bannerTitle}</TextOverlay></BannerTitle>
        </Wrapper>
    )
}

export default Banner