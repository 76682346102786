import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
height: 100vh;

h1{
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 2em;
    
}

`;

export const Emoji = styled.div``;

export const Subtext = styled.div`
width: 70%;
font-size: 16px;
color: var(--grey);
`;

export const ImageContainer = styled.div`
max-height: 225px;
max-width: 410px;
width: 80%;
min-width: 200px;
min-height: 200px;
position: relative;

margin-bottom: 20px;
img{
    width: 100%;
    height: 100%;
}
`;