import styled from "styled-components";



export const Wrapper = styled.div`
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${props => props.color? props.color : 'var(--white)'};
    width: ${ ({width}) => width};
    border-radius: var(--buttonBorderRadius);
    font-weight: var(--buttonFontWeight);
    border: 1px solid var(--goldSolid);
    background: ${props => props.background};
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    letter-spacing: var(--buttonLetterSpacing);

    :hover{
        background: ${props => props.hoverColor? props.hoverColor : 'rgba(255,255,255,0.15)'} ;

    }

    input{
        height: 0;
        width: 0;
        visibility: hidden;
    }
`;