import { useState, useEffect } from 'react';
import API from '../API';
import { UserProfileDetails } from '../objectPrototypes/objectClasses'
// import { getItemsSold, getItemData, getDeployedCollections, getNFTsClaimed, getCollectionOpenAndClose } from '../blockchain/functions'
import { getMarketplaceItemsForSaleByUser, getItemsOwnedAndForSaleAllCollections, getBidsForWalletAddressAllCollections } from '../blockchain/functionsUiollyNew'
export const useProfileFetch = (artistHandle) => {
    const [state, setState] = useState({ contentOptions: ['Curated'] });
    const [content, setContent] = useState('Curated');
    const [loading, setLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(true);
    const [error, setError] = useState(false);
    const [ownedNFTs, setOwnedNFTs] = useState([])
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                setLoading(true)
                setLoadingItems(true)
                setError(false)
                const res = await API.fetchProfile(artistHandle)
                setState(new UserProfileDetails(res))
                setLoading(false)
                setOwnedNFTs([])
                const c = await getItemsOwnedAndForSaleAllCollections(res.userDetails.public_address)
                const c_selling = await getMarketplaceItemsForSaleByUser(res.userDetails.public_address)
                setOwnedNFTs([...c, ...c_selling])
                // if (c !== undefined && c.length > 0) {
                //     setOwnedNFTs([...c, c_selling])
                // } else {
                //     setOwnedNFTs([])
                // }
                setLoadingItems(false)

            } catch (error) {
                setLoading(false)
                setError(true)
                setLoadingItems(false)
            }

        }
        if (artistHandle) {
            fetchProfile()
        }
    }, [artistHandle])
    return { state, loading, loadingItems, setContent, content, ownedNFTs }
}