import logo from './logo.svg';
import './App.css';

// Routing
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import Header from './components/Header'
import LandingPage from './components/LandingPage'
import ProductPage from './components/ProductPage';
import Faq from './components/Faq'
import ContactUs from './components/ContactUs'
import Drop from './components/Drop';
import Resale from './components/Resale';
import Collection from './components/Collection';
import Drops from './components/Drops';
import NotFound from './components/NotFound'
import UserProfile from './components/UserProfile'
import AccountSettings from './components/AccountSettings';
import Testing from './components/Testing';
import TestingFunctions from './components/TestingFunctions'
import AccountActivity from './components/AccountActivity'
import Marketplace from './components/Marketplace';
import UploadForm from './components/UploadForm';
import SuccessPage from './components/UploadForm/SuccessPage'
import SignUp from './components/SignUp'
import PrivateRoute from './components/PrivateRoute'
import TransactionNotification from './components/TransactionNotification'
import { AuthProvider } from './contexts/AuthContext'
import { Web3Provider } from './contexts/Web3Context'
import { CollectionStatusProvider } from './contexts/CollectionStatusContext'
import { GlobalStyle } from './GlobalStyle';

import {
  ACCOUNT_ACTIVITY,
  LOGOUT,
  LOGIN,
  SIGNUP
} from './config'

function App() {
  // const { currentUser } = useAuth();
  return (
    <Web3Provider>
      <AuthProvider>
        <CollectionStatusProvider>
          <div className="App">
            <Router>
              <Header />
              {/* <leave header out of routes bc its used on all pages. maybe remove div cllassname "app" /> */}
              <Routes>
                <Route path="/" exact element={<LandingPage />} />
                {/* <Route path="/product-page" exact element={<ProductPage />} /> */}
                <Route path="/account" element={<PrivateRoute />}>
                  <Route path="/account" element={<AccountActivity />} />
                </Route>
                <Route path="/resale" element={<PrivateRoute />}>
                  <Route path="/resale" element={<Resale />} />
                </Route>
                {/* <Route path="/testing" element={<PrivateRoute />}>
                  <Route path="/testing" element={<TestingFunctions />} />
                </Route> */}
                <Route path="/settings" element={<PrivateRoute />}>
                  <Route path="/settings" element={<AccountSettings />} />
                </Route>
                <Route path="/upload" element={<PrivateRoute />}>
                  <Route path="/upload" element={<UploadForm />} />
                </Route>
                <Route path="/success" element={<PrivateRoute />}>
                  <Route path="/success" element={<SuccessPage />} />
                </Route>
                <Route path="/signup" exact element={<SignUp type={SIGNUP} />} />
                <Route path="/login" exact element={<SignUp type={LOGIN} />} />
                <Route path="/drops" exact element={<Drops />} />
                <Route path="/marketplace" exact element={<Marketplace />} />
                {/* <Route path="/productpage/:blockchainAddress/:artworkID" exact element={<ProductPage global={true} />} />
                <Route path="/productpage/:blockchainAddress/:artworkID/:tokenID" exact element={<ProductPage global={false} />} />
                <Route path="/drops/:blockchainAddress" exact element={<Drop />} /> */}
                <Route path="/collections/:blockchainAddress" exact element={<Collection />} />
                <Route path="/collections/:blockchainAddress/:baseType" exact element={<ProductPage global={true} isSecondary={false} />} />
                <Route path="/collections/:blockchainAddress/:baseType/:tokenEdition" exact element={<ProductPage global={false} isSecondary={false} />} />
                <Route path="/marketplace/:blockchainAddress/:baseType/:tokenEdition" exact element={<ProductPage global={false} isSecondary={true} />} />
                <Route path="/profile/:userHandle" exact element={<UserProfile />} />
                <Route path="/faq" exact element={<Faq />} />
                <Route path="/contact" exact element={<ContactUs />} />
                <Route path="/testing" exact element={<Testing />} />
                <Route path="/*" exact element={<NotFound />} />
              </Routes>
              <TransactionNotification />
              <GlobalStyle />
            </Router>
          </div>
        </CollectionStatusProvider>
      </AuthProvider>
    </Web3Provider>
  );
}

export default App;
