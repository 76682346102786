import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
export const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;

export const Content = styled.div`
column-gap: 30px;
row-gap: 30px;
width: 100%;
justify-content: space-between;
display: flex;
flex-flow: row wrap;

`;

export const ArtistInfo = styled.div`



p{
    font-size: .8em;
    letter-spacing: 0.02rem;
    font-weight: 200;
}


`;


export const NavLink = styled(Link)`
all: unset;
user-select: none;
cursor: pointer;
color: var(--goldSolid);
`;


export const ArtworkContainer = styled.div`
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;

img{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
`;


export const Artwork = styled.div`

`;

export const ThumbnailBar = styled.div`
grid-area: sidebar;
transform-style: preserve-3d;
perspective: 1000px;
`;

export const ThumbnailBarWrapper = styled.div`

`;

export const Container = styled.div`

position: relative;
overflow: hidden;
`;


export const Frame = styled.div`
height: 300px;
cursor: pointer;
position: relative;
width: 100%;
display: flex;
max-height: 300px;
flex-direction: column;
align-items: center;
justify-content: center;
overflow: hidden;
flex-grow: 1;
flex-basis: 25%;
min-width: 300px;
.info{
    opacity: 0;

}

&:hover .info{
    opacity: 1;

}

`;

export const FeaturedArtInfo = styled.div`
text-transform: uppercase;
user-select: none;
position: absolute;
background: rgba(255,255,255,0.85);
color: var(--fontColor);
border: 1px solid #000;
width: 100%;
text-align: left;
height: 100%;
justify-content: center;
align-items: flex-start;
display: grid;
grid-template-rows: min-content 1fr;
padding: 20px;
overflow: hidden;
display: flex;
flex-direction: column;
`;


export const Bio = styled.p`
font-size: 12px;

display: -webkit-box;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

`;

export const Artist = styled.div`
font-size: 21px;
font-weight: 700;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FrameImageWrapper = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    img{
        object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    }
`;

export const Video = styled.video`
        object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;


`;