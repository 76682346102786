import React, { useRef } from 'react'

import {
    Wrapper, Image, ImageWrapper, DetailsWrapper, RowWrapper, Video,
    CurrentBidValue, CurrentBidLabel, LastSoldLabel, LastSoldValue, Pricing,
    Timer, Title, Artist, Edition, OuterWrapper, NavLink
} from './ArtworkCard.styles'



import TimerIcon from '../../images/timerIcon.svg'

import MintIcon from '../../images/mintIcon.svg'

const ImageArtwork = ({ image, title }) => {

    return (
        <ImageWrapper>

            <Image src={image} alt={title} />

        </ImageWrapper>
    )
}

const VideoArtwork = ({ artwork, isFeatured }) => {
    const videoRef = useRef(null);
    if (isFeatured && videoRef.current !== null) {
        videoRef.current.play()
    }
    else if (videoRef.current !== null) {
        videoRef.current.pause()
    }
    return (
        <Video
            ref={videoRef}
            src={artwork}
            width="100%"
            autoPlay={false}
            controls={false}
            onMouseOver={(event) => videoRef.current.play()}
            onMouseOut={(event) => videoRef.current.pause()}
            loop={true}
            muted
            playsInline
        />
    )
}


const Details = ({ value }) => {

    const label = value.saleType === 'auction' ? 'Current Bid' : 'Ask';
    return (
        <DetailsWrapper>

            <RowWrapper> <Title>{value.title}</Title><Edition>{value.edition} of {value.editionsReleased}</Edition></RowWrapper>
            <Artist>{value.artistName}</Artist>
            <Pricing>
                <CurrentBidLabel>{label}</CurrentBidLabel>
                <CurrentBidValue>{value.price}</CurrentBidValue>
                <LastSoldLabel>Last Sold</LastSoldLabel>
                <LastSoldValue>{value.lastSold}</LastSoldValue>
            </Pricing>
            <Timer>{value.saleType === 'auction' && <>{value.auctionEnd}<img src={TimerIcon} alt="auction-timer-icon" /></>}</Timer>
        </DetailsWrapper>
    )
}

const PrimaryMarketDetails = ({ value, collectionState }) => {

    const saleType = value.initialSaleType !== undefined ? value.initialSaleType : value.saleType;
    const priceAdj = value.price !== undefined ?
        value.price.split('.').length > 1 ?
            value.price.split('.')[0] + `${value.price.split('.')[1] !== '00' ? '.' + value.price.split('.')[1] : ''}` :
            value.price : undefined;
    const price = value.initialDropPrice !== undefined ? value.initialDropPrice : !priceAdj.includes('Ξ') ? `${priceAdj}Ξ` : `${priceAdj}`;

    const label = saleType.toUpperCase() === 'auction'.toUpperCase() ?
        collectionState === 'pending' ? 'Minimum Bid' : 'Highest Bid' : 'Fixed Price';

    const editionsAvailable = collectionState === 'pending' ? value.maxMint === '1' ? 'Single Edition' : value.maxMint + ' available' : value.maxMint - value.editionsReleased + ' available';
    return (
        <DetailsWrapper>
            <Artist>{value.display_name}</Artist>
            <RowWrapper> <Title>{value.title}</Title></RowWrapper>

            <Pricing isPrimary>
                <CurrentBidLabel>{label}</CurrentBidLabel>
                <CurrentBidValue>{price}</CurrentBidValue>
            </Pricing>
            <Timer justifyContent="flex-start" textAlign="left">{editionsAvailable}</Timer>
        </DetailsWrapper>
    )
}

const ArtworkCard = ({ market, collectionState, value, artwork, artist, title, editions, editionsForSale, price, timer, saleType, previousSale }) => {

    if (market === 'primary') {
        const link = '/productpage/' + value.blockchainAddress + '/' + value.artworkID;
        if (value.type === 'image') {
            return (
                <NavLink>
                    <OuterWrapper>
                        <Wrapper>
                            <ImageArtwork image={value.url || value.thumbnail} title={title} />
                            <PrimaryMarketDetails value={value} collectionState={collectionState} />
                        </Wrapper>
                    </OuterWrapper>
                </NavLink>
            )
        }
        else {
            return (
                <OuterWrapper>
                    <Wrapper>
                        <VideoArtwork artwork={value.url || value.thumbnail} title={title} />
                        <PrimaryMarketDetails value={value} collectionState={collectionState} />
                    </Wrapper>
                </OuterWrapper>
            )
        }
    }
    else {
        const link = '/productpage/' + value.blockchainAddress + '/' + value.artworkID + '/' + value.tokenID;
        if (value.type === 'image') {
            return (

                <NavLink>
                    <OuterWrapper>
                        <Wrapper>
                            <ImageArtwork image={value.url} title={title} />
                            <Details value={value} />
                        </Wrapper>
                    </OuterWrapper>
                </NavLink>
            )
        }
        else {

            return (
                <OuterWrapper>
                    <Wrapper>
                        <VideoArtwork artwork={value.url} title={title} />
                        <Details value={value} />
                    </Wrapper>
                </OuterWrapper>
            )
        }
    }

}

export default ArtworkCard
