import React, { useState, useEffect } from 'react'

import { getNonFungibleBaseType, getNonFungibleIndex } from '../../blockchain/blockchainUtils'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import {
    Wrapper,
    Center,
    ArtistBio,
    Spacer,
    ArtistBioContent,
    ArtistBioHeader,
    Social,
    ArtistBioText,
    ToggleWrapper,
    ToggleOption,
    EditProfileLink,
    ArtistBioPicture,
    ContentWrapper,
    SocialWrapper,
    CollectionWrapper,
    CollectionCoverWrapper,
    Image
} from './UserProfile.Styles'

import ArtworkCard from '../ArtworkCard'
import CollectionCard from '../CollectionCard'
import LoadingWheel from '../LoadingWheel'


import { useAuth } from '../../contexts/AuthContext'
import { useProfileFetch } from '../../hooks/useProfileFetch'

import FacebookIcon from '../../images/facebook.svg'
import TiktokIcon from '../../images/tiktok.svg'
import InstagramIcon from '../../images/instagramIcon.svg'

const Instagram = ({ instagram }) => (
    <SocialWrapper href={`https://instagram.com/${instagram}`} target="_blank" ><img src={InstagramIcon} /></SocialWrapper>
)

const Facebook = ({ facebook }) => (
    <SocialWrapper href={`https://facebook.com/${facebook}`} target="_blank"><img src={FacebookIcon} /></SocialWrapper>
)

const Tiktok = ({ tiktok }) => (
    <SocialWrapper href={`https://tiktok.com/${tiktok}`} target="_blank"><img src={TiktokIcon} /></SocialWrapper>
)

const Content = ({ state, content, ownedNFTs }) => {
    const navigate = useNavigate();
    if (content === 'Curated') { //nfts owned
        return (
            <>
                {
                    ownedNFTs.map((artwork, index) => (
                        <ArtworkCard artwork={artwork} key={index} onClick={() => {
                            navigate(`/marketplace/${artwork.contractAddress}/${getNonFungibleBaseType(artwork.tokenId)}/${getNonFungibleIndex(artwork.tokenId)}`)
                        }} />))
                }
            </>
        )
    }
    else if (content === 'Created' && state.collections.length > 0) {
        return (
            <>
                {
                    state.collections.map((artwork, index) => (
                        <CollectionCard collection={artwork} key={index} />))
                }
            </>
        )
    }
    else {
        return (
            <>
            </>
        )
    }
}


const Collection = ({ collection }) => {

    return (
        <CollectionWrapper>
            <div>{collection.collection_title}</div><div>{dayjs.unix(collection.release_date).format('MMM D YYYY')}</div>
            <CollectionCoverWrapper><Image src={collection.cover_path} /></CollectionCoverWrapper>
        </CollectionWrapper>
    )
}


const UserProfile = () => {
    const { currentUser } = useAuth()
    const { userHandle } = useParams()

    const [handle, setHandle] = useState(userHandle)
    const [showEdit, setShowEdit] = useState(false)
    useEffect(() => {
        setHandle(userHandle)
    }, [userHandle])
    useEffect(() => {
        if (currentUser != null && currentUser.userDetails !== undefined && currentUser.userDetails.handle !== undefined && currentUser.userDetails.handle === userHandle)
            setShowEdit(true)
        else {
            setShowEdit(false)
        }
    }, [currentUser, userHandle])

    const toggleOptions = ["gallery", "drops"];

    const { state, loading, loadingItems, setContent, content, ownedNFTs } = useProfileFetch(handle);
    const { socialMedia } = state
    const toggleContent = (event) => {
        const { value } = event.target.attributes.value;

        setContent(value)
    }

    console.log("state", ownedNFTs)

    if (loading) {

        return <Wrapper></Wrapper>
    }
    else {
        return (
            <Wrapper>
                {/* <ArtistBio>
                    <ArtistBioContent>


                    </ArtistBioContent>
                </ArtistBio> */}
                <Center>
                    <ArtistBioPicture><img src={state.profile_pic} alt={state.display_name} /></ArtistBioPicture>
                    {showEdit && <EditProfileLink to="/settings">Edit Profile</EditProfileLink>}
                    <ArtistBioHeader>{state.display_name}</ArtistBioHeader>
                    <p style={{ margin: 0, fontSize: '10px', color: 'var(--secondary)' }}>@{userHandle}</p>
                    <ArtistBioText>{state.bio}</ArtistBioText>
                    <a href={socialMedia.website} target="_blank">{socialMedia.website}</a>
                    <Social>
                        {socialMedia.instagram && <Instagram instagram={socialMedia.instagram} />}
                        {socialMedia.facebook && <Facebook facebook={socialMedia.facebook} />}
                        {socialMedia.tiktok && <Tiktok tiktok={socialMedia.tiktok} />}
                    </Social>

                    <ToggleWrapper>
                        {state.contentOptions.map((option) => (
                            <ToggleOption key={option} isSelected={option === content} onClick={() => { setContent(option) }} value={option}>{option}</ToggleOption>
                        ))}
                    </ToggleWrapper>
                </Center>
                <ContentWrapper>

                    {loadingItems && <LoadingWheel position="relative" height="100%" radius="20px" />}

                    {!loadingItems && <Content content={content} state={state} ownedNFTs={ownedNFTs} />}
                </ContentWrapper>
            </Wrapper>
        )
    }
}


export default UserProfile
