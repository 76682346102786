import styled from 'styled-components';

export const FormStyled = styled.form`
height: ${props => props.step === "review" ? '100%' : 'calc(100vh)'};
width: 100vw; // changed this to 100vw to keep stuff from shifting due to vert scroll bar
min-height: ${props => props.step === "review" ? '100%' : 'calc(100vh)'};
top: 0;

flex-direction: column;
position: relative;
margin-bottom: 50px;
display: flex;
align-items: center;
justify-content: start;
background: var(--background);

overflow: visible;//${props => props.step === "review" ? 'visible' : 'hidden'};
`;


export const Wrapper = styled.div`
position: absolute;
width: 100vw;
padding: 0 0 50px ;
background: var(--background);
z-index: 5;
top: 0;
left: 0;
min-height: 100vh;
height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
`;





export const LeftWrapper = styled.div`
grid-row-start: 1;
  grid-row-end: 2;
position: relative;
display: flex;
align-items: center;
justify-content: center;

flex-direction: column;
`;

export const BodyWrapper = styled.div`
display: grid;
grid-template-columns: 45%  1fr;
grid-template-rows: 1fr;
grid-template-areas: 'left right';
width:100%;
height: 450px;
`;

export const FullGridWidth = styled.div`
grid-area: left-start/left-start/right-end/right-end;
`;

export const CenterWrapper = styled.div`
grid-column-start: center-start;
grid-column-end: center-end;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
grid-row-start: 1;
  grid-row-end: 2;
`;

export const RightWrapper = styled.div`
  /* filter: drop-shadow(0 0 5px rgba(3, 0, 112, .4)); */
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: center-start;
  grid-column-end: right-end;
  /* padding: 50px; */
  grid-row-start: 1;
  grid-row-end: 2;


`;

export const NavWrapper = styled.div`
width: 100%;
display: grid;
grid-template-columns: ${props => props.backIsValid ? '1fr 1fr' : '1fr'};
grid-column-gap: 2%;
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
justify-content: space-between;
`;

export const ContentWrapper = styled.div`
height: 100%;
width: 100%;
background: var(--formBackground);
max-width: 1000px;
display: flex;
flex-direction: column;
align-items: center;
`;

export const Innercontainer = styled.div`
width: 100%;
height: 100%;
position: relative;
`;

export const ReviewDropWrapper = styled.div`
position: relative;
height: 100%;
width: 100%;
overflow: auto;
scroll-behavior: smooth;
background: var(--formBackground);
display: flex;
flex-direction: column;
align-items: center;
`;

export const GridWrapper = styled.div`
width: 100%;
height: 100vh;
position: relative;
height: 450px;
justify-items: center;
display: grid;
grid-row-gap: 5%;
grid-template-rows: auto 1fr min-content;

div:nth-of-type(1), div:nth-of-type(3){
  max-width: 500px;
}
`;