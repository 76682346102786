import styled from "styled-components";

export const Wrapper = styled.div`

`

export const Website = styled.div`
height: 20px;
letter-spacing: 0.03rem;
padding: 0 5px;
user-select: none;
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
border-radius: 3px;
font-size: 10px;
color: var(--background);
opacity: ${props => props.opacity ? props.opacity : '1'};
background: var(--primary);
`;

export const IconImg = styled.img`
height: 20px;

width: 20px;
opacity: ${props => props.opacity ? props.opacity : '1'};
`;

export const IconWrapper = styled.div`

`;

export const RowWrapper = styled.div`
display: flex;
column-gap: 5px;
`;

export const InputWrapper = styled.div`
width: 100%;
display: flex;
`;