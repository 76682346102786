import styled from 'styled-components'
export const Banner = styled.div`
height: 430px;
width: 100%;
display: flex;
user-select: none;
background: var(--lightBlue);
position: relative;
overflow: hidden;
background-repeat: no-repeat;
background-size: cover;
border-bottom: 1px solid var(--grey);
background-position: center;
margin-bottom: 20px;
/* justify-content: center;
align-items: center; */
@media screen and (max-width: 900px){
    height: 550px;
}
`;

export const GridContainer = styled.div`
display: grid;
position: absolute;
overflow: hidden;
height: inherit;
width: inherit;
row-gap: 15px;
/* border-bottom: 1px solid var(--grey); */ //line1
/* border-bottom: 1px solid #000000; */ //line2
/* border-bottom: 2px solid #000000; //line3 */
column-gap: 15px;
grid-template-columns: calc(10% - 15px) 1fr 1fr calc(10% - 15px);
grid-template-rows: 30px 1fr min-content min-content 30px ;
grid-template-areas: 
    '. . . .'
    '. siteTitle picture .'
    '. artist picture .'
    '. date about .'
    '. . . . ';
@media screen and (max-width: 900px){
    grid-template-areas:
    '. . . .'
    '. siteTitle siteTitle .'
    '. artist artist .'
    '. picture picture .'
    '. about about .'
    '. date date .'
    '. . . . ';
    grid-template-rows: 30px min-content min-content 1fr min-content min-content 30px;
    height: 550px;
}
`;


export const BannerImgBgdContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
height: inherit;
align-items: center;
grid-column: 1 / end;
grid-row: 1 / end;
position: absolute;
object-fit: cover;
justify-content: center;
position: ${props => props.overlay ? 'absolute' : 'relative'};
background: ${props => props.overlay ? 'linear-gradient(to bottom, rgba(255,255,255,0.25), rgba(255,255,255,1))' : 'none'};
img{
    min-height: 100%;
    min-width: 100%;
    object-position: center;
    object-fit: cover;
    grid-column: 1 / end;
}
`;

export const BannerImgContainer = styled.div`
grid-area: picture;
z-index: 1;
height: 100%;
width: 100%;
overflow: hidden;
position: relative;
border: 5px solid var(--lightestGrey);
box-shadow: 0 0px 15px 1px rgba(0,0,0,0.5);
display: flex;
`;

export const BannerImg = styled.img`
object-fit: cover;
min-height: 100%;
min-width: 100%;
`;

export const BannerAttribute = styled.div`
z-index: 1;
grid-area: about;
margin: 0;
text-align: right;
@media screen and (max-width: 900px){
    text-align: center;
    margin: 0;
}
`;
export const DateWrapper = styled.div`
margin: 0;
grid-area: date;
z-index: 1;
display: flex;
height: 100%;
align-items: center;
text-transform: uppercase;
position: relative;
@media screen and (max-width: 900px){
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    p{
        width: 100%;
        text-align: center;
    }
}
`;

export const TextOverlay = styled.h1`
/* width: 100%;
height: 100%; */

/* font-size: 21px; */
/* text-align: center; */
margin: 0;
@media screen and (max-width: 900px){
font-size: 2em;
}
`;

export const BannerTitle = styled.div`
justify-content: center;
grid-area: siteTitle;
text-align: left;
align-self: end;
bottom: 0;
margin: 0;
position: relative;
z-index: 1;
h1{
    text-align: left;
}
@media screen and (max-width: 900px){
    text-align: center;
    width: 100%;
    position: relative;
    h1{
    text-align: center;
}
}
`;

export const BannerTitle2 = styled(BannerTitle)`
margin: 0;
position: relative;

bottom: 0;
height: 100%;
grid-area: artist;
z-index: 1;
@media screen and (max-width: 900px){
    text-align: center;
    width: 100%;
    position: relative;
}
`;