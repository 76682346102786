import React, { useEffect, useState } from 'react'
import {
    Wrapper,
    IconWrapper,
    IconImg,
    RowWrapper,
    InputWrapper,
    Website

} from './SocialMediaModal.styles'
import Modal from '../../Modal'
import InputField from '../../InputField'
import Instagram from '../../../images/instagramIcon.svg'
import Facebook from '../../../images/facebook.svg'
import Tiktok from '../../../images/tiktok.svg'
import LoadingWheel from '../../LoadingWheel'
const Icon = ({ exists, src, setInput, onClick }) => {


    return (

        <IconWrapper onClick={onClick}><IconImg src={src} alt="social-icon" opacity={exists ? 1 : 0.5} /></IconWrapper>
        // <IconWrapper><IconImg src={src} alt="instagram" /></IconWrapper>

    )



}

const SocialMediaModal = ({ setSocialUpdated, socialUpdated, submitting, handleSocial, setState, state, setShowModal, showModal, storageData }) => {
    const initial = { icon: '', name: '' }
    const [input, setInput] = useState(initial)
    console.log("social updated", socialUpdated)
    const types = [{ icon: Instagram, name: 'instagram' }, { icon: Facebook, name: 'facebook' }, { icon: Tiktok, name: 'tiktok', placeholder: '@' }]
    return (
        <Modal
            showBackArrow={input.icon === '' ? undefined : true}
            onClick={handleSocial}
            submitting={submitting}
            onBackClick={() => { setInput(initial); setSocialUpdated('') }}
            buttonText={socialUpdated !== '' ? socialUpdated : "Save"}
            showModal={showModal}
            title={input.name !== '' ? input.name : "Social Media"}
            subtext={input.name !== '' ? undefined : "Click to add or edit social links"}
            closeModal={() => { setShowModal(false); setSocialUpdated('') }}>
            {/* <InputField /> */}

            {input.icon === '' &&
                <RowWrapper>
                    {types.map(item => (<Icon exists={state[item.name] !== ''} key={item.name} src={item.icon} onClick={() => { setInput(item) }} />))}
                    <Website opacity={state.website !== '' ? 1 : 0.5} onClick={() => { setInput({ name: 'website' }) }}>website</Website>
                </RowWrapper>
            }


            {input.icon !== '' &&
                <InputWrapper><InputField name={input.name} placeholder={input.placeholder ? input.placeholder : input.name}
                    value={state[input.name]} onChange={(e) => { setState(prev => ({ ...prev, [input.name]: e.target.value })) }} /></InputWrapper>}
        </Modal>
    )
}

export default SocialMediaModal