import React, { useState, useEffect } from 'react';
import web3 from "web3";
import { useWeb3 } from '../../../contexts/Web3Context';
import {
    MAX_DEC_PLACES
} from '../../../config'

import { ScrollWrapper, Label, Row, ScrollContent, Bid, Header, MinBid } from './PlaceBidModal.styles'
import LoadingWheel from '../../LoadingWheel'
import Modal from '../../Modal'
import InputField from '../../InputField';
import { useParams } from 'react-router-dom'

const PlaceBidModal = ({ pricingData, valid, setValid, setShowModal, showModal, artwork, submitting, blockchainAddress, baseType, getData }) => {
    const { tokenEdition } = useParams();
    const { validateBalance, handleBid } = useWeb3()
    const initialEdition = tokenEdition !== undefined ? parseInt(tokenEdition) : 1
    const [state, setState] = useState({
        edition: initialEdition.toString(),
        price: parseInt(pricingData.bids[initialEdition - 1]) ?
            web3.utils.fromWei(web3.utils.toBN((parseInt(pricingData.bids[initialEdition - 1]) + parseInt(pricingData.step))))
            : web3.utils.fromWei(web3.utils.toBN(pricingData.price)),
        minBid: parseInt(pricingData.bids[initialEdition - 1]) ? web3.utils.fromWei(web3.utils.toBN((parseInt(pricingData.bids[initialEdition - 1]) + parseInt(pricingData.step))))
            : web3.utils.fromWei(web3.utils.toBN(pricingData.price))
    })
    const { price, edition } = state;
    const [buttonText, setButtonText] = useState('Place Bid')

    const handleChange = (input) => (event) => {

        let value = event.target.value !== undefined ? event.target.value : event.target.attributes.value.value;
        if (input === 'price') {
            let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let decimal; //MAX_DEC_PLACES
            if (value.split('.').length > 1) {
                decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
                price = price + '.' + decimal;
            }
            setState(prev => ({
                ...prev,
                [input]: price
            }))
        }
        else if (input === 'edition') {

            value = value.replace(/\D/g, '')
            if (parseInt(value) > artwork.supply)
                value = artwork.supply

            setState(prev => ({
                ...prev,
                [input]: value
            }))
            if (value !== '' && value !== '0')
                handleEditionChange(value)

        }

    }
    useEffect(() => {
        validate().then(res => {
            if (res)
                setValid(true)
            else {
                setValid(false)
            }
        })
    }, [state])
    const handleEditionChange = (edition) => {
        setState(prev => ({
            ...prev,
            price: web3.utils.fromWei(web3.utils.toBN((parseInt(pricingData.bids[edition - 1]) ? parseInt(pricingData.bids[edition - 1]) + parseInt(pricingData.step) : parseInt(pricingData.price)))),
            minBid: web3.utils.fromWei(web3.utils.toBN((parseInt(pricingData.bids[edition - 1]) ? parseInt(pricingData.bids[edition - 1]) + parseInt(pricingData.step) : parseInt(pricingData.price))))
        }))
    }
    const validate = async () => {
        const bal = await validateBalance(state.price)
        if (!bal) {
            setButtonText('Insufficient funds')
            console.log("here!!"); return false
        }
        else if (state.edition === '' || parseInt(state.edition) === 0 || parseInt(state.edition) > artwork.supply) { console.log("here1"); return false }
        else if (state.price === '' ||
            state.price < state.minBid
        ) { setButtonText('Insufficient bid'); return false }
        setButtonText('Place Bid')
        return true
    }
    return (
        <Modal
            onClick={async () => {
                if (valid) {
                    try {
                        const res = await handleBid(blockchainAddress, parseInt(baseType), parseInt(edition), price)
                        console.log("res is product page modal", res)
                        getData(baseType)
                        setShowModal(false)
                    } catch (error) {
                        setValid(true)
                    }
                }
            }}
            disabled={!valid}
            direction="column"
            buttonText={buttonText}
            submitting={submitting}
            showModal={showModal}
            title="Place A Bid"
            subtext="Select an edition and place a bid."
            closeModal={() => setShowModal(false)}>
            <Header isHeader>
                <div>Edition</div><div>Current Bid (ETH)</div>
            </Header>
            <ScrollWrapper>
                <ScrollContent>
                    {pricingData.bids.map((bid, index) => {
                        return (
                            <Bid isCurrent={parseInt(state.edition) === index + 1} key={index} onClick={(e) => { setState(prev => ({ ...prev, edition: index + 1 })); handleEditionChange(index + 1) }} value={index + 1}>
                                <div>{index + 1}</div><div>{bid === '0' ? '-' : web3.utils.fromWei(web3.utils.toBN(bid)) + ' Ξ'}</div>
                            </Bid>
                        )
                    })}
                </ScrollContent>
            </ScrollWrapper>
            <Row>
                <InputField
                    type="text"
                    onChange={handleChange('edition')}
                    name='edition'
                    label={'Edition'}
                    placeholder=''
                    marginBottom={'0.8rem'}
                    value={state.edition}
                />
            </Row>
            <Row>
                <Label>Bid</Label>
                <InputField
                    type="text"
                    onChange={handleChange('price')}
                    name='price'
                    disabled={state.edition === ''}
                    // label={`Bid [Min. Bid:  ${state.minBid}]`}
                    placeholder=''
                    marginBottom={'0.8rem'}
                    value={state.price}
                />
                <MinBid>{`Edition Min. Bid: Ξ ${state.minBid}`}</MinBid>
            </Row>
        </Modal>
    )
};

export default PlaceBidModal;
