import React from 'react'
import dayjs from 'dayjs'
import {
    Banner,
    GridContainer,
    BannerImg,
    DateWrapper,
    BannerImgBgdContainer,
    BannerImgContainer,
    BannerAttribute,
    BannerTitle2,
    TextOverlay,
    BannerTitle
} from './HomeBanner.styles'
import { formatTimeZone2 } from '../../utils.js'

const HomeBanner = ({ collection }) => {
    const localTimeZoneAbbrev = ' ' + formatTimeZone2(dayjs())
    const displayDate = dayjs().unix() >= parseInt(collection.end_date) ?
        dayjs.unix(collection.release_date).format('dddd, MMMM DD') === dayjs.unix(collection.release_date).format('dddd, MMMM DD') ?
            `${dayjs.unix(collection.release_date).format('dddd, MMMM DD')}`
            : `${dayjs.unix(collection.release_date).format('dddd, MMMM DD')} - ${dayjs.unix(collection.end_date).format('dddd, MMMM DD')}`
        : dayjs().unix() >= parseInt(collection.release_date) ?
            dayjs.unix(collection.end_date).format('dddd, MMMM DD h:mm A') + localTimeZoneAbbrev :
            `${dayjs.unix(collection.release_date).format('dddd, MMMM DD h:mm A')} - ${dayjs.unix(collection.end_date).format('dddd, MMMM DD h:mm A')} ${localTimeZoneAbbrev}`
    if (collection.release_date === undefined)
        return <Banner></Banner>
    else return (
        <Banner>
            <BannerImgBgdContainer>
                <img src={collection.cover_path} style={{ minHeight: '110%', minWidth: '110%', filter: 'blur(5px)', position: 'absolute', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
            </BannerImgBgdContainer><BannerImgBgdContainer overlay></BannerImgBgdContainer>
            <GridContainer>
                <BannerImgContainer>
                    <div style={{ boxShadow: 'inset 0 0px 15px 1px rgba(0,0,0,0.5)', position: 'absolute', height: '100%', width: '100%' }}>
                    </div>
                    <BannerImg src={collection.cover_path} />
                </BannerImgContainer>
                <BannerAttribute><div style={{ fontSize: '12px' }}>{collection.collection_title}</div>
                    <div style={{ fontSize: '10px' }}>{displayDate} | Drop by {collection.artist_name}</div>
                </BannerAttribute>
                <BannerTitle><TextOverlay>Unique. Uiolly.</TextOverlay></BannerTitle>
                <BannerTitle2><h2 style={{margin: '0',fontWeight: '200'}}>Lorem Ipsum è che offre una distribuzione delle.</h2></BannerTitle2>
                {/* <DateWrapper>{displayDate}</DateWrapper> */}
            </GridContainer>
        </Banner>
    )
}

export default HomeBanner
