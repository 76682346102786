import React, { useState, useEffect } from 'react'
import { Wrapper } from './Collections.styles'

import {
    ACCOUNT_ACTIVITY_OPTIONS,
    ARTIST_ACCCOUNT_ACTIVITY_OPTIONS,
    PENDING,
    MINTED,
    UNSCHEDULED,
    SCHEDULED,
    LIVE,
    PAST,
    PURCHASED,
    SOLD,
    OFFERS_MADE,
    ALL_LISTED,
    OFFERS_RECEIVED
} from '../../../config'

import CollectionCard from '../../CollectionCard'
import Button from '../../Button'
import dayjs from 'dayjs'
import API from '../../../API'
import axios from "axios"
import { useNavigate, useLocation } from 'react-router-dom'
import { deployCollection } from "../../../blockchain/functionsUiollyNew"
import { useAuth } from '../../../contexts/AuthContext'
import { useWeb3 } from '../../../contexts/Web3Context'

const Collections = ({ fetchCollections, statusKey, collections, type, filter, setItem, breadcrumb, setBreadcrumb }) => {

    const navigate = useNavigate();
    console.log("c",collections)
    const { currentUser, retrieveUserDetails } = useAuth();
    const { mintMagic, selectedAccount, userAddress, txProcessing } = useWeb3();
    const [minted, setMinted] = useState(false)
    const [loading, setLoading] = useState(false)
    const collectionPagesExist = ["UPCOMING", "LIVE", "PAST"]
    //
    if (collections.length === 0) {
        return <Wrapper
            background="var(--lightestGrey)"
            isEmpty
        >
            <h1>{filter.text === 'NEEDS RESCHEDULE' ? 'NO COLLECTIONS REQUIRING RESCHEDULE' : `NO ${filter.text} COLLECTIONS`} </h1>
        </Wrapper>
    }
    else {
        return (
            <>
                {!collectionPagesExist.includes(filter.text) && <p style={{fontSize: "13px"}}>Collection contents can be viewed once scheduled by admin & minted by the artist.</p>}
                <Wrapper>
                    {collections.map(collection => (
                        <CollectionCard
                            button={filter.text === "UNMINTED" && !minted ? true : undefined}
                            //onCardSelect={() => onCardSelect(collection)}
                            setLoading={setLoading}
                            cursor={collectionPagesExist.includes(filter.text) ? 'pointer' : 'default'}
                            onCardSelect={collectionPagesExist.includes(filter.text) ? undefined : () => { console.log("minting") }}
                            buttonDisabled={txProcessing || loading || dayjs().unix() > parseInt(collection.release_date)}
                            onButtonSelect={() => {
                                setLoading(true)
                                const { items, release_date, end_date, unique_title } = collection;
                                const formattedCollection = {
                                    itemURI : '', //new
                                    itemURIs: [],
                                    prices: [],
                                    collection_title: '',
                                    editionSizes: [],
                                    saleFormats: [],
                                    release_date: null,
                                    end_date: null
                                }
                                items.map((item) => {
                                    // formattedCollection.itemURI = `${process.env.REACT_APP_API_DOMAIN}/collection/${unique_title}/{id}`;
                                    // opensea compatible below, EIP spec above
                                    formattedCollection.itemURI = `${process.env.REACT_APP_API_DOMAIN}/collection/${unique_title}/`;
                                    formattedCollection.prices.push(item.price)
                                    formattedCollection.editionSizes.push(item.max_mint)
                                    formattedCollection.saleFormats.push(item.sale_type === 'Auction' ? true : false)
                                })
                                const { itemURI, itemURIs, prices, editionSizes, saleFormats } = formattedCollection;
                                if (end_date !== null && dayjs().unix() < parseInt(release_date)) {
                                    // deployCollection(itemURIs, prices, editionSizes, saleFormats, release_date, end_date).then(result => {
                                        deployCollection(itemURI, prices, editionSizes, saleFormats, release_date, end_date).then(result => {
                                        if (result) {
                                            API.updateCollectionAddress(collection.id, result.events.collectionDeployed.returnValues.newCollectionAddress).then(() => {
                                                window.alert(
                                                    `collection deployed at the address ${result.events.collectionDeployed.returnValues.newCollectionAddress}`
                                                );
                                                fetchCollections()
                                                setLoading(false)
                                            }).catch(e => { console.log(e); setLoading(false); })
                                        }
                                        else {
                                            setLoading(false)
                                        }
                                    }).catch(e => { console.log(e); setLoading(false); })
                                    // handleDeployCollection(itemURIs, prices, editionSizes, saleFormats, release_date, end_date, collection.id).then(result => {
                                    //     console.log("result is", result)
                                    //     if (typeof (result) === 'string') {
                                    //         setLoading(false)
                                    //     }
                                    //     else if (result) {
                                    //         setLoading(false)
                                    //         // API.updateCollectionAddress(collection.id, result.events.collectionDeployed.returnValues.newCollectionAddress).then(() => {
                                    //         //     window.alert(
                                    //         //         `collection deployed at the address ${result.events.collectionDeployed.returnValues.newCollectionAddress}`
                                    //         //     );
                                    //         //     //fetchCollections()
                                    //         //     setLoading(false)
                                    //         // }).catch(e => { console.log(e); setLoading(false); })
                                    //     }
                                    //     else {
                                    //         //setLoading(false)
                                    //     }

                                    // }).catch(e => { console.log("err",e); setLoading(false); })
                                }
                            }}
                            key={collection.id}
                            collection={collection} />
                    ))}
                </Wrapper>
            </>
        )
    }

}

export default Collections
