import styled from 'styled-components';

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    margin: 12% 0;

        img{
            height: 50%;
            max-height: 150px;
        }
    
    
`;

export const Wrapper = styled.div`
    user-select: none;
    width: ${ props=> props.width!== undefined ? props.width : '100%'};
    max-width: ${ props=> props.maxWidth!== undefined ? props.maxWidth : '500px'};
    max-height: ${ props=> props.maxHeight!== undefined ? props.maxHeight : '500px'};
    height: ${ props=> props.height!== undefined ? props.height : '500px'};
    outline-offset: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05rem;
    background: ${props => props.dragOverEventLive ? 'rgb(250,250,250)' : 'var(--lightestGrey)'};
    background-blend-mode: multiply;
    background: ${props=> props.background ? `rgba(255,255,255,.8) url(${props.background})` : ''};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    outline: 2px dashed var(--lightestGrey);
    button{
        visibility: ${props => props.dragOverEventLive ? 'hidden' : 'visible'};

        input {
            display: none;
        }
    }

`;

export const Title = styled.div`
    font-size: 1em;
    font-family: Poppins;
    text-transform: uppercase;
    font-weight: 400;
`;

export const SubText = styled.div`
    
    text-transform: uppercase;
    font-size: var(--fontSmall);
    visibility: ${props => props.dragOverEventLive ? 'hidden' : 'visible'};
`;

export const Note = styled.div`
    color: var(--purpleGrey);
    font-weight: 500;
    font-size: var(--fontSmall);
`;

export const ButtonStyle = styled.div`
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${props => props.color? props.color : 'var(--white)'};
    width: ${ ({width}) => width};
    border: ${ ({border}) => border ? border : 'var(--buttonBorder)'};
    background: ${props => props.background};
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: var(--buttonFontWeight);
    justify-content: center;
    text-align: center;
    border-radius: var(--buttonBorderRadius);
    :hover{
        background: ${props => props.hoverColor? props.hoverColor : 'rgba(255,255,255,0.15)'} ;

    }

    input{
        height: 0;
        width: 0;
        visibility: hidden;
    }
`;