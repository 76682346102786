import styled from "styled-components";

export const Wrapper = styled.div`
height: 30vw;
width: 100%;
display: flex;
user-select: none;
position: relative;
overflow: hidden;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
margin-bottom: 20px;
align-items: center;

img{
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}
`;

export const OverlayWrapper = styled.div`
/* padding: 50px; */
position: absolute;
top: 0;
right: 0;
height: 100%;
max-width: 700px;
display: flex;
justify-content: center;
align-items: center;
width: 50%;
min-width: 500px;
`;

export const TextOverlay = styled.div`
width: 100%;
height: calc(100% - 100px);
margin: 0 50px;
text-align: center;
align-items: center;
padding: 10px;
justify-content: center;
color: var(--background);
background:  rgba(0,0,0,0.95);
display: flex;
flex-direction: column;
`;

export const Description = styled.div`
max-width: 50%;
max-height: 200px;
overflow: hidden;
font-size: 14px;
margin-top: 20px;
`;

export const Artist = styled.div`
font-size: 2.5em;
cursor: pointer;
font-weight: 700;
text-transform: uppercase;
    
`;

export const Title = styled.div`

    font-size: 1.5em;
    position: relative;
    width: 100%;

`;

export const Date = styled.div`
font-size: 1.5em;
color: var(--uiollyPurple);
text-transform: uppercase;
    
`;

export const Time = styled(Date)`
font-size: 0.7em;
`;