import React, { useState, useEffect, useRef } from 'react'

import UploadCoverArt from './UploadCoverArt'
import UploadArtwork from './UploadArtwork'
import ArtworkThumbnailContainer from './ArtworkThumbnailContainer'
import ArtworkDetails from './ArtworkDetails'
import ArtworkPreview from './ArtworkPreview'
import CoverArtPreview from './CoverArtPreview'
import ReviewArtworkCards from './ReviewArtworkCards'
import NavWrapper from './NavWrapper'
import StepHeader from './StepHeader'
import SuccessPage from './SuccessPage'
import FormHeader from './FormHeader'
import UploadFormDropPrev from './UploadFormDropPrev'
import LoadingWheel from '../LoadingWheel'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

import {
    VALID_FILE_TYPES,
    MAX_SIZE,
    MAX_UPLOADS,
    MAX_DEC_PLACES
} from '../../config'

// Styles
import { Innercontainer, ReviewDropWrapper, FullGridWidth, ContentWrapper, FormStyled, Wrapper, LeftWrapper, RightWrapper, BodyWrapper, CenterWrapper, GridWrapper } from './UploadForm.styles'

const UploadForm = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const initialState = {
        step: 1,
        display_name: currentUser !== undefined && currentUser.userDetails !== undefined ? `${currentUser.userDetails.display_name}` : '',
        email: currentUser !== undefined ? currentUser.email !== undefined ? currentUser.email : '' : '',
        handle: currentUser !== undefined && currentUser.userDetails !== undefined ? currentUser.userDetails.handle : '',
        artistProfilePic: currentUser !== undefined && currentUser.userDetails !== undefined ? currentUser.userDetails.profile_pic : '',
        collectionTitle: '',
        collectionDescription: '',
        collectionCoverArt: '',
        collectionArtworks: []
    }

    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true)
    const [index, setIndex,] = useState(0);
    const [artworkDetailsStep, setArtworkDetailsStep] = useState(0);
    let urlGenerated;
    const [collectionCover, setCollectionCover] = useState('');
    console.log("current user is: ", currentUser.userDetails, state)

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined && currentUser.userDetails !== undefined) {

            setState(prev => ({ ...prev, display_name: currentUser.userDetails.display_name, handle: currentUser.userDetails.handle, artistProfilePic: currentUser.userDetails.profile_pic }))
            setLoading(false)
        }
    }, [currentUser])

    const nextStep = (e) => {
        e.preventDefault();
        const { step } = state;
        if (validateStep(step)) {
            if (step === 4) {
                navigate('/success', { state: { values } })
            }
            setState(prev => ({ ...prev, step: prev.step + 1 }))
        }
    }

    const prevStep = (e) => {
        e.preventDefault();
        const { step } = state;
        if (step !== 1) {
            setState(prev => ({ ...prev, step: prev.step - 1 }))
        }
    }

    const handleCoverArtUpload = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (VALID_FILE_TYPES.collectionCover.includes(files[0].type)) {
                setState(prev => ({ ...prev, collectionCoverArt: files[0] }))
                // free up memory
                if (collectionCover !== undefined || collectionCover !== "") {
                    URL.revokeObjectURL(collectionCover)
                }
                setCollectionCover(URL.createObjectURL(files[0]))
            }
        }
    }

    const runBlobCleanup = () => {

        values.collectionArtworks.forEach(artwork => {
            URL.revokeObjectURL(artwork.thumbnail)
            console.log("removing: " + artwork.thumbnail)
        })

    }


    const handleFieldChange = (input) => (event) => {
        console.log(event.target)
        if (input === 'collectionTitle') {
            setState(prev => ({ ...prev, collectionTitle: event.target.value }))
        }
        else if (input === 'collectionDescription') {
            const { value, attributes } = event.target;
            setState(prev => ({ ...prev, collectionDescription: value.slice(0, attributes.maxlength.value) }))
        }

    }

    const handleArtworksUpload = (files) => {


        for (let i = 0; i < files.length; i++) {
            console.log("this file" + files[i].type)
            if (!state.collectionArtworks.map(artwork => artwork['fileName']).includes(files[i].name) && VALID_FILE_TYPES.collectionArtwork.includes(files[i].type)) {
                const artworkDetails = {
                    file: 'testest',
                    fileName: 'fileName',
                    display_name: state.display_name,
                    type: '',
                    thumbnail: '',
                    title: '',
                    saleType: 'Auction',
                    price: '',
                    market: 'primary',
                    collectionState: 'pending',
                    maxMint: '',
                    artworkDescription: '',
                }
                artworkDetails.file = files[i];
                artworkDetails.type = files[i].type.split('/')[0];
                artworkDetails.fileName = files[i].name;
                artworkDetails.thumbnail = URL.createObjectURL(files[i])
                setState(prev => ({ ...prev, collectionArtworks: [...prev.collectionArtworks, artworkDetails] }))
            }




        }


        console.log(state.collectionArtworks)

    }

    const handleCoverArtRemoval = (objectURL) => {
        URL.revokeObjectURL(objectURL)
        setState(prev => ({ ...prev, collectionCoverArt: "" }))
    }

    const handleArtworkRemoval = (event) => {
        event.preventDefault();
        let value;
        if (event.target.tagName === "IMG") {

            value = event.target.parentNode.parentNode.attributes.value.value;
        }
        else {
            value = event.target.parentNode.attributes.value.value;
        }
        console.log("value is", value, step, state.collectionArtworks.length)
        if (value !== undefined) {
            console.log("step is", step, state.collectionArtworks.length)
            const artworkIndex = values.collectionArtworks.findIndex(x => x.fileName === value);
            const { thumbnail } = values.collectionArtworks[artworkIndex];
            URL.revokeObjectURL(thumbnail)
            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.filter((artwork) => { return artwork.fileName !== value })
            }))
            if (state.collectionArtworks.length < 2 && state.step !== 2) {
                if (step !== 1) {
                    setState(prev => ({ ...prev, step: prev.step - 1 }))
                }
            } else if (artworkIndex < index) {
                setIndex(index - 1);
            }
        }

    }

    const removeAllArtworks = () => {

        values.collectionArtworks.forEach(artwork => URL.revokeObjectURL(artwork.thumbnail))
        setState(prev => ({ ...prev, collectionArtworks: [], step: prev.step - 1 }))

    }

    const incrementArtworkIndex = (e) => {
        e.preventDefault();
        if (validateStep(step)) {

            if (index < state.collectionArtworks.length - 1) {
                setIndex(index + 1)
            }
            else {
                nextStep(e)
            }
        }


        // setScrollTo(50);


    }

    const dArtworkIndex = (e) => {
        e.preventDefault();
        if (index > 0) {
            var number = index - 1;
            setIndex(index - 1)
        }
        else {
            prevStep(e)
        }
    }

    const validateStep = (step) => {
        switch (step) {
            case 1:
                return state.collectionCoverArt !== "" & state.collectionTitle !== "" & state.collectionDescription !== ""
            case 2:
                return values.collectionArtworks.length > 0
            case 3:
                console.log("index is", index, values.collectionArtworks[index])
                if (values.collectionArtworks[index].title === "")
                    return false
                else if (values.collectionArtworks[index].saleType === "")
                    return false
                else if (values.collectionArtworks[index].artworkDescription === "")
                    return false
                else if (values.collectionArtworks[index].price === '--' || values.collectionArtworks[index].price === '')
                    return false
                else if (values.collectionArtworks[index].maxMint === 0 || values.collectionArtworks[index].maxMint === '')
                    return false
                else {
                    console.log("is valid")
                    return true
                }

            case 4:
                return true
            default:
                return false
        }

    }

    const handleArtworkDetailsInput = (input) => (event) => {


        let value;
        if (event.target.value !== undefined) {
            value = event.target.value;
        } else {
            value = event.target.attributes.value.value;
        }

        if (input !== 'maxMint' && input !== 'price') {

            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.map((el, idx) => idx === index ? { ...el, [input]: value } : el)
            }))

        }
        if (input === 'price') {
            let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let decimal; //MAX_DEC_PLACES

            if (value.split('.').length > 1) {
                //decimal = value.split('.')[1] + '0';
                decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
                price = price + '.' + decimal;
            }

            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.map((el, idx) => idx === index ? { ...el, [input]: price } : el)
            }))


        }
        else if (input === 'maxMint') {
            value = value.replace(/\D/g, '')
            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.map((el, idx) => idx === index ? { ...el, [input]: value } : el)
            }))
        }

    }




    const priceOnBlur = (event) => {
        console.log("true", event.target)
        let value;
        if (parseFloat(event.target.value) !== 0 && event.target.value !== '') {
            if (event.target.value.split('.').length < 2) {
                value = event.target.value + '.00';

            }
            else {
                value = event.target.value.split('.')[0] + '.' + (event.target.value.split('.')[1]).substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            }
            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.map((el, idx) => idx === index ? { ...el, price: value } : el)
            }))

        }
        else {
            setState(prev => ({
                ...prev,
                collectionArtworks: prev.collectionArtworks.map((el, idx) => idx === index ? { ...el, price: '' } : el)
            }))
        }
    }

    const handleArtworkDetails = () => {

        return (
            <Wrapper>
                <FormStyled>
                    <FormHeader userDetailsStep={index} step={step} prevStep={dArtworkIndex} artworkIndex={index} collectionLength={values.collectionArtworks.length} totalLength={6} validateStep={validateStep} nextStep={(e) => incrementArtworkIndex(e)} />
                    <ContentWrapper>
                        <BodyWrapper>
                            <LeftWrapper>
                                <ArtworkThumbnailContainer
                                    index={index}
                                    step={step}
                                    removeItem={handleArtworkRemoval}
                                    values={values.collectionArtworks}
                                    removeAllArtworks={removeAllArtworks}
                                />
                                <ArtworkPreview artwork={values.collectionArtworks[index].thumbnail} type={values.collectionArtworks[index].type} />
                            </LeftWrapper>
                            <RightWrapper>
                                <ArtworkDetails
                                    artworkIndex={index}
                                    nextStep={incrementArtworkIndex}
                                    prevStep={dArtworkIndex}
                                    validateStep={validateStep}
                                    handleChange={handleArtworkDetailsInput}
                                    values={values}
                                    handleStateChange={setState}
                                    priceOnBlur={priceOnBlur}
                                ></ArtworkDetails>
                            </RightWrapper>
                        </BodyWrapper>

                    </ContentWrapper>
                </FormStyled>
            </Wrapper>
        )
    }

    const { step, collectionTitle, collectionDescription, collectionCoverArt, collectionArtworks, display_name, email } = state;

    const values = { step, collectionTitle, collectionDescription, collectionCoverArt, collectionArtworks, display_name, email }

    const review = { ...state, collectionCover: collectionCover };

    // need to include scenario where artist name is a collab between artist uploading and otra people
    if (loading)
        return <LoadingWheel />
    else if (currentUser.userDetails !== undefined && currentUser.userDetails.bio === '')
        return <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h1>We need a little more info first.</h1>
            Please add a bio to your profile before submitting a collection.
            <Button onClick={() => { navigate('/settings') }} text="Account Settings" marginTop="20px" width="300px" />
        </div>
    else {
        switch (step) {
            case 1:
                return (
                    <Wrapper>
                        <FormStyled>
                            <FormHeader userDetailsStep={index} step={step} prevStep={prevStep} totalLength={6} validateStep={validateStep} nextStep={nextStep} />
                            <ContentWrapper>
                                <UploadCoverArt
                                    handleFileUpload={handleCoverArtUpload}
                                    validateStep={validateStep}
                                    collectionCover={collectionCover}
                                    handleCoverArtRemoval={handleCoverArtRemoval}
                                    handleFieldChange={handleFieldChange}
                                    values={values}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    startingStep={initialState.step}
                                />
                            </ContentWrapper>
                        </FormStyled>

                    </Wrapper>

                )
            case 2:
                return (
                    <Wrapper>
                        <FormStyled>
                            <FormHeader userDetailsStep={index} step={step} prevStep={prevStep} totalLength={6} validateStep={validateStep} nextStep={nextStep} />
                            <ContentWrapper>
                                <ArtworkThumbnailContainer
                                    index={index}
                                    step={step}
                                    removeItem={handleArtworkRemoval}
                                    values={values.collectionArtworks}
                                    removeAllArtworks={removeAllArtworks}
                                />
                                <BodyWrapper>
                                    <FullGridWidth>
                                        < UploadArtwork
                                            handleFileUpload={handleArtworksUpload}
                                            validateStep={validateStep}
                                            collectionCover={collectionCover}
                                            handleFieldChange={handleFieldChange}
                                            values={values}
                                            nextStep={nextStep}
                                            prevStep={prevStep}
                                            startingStep={initialState.step}
                                        />
                                    </FullGridWidth>
                                </BodyWrapper>

                            </ContentWrapper>
                        </FormStyled>
                    </Wrapper>

                )

            case 3:
                while (index < collectionArtworks.length) {
                    return handleArtworkDetails()
                }
                break;
            case 4:
                return (<Wrapper>
                    <FormStyled step="review">
                        <FormHeader userDetailsStep={index} step={step} prevStep={prevStep} totalLength={6} validateStep={validateStep} nextStep={nextStep} />

                        <ReviewDropWrapper>
                            <Innercontainer>
                                <UploadFormDropPrev state={review} />
                            </Innercontainer>
                        </ReviewDropWrapper>
                    </FormStyled>
                </Wrapper>
                )
            case 5:
                console.log(state)
                // runBlobCleanup();
                return <SuccessPage values={values} />
            default:
                return <h1>Error</h1>
        }
    }

}

export default UploadForm
