import styled from "styled-components";

export const Picture = styled.div`
    width: ${props => props.radius ? props.radius : '100px'};
    height: ${props => props.radius ? props.radius : '100px'};
    display: flex;
    border-radius: 50%;
    background: var(--uiollyPurple);
    overflow: hidden;
    position: relative;
    img{
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;