import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
display: grid;
width: 100%;
height: 100%;
top: 60px;
`;

export const MarketplaceHeader = styled.div`
text-transform: uppercase;
user-select: none;
color: var(--fontColor);
height: 1.5rem;
width: 100%;
font-size: 1.5rem;
font-weight: 700;
position: relative;
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
margin-top: 50px;
:before{
    content: '';
    position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid var(--lightGrey);
  background: black;
  width: 100%;
  transform: translateY(-50%);

}

`;

export const FilterMenu = styled.div`
width: 100%;
position: sticky;
display: grid;
padding: 0 15px;
grid-gap: 15px;
grid-template-columns: 1fr min-content;
user-select: none;
height: var(--navBarHeight);
top: var(--navBarHeight);
background: var(--buttonGrey);
border-bottom: 1px solid var(--grey);
z-index: 1;
/* border-bottom: 1px solid var(--lightGrey); */
/* box-shadow: 0 0px 5px 1px rgba(0,0,0,0.2); */
padding: 0 20px;
justify-content: center;
>div:nth-of-type(2n + 1){
    /* align-items: start; */
}
`;

export const MarketplaceHeaderBgd = styled.div`

position: relative;
background: #000000;
padding: 20px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

h2{
    font-size: 12px;
    margin: 0;
    font-weight: 400;
}

`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const DropdownContainer = styled.div`
    display: flex;
    align-self: flex-start;
    position: absolute;
    bottom: 10px;
    right: 15px;
    height: 0;
    align-items: center;
`;

export const FilterTileContainer = styled.div`
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    background: #000000;
    border: 1px solid #ffffff;

`;

export const RemoveFilter = styled.button`
    all: unset;
    margin-left: 10px;
    cursor: pointer;
    color: var(--fontColor);
`;

export const NavArrow = styled.div`
height: 25px;
width: 25px;
opacity: ${props => props.isValid ? '1' : '0.25'};
transform: ${props => props.back ? '' : 'rotate(180deg)'};
display: flex;
align-items: center;
justify-content: center;
img{
    height: 90%;
    width: 90%;
}
`;