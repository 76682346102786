import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 2;
color: ${props => props.disabled ? 'var(--secondary)' : 'var(--fontColor)'};
`;


export const InputStyled = styled.input`
  background: none;
  border: var(--inputBorder);
color: var(--fontColor);
    height: 49px;
    box-shadow: ${props => props.boxShadow ? props.boxShadow : ''};
    font-weight: 600;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    padding: ${props => props.name === 'price' ? '0 20px 0 40px' : props.padding ? props.padding : '0 20px 0 20px'};
    width: 100%;
    position: relative;
    letter-spacing: .1rem;
    cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
    border: ${props => props.disabled ? '1px solid var(--lightGrey)' : 'var(--inputBorder)'};
    background: ${props => props.disabled ? 'var(--lightestGrey)' : 'var(--background)'};
    color: ${props => props.disabled ? 'var(--secondary)' : 'var(--fontColor)'};
    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 2px solid var(--uiollyPurple);
        outline-offset: -2px;
        
    }
    /* ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }  

   appearance: textfield;
   -webkit-appearance: textfield;
   -moz-appearance: textfield; */


`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const LabelStyledOverlap = styled.label`
    position: absolute;
    top: -0.5rem;
    background: ${ props => props.disabled ? 'linear-gradient(to bottom,var(--formBackground) 49%,var(--grey) 50%)': 'var(--formBackground)'};
    color: ${ props => props.disabled ? 'var(--lightGrey)': 'var(--fontColor)'};
    z-index: 2;
    padding: 0 5px;
    left: 0.5rem;
    font-size: var(--fontSmall);
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;