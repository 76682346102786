import React, { useState, useEffect } from 'react'

import { Wrapper, NavLink, NavMenu, NavBtnLink, DropDownNavBtn, DropDownContainerBox } from './Header.styles'

import AccountIcon from '../../images/accountIcon.svg'
import UiollyLogo from '../../images/uiollyLogo@4x.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'
import { useWeb3 } from '../../contexts/Web3Context';

import Dropdown from '../Dropdown'

import {
    ACCOUNT_ACTIVITY,
    LOGOUT,
    LOGIN,
    SIGNUP,
    CONNECT_WALLET,
    ACCOUNT_SETTINGS,
    MY_PROFILE,
    UPLOAD
} from '../../config'


const DropDownContainer = ({ options, handleChange, menuOpen, setMenuOpen }) => {

    return (
        <DropDownContainerBox>
            <Dropdown
                name='header'
                width="200px"
                textAlign="right"
                headerWidth="100px"
                dropdownWidth="200px"
                marginBottom={'0'}
                listBackground="var(--background)"
                border="1px solid (--textColor)"
                color="var(--textColor)"
                colorOnSelect="var(--uiollyPurple)"
                dropdownListMarginTop="20px"
                justifyContent="flex-end"
                background="var(--background)"
                options={options}
                valueX=""
                setMenuState={setMenuOpen}
                menuState={menuOpen}
                handleStateChange={handleChange}
                index={0}
                id='filter'
                setValidContinue={true}
            ></Dropdown>
        </DropDownContainerBox>
    )
}

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const [options, setOptions] = useState([{ value: '', innerText: '' }])
    const [menuOpen, setMenuOpen] = useState(false);
    const { connectWallet, userAddress } = useWeb3();
    const { currentUser, logout, login, signup } = useAuth();
    console.log("current user", currentUser, "wallet address", userAddress);
    useEffect(() => {
        setMenuOpen(false)
    }, [location.pathname])
    useEffect(() => {
        if (currentUser !== null) {
            setOptions(
                [{
                    value: ACCOUNT_ACTIVITY,
                    innerText: 'Account'
                },
                {
                    value: ACCOUNT_SETTINGS,
                    innerText: 'Settings'
                },
                {
                    value: userAddress ? 'WALLET_CONNECTED' : CONNECT_WALLET,
                    innerText: userAddress ? `${userAddress.slice(0, 5)}...${userAddress.slice(-5)}` : 'Connect Wallet'
                },
                {
                    value: MY_PROFILE,
                    innerText: 'Profile'
                },
                {
                    value: UPLOAD,
                    innerText: 'Upload'
                },
                {
                    value: LOGOUT,
                    innerText: 'Log out'
                }
                ]
            )
        }
        else {
            setOptions(
                [{
                    value: SIGNUP,
                    innerText: 'Sign Up'
                },
                {
                    value: LOGIN,
                    innerText: 'Log In'
                }
                    // ,
                    // {
                    //     value: userAddress ? 'WALLET_CONNECTED' : CONNECT_WALLET,
                    //     innerText: userAddress ? `${userAddress.slice(0, 5)}...${userAddress.slice(-5)}` : 'Connect Wallet'
                    // }
                ]
            )
        }
    }, [currentUser, userAddress])

    const handleChange = (event) => {
        const value = event.target.attributes.value.value; //parseInt(event.target.attributes.value.value);
        if (value === LOGOUT) {
            setError('')
            logout()
                .then((userCred) => {
                    navigate('/')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    setError(error)
                })
        }
        else if (value === CONNECT_WALLET || value === 'WALLET_CONNECTED') {
            connectWallet().then(() => {
                event.target.innerText = `${userAddress.slice(0, 5)}...${userAddress.slice(-5)}`;
            }).catch((e) => { console.log(e) });
            //console.log(event.target)

        }
        else if (value === MY_PROFILE) {
            if (currentUser.userDetails !== undefined)
                navigate(`/profile/${currentUser.userDetails.handle}`)
        }
        else {
            navigate(`/${value.toLowerCase()}`)
        }
        // else if (value === ACCOUNT_ACTIVITY) {
        //     navigate('/account')
        // }
        // else if (value === SIGNUP) {
        //     navigate('/signup')
        // }
        // else if (value === ACCOUNT_SETTINGS) {
        //     navigate('/settings')
        // }
        // else if (value === LOGIN) {
        //     navigate('/login')
        // }

    }

    // if (location.pathname === '/upload') {
    //     return (<></>)
    // }
    // else {

        return (
            <Wrapper showAccount={currentUser !== null}>
                <NavLink to="/" className="home" ><img style={{height: '35px'}} src={UiollyLogo} /></NavLink>
                <NavLink to="/marketplace" className="marketplace">Marketplace</NavLink>
                <NavLink to="/drops" className="drops">Drops</NavLink>
                {currentUser !== null && <NavLink to="/account" className="accountActivity">Account</NavLink>}
                {/* <NavLink to="/marketplace" className="marketplace">Marketplace</NavLink> */}
                <DropDownNavBtn className="account" onClick={() => { setMenuOpen(!menuOpen) }}
                >
                    <img src={AccountIcon} alt="logo-login-button" />
                </DropDownNavBtn>
                <DropDownContainer
                    options={options}
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    handleChange={handleChange}
                />
            </Wrapper >
        )
    // }
}

export default Header
