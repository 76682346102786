import React from 'react'
import { useWeb3 } from '../../contexts/Web3Context'
import { LoadingWheelWrapper, Wrapper, ErrorWrapper } from './LoadingWheel.styles'

const LoadingWheel = ({ background, width, height, position, top, zIndex, radius, border, borderOuter }) => {
    const { networkError, web3 } = useWeb3()
    return (
        <Wrapper
            border={borderOuter}
            background={background}
            width={width}
            networkError={networkError.status}
            height={height}
            position={position}
            top={top}
            zIndex={zIndex} >
                {networkError.status && <ErrorWrapper><div>Welcome to Uiolly.</div>{networkError.message}</ErrorWrapper>}
            <LoadingWheelWrapper border={border} radius={radius}>

            </LoadingWheelWrapper>
        </Wrapper>
    )
}

export default LoadingWheel
