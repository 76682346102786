import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
`;

export const CollectionArtworksHeader = styled.div`
text-transform: uppercase;
user-select: none;

height: 1.5rem;
width: 100%;
font-size: 1.5rem;
font-weight: 700;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 50px;
:before{
    content: '';
    position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid var(--fontColor);
  background: var(--background);
  width: 100%;
  transform: translateY(-50%);

}

`;

export const CollectionArtworksHeaderBgd = styled.div`
color: ${ props => props.textColor ? props.textColor : 'var(--fontColor)'};
position: relative;
background: var(--background);
padding: 20px;


`;

export const CollectionArtworks = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;