import React from 'react'
import { useWeb3 } from '../../contexts/Web3Context'
import Close from '../../images/close.svg'
import LoadingWheel from '../LoadingWheel'
import { Wrapper, CloseWrapper } from './TransactionNotification.styles'

const TransactionNotification = () => {

    const { txProcessing, txMessage, showMsg, setShowMsg } = useWeb3()
    return (
        <Wrapper showMsg={showMsg}>
            {txProcessing && <LoadingWheel
                width='20px'
                radius="20px"
                height='100%'
                position='relative'
                background="transparent"
            />}
            {txMessage}
            <CloseWrapper onClick={() => setShowMsg(false)}><img src={Close} alt="x" /></CloseWrapper></Wrapper>
    )
}

export default TransactionNotification