import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import web3 from 'web3'

import { useWeb3 } from '../../../contexts/Web3Context';
import { useResale } from '../../../hooks/useResale'

import { roundData } from '../../../utils'

import { getMarketplaceItem, getEditionOfferEvents } from '../../../blockchain/functionsUiollyNew'
import { roundDataWei } from '../../../blockchain/blockchainUtils'

import {
    MAX_DEC_PLACES
} from '../../../config'

import GridItem from '../../GridItem'
import Modal from '../../Modal'
import InputField from '../../InputField';

import { ETHConversionWrapper, ScrollWrapper, Label, Row, ScrollContent, Bid, Header, MinBid } from './MakeOfferModal.styles'

const MakeOfferModal = ({ pricingData, closeModal, valid, setValid, setShowModal, showModal, artwork, submitting, blockchainAddress, baseType, getData }) => {
    const { marketplaceBid, marketplaceMakeOffer, validateBalance, userAddress } = useWeb3()

    const getMinBid = () => {
        if (!pricingData.isAuction)
            return 0
        else if (roundDataWei(pricingData.auctionHigh, 8) === 0)
            return roundDataWei(pricingData.price)
        else
            return roundDataWei(pricingData.auctionHigh, 8) + roundDataWei(pricingData.step, 8)
        pricingData.isAuction && roundDataWei(pricingData.auctionHigh, 8)
    }

    const getButtonText = () => {
        return pricingData.isAuction ? 'Place Bid' : 'Make Offer'
    }
    console.log("PRICING DATA", pricingData)
    const [buttonText, setButtonText] = useState(getButtonText())
    const [highLow, setHighLow] = useState({ high: '', low: '' })
    const [conversion, setConversion] = useState('')
    const [conversionRatio, setConversionRatio] = useState(0)
    const [item, setItem] = useState({
        artwork,
        isAuction: pricingData.isAuction,
        // saleType: saletypeOptions[0],
        price: '',
        minBid: getMinBid()
    })

    useEffect(() => {

        const init = async () => {
            if (!pricingData.isAuction && pricingData.activeOffers !== undefined) {
                setHighLow({
                    high: pricingData.highestOffer !== 0 ? pricingData.highestOffer + 'Ξ' : '-', low: pricingData.lowestOffer !== 0
                        && pricingData.lowestOffer !== pricingData.highestOffer ? pricingData.lowestOffer + 'Ξ' : '-'
                })
                const found = pricingData.activeOffers.find(it => it.offerer.toLowerCase() === userAddress.toLowerCase())
                if (found)
                    setButtonText(`Replace My Current Offer of ${found.price}`)
            }
            const res = await fetch('https://api.coinbase.com/v2/prices/ETH-USD/spot')
            const res1 = await res.json()
            setConversionRatio(res1.data.amount);

        }
        init()

    }, [])

    const priceOnBlur = (event) => {
        let value;
        if (parseFloat(event.target.value) !== 0 && event.target.value !== '') {
            if (event.target.value.split('.').length < 2) {
                value = event.target.value + '.00';
            }
            else {
                value = event.target.value.split('.')[0] + '.' + (event.target.value.split('.')[1]).substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            }
            setItem(prev => ({ ...prev, price: value }))
        }
        else {
            setItem(prev => ({ ...prev, price: value }))
        }
    }

    const onPriceChange = (e) => {
        const value = e.target.value
        let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let decimal; //MAX_DEC_PLACES
        if (value.split('.').length > 1) {
            decimal = value.split('.')[1].substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
            price = price + '.' + decimal;
        }
        setItem(prev => ({ ...prev, price: price }))
        if (conversionRatio !== '') {
            if (price === '' || value === '.') {
                setConversion('')
            }
            else {
                const conv = (Math.round(((parseFloat(price) * conversionRatio) + Number.EPSILON) * 100) / 100).toString();
                const priceAdj =
                    conv.split('.').length > 1 ?
                        conv.split('.')[0] + `${conv.split('.')[1] !== '00' ? '.' + (conv.split('.')[1] + '0').slice(0, 2) : '.00'}`
                        : conv.split('.')[0] + '.00'
                setConversion('$' + priceAdj.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '')
            }
        }
    }
    useEffect(() => {

        const check = async () => {
            const isValid = await validateFields()
            if (isValid)
                setValid(true)
            else
                setValid(false)
        }
        check()

    }, [item])

    const validateFields = async () => {
        if (item.price === undefined || item.price === '' || item.price === '0') { console.log("here1"); return false }
        // else if (item.isAuction) {
        //     if (item.salesOpenTime >= item.salesCloseTime) { console.log("here2"); return false }
        //     else if (dayjs().unix() <= item.salesOpenTime){ return false}
        //     else if (selectedDay === '') { console.log("here3"); return false }
        //     else if (runTime === null) { console.log("here4"); return false }
        //     else if (runTime === 0) { console.log("here5"); return false }
        //     else if (runTime === '') { console.log("here6"); return false }
        //     else if (startTime === '') { console.log("here7"); return false }
        // }
        if (item.price === undefined || Number(item.price) === 0 || isNaN(Number(item.price))) { console.log("here1"); return false }
        const bal = await validateBalance(item.price)
        if (!bal) {
            setButtonText('Insufficient funds')
            return false
        }
        else if (item.isAuction) {
            console.log(item.price, item.minBid, Number(item.price) < Number(item.minBid))
            if (Number(item.price) < item.minBid) {
                console.log("HERE")
                return false
            }
            // if (item.salesOpenTime >= item.salesCloseTime) { console.log("here2"); return false }
            // else if (dayjs().unix() >= item.salesOpenTime) { return false }
            // else if (selectedDay === '') { console.log("here3"); return false }
            // else if (runTime === null) { console.log("here4"); return false }
            // else if (runTime === 0) { console.log("here5"); return false }
            // else if (runTime === '') { console.log("here6"); return false }
            // else if (startTime === '') { console.log("here7"); return false }
        }
        setButtonText(getButtonText())
        return true


    }

    const onSubmit = async () => {
        try {
            if (valid && item.price !== '' && pricingData.itemId !== undefined) {
                if (pricingData.isAuction){
                    console.log("HERERER")
                    const res = await marketplaceBid(pricingData.itemId, item.price)
                }else{
                    console.log("HERERER2")
                    const res = await marketplaceMakeOffer(pricingData.itemId, item.price)
                }
                getData(baseType)
                closeModal()
            }
        } catch (e) {
            console.log("an error occurred", e)
            setValid(true)
        }
    }

    return (
        <Modal
            onClick={onSubmit}
            maxWidth="300px"
            disabled={!valid}
            direction="column"
            buttonText={buttonText}
            submitting={submitting}
            showModal={showModal}
            title={pricingData.isAuction ? "Place Bid" : "Make An Offer"}
            // subtext="Select an edition and place a bid."
            closeModal={closeModal}>
            <GridItem gridCol="1/ span 2" gridRow="5 / span 1" gridTempCols="1fr 1fr" display="grid">
                {!pricingData.isAuction && <><GridItem height="20px" fontSize="18px" fontWeight="600" flexDirection="row" justifySelf="start" gridRow="3">{highLow.high}</GridItem>
                    <GridItem margin="0 0 5px" flexDirection="row" justifySelf="start" gridRow="4" >Active Highest Offer</GridItem>
                    <GridItem height="20px" fontSize="18px" fontWeight="600" flexDirection="row" justifyContent="end" gridRow="3">{highLow.low}</GridItem>
                    <GridItem flexDirection="row" alignItems="start" justifyContent="end" gridRow="4" >Active Lowest Offer</GridItem></>}
                {pricingData.isAuction && <><GridItem height="20px" fontSize="18px" fontWeight="600" flexDirection="row" justifyContent="start" gridRow="3">{item.minBid}Ξ</GridItem>
                    <GridItem flexDirection="row" alignItems="start" justifyContent="start" gridRow="4" >Minimum Bid</GridItem></>}
            </GridItem>
            <GridItem display="grid" gridTempCols="1fr 1fr">
                <InputField
                    type="text"
                    onChange={onPriceChange}
                    // onKeyPress={continueKey}
                    name='price'
                    priceOnBlur={priceOnBlur}
                    label={pricingData.isAuction ? 'Bid' : 'Offer'}
                    placeholder=''
                    marginBottom={'0.8rem'}
                    value={item.price}
                // setValidContinue={setValidContinue}
                />
                <ETHConversionWrapper>{conversion}</ETHConversionWrapper></GridItem>
        </Modal>
    )
}

export default MakeOfferModal