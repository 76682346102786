import { useRef, useEffect, useState } from "react";
import {
    getMarketplaceItemsByArtist,
    getMarketplaceItems, getTokensOwned,
    getItemData, getURI, getCollectionOpenAndClose
} from '../blockchain/functionsUiollyNew'
import API from '../API';
import { User, Artist } from '../objectPrototypes/objectClasses'
import dayjs from "dayjs";

const initialState = {
    artworkList: [],
    artworks: [],
    collections: [],
    unFiltered: [],
};


export const useMarketHook = () => {
    const initial = useRef(true)
    const requestsInFlight = useRef(0);
    const numToServe = 2;
    const [filterMethod, setFilterMethod] = useState("none")
    // const [page, setPage] = useState(1)
    const page = useRef(1)
    const [activeFilters, setActiveFilters] = useState([])

    const dataArr = useRef([])
    const [state, setState] = useState(initialState);
    const sortOptions = [
        { value: 1, innerText: 'Price - High to Low', type: 'HighLow' },
        { value: 2, innerText: 'Price - Low to High', type: 'LowHigh' },
        { value: 3, innerText: 'Oldest', type: null },
        { value: 4, innerText: 'Newest', type: 'Newest' }
    ]
    const [sortBy, setSortBy] = useState({ value: sortOptions[2].value, type: sortOptions[2].type })
    const [sortMenuOpen, setSortMenuOpen] = useState(false)
    const handleSortChange = (e) => {
        const value = parseInt(e.target.value)
        setSortBy({ value: sortOptions[value - 1].value, type: sortOptions[value - 1].type, })
        //return sortOptions[value - 1].onClick()
    }

    const [breadcrumb, setBreadcrumb] = useState('Marketplace')
    const [totalResults, setTotalResults] = useState(numToServe)
    const [marketItems, setMarketItems] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingSidebar, setLoadingSidebar] = useState(false)
    const scrollTo = useRef(0)
    const [error, setError] = useState(false)
    // get off chain artists and collections
    const [filterTotals, setFilterTotals] = useState({ totalArtists: 0, totalCollections: 0 })
    const [sidebarContent, setSidebarContent] = useState({ Artists: [], Collections: [] })
    const initialBanner = {
        image: null, name: '', edition: '', artist_name: '', bannerTitle: 'Uiolly Marketplace'
    }
    const [banner, setBanner] = useState({})
    const fetchData = async (fetchPage = null) => {
        try {
            setError(false);
            
            requestsInFlight.current += 1;
            console.log("FETCHING WITH", page.current, document.scrollingElement.scrollTop)
            //sortBy = null, getItemTotal = false, sliceAtEnd = false, fetchAtEnd = false
            // const { artworks, itemTotal } = await getMarketplaceItems(fetchPage !== null ? fetchPage : page, numToServe, false,
            //     { salesCloseTime: 'salesCloseTime' }, true, activeFilters, sortBy.type, true, true, true)
            const { artworks, itemTotal } = await getMarketplaceItems(fetchPage !== null ? fetchPage : page.current, numToServe, false,
                { salesCloseTime: 'salesCloseTime' }, true, activeFilters, sortBy.type, true, true, true)

            requestsInFlight.current -= 1;
            if (requestsInFlight.current > 0)
                return
            if (artworks.length) {
                if (fetchPage !== null)
                    page.current = 1;
                    scrollTo.current = document.scrollingElement.scrollTop
                const rand_indx = Math.floor(Math.random() * artworks.length)
                setBanner(artworks[rand_indx])
                setMarketItems(artworks)
                setTotalResults(itemTotal)
            } else {
                scrollTo.current = document.scrollingElement.scrollTop
                setBanner(initialBanner)
                setMarketItems([])
                setTotalResults(numToServe)
            }
            document.scrollingElement.scrollTo(0, scrollTo.current)
            setLoading(false);

        } catch (e) {
            setError(true)
            setLoading(false);
        }
    }
    const getFilters = async () => {
        setLoadingSidebar(true)
        try {
            const res = await API.getMarketplaceFilters()
            setSidebarContent({
                Artists: [...res.artists],
                Collections: [...res.collections]
            })

            setFilterTotals({ totalArtists: res.total_artists, totalCollections: res.total_collections })
            setLoadingSidebar(false)
        } catch (error) {
            setLoadingSidebar(false)
        }
        //p, resultsperpage, filter_arr {artist, artist_id},{collection,collection_id}

    }
    useEffect(() => {
        const init = async () => {
            setLoading(true)
            getFilters()
            fetchData()
        }
        init()
    }, [])

    const handlePageNav = (input = 1) => async (event) => {
        // if (input < 0 && (page - 1) > 0) {
        //     fetchData(page - 1)
        //     setPage(page + input)
        // }
        // else if (input > 0 && page + 1 <= Math.ceil(totalResults / numToServe)) {
        //     fetchData(page + 1)
        //     setPage(page + 1)
        // }
        console.log("HANDLE")
        if (input < 0 && (page.current - 1) > 0) {
            page.current = page.current - 1
            await fetchData()

        }
        else if (input > 0 && page.current + 1 <= Math.ceil(totalResults / numToServe)) {
            page.current = page.current + 1
            await fetchData()

        }
    }

    useEffect(() => {
        fetchData()
        console.log("active fitlers", activeFilters, filterMethod)
        // getFilters()
    }, [filterMethod, sortBy])

    useEffect(() => {
        if (initial.current) {
            initial.current = false
            return
        }
        fetchData(1)
    }, [activeFilters])
    useEffect(() => { console.log(state.artworks) }, [state])



    return {
        state,
        fetchData,
        banner,
        marketItems,
        setState,
        setLoading,
        setError,
        loading,
        loadingSidebar,
        filterMethod,
        setFilterMethod,
        activeFilters,
        setActiveFilters,
        sidebarContent,
        sortOptions,
        handleSortChange,
        sortMenuOpen,
        setSortMenuOpen,
        sortBy,
        totalResults,
        page,
        numToServe,
        handlePageNav
    };
};
