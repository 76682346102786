import React, { useState, useRef, useEffect } from 'react'

import SortIcon from '../../images/sortIcon.svg'

import { DropdownContainer, DropdownHeader, DropdownListContainer, DropdownList, ListItem, DropdownArrow, LabelStyled, SortWrapper } from './Dropdown.styles'

// const CloseDropdown = ({shouldRotate}) => (

//     <DropdownArrow src={DropdownIcon} alt="dropdown-arrow" shouldRotate={shouldRotate}/>
// )

const Label = ({ name, label, marginBottom }) => (
    <LabelStyled htmlFor={name} marginBottom={marginBottom}>{label}</LabelStyled>

)

const Sort = () => (
    <SortWrapper></SortWrapper>
)

const Option = ({ textAlign, color, colorOnSelect, value, defaultSelect, innerText, onClick, isSelected, justifyContent }) => (
    <ListItem defaultSelect={defaultSelect} value={value}
        isSelected={isSelected}
        color={color}
        textAlign={textAlign}
        colorOnSelect={colorOnSelect}
        justifyContent={justifyContent}
        onClick={onClick}>


        {innerText}</ListItem>
);

const Dropdown = ({
    textAlign,
    justifyContent,
    label,
    height,
    width,
    border,
    background,
    listBackground,
    name,
    marginBottom,
    boxShadow,
    color,
    menuState,
    setMenuState,
    colorOnSelect,
    backgroundOnSelect,
    dropdownListMarginTop,
    options, setState, handleStateChange, valueX, key, id, handleOrderChange, order }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(valueX)

    const [currentVal, setCurrentVal] = useState(valueX)

    const initial = useRef(true);
    const handleChange = (event) => {
        console.log(event.target)
        setIsOpen(!isOpen)

    }

    const selectNew = (event) => {
        console.log(event.target.innerHTML)
        setValue(event.target.innerHTML);
        if (typeof (handleStateChange) === 'function')
            handleStateChange()
        setIsOpen(!isOpen)
    }

    const selectNew2 = (event) => {
        event.preventDefault();
        if (typeof (handleStateChange) === 'function')
            handleStateChange(event)

        setIsOpen(!isOpen)
        if (menuState !== undefined && setMenuState !== undefined) {
            setMenuState(!menuState)
        }
    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }
        console.log("change initiated")
        setIsOpen(!isOpen)
    }, [
        // valueX
    ])

    if (name === 'sortBy') {
        return (
            <>
                <Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>
                <DropdownContainer id={id}>
                    <DropdownHeader
                        justifyContent={justifyContent}
                        marginRight="20px"
                        order={order}
                    ><span onClick={handleChange}>Sort by {valueX} ({order ? "Lowest" : "Highest"})</span>
                        {/* <CloseDropdown shouldRotate={isOpen}/> */}
                        <SortWrapper onClick={handleOrderChange}>
                            <img src={SortIcon} alt="sort-icon" />
                        </SortWrapper>
                    </DropdownHeader>

                    {isOpen && (
                        <DropdownListContainer>
                            <DropdownList justifyContent={justifyContent} boxShadow={boxShadow}>
                                {
                                    options.map(option => (
                                        <Option
                                            color={option.isDisabled !== undefined && option.isDisabled ? 'rgb(255,0,0)' : color}
                                            colorOnSelect={colorOnSelect}
                                            backgroundOnSelect={backgroundOnSelect}
                                            value={option.value}
                                            innerText={option.innerText}
                                            key={option.value}
                                            defaultSelect={option.isDefault}
                                            onClick={
                                                option.isDisabled !== undefined && option.isDisabled ? undefined :
                                                    typeof (handleStateChange) === "function" ? selectNew2 : () => { console.log("beep") }} //was selectNew
                                            isSelected={valueX.includes(option.value)}
                                        />

                                    ))
                                }
                            </DropdownList>
                        </DropdownListContainer>
                    )}


                </DropdownContainer>
            </>
        )

    }
    else if (name === 'filter') {
        return (
            <>
                <Label
                    name={name}
                    label={label}
                    marginBottom={marginBottom}
                ></Label>
                <DropdownContainer
                    width={width}
                    id={id}>
                    <DropdownHeader
                        justifyContent={justifyContent}
                        paddingRight="20px"
                        background={background}
                        onClick={handleChange}>
                        Filter
                    </DropdownHeader>

                    {isOpen && (
                        <DropdownListContainer
                            border={border}>
                            <DropdownList
                                listBackground={listBackground}
                                border={border}
                                boxShadow={boxShadow}
                                marginTop={dropdownListMarginTop}>
                                {
                                    options.map(option => (
                                        <Option
                                            color={option.isDisabled !== undefined && option.isDisabled ? 'rgb(255,0,0)' : color}
                                            colorOnSelect={colorOnSelect}
                                            backgroundOnSelect={backgroundOnSelect}
                                            justifyContent={justifyContent}
                                            value={option.value}
                                            innerText={option.innerText}
                                            key={option.value}
                                            defaultSelect={option.isDefault}
                                            onClick={option.isDisabled !== undefined && option.isDisabled ? undefined : selectNew2} //was selectNew
                                            isSelected={valueX.includes(option.value)}
                                        />

                                    ))
                                }
                            </DropdownList>
                        </DropdownListContainer>
                    )}


                </DropdownContainer>
            </>
        )

    }
    else {
        return <>
            <DropdownContainer
                textAlign={textAlign}
                width={width}
                id={id}>
                {/* <DropdownHeader
                    justifyContent={justifyContent}
                    paddingRight="20px"
                    background={background}
                    onClick={handleChange}>
                    Filter
                </DropdownHeader> */}
                {menuState && (
                    <DropdownListContainer
                        border={border}>
                        <DropdownList
                            listBackground={listBackground}
                            border={border}
                            boxShadow={boxShadow}
                            marginTop={dropdownListMarginTop}
                        >
                            {
                                options.map(option => (
                                    <Option
                                        color={option.isDisabled !== undefined && option.isDisabled ? 'var(--grey)' : color}
                                        textAlign={textAlign}
                                        colorOnSelect={colorOnSelect}
                                        backgroundOnSelect={backgroundOnSelect}
                                        justifyContent={justifyContent}
                                        value={option.value}
                                        innerText={option.innerText}
                                        key={option.value}
                                        defaultSelect={option.isDefault}
                                        onClick={option.isDisabled !== undefined && option.isDisabled ? undefined : selectNew2} //was selectNew
                                        isSelected={valueX.includes(option.value)}
                                    />

                                ))
                            }
                        </DropdownList>
                    </DropdownListContainer>
                )}


            </DropdownContainer>
        </>
    }

}

export default Dropdown
