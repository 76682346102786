import React, { useState, useEffect } from 'react'

import { Wrapper, CharacterCount, TextAreaStyled, LabelStyled } from './TextArea.styles'


const Label = ({ name, marginBottom, label }) => (


    <LabelStyled htmlFor={name}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)




const TextArea = ({ showCharCount, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, rows, maxLength }) => {
    const [charCount, setCharCount] = useState(0)

    useEffect(() => {
        if (value !== undefined)
            setCharCount(value.length)
    }, [value])

    if (label !== undefined) {

        return (
            <Wrapper>
                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                ></Label>
                <TextAreaStyled
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    name={name}
                    onKeyPress={onKeyPress}
                    rows={rows !== undefined ? rows : 3}
                    maxLength={maxLength !== undefined ? maxLength : 1000}

                />
                {showCharCount && <CharacterCount>{charCount} of {maxLength !== undefined ? maxLength : 1000} used</CharacterCount>}
            </Wrapper>

        )
    }
    else {
        return (
            <Wrapper>
                <TextAreaStyled
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    rows={rows !== undefined ? rows : 3}
                    maxLength={maxLength !== undefined ? maxLength : 1000}

                />
                {showCharCount && <CharacterCount>{charCount} of {maxLength !== undefined ? maxLength : 1000} used</CharacterCount>}
            </Wrapper>
        )
    }



}

export default TextArea
