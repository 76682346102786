import React, {useRef} from 'react'

import { Wrapper, Image, Video } from './ArtworkPreview.styles'

const ImageArtwork = ({artwork})=>{
    
    return (
        <Image src={artwork} alt="image" />
    )
}

const VideoArtwork = ({artwork})=>{
    const videoRef = useRef(null);
    
    return (
        <Video
        disablePictureInPicture
        ref={videoRef}
        src={artwork}
        width="100%"
        autoPlay={false}
        controls={false}
        loop={true}
        // muted
        onMouseOver={ (event) => videoRef.current.play()}
        onMouseOut={ (event) => videoRef.current.pause()}
        playsInline
        />
    )
}

const ArtworkPreview = ({artwork, type}) => {
    const artworkType = type.split('/')[0];
    return (
        <Wrapper>
            {
                artworkType === 'image' && (
                    <ImageArtwork  artwork={artwork}></ImageArtwork>
                )
                
            }
            {
                artworkType === 'video' && (
                    <VideoArtwork artwork={artwork}></VideoArtwork>
                )
            }
        </Wrapper>
    )
}

export default ArtworkPreview
