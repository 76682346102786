import styled from "styled-components";

export const DropCountdownWrapper = styled.div`
    display: flex;
    color: var(--lightGrey );
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    text-transform: uppercase;
    img{
        height: 60px;
    }
`;

export const DropdownCounter = styled.div`
    background: var(--goldGradient);
    font-size: 3em;
    width: 19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;