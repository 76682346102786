import * as data from "./artworks.json";
import * as collectionData from "./collections.json"
import * as artists from "./artists.json"

import axios from 'axios'

const apiSettings = {

    submitCollection: async (collection) => {
        const formData = new FormData();
        formData.append("collection-items", JSON.stringify(collection.collectionArtworks));
        collection.collectionArtworks.forEach((artwork, index) => {
            formData.append('artwork-' + index, artwork.file);
        });
        formData.append("collection-title", collection.collectionTitle);
        formData.append("artist", "Name2");
        formData.append("email", collection.email);
        formData.append("collection-description", collection.collectionDescription);
        formData.append("collection-cover-art", collection.collectionCoverArt);
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/save_collection',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        return res
    },
    fetchArtworks: (filterMethod, filter) => {
        const artworks = [];
        if (filterMethod === 'Collection' && filter !== null) {
            data.Artworks.forEach((artwork) => {
                if (artwork.price !== '--' && !artworks.includes(artwork) && artwork.blockchainAddress === filter) {
                    artworks.push(artwork);
                }

            });
        }
        else {
            data.Artworks.forEach((artwork) => {
                if (artwork.price !== '--' && !artworks.includes(artwork)) {
                    artworks.push(artwork);
                }

            });
        }
        return artworks;
    },
    setAccessToken: async (email, token) => {
        const qs = require('qs');
        const data = qs.stringify({
            'email': email,
            'token': token
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/set_access_token`, //api/config/database.php
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });

        return res
    },

    fetchItem: (blockchainAddress, tokenID, isGlobal = false) => { //this should be tokenID not artworkID (artworkID is not unique)


        const artwork = isGlobal ?
            data.Artworks.filter((artwork) => {
                return artwork.blockchainAddress === blockchainAddress
            })[0]

            : data.Artworks.filter((artwork) => {
                return artwork.blockchainAddress === blockchainAddress && artwork.tokenID === tokenID
            })[0]

        const collectionInfo = collectionData.Collections.filter((collection) => {
            return collection.collectionID === artwork.collectionID
        })[0]
        const collectionInfoReduced = { ...collectionInfo, collectionArtworks: collectionInfo.collectionArtworks.filter((cArtworks) => { return cArtworks.artworkID === artwork.artworkID })[0] }
        console.log(collectionInfoReduced)
        return [artwork, collectionInfoReduced];
    },
    fetchJSON: async (uri) => {
        let res = await axios({
            method: 'get',
            url: `${uri}`
        });
        return res.data
    },
    fetchCollection: (blockchainAddress) => {
        const collection = collectionData.Collections.filter((collection) => {
            return collection.blockchainAddress === blockchainAddress
        })[0]
        const artistInfo = artists.Artists.filter((artist) => {
            return artist.artistID === collection.artistID
        })[0]
        return [collection, artistInfo];
    },
    fetchCollections: (artworkList) => {


        const collections = [];
        artworkList.forEach((artwork) => {
            const collection = collectionData.Collections.filter((collection) => {
                return collection.blockchainAddress === artwork.blockchainAddress
                    && !collections.includes(collection)
            })[0];
            if (collection !== undefined)
                collections.push(collection)
        })
        return collections;
    },

    fetchArtistProfile: (content, artistHandle) => {

        const artistInfo = artists.Artists.filter((artist) => {
            return artist.artistHandle === '@' + artistHandle
        })[0]


        const contentToReturn = [];
        if (content === 'gallery') {
            artistInfo.ownedNFTs.forEach((nft) => {
                const item = data.Artworks.filter((artwork) => {
                    return artwork.blockchainAddress === nft.blockchainAddress && artwork.tokenID === nft.tokenID
                })[0]
                contentToReturn.push(item);

            })
        }
        else if (content === 'drops') {
            artistInfo.collectionsMinted.forEach((nft) => {
                console.log(nft)
                const item = collectionData.Collections.filter((collection) => {
                    return collection.blockchainAddress === nft.blockchainAddress
                })[0]
                contentToReturn.push(item);

            })
        }


        return [artistInfo, contentToReturn];
    },
    fetchListings: (showAll, blockchainAddress, artworkID) => {

        if (showAll) {
            const artworks = data.Artworks.filter((artwork) => {
                return artwork.blockchainAddress === blockchainAddress && artwork.artworkID === artworkID
            });

            return artworks;
        }
        const artworks = data.Artworks.filter((artwork) => {
            return artwork.blockchainAddress === blockchainAddress && artwork.artworkID === artworkID && artwork.price != "--"
        });

        return artworks;
    },
    addUser: async (user) => {
        var qs = require('qs');
        var data = qs.stringify({
            'email': user.email,
            'username': user.username,
            'first_name': user.firstName,
            'last_name': user.lastName,
            'public_address': user.publicAddress
        });
        let res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/signup',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });
        return res
    },
    fetchUser: async (email = undefined, wallet_address = undefined) => {


        const params = {
            email,
            wallet_address
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_user_details`,
            { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

        // return res.data
        // let res = await axios({
        //     method: 'get',
        //     url: `${process.env.REACT_APP_API_DOMAIN}/get_user_details?email=${email}`
        // });
        return { userDetails: res.data.userDetails, isArtist: res.data.isArtist }
    },
    fetchLiveDrops: async () => {

        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/get_live_drops`
        });
        return res.data.live_drops || [];
    },
    fetchNotLiveDrops: async (limit = undefined, initial_offset = undefined, p = undefined) => {
        const params = {
            limit: limit,
            initial_offset: initial_offset,
            p: p
        }

        let res = await axios.get(process.env.REACT_APP_API_DOMAIN + '/get_drops_not_live',
            { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

        return res.data.drops || [];
    },
    fetchDrops: async (contract_address) => {
        if (contract_address === undefined) {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/get_drops`
            });
            return res.data.drops;
        }
        else {
            let res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_DOMAIN}/get_drops?contract_address=${contract_address}`
            });
            return res.data.collection
        }

    },
    fetchDropsAddressArray: async (contract_address_array) => {
        const formData = new FormData();
        formData.append("contract_address_array", JSON.stringify(contract_address_array));
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/get_drops_post_arr',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        return res.data.drops

    },
    fetchProfile: async (handle) => {

        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/get_user?handle=${handle}`
        });
        return res.data
    },
    fetchSubmittedCollections: async (currentUser, collection_status) => {

        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/get_user_collections?email=${currentUser.email}&access_token=${currentUser.accessToken}&collection_status=${collection_status}`
        });
        console.log("res is", res)
        return res.data.collections || []
    },
    fetchCollectionStatuses: async () => {

        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/get_collection_status_keys`
        });
        return res.data.valid_statuses
    },
    updateUser: async (user, accessToken) => {

        if (!Object.keys(user).includes("email")) {
            console.log("error!!")
            return;
        }
        const formData = new FormData();
        console.log(user, "user is")
        formData.append("email", user.email)
        formData.append("access_token", accessToken)

        if (Object.keys(user).includes("profile_pic")) {
            formData.append("profile-picture", user.profile_pic);
            formData.append("update-profile-picture", true);
        }
        Object.keys(user).forEach(key => {
            if (key !== 'profile_pic')
                formData.append(key, user[key]);
        })
        console.log("updating user", formData)
        // if (Object.keys(user).includes("bio")) {
        //     formData.append("bio", user.bio);
        // }
        // if (Object.keys(user).includes("handle")) {
        //     formData.append("handle", user.handle);
        // }
        // if (Object.keys(user).includes("displayName")) {
        //     formData.append("display_name", user.displayName);
        // }
        // if (Object.keys(user).includes("instagram")) {
        //     formData.append("instagram", user.instagram);
        // }
        // if (Object.keys(user).includes("website")) {
        //     formData.append("website", user.website);
        // }
        // if (Object.keys(user).includes("facebook")) {
        //     formData.append("facebook", user.facebook);
        // }
        // if (Object.keys(user).includes("instagram")) {
        //     formData.append("instagram", user.instagram);
        // }
        const res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/update_user',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        })
        return res
    },
    updateCollectionAddress: async (collection_id, collectionAddress) => {
        const res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/update_collection?collection_id=${collection_id}&contract_address=${collectionAddress}`
        })
        return res
    },
    getFeaturedArtists: async () => {
        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/curr_featured_artists`
        });
        return res.data.artists
    },
    getMarketplaceFilters: async (page = undefined, resultsPerPage = undefined, filter_arr = undefined) => {
        const params = {
            c: resultsPerPage,
            p: page,
            filter_arr // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_marketplace_filters`,
            { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

        return res.data
    }, //get_most_popular_artworks
    getEditionDetails: async (contract_address, base_type, edition) => {
        const params = {
            contract_address,
            base_type,
            edition
            // [{ type, value}, {artist, artist_id},{collection, collection_id}]
        }

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_edition_details`,
            { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

        return res.data
    },
    getMostPopularArtworks: async () => {

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_most_popular_artworks`)
        return res.data
    },
    getRandomArtwork: async () => {

        let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_random_artwork`)
        return res.data
    },
    updateEditionDetails: async (contract_address, base_type, edition, price) => {
        var qs = require('qs');
        //const currentData = await getEditionDetails(contract_address, baseType, edition)
        const data = qs.stringify({
            contract_address,
            base_type,
            edition,
            price
        });


        let res = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/update_edition_details',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        });
        return res
    },
    updateEditionDetailsTemp: async () => {
        // var qs = require('qs');
        // //const currentData = await getEditionDetails(contract_address, baseType, edition)
        // const data = qs.stringify({
        //     contract_address: '0xe4470a47E93E3f1368A9EB4966D9aD69d62E2a21',
        //     base_type: '2',
        //     edition: '5',
        //     price: '0.09'
        // });


        // let res = await axios({
        //     method: 'post',
        //     url: process.env.REACT_APP_API_DOMAIN + '/update_edition_details',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        //     data: data
        // });
        // return res
    }
    // getMarketplaceFilters: async (currentUser, page = undefined, resultsPerPage = undefined, filter = undefined, filter_arr = null) => {
    //     const params = {
    //         email: currentUser.email,
    //         access_token: currentUser.accessToken,
    //         c: resultsPerPage,
    //         p: page,
    //         filter: filter
    //     }

    //     let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_marketplace_filters`,
    //         { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

    //     return res
    // }
}

export default apiSettings;