import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'



export const NavLink = styled(Link)`
    all: unset;
`;

export const OuterWrapper = styled.div`
padding: 15px;
position: relative;
width: calc(30px + var(--artworkCardImageSize));
`;

export const Wrapper = styled.div`
    /* height: var(--artworkCardHeight); */
    cursor: ${props => props.cursor ? props.cursor : 'default'};
    max-width: var(--artworkCardImageSize);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    background: var(--background);
    flex-direction: column;
    outline: 1px solid var(--fontColor);
    user-select: none;
`;

export const ImageWrapper = styled.div`
    width: var(--artworkCardImageSize);
    height: var(--artworkCardImageSize);
    overflow: hidden;
    
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const Image = styled.img`
    object-fit: cover;
    position: relative;
    min-height: 100%;
    min-width: 100%;
   
`;

export const DetailsWrapper = styled.div`
padding: 10px;
`;

export const Timer = styled.div`
display: flex;
color: var(--grey);
font-size: 10px;
text-align: ${props => props.textAlign ? props.testAlign : 'right'};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-end'};
align-items: center;
margin-top: 0.5rem;
width: 100%;
height: 1rem;
text-transform: uppercase;
position: relative;
img{
    margin-left: 5px;
    height: 12px;
    width: 12px;
    
}
`;

export const Title = styled.div`
font-size: 12px;
max-width: 80%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

export const Artist = styled.div`
font-size: 10px;
`;

export const Edition = styled.div`
font-size: 10px;
text-align: right;

`;

export const Pricing = styled.div`
align-items: end;
margin-top: 0.5rem;
display: grid;
align-items: end;
grid-template-columns: ${props => props.isPrimary ? 'min-content 1fr': '1fr 1fr'}; 
grid-template-rows: auto auto;
grid-column-gap: 8px;
grid-template-areas: ${props => props.isPrimary ? "'CurrentBidValue CurrentBidLabel'" : 
 "'CurrentBidValue LastSoldValue''CurrentBidLabel LastSoldLabel'"};

`;

export const CurrentBidValue = styled.div`
    grid-area: CurrentBidValue;
    font-size: 16px;
    line-height: 1rem;
    text-align: left;

`;

export const CollectionSize = styled.div`
    grid-area: CurrentBidLabel;
    font-size: 10px;
    margin-top: 0.5rem;
    color: var(--grey);
    line-height: .8rem;
    text-transform: uppercase;
    text-align: left;
`;

export const LastSoldValue = styled.div`
    grid-area: LastSoldValue;
    font-size: 12px;
    text-align: right;
`;

export const LastSoldLabel = styled.div`
    grid-area: LastSoldLabel;
    font-size: 10px;
    text-align: right;
    text-transform: uppercase;
`;



export const Video = styled.video`
    min-width: var(--artworkCardImageSize);
    min-height: var(--artworkCardImageSize);
    object-fit: cover;


`;

export const RowWrapper = styled.div`
display: flex;
position: relative;
width: 100%;
align-items: flex-end;
justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
width: 100%;
padding: 0 10px 10px;
position: relative;
display: flex;
`;