import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// }
// LOCAL
const firebaseConfig = process.env.REACT_APP_CHAIN === 'GANACHE' ? {
    apiKey: "AIzaSyA9b04tUQZomhyvOsLlFpWYFsGF3OTAs1U",
    authDomain: "ui-ci-firebase.firebaseapp.com",
    projectId: "ui-ci-firebase",
    storageBucket: "ui-ci-firebase.appspot.com",
    messagingSenderId: "441140724743",
    appId: "1:441140724743:web:307f1a313fabcfba8216a2"
} : 
{
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY_JUDY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_JUDY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_JUDY,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_JUDY,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_JUDY,
        appId: process.env.REACT_APP_FIREBASE_APP_ID_JUDY,
    }



// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export default app