import styled from "styled-components";

export const Wrapper = styled.div`
top: 60px;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
`;

export const ArtistPicture = styled.div`
width: 50px;
height: 50px;
display: flex;
justify-content: center;
cursor: pointer;
position: relative;
z-index:1;
border-radius: 50%;
overflow: hidden;
img{
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
}
`;

export const Bio = styled.p`
text-align: center;
max-width: 500px;
`;

export const H2 = styled.h2`
text-transform: capitalize;
`;

export const Description = styled.p`
max-width: 1300px;
width: 90%;
text-align: center;
`;

export const ItemWrapper = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
justify-content: center;
`;

export const SocialWrapper = styled.a`
all: unset;
cursor: pointer;
padding: 5px;
height: 15px;
width: 15px;
position: relative;
img{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
`;


export const Social = styled.div`
display: flex;
width: min-content;
column-gap: 10px;
max-width: 500px;
`;