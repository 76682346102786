import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 90%;
    position: relative;
    height: 50vh;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    scroll-behavior: smooth;
    mask-image: linear-gradient(0deg, transparent 2%, red 5%, red 95%, transparent 98%);
`;

export const EmptySpacer = styled.span`
height: 25px;
width: 100%;
position: relative;
display: inline-block;
flex-shrink: 0;
`;

export const ScrollWrapper = styled.div`
margin: auto;
max-height: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`;

export const ScrollBarContainer = styled.div`
height: min-content;

position: absolute;
right: 20px;
align-items: center;
justify-content: space-between;
display: flex;
flex-direction: column;

div:not(:first-child){
    margin-top: 20px;
}

`;

export const Scroller = styled.div`
width: 100%;
background: rgb(200,0,200);

height: ${props => props.scrollerHeight ? props.scrollerHeight + '%' : ''};
`;

export const OuterWrapper = styled.div`
width: 100%;
height: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
`;