import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
`;

export const CollectionCover = styled.div`
overflow: hidden;
width: 100%;
height: 25vw;
display: flex;

img{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
}
`;

export const ArtistPicture = styled.div`
    width: 58px;
    height: 58px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    position: relative;
    margin-top: calc( 58px / -2);
    img{
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
`;

export const ArtistName = styled(Link)`
    all: unset;
    user-select: none;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 200;

`;

export const ArtistHandle = styled.div`
    font-size: 10px;
`;

export const Description = styled.div`
    color: var(--lightGrey);
    z-index: 1;
    text-align: center;
    max-width: 800px;
    margin: 20px 0 20px;

`;



export const CollectionArtworksHeader = styled.div`
text-transform: uppercase;
user-select: none;
color: var(--lightGrey);
height: 1.5rem;
width: 100%;
font-size: 1.5rem;
font-weight: 700;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 50px;
:before{
    content: '';
    position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid var(--lightGrey);
  background: var(--background);
  width: 100%;
  transform: translateY(-50%);

}

`;

export const CollectionArtworksHeaderBgd = styled.div`

position: relative;
background: var(--background);
padding: 20px;

`;

export const CollectionArtworks = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const CollectionTitle = styled.div`
    font-size: 1.5rem;
    font-family: Montserrat;
`;


export const ArtistBio = styled.div`
    display: grid;
    height: 200px;
    grid-template-columns: 10% auto 10%;
    grid-template-areas: 'spacer1 content spacer2';
    width: 100%;
    color: var(--lightGrey);
    position: relative;
    grid-column-gap: 20px;
    > div {
        border: 1px solid var(--lightGrey);
    }
`;

export const ArtistBioText = styled.div`
    font-size: 14px;
    display: flex;
    max-width: 800px;
`;


export const Content = styled.div`
position: relative;
display: flex;
height: 100%;

justify-content: center;
border: 1px solid var(--lightGrey);
grid-area: content;
padding: 40px;
flex-direction: column;

a{
    all: unset;
    font-size: 12px;
    color: var(--goldSolid);
    cursor: pointer;
    font-weight: 500;
    display: flex;
    width: min-content;

}
`

export const ArtistBioHeader = styled.div`
border-bottom: 1px solid var(--lightGrey);
position: relative;
display: flex;
font-weight: 700;
text-transform: uppercase;
white-space: nowrap;
width: min-content;
`;

export const Spacer = styled.div`
position: relative;
display: flex;
height: 100%;
width: 100%;
border: 1px solid var(--lightGrey);
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`

export const Social = styled.div`
display: flex;
height: 20px;
width: min-content;
max-width: 500px;
`;

export const ArtistBioPicture = styled.div`
    width: 58px;
    height: 58px;
    display: flex;
    border-radius: 50%;
    margin-left: calc(-58px / 2 - 30px - 20px);
    overflow: hidden;
    position: absolute;
    img{
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;

    }
`;

export const DropCountdownWrapper = styled.div`
    display: flex;
    color: var(--lightGrey );
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    text-transform: uppercase;
    img{
        height: 60px;
    }
`;

export const DropdownCounter = styled.div`
    background: var(--goldGradient);
    font-size: 3em;
    
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;