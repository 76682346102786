import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-wrap: wrap;
width: ${props => props.isEmpty ? '100%' : ''};
height: ${props => props.isEmpty ? '100%' : ''};
background: ${props => props.isEmpty ? 'var(--lightestGrey)' : ''};
color: ${props => props.isEmpty ? 'var(--lightGrey)' : ''};
justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
align-items: ${props => props.isEmpty ? 'center' : 'flex-start'};

`;