import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom'

export const OuterWrapper = styled.div`
width: 100%;

display: flex;
position: relative;

height: 100%;
`;


export const ColWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
position: relative;
top: 60px;
overflow: hidden;
height: calc( 100vh - 60px);
`;



export const LinkWrapper = styled.div`
display: flex;
`;

export const SideBarWrapper = styled.div`
width: 30%;
position: relative;
display: flex;
flex-direction: column;
user-select: none;
max-width: 250px;
height: 100%;
`;
export const GridHeader = styled.div`
display: grid;
user-select: none;
grid-template-columns: min-content min-content 1fr min-content;
grid-template-rows: min-content;
grid-column-gap: 20px;
font-size: 21px;
justify-items: end;
`;
export const NavLink = styled.div`
display: flex;
cursor: pointer;
text-transform: uppercase;
align-items: center;
grid-column: -1;
width: min-content;
img{
    margin-left: 3px;
    height: 20px;
    width: 20px;

}
`;
export const PageTab = styled.div`
color: ${props => props.color ? props.color : 'var(--fontColor)'};
/* margin: 15px 15px 0 0; */

text-transform: uppercase;
cursor: pointer;
`;

export const AccountActivityHeader = styled.div`
position: relative;
font-weight: 700;
    font-size: 21px;
    font-family: Montserrat, arial, sans-serif;
    text-align: left;
    text-transform: uppercase;
    

`;

export const PageHeader = styled.div`
display: flex;
padding: 10px 30px;
flex-direction: column;
border-bottom: 1px solid var(--black);
`;

export const NavHeader = styled.div`
text-transform: uppercase;
padding: 10px 30px;
margin: 10px 0 0 0;
font-size: 16px;
`;

export const Nav = styled.div`
font-size: 14px;
cursor: pointer;
color: var(--lighterGrey);
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 30px;
letter-spacing: 0.03rem;
font-weight: ${props => props.isActive ? '700' : ''};
font-size: 12px;
text-transform: uppercase;
color: ${props => props.isActive ? 'var(--uiollyPurple)' : 'var(--grey)'};
/* background: ${props => props.isActive ? 'var(--uiollyPurple)' : 'transparent'};  */
img {
    height: 10px;
    width: 10px;
}
`;

export const SideBarContent = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
overflow: auto;
`;

// export const NavLink = styled(Link)`
// all: unset;
// cursor: pointer;
// padding: 10px;
// font-size: 12px;
// margin: 5px 0;
// color: var(--lighterGrey);
// `;

export const CatWrapper = styled.div``;

export const RightContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100vh;
    height: 100%;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    top: 0;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    display: grid;
    grid-template-rows: 75px 1fr min-content;
`;

export const Row = styled.div`
display: flex;
width: 100%;
align-items: center;
height: 60px;
justify-content: space-between;
user-select: none;
padding: 0 50px;
margin-bottom: 10px;
text-transform: uppercase;

div{
    display: flex;
}
>div>div:not(:first-child){
    margin-left: 0.5rem;
}
`;

export const Subtext = styled.p`
text-transform: none;
font-size: 12px;
margin: 0;
color: var(--grey);
`

export const Innercontainer = styled.div`
width: 100%;
height: calc(100% - 80px);
overflow-x: hidden;
padding: ${props => props.isMessages ? '0 20px' : '0 50px 0 35px'};
position: relative;
display: flex;
`;

export const ScrollContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
`;

export const DateWrapper = styled.div`
font-size: 13px;
display: flex;
align-items: flex-end;
white-space: nowrap;
`;