// ganache: interfaceUiollyNew
// import NFTinterface from "./interfaceUiollyNew/NFTinterface";
// import deployerInterface from "./interfaceUiollyNew/deployerInterface";
// import marketInterface, { marketplaceAddress } from "./interfaceUiollyNew/marketInterface";


// ropsten: interfaceRopsten
import NFTinterface from "./interfaceRopsten/NFTinterface";
import deployerInterface from "./interfaceRopsten/deployerInterface";
import marketInterface, { marketplaceAddress } from "./interfaceRopsten/marketInterface";



import web3 from "./web3";
import {
    VALID_BID_FILTERS,
    PRIMARY_MARKET,
    SECONDARY_MARKET,
    VALID_AUCTION_ITEM_FILTER_CONSTRAINTS,
    VALID_MARKET_ITEM_FILTER_CONSTRAINTS,
    MARKET_DATA_TYPES
} from '../config'
import { varNameToString } from '../helpers'
import {
    meetsFilterCondition,
    getValidConstraints,
    getBidStatus,
    checkIfEventDataRecorded
} from './blockchainHelpers'
import { PrimaryMarketData, SecondaryMarketData } from '../objectPrototypes/objectClasses'
import API from '../API'
import {
    roundDataWei,
    convert_WEI_ETH,
    isBaseType, getNonFungibleTokenIdFromBaseType,
    getNonFungibleTokenIdFromBaseId, getNonFungibleBaseType, getNonFungibleIndex
} from './blockchainUtils'
import BigNumber from "bignumber.js";
import bigInt from "big-integer";
import dayjs from 'dayjs'

export const buy = async (_tokenAddress, _baseType, _value, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await NFTinterface(_tokenAddress);
        //console.log("token address", _tokenAddress, _baseType)
        let value = _value;
        let result = await instance.methods
            .mintNonFungible(_baseType)
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })

        const { events: { itemSold: { returnValues } } } = result
        const { tokenId, pricePaid } = returnValues
        const edition = getNonFungibleIndex(tokenId)
        const price_eth = web3.utils.fromWei(web3.utils.toBN(pricePaid))
        const res2 = await API.updateEditionDetails(_tokenAddress, _baseType, edition, price_eth)

        return result;
    } catch (error) {
        return error.stack
    }
}
export const buyFromMarketplace = async (_itemId, _priceWEI, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let value = _priceWEI;
        let instance = await marketInterface();
        let result = await instance.methods
            .createMarketSale(_itemId) // NOT TOKEN ID!!!!!!!!!!!!!!
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    //console.log("tx hash", txH)
                    setShowMsg(true)
                }
            })
        // const data = await getItemDataTokenId(marketItem.nftContract, marketItem.tokenId)
        const { events: { ItemSold: { returnValues } } } = result
        const { nftContract, tokenId, price } = returnValues
        const price_eth = web3.utils.fromWei(web3.utils.toBN(price))
        const edition = getNonFungibleIndex(tokenId)
        const baseType = getNonFungibleBaseType(tokenId)
        const res2 = await API.updateEditionDetails(nftContract, baseType, edition, price_eth)
        return result
    } catch (error) {
        return error.stack
    }
};
export const claimNFTMarketplace = async (_itemId, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await marketInterface();
        let result = await instance.methods
            .claimNFT(_itemId)
            .send({ from: window.ethereum.selectedAddress }, (e, txH) => {
                if (e) {
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        const { events: { ItemSold: { returnValues } } } = result
        const { nftContract, tokenId, price } = returnValues
        const price_eth = web3.utils.fromWei(web3.utils.toBN(price))
        const edition = getNonFungibleIndex(tokenId)
        const baseType = getNonFungibleBaseType(tokenId)
        const res2 = await API.updateEditionDetails(nftContract, baseType, edition, price_eth)
        return result;
    } catch (error) {
        //console.log(error)
        return error.stack
    }
}
export const makeOffer = async (_itemId, _offerETH, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let value = web3.utils.toWei(_offerETH.toString());
        let instance = await marketInterface();
        let result = await instance.methods
            .makeOffer(_itemId)
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        return result
    } catch (error) {
        //console.log(error);
        return error.stack
    }
};

export const acceptOffer = async (_itemId, _offerId, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await marketInterface();
        let result = await instance.methods
            .acceptOffer(_itemId, _offerId)
            .send({ from: window.ethereum.selectedAddress }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        const { events: { OfferAccepted: { returnValues } } } = result
        const { nftContract, tokenId, offer } = returnValues
        const price_eth = web3.utils.fromWei(web3.utils.toBN(offer))
        const edition = getNonFungibleIndex(tokenId)
        const baseType = getNonFungibleBaseType(tokenId)
        const res2 = await API.updateEditionDetails(nftContract, baseType, edition, price_eth)
        return result
    } catch (error) {
        //console.log(error);
        return error.stack
    }
};

export const revokeOffer = async (_itemId, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        console.log("ADDRESS IS", window.ethereum.selectedAddress)
        let instance = await marketInterface();
        let result = await instance.methods
            .revokeOffer(_itemId)
            .send({ from: window.ethereum.selectedAddress }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        return result
    } catch (error) {
        //console.log(error);
        return error.stack
    }
};

export const bid = async (_tokenAddress, _baseType, _tokenEdition, _value, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await NFTinterface(_tokenAddress)
        let value = web3.utils.toWei(_value.toString());
        let result = await instance.methods
            .bid(_baseType, _tokenEdition)
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    return e.stack
                } else {
                    setTxProcessing(true)
                    //console.log("t hash", txH)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        return result;
    } catch (error) {
        //console.log(error)
        return error.stack
    }
}
export const withdrawFunds = async (_tokenAddress, _value, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await NFTinterface(_tokenAddress)
        let value = web3.utils.toWei(_value.toString());
        let result = await instance.methods
            .withdraw()
            .send({ from: window.ethereum.selectedAddress }, (e, txH) => {
                if (e) {
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        return result;
    } catch (error) {
        //console.log(error)
        return error.stack
    }
}
export const bidMarketplaceItem = async (_itemId, _bidETH, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let value = web3.utils.toWei(_bidETH.toString());
        let instance = await marketInterface();
        let result = await instance.methods
            .bid(_itemId)
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        return result
    } catch (error) {
        //console.log(error);
        return error.stack
    }
};
export const claimNFT = async (_tokenAddress, _tokenId, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let instance = await NFTinterface(_tokenAddress)
        let result = await instance.methods
            .claimNFT(_tokenId)
            .send({ from: window.ethereum.selectedAddress }, (e, txH) => {
                if (e) {
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    setShowMsg(true)
                }
            })
        const bid = await instance.methods.tokenId_bids(_tokenId).call();
        const bid_eth = web3.utils.fromWei(web3.utils.toBN(bid))
        const edition = getNonFungibleIndex(_tokenId)
        const baseType = getNonFungibleBaseType(_tokenId)
        const res2 = await API.updateEditionDetails(_tokenAddress, baseType, edition, bid_eth)
        return result;
    } catch (error) {
        //console.log(error)
        return error.stack
    }
}


export const deployCollection = async (_uri, _prices, _amounts, _auction, _releaseDate, _endDate) => {
    try {
        let instance = await deployerInterface();
        let prices = [];
        _prices.map((el) => {
            prices.push(web3.utils.toWei(el));
        });
        //console.log({ _uri, prices, _amounts, _auction, _releaseDate, _endDate });
        let result = await instance.methods
             .createToken(_uri, prices, _amounts, _auction, _releaseDate, _endDate)
            //.createToken(_uri, prices, _amounts, _auction, dayjs().unix(), dayjs().add(3, 'hour').unix())
            .send({ from: window.ethereum.selectedAddress });
        return result;
    } catch (error) {
        //console.log(error);
    }
};

export const getTokensOwned = async (_address, _tokenId) => {
    try {

        let instance = await NFTinterface(_address);
        let result = await instance.methods.balanceOf(window.ethereum.selectedAddress, _tokenId).call()
        return result
    } catch (error) {
        //console.log(error);
    }
}


export const setForResale = async (_address, _tokenId, _priceETH, _isAuction, _salesOpenTime, _salesCloseTime, setTxProcessing, setTxMessage, setShowMsg) => {
    try {
        let _price = web3.utils.toWei(_priceETH.toString());
        let instanceNFT = await NFTinterface(_address);
        let result1 = await instanceNFT.methods.setApprovalForAll(marketplaceAddress, true)
            .send({ from: window.ethereum.selectedAddress })
        let instance = await marketInterface(); //listingPrice
        let value = web3.utils.toWei(0.001.toString()); //0.001
        let result = await instance.methods
            .createMarketItem(_address, _tokenId, _price, _isAuction, _salesOpenTime, _salesCloseTime)
            .send({ from: window.ethereum.selectedAddress, value }, (e, txH) => {
                if (e) {
                    //console.log("error", e.stack)
                    return e.stack
                } else {
                    setTxProcessing(true)
                    setTxMessage("Your transaction is pending")
                    //console.log("tx hash", txH)
                    setShowMsg(true)
                }
            })


        return result
    } catch (error) {
        //console.log(error);
    }
};

export const getDeployedCollections = async () => {
    try {
        let instance = await deployerInterface();
        let result = await instance.methods.getDeployedCollections().call()
        return result;
    } catch (error) {
        //console.log(error);
        return []
    }
}

export const getTokensPurchasedFromMarketplace = async (_walletAddress = null) => {
    try {
        const walletAddressFormatted = web3.utils.toChecksumAddress(_walletAddress !== null ? _walletAddress : window.ethereum.selectedAddress)
        // let instance = await marketInterface();
        // const res = []
        let instance = await marketInterface();
        let result = await instance.methods.fetchMyNFTs().call({ from: walletAddressFormatted })
        const itemData = []

        // if (result.length)
        //     for (let index = 0; index < result.length; index++) {

        //         const item = result[index]
        //         const { tokenId, nftContract } = item

        //         const baseType = getNonFungibleBaseType(item.tokenId)
        //         const edition = getNonFungibleIndex(tokenId)

        //         const dataPrevFetched = itemData.find(it => it.nftContract === nftContract && it.baseType === baseType)
        //         let _itemData = {}

        //         if (!dataPrevFetched) {
        //             _itemData = await getItemData(nftContract, baseType, edition)
        //             itemData.push({ ..._itemData })

        //         } else {
        //             _itemData = dataPrevFetched
        //             const itemData = await getItemData(nftContract, baseType, edition, null,
        //                 _itemData.salesOpenTime, _itemData.salesCloseTime, null, _itemData.jsonData, _itemData.uri)
        //             _itemData = { ..._itemData, itemData }

        //         }

        //         let instance = await NFTinterface(nftContract);
        //         const ownershipCheck = await instance.methods.ownerOf(tokenId).call();
        //         const shouldAdd = walletAddressFormatted === null || ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase()

        //         if (shouldAdd) {
        //             const data = await getMarketplaceItem(item.itemId, item.nftContract, item.tokenId, null, item)
        //             res.push(data)
        //         }
        //     }
        // return res
        return result
    } catch (error) {
        //console.log(error);
    }
}

export const getItemsOwnedAndForSaleAllCollections = async (_walletAddress = null, filter = null) => {
    try {
        // if (!Object.values(VALID_BID_FILTERS).includes(filter))
        //     throw new Error('Invalid Filter')
        const collections = await getDeployedCollections()
        const walletAddressFormatted = _walletAddress !== null ? web3.utils.toChecksumAddress(_walletAddress) : null
        const items = [];
        if (collections !== null)
            for (let i = 0; i < collections.length; i++) {
                const collection = collections[i];
                const res = await getItemsSold(collection, walletAddressFormatted)
                items.push(...res)
            }
        const marketItems = await getTokensPurchasedFromMarketplace(walletAddressFormatted)
        if (marketItems.length > 0) {
            for (let i = 0; i < marketItems.length; i++) {
                const item = marketItems[i]
                let instance = await NFTinterface(item.nftContract);
                const ownershipCheck = await instance.methods.ownerOf(item.tokenId).call();
                //const walletAddressIsOwner = ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase();
                const shouldAdd = walletAddressFormatted === null || ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase()
                if (shouldAdd) {
                    //const data = await getItemDataTokenId(item.nftContract, item.tokenId) //primary
                    const data = await getMarketplaceItem(item.itemId, item.nftContract, item.tokenId, instance, item)
                    //const { activeOffers, highestOffer, lowestOffer } = await getEditionOfferEvents(item.itemId)
                    //items.push({ ...data, ...item, activeOffers, highestOffer, lowestOffer })


                    items.push({ ...data })

                }
            }
        }
        return items

    } catch (error) {

    }
}

// export const getItemsOwnedAndForSaleAllCollections = async (_walletAddress = null, filter = null) => {
//     try {
//         // if (!Object.values(VALID_BID_FILTERS).includes(filter))
//         //     throw new Error('Invalid Filter')
//         const collections = await getDeployedCollections()
//         const walletAddressFormatted = _walletAddress !== null ? web3.utils.toChecksumAddress(_walletAddress) : null
//         const items = [];
//         if (collections !== null)
//             for (let i = 0; i < collections.length; i++) {
//                 const collection = collections[i];
//                 const res = await getItemsSold(collection, walletAddressFormatted)
//                 items.push(...res)
//             }
//         const marketItems = await getTokensPurchasedFromMarketplace(walletAddressFormatted)
//         if (marketItems.length > 0) {
//             for (let i = 0; i < marketItems.length; i++) {
//                 const item = marketItems[i]
//                 let instance = await NFTinterface(item.nftContract);
//                 const ownershipCheck = await instance.methods.ownerOf(item.tokenId).call();
//                 //const walletAddressIsOwner = ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase();
//                 const shouldAdd = walletAddressFormatted === null || ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase()
//                 if (shouldAdd) {
//                     const data = await getItemDataTokenId(item.nftContract, item.tokenId) //primary
//                     const { activeOffers, highestOffer, lowestOffer } = await getEditionOfferEvents(item.itemId)
//                     items.push({ ...data, ...item, activeOffers, highestOffer, lowestOffer })

//                     // //
//                     // const data = await getMarketplaceItem(item.itemId, item.nftContract, item.tokenId, null, item)
//                     // const data = await getItemDataTokenId(nftContract, tokenId)
//                     // const pricing = await getItemPricingData(instance, marketItem, SECONDARY_MARKET, null, null, marketItem.itemId)
//                     // const secondaryMarketData = new SecondaryMarketData({ ...marketItem, ...pricing })

//                     // return { ...data, nftContract, tokenId, artist, secondaryMarketData }
//                     // //


//                 }
//             }
//         }
//         return items

//     } catch (error) {

//     }
// }
export const getItemsSold = async (_collectionAddress, _walletAddress = null, _interfaceInstance = null) => {
    try {
        const walletAddressFormatted = _walletAddress !== null ? web3.utils.toChecksumAddress(_walletAddress) : null
        let instance = _interfaceInstance !== null ? _interfaceInstance : await NFTinterface(_collectionAddress);
        let salesOpenTime = await instance.methods.salesOpenTime().call();
        let salesCloseTime = await instance.methods.salesCloseTime().call();
        const isOpen = dayjs().unix() >= salesOpenTime && dayjs().unix() < salesCloseTime
        let contractAddress = _collectionAddress
        const contractItems = []
        const itemData = []
        let purchaseEvents = await instance.getPastEvents('itemSold', { filter: walletAddressFormatted !== null ? { buyer: walletAddressFormatted } : {}, fromBlock: 1, toBlock: 'latest' })
        for (let i = 0; i < purchaseEvents.length; i++) {
            const event = purchaseEvents[i];
            const { tokenId, pricePaid } = event.returnValues
            const ownershipCheck = await instance.methods.ownerOf(tokenId).call();
            //const walletAddressIsOwner = ownershipCheck.toLowerCase() === _walletAddress.toLowerCase();
            const shouldAdd = walletAddressFormatted === null || ownershipCheck.toLowerCase() === walletAddressFormatted.toLowerCase()
            if (shouldAdd) {
                const _baseType = getNonFungibleBaseType(tokenId)
                const edition = getNonFungibleIndex(tokenId)
                const dataPrevFetched = itemData.find(it => it.baseType === _baseType)
                let _itemData = {}
                if (!dataPrevFetched) {
                    // _itemData = await getItemDataWithoutBids(instance, _baseType)
                    _itemData = await getItemData(_collectionAddress, _baseType, edition, instance, salesOpenTime, salesCloseTime)
                    const _jsonData = await API.fetchJSON(_itemData.uri)
                    _itemData = { ..._itemData, ..._jsonData }
                    itemData.push({ ..._jsonData, ..._itemData })
                } else {
                    _itemData = dataPrevFetched
                }

                contractItems.push({ ..._itemData, isOpen, salesOpenTime, salesCloseTime, contractAddress, tokenId, edition, price: pricePaid })
            }

        }
        return contractItems

    } catch (error) {
        //console.log(error);
    }
}

export const getBids = async (_address) => {
    try {
        //console.log("getting bids for", _address);
        let instance = await NFTinterface(_address); //tokenId_bidders
        let items = await instance.methods.collectionItems().call();
        let salesOpenTime = await instance.methods.salesOpenTime().call();
        let salesCloseTime = await instance.methods.salesCloseTime().call();
        //let b = (12n ** 122n) & (13n ** 133n);
        //console.log("collection items", items)
        let bids = {
            winning: [],
            all: [],
            isOpen: dayjs().unix() >= salesOpenTime && dayjs().unix() < salesCloseTime
        };
        for (let _baseType = 1; _baseType <= items; _baseType++) {

            //let data = await instance.methods.baseTypeToData(web3.utils.toBN(i) << 128).call(); //consider

            let data = await instance.methods.baseTypeToData(_baseType).call(); //tokenId_bidders
            let supply = Number(data.maxSupply);
            let bidEvents = await instance.getPastEvents('bidReceived', { filter: {}, fromBlock: 1, toBlock: 'latest' })
            for (let _edition = 1; _edition <= data.maxSupply; _edition++) {
                const tokenId = getNonFungibleTokenIdFromBaseType(_baseType, _edition)
                // filters only work on indexed parameters
                // let bidEvents = await instance.getPastEvents('bidReceived', { filter: { bidder: window.ethereum.selectedAddress }, fromBlock: 1, toBlock: 'latest' })
                const bidder = await instance.methods
                    .tokenId_bidders(tokenId) //all the bids im winning
                    .call();
                if (bidder.toLowerCase() === window.ethereum.selectedAddress.toLowerCase())
                    bids.winning.push({ bidder, tokenId })
                const filteredByTokensUserHasBidOn = bidEvents.filter(it => tokenId === it.returnValues.tokenId && bidEvents.some(anyItem => tokenId === anyItem.returnValues.tokenId
                    && anyItem.returnValues.bidder.toLowerCase() === window.ethereum.selectedAddress.toLowerCase())
                )
                if (filteredByTokensUserHasBidOn.length > 0)
                    bids.all.push(filteredByTokensUserHasBidOn.map(it => it.returnValues))
            }


        }
        return bids
    } catch (error) {
        //console.log(error);
        return error.stack
    }
}

export const marketBids = async (_walletAddress = null, filter = null) => {
    try {
        if (!Object.values(VALID_BID_FILTERS).includes(filter))
            throw new Error('Invalid Filter')
        let instance = await marketInterface();
        const currentMarketAuctions = await getMarketplaceItems(-1, -1, false, { isAuction: true }, false) // get all for which isAuction is true
        const auctionItemIds = currentMarketAuctions.map(it => it.itemId)
        const myAddress = _walletAddress !== null ? web3.utils.toChecksumAddress(_walletAddress) : web3.utils.toChecksumAddress(window.ethereum.selectedAddress)
        const eventFilter = { bidder: myAddress, itemId: auctionItemIds }
        const myBidEvents = await instance.getPastEvents('Bid', { filter: eventFilter, fromBlock: 1, toBlock: 'latest' })
        console.log(myBidEvents)
        const bidEvents = {
            winning: [],
            won: [],
            outbid: []
        }

        for (let i = 0; i < myBidEvents.length; i++) {

            const { returnValues: { tokenId, bid, itemId } } = myBidEvents[i]
            const { found_index, found_key } = checkIfEventDataRecorded(bidEvents, itemId)

            if (found_index === -1) {
                const marketItem = currentMarketAuctions.find(it => it.itemId === itemId)
                const res = await getMarketplaceItem(marketItem.itemId, null, null, instance, marketItem)
                const { secondaryMarketData: { salesCloseTime, auctionHigh, highestBidder } } = res
                const amWinningOrWinner = highestBidder.toLowerCase() === myAddress.toLowerCase()
                const isOpen = dayjs().unix() < salesCloseTime

                const bidStatus = getBidStatus(amWinningOrWinner, isOpen, filter)

                if (bidStatus) {
                    const bidStatusLC = bidStatus.toLowerCase()
                    let newData = {
                        ...res.secondaryMarketData,
                        bid: { auctionHigh, amount: bid, tokenId, itemId }
                    }
                    if (bidStatus === VALID_BID_FILTERS.BID_FILTER_WON)
                        newData = { ...newData, isClaimed: false, isClaimable: true }
                    bidEvents[bidStatusLC].push({
                        ...res,
                        secondaryMarketData: newData,
                        bidStatus
                    })
                }

            } else if (convert_WEI_ETH(bid) > convert_WEI_ETH(bidEvents[found_key][found_index].bid)) {
                bidEvents[found_key][found_index].amount = bid
            }
        }

        return bidEvents
    } catch (error) {
        console.log("! ! ! ! ! ! !", error)
        return error.stack
    }
}


export const getMarketData = async (_nftContract, _itemId, marketInstance = null) => {
    // try {

    //     let instance = marketInstance !== null ? marketInstance : await marketInterface();
    //     // isBaseType(_tokenId) // use currentsupply to get all data
    //     const marketItem = await instance.methods.idToMarketItem(_itemId).call();
    //     let result = await instance.getPastEvents('ItemSold', { filter: { nftContract: _nftContract, tokenId: _tokenId }, fromBlock: 1, toBlock: 'latest' })
    //     let result = await instance.getPastEvents('ItemSold', { filter: { nftContract: _nftContract, tokenId: _tokenId }, fromBlock: 1, toBlock: 'latest' })
    //     return result
    // }
    // catch (error) {
    //     //console.log(error);
    // }
}


// export const getItemDataWithoutBids = async (instance, _baseType) => {
//     try {
//         let step = await instance.methods.auctionStep().call(); //isSaleLive //salesOpenTime
//         let data = await instance.methods.baseTypeToData(_baseType).call(); //salesOpenTime
//         let uri = await instance.methods.uri(_baseType).call()
//         let baseType = _baseType;
//         let isAuction = data.isAuction;
//         let supply = Number(data.maxSupply);
//         let currentSupply = Number(data.currentSupply);
//         let price = data.price;
//         let bids = [];
//         let auctionHigh = 0;
//         let smallBid = 0;
//         let indexSmall = 0;
//         if (isAuction)
//             for (let i = 1; i <= supply; i++) {
//                 const trueId = getNonFungibleTokenIdFromBaseType(baseType, i)
//                 let bid = await instance.methods
//                     .tokenId_bids(trueId)
//                     .call();
//                 if (auctionHigh === 0) {
//                     auctionHigh = bid;
//                     smallBid = bid;
//                     indexSmall = i;
//                 } else if (Number(bid) > Number(auctionHigh)) {
//                     auctionHigh = bid;
//                 } else if (Number(bid) < Number(smallBid)) {
//                     smallBid = bid;
//                     indexSmall = i;
//                 }
//                 bids.push(bid);
//             }

//         return {
//             baseType,
//             isAuction,
//             currentSupply,
//             supply,
//             price,
//             step,
//             uri,
//             bids,
//             auctionHigh,
//             smallBid
//         };
//     } catch (error) {
//         //console.log(error);
//     }
// };
export const getBidsForWalletAddressAllCollections = async (filter = null) => {
    try {
        if (!Object.values(VALID_BID_FILTERS).includes(filter))
            throw new Error('Invalid Filter')
        const collections = await getDeployedCollections()
        const bids = {
            winning: [],
            won: [],
            outbid: []
        }
        if (collections !== null)
            for (let i = 0; i < collections.length; i++) {
                const collection = collections[i];
                const res = await getBidsForWalletAddress(collection, bids, filter)
                Object.keys(res).forEach(key => {
                    if (res[key].length)
                        bids[key].push(...res[key])
                })
            }
        return bids

    } catch (error) {

    }
}

export const getMarketplaceItemAuctionData = async (_instance = null, itemId) => {
    let instance = _instance !== null ? _instance : await marketInterface();
    let step = await instance.methods.auctionStep().call(); //isSaleLive //salesOpenTime
    const auctionDetails = await instance.methods.itemIdToAuctionData(itemId).call()

}

export const itemFitsConstraints = (item, constraints) => Object.entries(constraints).reduce((a, b) => {
    return b[1] !== item[b[0]] ? false : a
}, true)

// fetchItemDetails = fetch the full item details
// filterObject - return only certain item details

const filterMarketplaceItems = async (instance, filterObject) => {
    if (filterObject === null)
        return await instance.methods.fetchMarketItems().call()
    else if (Object.keys(filterObject)[0].toUpperCase() === 'ARTIST')
        return await instance.methods.fetchMarketItemsByArtist(filterObject.artist).call()
    else if (Object.keys(filterObject)[0].toUpperCase() === 'COLLECTION')
        return await instance.methods.fetchMarketItemsByCollection(filterObject.collection).call()
    else if (Object.keys(filterObject)[0].toUpperCase() === 'TOKENID')
        return await instance.methods.fetchMarketItemsByTokenId(filterObject.nftContract, filterObject.tokenId).call()
    else
        return await instance.methods.fetchMarketItems().call()
}

const addedViaArtistFilter = (itemArray, filter) => {
    if (Object.keys(filter)[0].toUpperCase() !== 'COLLECTION')
        return false
    else return itemArray.findIndex(it => it.includes(filter.collection)) !== -1
}

export const getPopularArtworks = async () => {
    try {
        const artworks = await API.getMostPopularArtworks()
        const filterOb = artworks.map(artwork => ({ tokenId: getNonFungibleTokenIdFromBaseType(artwork.base_type, 0), nftContract: artwork.contract_address }))
        const marketplaceItems = await getMarketplaceItems(1, 4, true, null, true, filterOb)
        //console.log("marketplace items: ", marketplaceItems)
        return marketplaceItems
    } catch (error) {
        //console.log(error)
        return error.stack
    }
} //

export const getRandomArtwork = async () => {
    try {
        const artwork = await API.getRandomArtwork()
        const filterOb = { tokenId: getNonFungibleTokenIdFromBaseType(artwork.base_type, 0), nftContract: artwork.contract_address }
        const marketplaceItems = await getMarketplaceItems(1, 1, true, null, true, filterOb)
        //console.log("marketplace items: ", marketplaceItems)
        return marketplaceItems
    } catch (error) {
        //console.log(error)
        return error.stack
    }
}

export const getPriceForSort = (item) => {
    if (item.isAuction && item.auctionData && Number(item.auctionData.highestBid) > 0)
        return Number(item.auctionData.highestBid)
    else
        return Number(item.price)
}

export const sortItemArray = (itemArray, sortBy = null, fetchAtEnd = false) => {
    //console.log("ITEM ARRAY", itemArray)
    if (sortBy === null)
        return itemArray
    else if (sortBy === 'HighLow')
        return itemArray.sort((a, b) => { return getPriceForSort(b) - getPriceForSort(a) })
    else if (sortBy === 'LowHigh')
        return itemArray.sort((a, b) => { return getPriceForSort(a) - getPriceForSort(b) })
    else if (sortBy === 'Newest')
        return itemArray.reverse()
    else
        return itemArray
}

export const getMarketplaceItems = async (
    page = 1, numResToServe = 50, shouldSlice = true, saleTypeConstraint = null,
    fetchItemDetails = true, filterObject = [], sortBy = null, getItemTotal = false, sliceAtEnd = false, fetchAtEnd = false) => { //VALID_MARKET_ITEM_FILTER_CONSTRAINTS
    console.log("sortby", sortBy)
    try {
        let instance = await marketInterface();
        let itemArray = []
        let itemTotal = 0
        let constraints;
        let result = []

        if (!filterObject.length) {
            const filteredItems = await filterMarketplaceItems(instance, null)
            itemTotal = filteredItems.length
            itemArray.push(...filteredItems)
        }
        else {
            let sorted = filterObject.sort((a, b) => { return Object.keys(a)[0].toLowerCase().localeCompare(Object.keys(b)[0].toLowerCase()) })
            for (let filterIndex = 0; filterIndex < sorted.length; filterIndex++) {
                const filter = sorted[filterIndex];
                // if (Object.keys(filter)[0].toUpperCase() !== 'COLLECTION' ||
                //     (Object.keys(filter)[0].toUpperCase() === 'COLLECTION'
                //         && itemArray.findIndex(it => it.includes(filter.collection)) === -1))
                if (!addedViaArtistFilter(itemArray, filter)) {
                    const filteredItems = await filterMarketplaceItems(instance, filter)
                    itemArray.push(...filteredItems)
                }
            }
        }
        //itemArray = await filterMarketplaceItems(instance, filterObject)//instance.methods.fetchMarketItems().call()
        if (itemArray.length > 0) {
            if (sortBy !== 'HighLow' && sortBy !== 'LowHigh')
                itemArray = sortItemArray(itemArray, sortBy)
            if ((shouldSlice && !sliceAtEnd) && page > 0 && numResToServe > 0 && (sortBy !== 'HighLow' && sortBy !== 'LowHigh')) {// CHECK
                itemTotal = itemArray.length
                itemArray = itemArray.slice((page - 1) * numResToServe, (page - 1) * numResToServe + numResToServe)
            }
            for (let i = 0; i < itemArray.length; i++) {
                const item = itemArray[i]
                let shouldAdd = true
                let auctionData;
                if (saleTypeConstraint !== null) {
                    constraints = getValidConstraints(VALID_MARKET_ITEM_FILTER_CONSTRAINTS, saleTypeConstraint)
                    if (Object.keys(constraints).length > 0) {
                        shouldAdd = itemFitsConstraints(item, constraints)
                    }
                    constraints = getValidConstraints(VALID_AUCTION_ITEM_FILTER_CONSTRAINTS, saleTypeConstraint)

                    if (Object.keys(constraints).length > 0 && item.isAuction && shouldAdd) {
                        auctionData = await instance.methods.itemIdToAuctionData(item.itemId).call();
                        if (Object.keys(constraints).includes('salesCloseTime'))
                            shouldAdd = dayjs().unix() < auctionData.salesCloseTime
                    }
                }
                if (shouldAdd) {
                    if (fetchItemDetails && !fetchAtEnd) {
                        const res = await getMarketplaceItem(item.itemId, null, null, instance, item)
                        result.push(res)
                    } else {
                        result.push(item.isAuction ? { ...item, auctionData } : item)
                    }
                }
            }
            if (sortBy === 'HighLow' || sortBy === 'LowHigh') {
                console.log("RESULT", result)
                result = sortItemArray(result, sortBy, fetchAtEnd)
            }
            console.log(sliceAtEnd, sortBy, numResToServe, page, "X")
            if (((shouldSlice || sliceAtEnd) && page > 0 && numResToServe > 0) || ((shouldSlice && sortBy === 'HighLow' || sortBy === 'LowHigh') && page > 0 && numResToServe > 0)) {
                itemTotal = result.length
                result = result.slice((page - 1) * numResToServe, (page - 1) * numResToServe + numResToServe)
                if (fetchAtEnd) {
                    for (let index = 0; index < result.length; index++) {
                        const item = result[index];
                        const res = await getMarketplaceItem(item.itemId, null, null, instance, item)
                        result[index] = res
                    }
                    // result = result.slice(0, newResult.length)
                }
            }
        }
        console.log("result", result)
        return !getItemTotal ? result : { artworks: result, itemTotal }
    } catch (error) {
        console.log(error);
        return error
    }
};
export const getMarketplaceItemsByArtist = async (artist_address, page = 1, numResToServe = 50, saleTypeConstraint = null, fetchItemDetails = true) => {
    // try {
    //     let instance = await marketInterface();
    //     let itemArray = await instance.methods.fetchMarketItemsByArtist(artist_address).call()
    //     if (itemArray.length > 0) {
    //         if (page > 0 && numResToServe > 0)
    //             result = result.slice((page - 1) * numResToServe, (page - 1) * numResToServe + numResToServe)
    //         for (let i = 0; i < result.length; i++) {
    //             const item = result[i]
    //             //const data = await instance.methods.MarketItemCreated(i).call();
    //             const data = await getItemDataTokenId(item.nftContract, item.tokenId) //primary
    //             result[i] = { ...data, ...result[i] }
    //             //let collectionInstance = await NFTinterface(result[i].nftContract)
    //             //const items = await getCollectionItemsAndData(result[i].nftContract)
    //         }
    //     }
    //     return result
    // } catch (error) {
    //     //console.log(error);
    //     return error
    // }
};
export const getMarketplaceItem = async (_itemId = null, _nftContract = null, _tokenId = null, _instance = null, item = null) => {
    try {
        let instance = _instance !== null ? _instance : await marketInterface();

        if (_itemId !== null) {

            const marketItem = item !== null ? item : await instance.methods.fetchMarketItem(_itemId).call();
            const { nftContract, tokenId, artist } = marketItem
            const data = await getItemDataTokenId(nftContract, tokenId)
            const pricing = await getItemPricingData(instance, marketItem, SECONDARY_MARKET, null, null, marketItem.itemId)
            const secondaryMarketData = new SecondaryMarketData({ ...marketItem, ...pricing })

            return { ...data, nftContract, tokenId, artist, secondaryMarketData }

        } else {

            const itemHistoryExists = await instance.methods.fetchMarketItemsByTokenId(_nftContract, _tokenId).call()
            const data = await getItemDataTokenId(_nftContract, _tokenId)

            if (itemHistoryExists.length) {
                const mostRecent = itemHistoryExists.slice(-1)[0]
                const { nftContract, tokenId, artist } = mostRecent
                const pricing = await getItemPricingData(instance, mostRecent, SECONDARY_MARKET, null, null, mostRecent.itemId)
                const secondaryMarketData = new SecondaryMarketData({ ...mostRecent, ...pricing })

                return { ...data, nftContract, tokenId, artist, secondaryMarketData }

            } else {

                return data
            }
        }

    } catch (error) {
        //console.log(error);
        return error
    }
}
export const getMarketplaceItemsForSaleByUser = async (wallet_address, sale_type_constraint = null) => { // sale_type_constraint: { isAuction: true } || {isAuction: false}
    try {
        let instance = await marketInterface();
        let marketItems = await instance.methods.fetchMarketItems().call()
        const result = []
        if (marketItems.length > 0) {
            for (let i = 0; i < marketItems.length; i++) {
                const item = marketItems[i]
                if (item.seller.toUpperCase() === wallet_address.toUpperCase()) {

                    // const data = await getItemDataTokenId(item.nftContract, item.tokenId) //primary
                    // const pricing = await getItemPricingData(instance, item, SECONDARY_MARKET, null, null, item.itemId)

                    const data = await getMarketplaceItem(item.itemId, item.nftContract, item.tokenId, null, item)
                    //return { ...data, nftContract, tokenId, artist, secondaryMarketData }
                    // if (item.isAuction) {
                    //     result.push({ ...data, ...marketItems[i] })
                    // }
                    // else {
                    //     const { activeOffers, highestOffer, lowestOffer } = await getEditionOfferEvents(item.itemId)
                    //     result.push({ ...data, ...marketItems[i], activeOffers, highestOffer, lowestOffer })
                    // }
                    result.push(data)
                }
            }
        }
        return result
    } catch (error) {
        //console.log(error);
        return error
    }
};



export const getMarketplaceItemsByCollection = async (collectionContract, page = 1, numResToServe = 50) => {
    try {
        let instance = await marketInterface();
        let result = await instance.methods.fetchMarketItemsByCollection(collectionContract).call()
        if (result.length > 0) {
            result = result.slice((page - 1) * numResToServe, (page - 1) * numResToServe + numResToServe)
            for (let i = 0; i < result.length; i++) {
                const item = result[i]
                //const data = await instance.methods.MarketItemCreated(i).call();
                const data = await getItemDataTokenId(item.nftContract, item.tokenId) //primary
                result[i] = { ...data, ...result[i] }
                //let collectionInstance = await NFTinterface(result[i].nftContract)
                //const items = await getCollectionItemsAndData(result[i].nftContract)
            }
        }
        return result
    } catch (error) {
        //console.log(error);
        return error
    }
};

export const getMarketplaceItemHighsAndLows = async (_nftContract, _tokenId) => {
    const _baseType = getNonFungibleBaseType(_tokenId)
    let highestBidAllEditions = 0;
    let lowestAskAllEditions = 0;
    try {
        let instance = await marketInterface();
        let result = await instance.methods.fetchMarketItems().call()
        if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
                const item = result[i]
                if (item.nftContract === _nftContract && getNonFungibleBaseType(item.tokenId) === _baseType) {
                    if (item.isAuction) {
                        const auctionData = await instance.methods.itemIdToAuctionData(item.itemId).call();
                        highestBidAllEditions = auctionData.highestBid > highestBidAllEditions ? auctionData.highestBid : highestBidAllEditions;
                    } else {
                        lowestAskAllEditions = item.price < lowestAskAllEditions || lowestAskAllEditions === 0 ? item.price : lowestAskAllEditions;
                    }
                }
            }
        }
        return {
            highestBidAllEditions: highestBidAllEditions !== 0 ? web3.utils.fromWei(web3.utils.toBN(highestBidAllEditions)) + 'Ξ' : '-',
            lowestAskAllEditions: lowestAskAllEditions !== 0 ? web3.utils.fromWei(web3.utils.toBN(lowestAskAllEditions)) + 'Ξ' : '-'
        }

    } catch (error) {
        //console.log(error);
        return error
    }
}

export const getEditionOfferEvents = async (_itemId) => {

    try {
        let instance = await marketInterface();
        const filter = { itemId: _itemId }
        const offersMade = await instance.getPastEvents('NewOffer', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersRevoked = await instance.getPastEvents('OfferRevoked', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersRevokedOfferId = offersRevoked.map(it => it.returnValues.offerId)
        let highestOffer = 0
        let lowestOffer = 0
        const currentWalletAddress = web3.utils.toChecksumAddress(window.ethereum.selectedAddress)
        let myOffer = {}
        const activeOffers = offersMade.reduce((a, b) => {
            let found = offersRevokedOfferId.findIndex(c => c === b.returnValues.offerId)
            if (found === -1) {
                const { itemId, offerId, offerer, price, time } = b.returnValues
                a.push({
                    itemId,
                    offerId,
                    offerer,
                    price: roundDataWei(price) + 'Ξ',
                    time
                })
                if (offerer === currentWalletAddress) {
                    myOffer = {
                        itemId,
                        offerId,
                        offerer,
                        price: roundDataWei(price) + 'Ξ',
                        time
                    }
                }
                if (roundDataWei(price) > highestOffer)
                    highestOffer = roundDataWei(price)
                if (lowestOffer === 0 || roundDataWei(price) < lowestOffer)
                    lowestOffer = roundDataWei(price)
            }
            return a
        }, [])
        return { activeOffers, highestOffer, lowestOffer, myOffer }
    } catch (error) {
        //console.log(error)
    }


}

export const getMyOffersMade = async (_walletAddress) => {
    try {
        let instance = await marketInterface();
        const checkSummedWalletAddress = web3.utils.toChecksumAddress(_walletAddress)
        const filter = { offerer: checkSummedWalletAddress }
        let offersMade = await instance.getPastEvents('NewOffer', { filter, fromBlock: 1, toBlock: 'latest' })
        let offersMadeNF = await instance.getPastEvents('NewOffer', { filter: {}, fromBlock: 1, toBlock: 'latest' })
        const offersRevoked = await instance.getPastEvents('OfferRevoked', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersRevokedOfferId = offersRevoked.map(it => ({ offerId: it.returnValues.offerId, itemId: it.returnValues.itemId }))
        console.log("offersMade", offersMade, offersMadeNF, checkSummedWalletAddress)
        offersMade = offersMade.reduce((a, b) => {
            let found = offersRevokedOfferId.findIndex(c => c.offerId === b.returnValues.offerId && c.itemId === b.returnValues.itemId)
            if (found === -1) {
                const { itemId, offerId, offerer, price, time } = b.returnValues
                a.push({
                    itemId,
                    offerId,
                    offerer,
                    price: roundDataWei(price) + 'Ξ',
                    offer: roundDataWei(price) + 'Ξ',
                    time
                })
            }
            return a
        }, [])
        const activeOffers = []
        for (let index = 0; index < offersMade.length; index++) {
            const element = offersMade[index];
            const { itemId } = element
            let result = await instance.methods.fetchMarketItem(itemId).call()
            if (/^0x0+$/.test(result.owner)) { // test if unsold
                // const data = await getItemDataTokenId(result.nftContract, result.tokenId)
                const data = await getMarketplaceItem(itemId, result.nftContract, result.tokenId, instance, result)
                activeOffers.push({ ...element, ...data, ...result })
            }
        }
        return activeOffers
    } catch (error) {
        //console.log(error)
    }
}

export const getMyOffersExpired = async (_walletAddress) => {
    try {
        let instance = await marketInterface();
        const checkSummedWalletAddress = web3.utils.toChecksumAddress(_walletAddress)
        const filter = { offerer: checkSummedWalletAddress }
        let offersMade = await instance.getPastEvents('NewOffer', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersRevoked = await instance.getPastEvents('OfferRevoked', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersRevokedOfferId = offersRevoked.map(it => ({ offerId: it.returnValues.offerId, itemId: it.returnValues.itemId }))
        // const myItemsForSale = getMarketplaceItemsForSaleByUser
        offersMade = offersMade.reduce((a, b) => {
            let found = offersRevokedOfferId.findIndex(c => c.offerId === b.returnValues.offerId && c.itemId === b.returnValues.itemId)
            if (found === -1) {
                const { itemId, offerId, offerer, price, time } = b.returnValues
                a.push({
                    itemId,
                    offerId,
                    offerer,
                    price: roundDataWei(price) + 'Ξ',
                    time
                })
            }
            return a
        }, [])
        const expOffers = []
        for (let index = 0; index < offersMade.length; index++) {
            const element = offersMade[index];
            const { itemId } = element
            let result = await instance.methods.fetchMarketItem(itemId).call()
            if (!(/^0x0+$/.test(result.owner)) && result.owner.toLowerCase() !== _walletAddress.toLowerCase() && !result.isRevoked) { // test
                const data = await getItemDataTokenId(result.nftContract, result.tokenId)
                expOffers.push({ ...element, ...data, ...result })
            }
        }
        return expOffers
    } catch (error) {
        //console.log(error)
    }
}

export const getEditionEvents = async (_nftContract, _tokenId) => {
    try {

        let instance = await NFTinterface(_nftContract)
        const filter1 = { tokenId: _tokenId }
        const initialSale = await instance.getPastEvents('itemSold', { filter: filter1, fromBlock: 1, toBlock: 'latest' })
        const filter = { nftContract: _nftContract, tokenId: _tokenId }
        instance = await marketInterface();
        const marketItemsCreated = await instance.getPastEvents('MarketItemCreated', { filter, fromBlock: 1, toBlock: 'latest' })
        const marketItemsSold = await instance.getPastEvents('ItemSold', { filter, fromBlock: 1, toBlock: 'latest' })
        const offersAccepted = await instance.getPastEvents('OfferAccepted', { filter, fromBlock: 1, toBlock: 'latest' })
        // return result
        return [...initialSale, ...marketItemsCreated, ...marketItemsSold, ...offersAccepted].sort((a, b) => parseInt(a.time) - parseInt(b.time))
    } catch (error) {
        //console.log(error)
    }


}




// export const getCollectionItemsAndData2 = async (_address, _instance = null) => {
//     let instance = _instance !== null ? _instance : await NFTinterface(_address);
//     let items = await instance.methods.collectionItems().call();
//     const result = [];
//     for (let baseType = 1; baseType <= items; baseType++) {
//         const tokenId = getNonFungibleTokenIdFromBaseType(baseType, 0)
//     }
// }

export const getItemDataTokenId = async (_address, _tokenId, _instance = null) => {
    try {

        const _baseType = getNonFungibleBaseType(_tokenId)
        const edition = getNonFungibleIndex(_tokenId)
        const tokenId = _tokenId;//getNonFungibleTokenIdFromBaseType(baseType, 0); // no edition because base token id
        const data = await getItemData(_address, _baseType, edition)

        const result = {
            ...data,
            edition,
            tokenId
        }


        return {
            ...data,
            edition,
            tokenId
        }
    } catch (error) {
        //console.log(error);
    }

}

export const getItemPricingData = async (instance, data, MARKET_DATA_TYPE, _baseType = 1, supply = 1, _itemId, _salesOpenTime = null, _salesCloseTime = null, _step = null) => {
    let salesOpenTime = _salesOpenTime !== null ? _salesOpenTime : null
    let salesCloseTime = _salesCloseTime !== null ? _salesCloseTime : null

    if (MARKET_DATA_TYPE === PRIMARY_MARKET && salesOpenTime === null) {
        salesOpenTime = await instance.methods.salesOpenTime().call();
        salesCloseTime = await instance.methods.salesCloseTime().call();
    }
    let step = _step !== null ? _step : await instance.methods.auctionStep().call(); //isSaleLive //salesOpenTime
    let isAuction = data.isAuction;
    let price = data.price;

    let pricingData = {
        auctionHigh: 0
    }

    if (isAuction) {

        if (MARKET_DATA_TYPE === PRIMARY_MARKET) {
            pricingData =
            {
                ...pricingData,
                auctionLow: 0,
                bids: []
            }
            for (let i = 1; i <= supply; i++) {
                const trueId = getNonFungibleTokenIdFromBaseType(_baseType, i)
                let bid = await instance.methods
                    .tokenId_bids(trueId)
                    .call();
                if (pricingData.auctionHigh === 0) {
                    pricingData.auctionHigh = bid;
                    pricingData.auctionLow = bid;
                } else if (Number(bid) > Number(pricingData.auctionHigh)) {
                    pricingData.auctionHigh = bid;
                } else if (Number(bid) < Number(pricingData.auctionLow)) {
                    pricingData.auctionLow = bid;
                }
                pricingData.bids.push(bid);
            }
        } else if (MARKET_DATA_TYPE === SECONDARY_MARKET) {
            const auctionData = await instance.methods.itemIdToAuctionData(_itemId).call()
            pricingData =
            {
                salesOpenTime: auctionData.salesOpenTime,
                salesCloseTime: auctionData.salesCloseTime,
                auctionHigh: auctionData.highestBid,
                highestBidder: auctionData.highestBidder
            }
            price = Number(auctionData.highestBid) !== 0 ? Number(auctionData.highestBid) : price// check
        }
    } else if (MARKET_DATA_TYPE === SECONDARY_MARKET) {
        const editionOfferEvents = await getEditionOfferEvents(_itemId)

        pricingData = {
            ...editionOfferEvents
        }
    }
    return {
        salesOpenTime,
        salesCloseTime,
        step,
        isAuction,
        price,
        ...pricingData
    }

}

export const fetchURIandJSONData = async (_address, baseType, _instance = null) => {
    try {
        let instance = _instance !== null ? _instance : await NFTinterface(_address)
        const uri = await instance.methods.uri(baseType).call()
        const jsonData = await API.fetchJSON(uri)
        return { uri, jsonData }
    } catch (error) {

    }
}

export const getItemData = async (_address, _baseType, _edition = 0, _instance = null,
    _salesOpenTime = null, _salesCloseTime = null, _step = null, _json = null, _uri = null) => {
    try {
        const contractAddress = _address;
        let baseType = _baseType;
        let instance = _instance !== null ? _instance : await NFTinterface(_address);
        let data = await instance.methods.baseTypeToData(baseType).call(); //salesOpenTime
        let owner;
        const uri = _uri !== null ? _uri : await instance.methods.uri(baseType).call()
        const jsonData = _json !== null ? _json : await API.fetchJSON(uri)
        let baseTypeId = data.baseTypeId //const tokenId = getNonFungibleTokenIdFromBaseType(baseType, 0);
        let tokenId;
        let edition;
        if (_edition !== 0) {
            tokenId = getNonFungibleTokenIdFromBaseType(baseType, _edition); // no edition because base token id
            edition = _edition
            owner = await instance.methods.ownerOf(tokenId).call()
        }

        // let isAuction = data.isAuction;

        const supply = Number(data.maxSupply);
        const maxSupply = Number(data.maxSupply);
        const currentSupply = Number(data.currentSupply);

        // let price = data.price;

        let primaryMarketData = await getItemPricingData(instance, data, PRIMARY_MARKET, _baseType, supply)
        primaryMarketData = new PrimaryMarketData(primaryMarketData)

        return {
            ...jsonData,
            contractAddress,
            baseType,
            baseTypeId,
            tokenId, // maybe not
            edition,
            owner,
            uri,
            supply,
            maxSupply,
            currentSupply,
            primaryMarketData
        };
    } catch (error) {
        //console.log(error);
    }
};

export const getBidsForWalletAddress = async (_collectionAddress, bidsArray = null, filter = null) => {
    try {
        if (!Object.values(VALID_BID_FILTERS).includes(filter))
            throw new Error('Invalid Filter')
        let instance = await NFTinterface(_collectionAddress); //tokenId_bidders
        let salesOpenTime = await instance.methods.salesOpenTime().call();
        let salesCloseTime = await instance.methods.salesCloseTime().call();
        let contractAddress = _collectionAddress
        let bids = {
            winning: [],
            won: [],
            outbid: [],
            isOpen: dayjs().unix() >= salesOpenTime && dayjs().unix() < salesCloseTime
        };
        const itemData = []
        const { isOpen } = bids
        const myBidEvents = await instance.getPastEvents('bidReceived', { filter: { bidder: web3.utils.toChecksumAddress(window.ethereum.selectedAddress) }, fromBlock: 1, toBlock: 'latest' })
        //checkSummedWalletAddress
        const myMaxBidEventsPerToken_OpenCollection = myBidEvents.reduce((a, b) => {
            let found = a.findIndex(c => c.tokenId === b.returnValues.tokenId)
            if (found === -1) {
                a.push(b.returnValues)
            } else if (parseInt(b.returnValues.amount) > parseInt(a[found].amount)) {
                a[found] = b.returnValues
            }
            return a
        }, [])


        const myAddress = window.ethereum.selectedAddress.toLowerCase()

        for (let i = 0; i < myMaxBidEventsPerToken_OpenCollection.length; i++) {
            const event = myMaxBidEventsPerToken_OpenCollection[i];
            const { tokenId, amount } = event
            const _baseType = getNonFungibleBaseType(tokenId)
            const edition = getNonFungibleIndex(tokenId)
            const dataPrevFetched = itemData.find(it => it.baseType === _baseType)
            let _itemData = {}
            if (!dataPrevFetched) {
                // _itemData = await getItemDataWithoutBids(instance, _baseType)
                _itemData = await getItemData(_collectionAddress, _baseType, edition, instance, salesOpenTime, salesCloseTime)
                itemData.push({ ..._itemData })
            } else {
                _itemData = dataPrevFetched // EDITION WILL BE INCORRECT?
            }
            const highestBidder = await instance.methods
                .tokenId_bidders(tokenId)
                .call();

            let auctionHigh = await instance.methods
                .tokenId_bids(tokenId)
                .call();
            const amWinningOrWinner = highestBidder.toLowerCase() === myAddress.toLowerCase()
            const bidStatus = getBidStatus(amWinningOrWinner, isOpen, filter)

            if (bidStatus !== undefined) {
                const bidStatusLC = bidStatus.toLowerCase()
                if (bidStatus === VALID_BID_FILTERS.BID_FILTER_WON) {
                    const userClaimed = await isClaimed(_collectionAddress, tokenId, instance)
                    if (!userClaimed)
                        bids[bidStatusLC].push({
                            ..._itemData,
                            primaryMarketData: {
                                ..._itemData.primaryMarketData,
                                isOpen,
                                bid: { amount, tokenId, auctionHigh },
                                bidStatus,
                                isClaimed: userClaimed,
                                isClaimable: !userClaimed
                            }
                        })
                } else
                    bids[bidStatusLC].push({
                        ..._itemData,
                        primaryMarketData: {
                            ..._itemData.primaryMarketData,
                            isOpen,
                            bid: { amount, tokenId, auctionHigh },
                            bidStatus
                        }
                    })
            }
        }

        return bids

    } catch (error) {
        //console.log(error);
    }
}
//auction wins
export const getNFTsClaimed = async (_address) => {
    try {
        let instance = await NFTinterface(_address);
        let event = await instance.getPastEvents('NFTclaimed', { filter: {}, fromBlock: 1, toBlock: 'latest' })
        return event

    } catch (error) {
        //console.log(error);
    }
}
export const getClaimedStatus = async (_address, _tokenId, _tokenEdition) => {
    try {
        let instance = await NFTinterface(_address);
        let result = await instance.methods.tokenId_edition_claimed(_tokenId, _tokenEdition).call()
        return result;
    } catch (error) {
        return "Error"
    }
}

export const getURI = async (_address, _tokenId) => {
    try {
        const bigAF = new BigNumber(_tokenId)
        let instance = await NFTinterface(_address);
        let result = await instance.methods.uri(_tokenId).call()
        return result;
    } catch (error) {
        //console.log(error);
    }
};

export const isClaimed = async (_address, _tokenId, _instance = null) => {
    try {
        let instance = _instance !== null ? _instance : await NFTinterface(_address);
        let result = await instance.methods.ownerOf(_tokenId).call()
        return result.toLowerCase() === window.ethereum.selectedAddress.toLowerCase();
    } catch (error) {
        //console.log(error);
    }

}

export const getCollectionOpenAndClose = async (_address) => {
    try {
        let instance = await NFTinterface(_address);
        let salesOpenTime = await instance.methods.salesOpenTime().call();
        let salesCloseTime = await instance.methods.salesCloseTime().call();
        return {
            salesOpenTime,
            salesCloseTime
        }
    } catch (error) {
        //console.log(error);
    }
};

export const getCollectionItems = async (_address) => {
    try {
        let instance = await NFTinterface(_address);
        let items = await instance.methods.collectionItems().call();

        //let b = (12n ** 122n) & (13n ** 133n);
        let itemsData = [];
        for (let i = 1; i <= items; i++) {
            //let data = await instance.methods.baseTypeToData(web3.utils.toBN(i) << 128).call(); //consider
            let data = await instance.methods.baseTypeToData(i).call();
            itemsData.push(data);
        }

        return itemsData;
    } catch (error) {
        //console.log(error);
    }
};

export const fetchLiveCollections = async () => {
    try {
        const c = await getDeployedCollections()
        const collections = [];
        if (c !== null)
            for (let i = 0; i < c.length; i++) {
                const collection = c[i];
                let instance = await NFTinterface(collection);
                let salesOpenTime = await instance.methods.salesOpenTime().call();
                let salesCloseTime = await instance.methods.salesCloseTime().call();
                const isOpen = dayjs().unix() >= salesOpenTime && dayjs().unix() < salesCloseTime
                if (isOpen) {
                    const itemsAndData = await getCollectionItemsAndData(collection)
                    const artistInfo = await API.fetchProfile(itemsAndData.artist_handle)
                    collections.push({
                        itemsAndData,
                        artistInfo
                    })
                }
            }
        return collections

    } catch (error) {

    }

}

export const fetchCollections_PendingAndPast = async () => {
    try {
        const c = await getDeployedCollections()
        const collections = [];
        if (c !== null)
            for (let i = 0; i < c.length; i++) {
                const collection = c[i];
                let instance = await NFTinterface(collection);
                let salesOpenTime = await instance.methods.salesOpenTime().call();
                let salesCloseTime = await instance.methods.salesCloseTime().call();
                const isOpen = dayjs().unix() >= salesOpenTime && dayjs().unix() < salesCloseTime
                if (!isOpen) {
                    const itemsAndData = await getCollectionItemsAndData(collection)
                    const artistInfo = await API.fetchProfile(itemsAndData.artist_handle)
                    collections.push({
                        itemsAndData,
                        artistInfo
                    })
                }
            }
        return collections

    } catch (error) {

    }

}

export const getCollectionItemsAndData = async (_address) => {
    try {

        let instance = await NFTinterface(_address);
        let step = await instance.methods.auctionStep().call(); //isSaleLive //salesOpenTime
        let salesOpenTime = await instance.methods.salesOpenTime().call();
        let salesCloseTime = await instance.methods.salesCloseTime().call();
        const contractAddress = _address;
        let items = await instance.methods.collectionItems().call();
        const result = [];

        for (let baseType = 1; baseType <= items; baseType++) {
            const itemData = await getItemData(_address, baseType, 0, instance, salesOpenTime, salesCloseTime, step)
            result.push({
                ...itemData
            })
        }

        const res = result.length > 0 ? {
            items: result,
            salesOpenTime,
            salesCloseTime,
            contractAddress,
            step,
            artist_handle: result[0].artist_handle
        } : {}
        return res
    } catch (error) {
        //console.log(error);
    }

}