import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
export const Wrapper =  styled.div`
position: relative;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const NavToDrop = styled.div`
all: unset;
cursor: pointer;
position: relative;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;
export const ItemWrapper = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
justify-content: center;
`;

export const CollectionArtworksHeaderBgd = styled.div`
color: ${ props => props.textColor ? props.textColor : 'var(--lightGrey)'};
position: relative;
background: #000000;
padding: 20px;

`;

export const CollectionArtworksHeader = styled.div`
text-transform: uppercase;
user-select: none;

height: 1.5rem;
width: 100%;
font-size: 1.5rem;
font-weight: 700;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 50px;
:before{
    content: '';
    position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid var(--lightGrey);
  background: black;
  width: 100%;
  transform: translateY(-50%);

}
`;