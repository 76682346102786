import styled from 'styled-components'

export const Wrapper = styled.div`
    overflow: hidden;
    width: 100vw;
    position: absolute;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100vh;
`;

export const Content = styled.div`
width: 90%;
height: 90%;
padding: 20px;
background: var(--grey);
position: relative;
max-width: 600px;
max-height: 500px;
display: grid;
grid-template-rows: min-content min-content min-content 1fr min-content;
grid-template-columns: 1fr;
grid-row-gap: 20px;
grid-template-areas: 'popupheader' 'listingsControls' 'listingsheader' 'listings' 'button';

`;

export const ButtonWrapper = styled.div`
grid-area: button;
`;

export const WindowHeader = styled.div`
    font-size: 15px;
    grid-area: popupheader;
    text-transform: uppercase;
    
`;

export const RowWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

export const CloseButtonWrapper = styled.div`
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
font-size: 10px;
user-select: none;
cursor: pointer;
border-radius: 50%;
border: 1px solid #ffffff;
`;

export const ListingsWrapperOuter = styled.div`
grid-area: listings;
width: 100%;
`;

export const ListingWrapper = styled.div`
display: grid;
align-items: center;
color: ${props => props.index? props.index % 2 === 0 ? '#ffffff': '#999999' :'#ffffff'};
font-size: 12px;
margin: 10px 0;
grid-column-gap: 10px;
user-select: none;
grid-template-columns: 25px 1fr 1fr 1fr;
grid-template-areas: 'checkbox edition price seller';
`;

export const CheckboxWrapper = styled.div`
    grid-area: checkbox;
`;

export const EditionWrapper = styled.div`
grid-area: edition;
`;

export const PriceWrapper = styled.div`
grid-area: price;
`;

export const SellerWrapper = styled.div`
grid-area: seller;
text-align: right;
`;


export const ListingsHeader = styled.div`
grid-area: listingsheader;
text-transform: uppercase;
font-size: 12px;
grid-column-gap: 10px;
display: grid;
grid-template-columns: 25px 1fr 1fr 1fr;

grid-template-areas: '. edition price seller';
`;

export const ListingsHeaderText = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
text-align: ${props => props.align ? props.align : 'left'};
`;

export const ListingsControl = styled.div`
grid-area: listingsControls;
display: flex;
justify-content: space-between;
`;

export const DropdownContainer = styled.div`
width: 50%;
display: flex;
flex-direction: column;
`;