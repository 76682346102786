import styled from "styled-components";

export const Wrapper = styled.div`
height: 100vh;
top: 60px;
position: relative;
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
align-items: center;
`;

export const ButtonWrapper = styled.div`
width: 100%;
max-width: 300px;
position: relative;
display: flex;
`;