import styled from 'styled-components';

export const Wrapper = styled.button`
width: ${props => props.width ? props.width : '100%'};
max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
display: flex;
align-items: center;
justify-content: center;
position: relative;
border: var(--buttonBorder);
border-radius: var(--buttonBorderRadius);
font-weight: var(--buttonFontWeight);
text-transform: uppercase;
font-size: var(--buttonFontSize);
letter-spacing: var(--buttonLetterSpacing);
background: transparent;
img {
    height: 12px;
    transform: ${props => props.direction === 'back' ? 'rotate(180deg)' : ''};
}
cursor:  ${ props => props.validContinue ? 'pointer' : 'not-allowed'};
opacity: ${ props => props.validContinue ? '1' : '.25'};

`;

export const InnerWrapper = styled.div`
    
`;