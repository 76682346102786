import styled from "styled-components";

export const Wrapper = styled.div`
width: var(--badgeSize);
cursor: pointer;
height: var(--badgeSize);

display: flex;
position: relative;
z-index: 1;
box-shadow: 
    5px 5px 10px 0 rgba(133, 120, 250,.5),
    -5px -5px 10px 0 rgba(255, 255, 255, 1),
    -5px -5px 5px 0 rgba(255, 255, 255, 1);
align-items: center;
justify-content: center;
border-radius: 50%;
position: relative;
background: linear-gradient(45deg,
    #E8E8FF,
     #FFF0FA 25%,
     #FFFBF5 47%,
     #FFFBF5 56%,
     #FFF2FB 65%,
     #F5F5FF 77%
     );

`;

export const OuterWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;



`;


export const Value = styled.div`
    margin-top: 10px;
    font-size: 12px;
font-family: Stoke;
user-select: none;
font-weight: var(--buttonFontWeight);
letter-spacing: var(--buttonLetterSpacing);
`;