import styled from "styled-components";

export const Text = styled.div`
font-size: 12px;
`;

export const ScrollWrapper = styled.div`
overflow: auto;
max-height: 200px;
border: 1px solid var(--lightestGrey);
position: relative;
`;

export const ScrollContent = styled.div`
height: 100%;
scroll-behavior: smooth;
`;

export const Bid = styled.div`
display: flex;
justify-content: space-between;
cursor: pointer;
padding: ${props => props.isHeader ? '10px' : '10px 20px'};
font-size: 12px;
:nth-of-type(2n){
    background: ${props => props.isCurrent ? 'var(--uiollyPurple)' : 'var(--lightestGrey)'};
}
background: ${props => props.isCurrent ? 'var(--uiollyPurple)' : ''};
`;

export const Header = styled(Bid)`
font-size: 10px;
text-transform: uppercase;
color: var(--secondary);
background: var(--background) !important;
`;

export const Row = styled.div`
display: grid;
grid-template-columns: 2fr 1fr;
grid-template-areas: 'label label''input min';
`;

export const MinBid = styled.div`
font-size: 12px;
background: var(--black);
white-space: nowrap;
display: flex;
align-items: center;
padding: 5px;
/* outline: 2px solid var(--secondary); */
outline-offset: -2px;
grid-area: min;
text-transform: uppercase;
color: var(--background);
`;

export const Label = styled.div`
font-size: var(--fontSmall);
text-transform: uppercase;
letter-spacing: 0.05rem;
grid-area: label;
font-weight: 700;
margin-bottom: 0.8rem;
`;

export const ETHConversionWrapper = styled.div`
    background: #000000;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 20px;
    color: #ffffff;
    user-select: none;
`;