import web3 from "../web3";

import ABI from "../abiRopsten/Marketplace.json";

export const marketplaceAddress = "0xf4A190D977EcF110Bde70D0A6c60f630aD891376"
const instance = async () => {
    try {
        return new web3.eth.Contract(ABI.abi, marketplaceAddress);
    } catch (error) {
        console.log(error);
    }
};

export default instance;